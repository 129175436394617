import distance from '@turf/distance'


export const distanceBetweenPoints = (point1, point2) =>
  Math.sqrt(Math.pow(point1.y - point2.y, 2) + Math.pow(point1.x- point2.x, 2))


export const toPointFeature = position => ({
  geometry: {
    coordinates: position,
    type: "Point"
  },
  propeties: {},
  type: "Feature"
})


export const distanceBetweenPositions = (position1, position2) =>
  distance(toPointFeature(position1), toPointFeature(position2))


export const allCoordsNotEmpty = (...coords) =>
  coords.every(coord => typeof coord !== 'undefined'
                        & coord !== null)
