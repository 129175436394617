/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import React from 'react'

import Item from './Item'


const Pan = ({ pan }) => {
  const { items, name } = pan

  return (
    <div className="pan">
      <div className="name">
        {name}
      </div>
      {items.filter(item => !item.isHidden).map((item) => (
        <Item
          item={item}
          key={item.name} 
        />
      ))}
    </div>
  )
}

Pan.propTypes = {
  pan: PropTypes.shape({
    items: PropTypes.array,
    name: PropTypes.string
  }).isRequired,
}

export default Pan
