import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { requestData } from 'redux-thunk-data'

import selectIsFeatureDisabledByName from 'redux/selectors/selectIsFeatureDisabledByName'

import DefaultWhenDisabled from './DefaultWhenDisabled'

const FeaturedRoute = ({
  componentWhenDisabled,
  featureName,
  renderWhenDisabled,
  ...routeProps
}) => {
  const dispatch = useDispatch()

  const areFeaturesLoaded = useSelector(state =>
    state.data.features ? true : false)

  const isRouteDisabled = useSelector(state => {
    if (!featureName) return false
    return selectIsFeatureDisabledByName(state, featureName)
  })
  const { path } = routeProps

  useEffect(() => {
    if (areFeaturesLoaded) {
      return
    }
    dispatch(requestData({ apiPath: '/features' }))
  }, [areFeaturesLoaded, dispatch])


  if (!areFeaturesLoaded) {
    return null
  }

  if (isRouteDisabled) {
    return (
      <Route
        component={componentWhenDisabled}
        path={path}
        render={renderWhenDisabled}
      />
    )
  }

  return <Route {...routeProps} />
}

FeaturedRoute.defaultProps = {
  componentWhenDisabled: DefaultWhenDisabled,
  featureName: null,
  renderWhenDisabled: null
}

FeaturedRoute.propTypes = {
  componentWhenDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  featureName: PropTypes.string,
  renderWhenDisabled: PropTypes.func,
}

export default FeaturedRoute