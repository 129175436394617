import classnames from 'classnames'
import L from 'leaflet'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Marker, Tooltip } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { selectCurrentUser } from 'with-react-redux-login'

import Icon from 'components/layout/Icon'
import eligibilityType from 'components/propTypes/eligibilityType'
import { ROOT_PATH } from 'utils/config'

import AddressEligible from '../views/AddressEligible'
import AddressNotEligible from '../views/AddressNotEligible'
import AddressOutsidePerimeter from '../views/AddressOutsidePerimeter'
import ParcelNotFound from '../views/ParcelNotFound'

const parcelIcon = new L.Icon({
  className: 'parcel-marker',
  iconAnchor: [0, 30],
  iconRetinaUrl: `${ROOT_PATH}/icons/ico-geoloc-solid2.svg`,
  iconSize: [21, 30],
  iconUrl: `${ROOT_PATH}/icons/ico-geoloc-solid2.svg`,
})
const parcelOffset = [0, -15]

const noParcelIcon = new L.divIcon({
  html: '<i class="no-parcel-marker las la-map-pin"></i>',
  iconAnchor: [0, 30],
  iconSize: [21, 30],
})
const noParcelOffset = [10, -37]

const CtaTooltip = ({
  eligibility,
  isFail,
  onCloseClick,
  onModifyClick,
  position,
}) => {
  const { address, parcel, availableProgrammes, programme: programmeEligibility } = eligibility || {}
  const { properties: addressProperties } = address || {}
  const { name: addressName, postalCode, townName } = addressProperties || {}
  const currentUser = useSelector(selectCurrentUser)

  const addressValue = `${addressName}, ${postalCode} ${townName}`

  const hasParcel = eligibility && parcel
  let hasAddressOutsideEligibility
  if (!isFail && address) {
    if (!(programmeEligibility && programmeEligibility.isCityEligible)) {
      hasAddressOutsideEligibility = true
    }
    hasAddressOutsideEligibility = false
  }
  let isAddressEligible = true
  if (programmeEligibility) {
    isAddressEligible = programmeEligibility.isAddressEligible
  }
  const needToClickOnParcel = isFail || (!hasParcel && !hasAddressOutsideEligibility)

  const title = useMemo(() => {
    if (needToClickOnParcel) {
        return 'Cliquez sur votre parcelle'
    }
    if (hasAddressOutsideEligibility) {
        return 'Adresse hors périmètre'
    }
    if (isAddressEligible) {
        return 'Adresse éligible'
    }
    if (programmeEligibility.code === 'eaux-et-vilaine'){
      return 'Éligibilité à vérifier'
    }
    return 'Adresse non éligible'
  }, [hasAddressOutsideEligibility, isAddressEligible, needToClickOnParcel, programmeEligibility])

  const ctaElement = useMemo(() => {
    if (needToClickOnParcel) {
     return (
       <ParcelNotFound
         eligibilityFormUrl={programmeEligibility && programmeEligibility.eligibilityInformation}
       />
     )
    }
    if (hasAddressOutsideEligibility) {
      return (
        <AddressOutsidePerimeter
          eligibilityFormUrl={programmeEligibility && programmeEligibility.eligibilityInformation}
        />
       )
    }
    if (isAddressEligible) {
        return (
          <AddressEligible
            address={address}
            availableProgrammes={availableProgrammes}
            currentUser={currentUser}
            parcel={parcel}
            programmeEligibility={programmeEligibility && programmeEligibility}
          />
        )
    }
    return (
      <AddressNotEligible programmeEligibility={programmeEligibility && programmeEligibility} />
    )
  }, [address, availableProgrammes, currentUser, hasAddressOutsideEligibility, isAddressEligible, needToClickOnParcel, parcel, programmeEligibility])

  return (
    <Marker
      icon={hasParcel
        ? parcelIcon
        : noParcelIcon}
      position={position}
    >
      <Tooltip
        className={classnames('cta-tooltip', {
          'click-parcel': needToClickOnParcel,
          'no-ppri': !isAddressEligible,
          'outside-eligibility': hasAddressOutsideEligibility,
        })}
        direction="top"
        offset={hasParcel
          ? parcelOffset
          : noParcelOffset}
        opatownName={1}
        permanent
        position={position}
      >
        <div className="over-arrow-shadow">
          <button
            className="close"
            onClick={onCloseClick}
            type="button"
          >
            <Icon name="la-times" />
          </button>
          <div className="hero">
            <div className="title">
              {title}
            </div>
            {addressName && (
              <div
                className="info-address"
              >
                {addressValue}
              </div>)}
            <NavLink
              className="reset"
              onClick={onModifyClick}
              to="/eligibilite"
            >
              Modifier
            </NavLink>
          </div>
          <div className="cta">
            {ctaElement}
          </div>
        </div>
      </Tooltip>
    </Marker>
  )
}

CtaTooltip.defaultProps = {
  eligibility: {},
  isFail: false,
  position: null
}

CtaTooltip.propTypes = {
  eligibility: eligibilityType,
  isFail: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  onModifyClick: PropTypes.func.isRequired,
  position: PropTypes.arrayOf(PropTypes.number)
}

export default CtaTooltip
