import React from 'react'

import Field from 'components/layout/form/Field'


const AssemblyDescription = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="assembly-description"
    >
      {'Descriptif du bâti I'}
    </h1>
    <Field name="propertyHasSingleLevel" />
    <Field name="buildingYear" />
    <Field name="outbuildingsCount" />
    <Field name="housingsCountOnParcel" />
  </div>
)

export default AssemblyDescription
