import { createSelector } from 'reselect'


export default createSelector(
  state => state.data.__activities__,
  (state, dossierId) => dossierId,
  (activities, dossierId) => (activities || []).filter(activity =>
    !activity.id
    && activity.localIdentifier
    && activity.localDossierId === dossierId
  ))
