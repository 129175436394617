import { useEffect, useState } from 'react'

import { LocalAsset } from 'redux/persistor'


export default (asset, config={}) => {
  const { assetsAreLocalWritten=true } = config
  const [localAsset, setLocalAsset] = useState()

  useEffect(() => {
    if (!asset || !assetsAreLocalWritten) return
    async function set() {
      const nextLocalAsset = new LocalAsset(asset.localIdentifier)
      nextLocalAsset.dataUrl = await nextLocalAsset.read()
      setLocalAsset(nextLocalAsset)
    }
    set()
  }, [asset, assetsAreLocalWritten, setLocalAsset])

  return localAsset
}
