import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import Annex from './Annex'
import AnnexesDoorFrames from './AnnexesDoorFrames'
import AnnexesVentilations from './AnnexesVentilations'
import Basement from './Basement'
import BuildingDescription from './BuildingDescription'
import Electricity from './Electricity'
import Evacuation from './Evacuation'
import ExternalArrangement from './ExternalArrangement'
import FlooringsAndSurveyorStudies from './FlooringsAndSurveyorStudies'
import Hazard from './Hazard'
import Heating from './Heating'
import PrincipalDoorFrames from './PrincipalDoorFrames'
import PrincipalVentilations from './PrincipalVentilations'
import RefugeSpace from './RefugeSpace'
import StructureAndInterior from './StructureAndInterior'


const Treatment = () => {
  const { dossierId } = useParams()
  const { outbuildingsCount } = useSelector(state =>
    selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}

  return (
    <>
      <BuildingDescription />
      <FlooringsAndSurveyorStudies />
      <Hazard />
      <Evacuation />
      <RefugeSpace />
      <Basement />
      <StructureAndInterior />
      <PrincipalDoorFrames />
      <PrincipalVentilations />
      <Electricity />
      <Heating />
      {Array(outbuildingsCount).fill()
                               .map((_, i) => i+1)
                               .map(i => (<Annex 
                                 key={i}
                                 number={i}
                                          />))}
      <AnnexesDoorFrames />
      <AnnexesVentilations />
      <ExternalArrangement />
    </>
  )
}

export default Treatment
