export default (node, {
  clickable,
  doubleClickable,
  onClick,
  onDoubleClick,
  uuid
}, config) => {
  const { isNodesListening, stage } = config
  node.on('click tap', event => {
    if (!isNodesListening) return
    if (stage.isDraggingNode || stage.isTextingNode) return
    setTimeout(() => {
      stage.hasAlreadyClicked = false
    }, 500)
    if (stage.hasAlreadyClicked) {
      doubleClickable && onDoubleClick(event, uuid)
      return
    }
    clickable && onClick(event, uuid)
    stage.hasAlreadyClicked = true
  })

  node.on('mouseover', event => {
    document.body.style.cursor = (!isNodesListening || stage.isTextingNode)
      ? 'default'
      : 'pointer'
  })
  node.on('mouseleave', event => {
    document.body.style.cursor = 'default'
  })

  if (node.labelizerNode) {
    node.labelizerNode.on('mouseover', event => {
      document.body.style.cursor = (!isNodesListening || stage.isTextingNode)
        ? 'default'
        : 'pointer'
    })
    node.labelizerNode.on('mouseleave', event => {
      document.body.style.cursor = 'default'
    })
  }
}
