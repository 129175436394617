import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'


import CheckboxesField from 'components/layout/form/fields/CheckboxesField'
import CheckboxField from 'components/layout/form/fields/CheckboxField'
import NumberField from 'components/layout/form/fields/NumberField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import selectFieldByName from 'redux/selectors/selectFieldByName'
import {yesOrNoOptions} from 'utils/form'

const FIELD_COMPONENTS_BY_NAME = {
  CheckboxField,
  CheckboxesField,
  NumberField,
  RadiosField,
  SelectField,
  TextField,
  TextareaField
}

export const YES_OR_NO_OPTIONS = yesOrNoOptions

const NUMBER_SQLA_TYPES = [
  'BIGINT',
  'DOUBLE PRECISION',
  'DOUBLE_PRECISION',
  'INTEGER',
  'NUMERIC',
]

const fieldTypeFrom = sqlaType => {
  for (let number_sqla_type of NUMBER_SQLA_TYPES) {
    if (sqlaType.startsWith(number_sqla_type)) {
        return 'number'
    }
  }
  if (sqlaType === 'BOOLEAN') {
    return 'radios'
  }
  return 'text'
}

const fieldMaxLengthFrom = sqlaType => {
  if (sqlaType.startsWith('VARCHAR(')) {
    return parseInt(sqlaType.split('(')[1].slice(0, -1))
  }
  return undefined
}


const Field = props => {
  const { prefixPath, name } = props

  const field = useSelector(state =>
    selectFieldByName(state,
                      `${prefixPath||''}${name}`,
                      [prefixPath, name]))

  if (!field) return null

  const { label, placeholder, sqlaType, ui, unit, values } = field

  const fieldType = fieldTypeFrom(sqlaType)
  
  const fieldProps = {
    label: `${label}\u00A0:`,
    lang: 'fr-FR',
    maxLength: fieldMaxLengthFrom(sqlaType),
    name,
    options: values && Object.entries(values).map(entry => ({
      label: entry[1],
      value: fieldType === 'number'
              ? parseInt(entry[0])
              : entry[0],
    })),
    placeholder,
    type: fieldType,
    ...props
  }


  if (typeof fieldProps.prefixPath !== 'undefined') {
    delete fieldProps.prefixPath
  }

  if (unit) {
    fieldProps.unitLabel = unit
  }

  if (sqlaType === 'BOOLEAN') {
    fieldProps.isBoolean = true
    fieldProps.options = YES_OR_NO_OPTIONS
  }

  let uiType = ui
  if (!uiType && values) {
    uiType = 'select'
  }
  if (!uiType) {
    uiType = fieldType
  }

  const fieldComponentName = `${uiType[0].toUpperCase()}${uiType.slice(1)}Field`
  const FieldComponent = FIELD_COMPONENTS_BY_NAME[fieldComponentName]

  if (!FieldComponent) {
    console.warn(`This ${name} field wanted a ui ${fieldComponentName} but it does not exist.`)
    return null
  }

  if (uiType === 'select' && values.length === 0) {
    console.warn(`This ${name} field should be a select but has no options.`)
    return null
  }

  return <FieldComponent {...fieldProps} />
}


Field.defaultProps = {
  prefixPath: null,
}


Field.propTypes = {
  name: PropTypes.string.isRequired,
  prefixPath: PropTypes.string,
}

export default Field
