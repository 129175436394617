import React from 'react'

import svgsByName from 'utils/svgs'


const Palette = () => (
  <div className="palette">
    {Object.keys(svgsByName).map(name => {
      const SVGComponent = svgsByName[name]
      return (
        <SVGComponent
          id={name}
          key={name}
        />
      )
    })}
  </div>
)

export default Palette
