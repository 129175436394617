import PropTypes from 'prop-types'
import React from 'react'
import { GeoJSON } from 'react-leaflet'

import {
    heavyBuildingPolygonStyle,
    lightBuildingPolygonStyle,
    parcelPolygonBorder,
    parcelPolygonFill,
} from 'utils/diagnostic'


const MainParcelTile = ({ dossierParcels }) => {
    if (dossierParcels.length === 0) return

    return (
      <>
        {dossierParcels.map(dossierParcel => (
          <GeoJSON
            data={dossierParcel.parcel.feature}
            key={`${dossierParcel.parcel.id}-fill`}
            style={parcelPolygonFill}
            zIndex={1}
          />
            ))}
        {dossierParcels.map(dossierParcel =>
                dossierParcel.parcel?.buildingFeatures?.features?.map(feature => (
                  <GeoJSON
                    data={feature}
                    key={`${dossierParcel.parcel.id}/${feature.id}`}
                    style={feature.properties.type === '01'
                            ? heavyBuildingPolygonStyle
                            : lightBuildingPolygonStyle}
                    zIndex={2}
                  />)))}
        {dossierParcels.map(dossierParcel => (
          <GeoJSON
            data={dossierParcel.parcel.feature}
            key={`${dossierParcel.parcel.id}-border`}
            style={parcelPolygonBorder}
            zIndex={3}
          />
            ))}
      </>
    )
}

MainParcelTile.defaultProps = {
    dossierParcels: []
}
  
MainParcelTile.propTypes = {
    dossierParcels: PropTypes.arrayOf(PropTypes.shape),
}

export default MainParcelTile