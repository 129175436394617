import { Group, Image, Rect, Text } from 'konva'

import populate from 'utils/drawing/populate'
import { scaleFrom } from 'utils/leaflet'


export const addBackgroundImage = async (layer, dataUrl) => {
  const stage = layer.parent
  const id = 'background'
  let backgroundNode = stage.find(`#${id}`)[0]
  if (!backgroundNode) {
    if (!dataUrl) return
    backgroundNode = await new Promise(resolve =>
      Image.fromURL(dataUrl, resolve))
    backgroundNode.setAttrs({
      height: stage.height(),
      id,
      width: stage.width()
    })
    layer.add(backgroundNode)
  }
  return backgroundNode
}


export const addNorthDirection = layer => {
  const stage = layer.parent
  const backgroundNode = stage.find(`#background`)[0]
  if (!backgroundNode) return
  let northDirectionNode = stage.find('#north-direction')[0]
  if (!northDirectionNode) {
    northDirectionNode = new Group({
      id: 'north-direction',
      scaleX: 0.8,
      scaleY: 0.8,
      x: 11,
      y: 17,
    })
    const northDirectionElement = document.getElementById('ico-30-nord.svg')
    if (!northDirectionElement) return
    populate(northDirectionElement, northDirectionNode, { strokeScaleEnabled: true })
    layer.add(northDirectionNode)
  }
  return northDirectionNode
}


export const addScale = (layer, zoom, distanceInMeters) => {
  const stage = layer.parent
  const backgroundNode = stage.find(`#background`)[0]
  if (!backgroundNode) return
  let scaleNode = stage.find('#scale')[0]
  if (!scaleNode) {
    const scale = scaleFrom(zoom,  distanceInMeters / stage.width())
    const { distance, unit, width } = scale || {}
    const scaleNode = new Group({
      id: 'scale',
      x: 11,
      y: stage.height() - 17,
    })
    const bottomLine = new Rect({
      fill: 'black',
      height: 5,
      width
    })
    scaleNode.add(bottomLine)
    const leftTick = new Rect({
      fill: 'black',
      height: -5,
      width: 1
    })
    scaleNode.add(leftTick)
    const rightTick = new Rect({
      fill: 'black',
      height: -5,
      width: 1,
      x: width - 1,
    })
    scaleNode.add(rightTick)
    const leftText = new Text({
      text: '0',
      x: leftTick.x() - 3,
      y: leftTick.y() - 16
    })
    scaleNode.add(leftText)
    const rightText = new Text({
      text: `${distance} ${unit}`,
      x: rightTick.x() - 16,
      y: rightTick.y() - 16
    })
    scaleNode.add(rightText)
    layer.add(scaleNode)
  }
  return scaleNode
}
