import { useEffect } from 'react'


import { IS_SAFARI,  IS_STANDALONE } from 'utils/config'

export default () => {
  useEffect(() => {
    if (!IS_SAFARI && !IS_STANDALONE) return
    window.scrollTo(0, 1)
  }, [])
}
