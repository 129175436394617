const Trap = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : EA1",
    },
    {
        componentType : "NumberField",
        label : "Longueur : ",
        name : "trapLength",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm',
    },
    {
        componentType : "NumberField",
        label : "Largeur : ",
        name : "trapWidth",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm',
    },
    {
        componentType : "TextareaField",
        label : "Localisation et commentaire :",
        name : "comment",
    },
]

export default Trap
  