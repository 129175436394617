import createCachedSelector from 're-reselect'

import selectMeasurementsWithComputedPropertiesByDossierId from './selectMeasurementsWithComputedPropertiesByDossierId'


const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


function convertToNumberingScheme(number) {
  const baseChar = ("A").charCodeAt(0)
  let letters  = ""

  do {
    number -= 1
    letters = String.fromCharCode(baseChar + (number % 26)) + letters
    number = (number / 26) >> 0 // quick `floor`
  } while(number > 0)

  return letters
}


export default createCachedSelector(
  selectMeasurementsWithComputedPropertiesByDossierId,
  measurements => {
    if (!measurements) return
    const indexedMeasurements = measurements.map(m => ({...m}))

    function dateCreatedFrom(measurement) {
        return new Date(measurement.dateCreated)
    }

    indexedMeasurements.sort((m1, m2) =>
      dateCreatedFrom(m1) > dateCreatedFrom(m2)
      ? 1
      : -1)

    const lastIndexByItemName = {}
    const lastIndexByPanName = {}
    const lastIndexByTag = {}

    return indexedMeasurements.map(m => {
      if (m.label) return m
      const item = m.item

      if (m.itemName && !item) {
        console.warn(`Weird... This ${m.type} ${m.itemName} item is not anymore in the controls, maybe this is a deprecated one.`)
        return m
      }

      if (!item || item.indexable === false) return m

      if (item.indexedOver && item.indexedOver.validate(m)) {
        if (!lastIndexByTag[item.indexedOver.tag]) {
          lastIndexByTag[item.indexedOver.tag] = 1
        } else {
          lastIndexByTag[item.indexedOver.tag] = lastIndexByTag[item.indexedOver.tag] + 1
        }
        m.index = lastIndexByTag[item.indexedOver.tag]
      } else if (m.panName && item.indexedOverAllSamePanItems) {
        if (!lastIndexByPanName[m.panName]) {
          lastIndexByPanName[m.panName] = 1
        } else {
          lastIndexByPanName[m.panName] = lastIndexByPanName[m.panName] + 1
        }
        m.index = lastIndexByPanName[m.panName]
      } else {
        if (!lastIndexByItemName[m.itemName]) {
          lastIndexByItemName[m.itemName] = 1
        } else {
          lastIndexByItemName[m.itemName] = lastIndexByItemName[m.itemName] + 1
        }

        if (item.indexedOnlyIfMoreThanOne) {
          if (lastIndexByItemName[m.itemName] === 1) {
            return m
          } else if (lastIndexByItemName[m.itemName] === 2) {
            const firstMeasurement = indexedMeasurements.find(subM =>
              subM.itemName === m.itemName && !subM.label && !subM.index)
            firstMeasurement.index = 1
          }
        }
        m.index = lastIndexByItemName[m.itemName]
      }

      if (item.indexedByAlphabet) {
        m.index = convertToNumberingScheme(m.index)
      }
      return m
    })
  }
)(mapArgsToCacheKey)
