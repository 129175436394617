import React from 'react'

import Field from 'components/layout/form/Field'


const Hazard = () => (
  <div className="hazard entry-group">
    <h1
      className="entry-title"
      id="hazard"
    >
      {'Aléa I'}
    </h1>
    {/* <Field name="propertyHazardTypes" />  TODO: should be a multiple select or checkboxes, can be set in alabri for now */}
    <Field name="propertyMainHazardType" />
    <Field name="propertyMainHazardZone" />
    <Field name="propertyOtherHazardZone" />
  </div>
)

export default Hazard
