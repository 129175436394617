import createCachedSelector from 're-reselect'
import {
  selectEntityByActivityIdentifier,
  selectEntityByKeyAndId,
  selectValueByWalk
} from 'redux-thunk-data'

const mapArgsToCacheKey = (state, top) =>
  `${top?.activityIdentifier || ' '}${top?.id || ' '}${top?.stateKey || ' '}`


export default createCachedSelector(
  state => state.data,
  state => state.data.fields,
  (state, top) => selectEntityByActivityIdentifier(state, top.activityIdentifier),
  (state, top) => selectEntityByKeyAndId(state, top.key, top.id),
  (state, top) => top.activityIdentifier,
  (state, top) => top.id,
  (state, top) => top.key,
  (data, fields, entityFromActivityIdentifier, entityFromKeyAndId, topActivityIdentifier, topId, topKey) => {
    const topEntity = entityFromActivityIdentifier || entityFromKeyAndId
    if (!fields || !topEntity) return
    return fields.reduce((agg, field) => ({
        ...agg,
        [field.name]: selectValueByWalk({ data },
                                        {
                                          path: field.name,
                                          topActivityIdentifier,
                                          topId,
                                          topKey,
                                        })
      }), {})
  }
)(mapArgsToCacheKey)
