import controllerCreatorsByType from './creators'


export default config => {
  const {
    onChange,
    onClick,
    stage,
    tool
  } = config

  stage.off('click tap')
  stage.off('mousedown touchstart')
  stage.off('mouseup touchend')
  stage.off('mousemove touchmove')

  if (!tool) return
  let controllerCreator = controllerCreatorsByType[tool.name]
  let item
  if (!controllerCreator) {
    item = config[tool.name]
    if (item) {
      controllerCreator = controllerCreatorsByType[item.type]
    }
  }

  if (typeof controllerCreator !== 'function') return

  const handleChange = async (event, patch) => {
    stage.isFrozen = true
    const uuid = await onChange(event, null, patch)
    stage.isFrozen = false
    onClick(event, uuid)
  }

  controllerCreator({
    ...config,
    handleChange,
    item
  })

}
