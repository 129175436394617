import { distanceBetweenPoints } from '../../distance'
import { handleStopText } from '../../nodify/creators/text'


const topNodeOverBackgroundFrom = node => {
  if (node.attrs.id === 'background') return
  if (!node.parent) return
  if (node.parent.nodeType === 'Layer') return node
  return topNodeOverBackgroundFrom(node.parent)
}


export default config => {
  const {
    onClick,
    stage,
    uuids,
  } = config

  stage.isDraggingNode = false

  stage.on('click tap', event => {

    if (stage.isTextingNode && !stage.hasAlreadyClicked) {
      handleStopText(event, stage.textingNode, config)
    }

    const shouldComparePointerPositionWithNodePosition = stage.isDraggingNode &&
                                                        !stage.isFreezingDraggingNode &&
                                                        stage.draggingPointerPosition
    if (shouldComparePointerPositionWithNodePosition) {
      const distance = distanceBetweenPoints(stage.getPointerPosition(),
                                             stage.draggingPointerPosition)
      if (distance > 10) {
        stage.isFreezingDraggingNode = true
        stage.draggingNode.setAttr('draggable',false)
      }
    }

    if (stage.isDraggingNode ||
        stage.isTextingNode ||
        stage.isTransformingNode) return
    if (event.evt.detail === 2) return
    const node = topNodeOverBackgroundFrom(event.currentTarget)
    if (node) return
    onClick(event, uuids)
  })
}
