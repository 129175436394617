import patchCreatorsByType from './creators'


export default (type, pointPatch, config) => {
  const {
    color,
    item,
    tool
  } = config
  const { tag: colorTag } = color || {}
  const { name: toolName } = tool || {}
  const { name: itemName } = item || {}
  const {
    role,
    strokeWidth
  } = (item || tool)

  const patch = patchCreatorsByType[type](pointPatch, config)

  const isMeasurementCreation = typeof patch.type !== 'undefined'
  if (isMeasurementCreation) {
    Object.assign(patch, {
      colorTag,
      itemName,
      role,
      strokeWidth,
      toolName
    })
  }

  return patch
}
