import React from 'react'

import AddressForm from 'components/layout/AddressForm'
import Field from 'components/layout/form/Field'
import TextField from 'components/layout/form/fields/TextField'


const DossierInformation = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="dossier-information"
    >
      {'Informations Dossier'}
    </h1>
    <Field
      name="number"
      readOnly
    />
    <Field name="type" />
    <TextField
      label="Parcelle(s) cadastrale(s) :"
      name="parcels"
      readOnly
      sublabel="Principale puis suivante(s) séparées par des virgules. Ex. : BW0117, BW0118, BW205"
    />
    <div className="entry-subtitle">
      <div>
        {'Adresse du bien'}
      </div>
    </div>
    <AddressForm prefixFieldName="address." />
  </div>
)

export default DossierInformation
