import PropTypes from 'prop-types'
import React from 'react'

import Field from 'components/layout/form/Field'


const ContactForm = ({ prefixFieldName, labelSuffix }) => (
  <>
    <Field
      label={`Civilité${labelSuffix}`}
      name={`${prefixFieldName}title`}
    />
    <Field
      label={`Nom${labelSuffix}`}
      name={`${prefixFieldName}lastName`}
    />
    <Field
      label={`Prénom${labelSuffix}`}
      name={`${prefixFieldName}firstName`}
    />
    {/*<Field name={`${prefixFieldName}contactLink`} />*/}
    <Field
      label={`Téléphone mobile${labelSuffix}`}
      name={`${prefixFieldName}mobilePhone1`}
    />
    {/*<Field name={`${prefixFieldName}fixedlinePhone`} />*/}
    <Field
      label={`Adresse e-mail${labelSuffix}`}
      name={`${prefixFieldName}email`}
    />
  </>
)

ContactForm.propTypes = {
  labelSuffix: PropTypes.string.isRequired,
  prefixFieldName: PropTypes.string.isRequired
}

export default ContactForm
