import PropTypes from 'prop-types'
import React from 'react'

import Suggestion from './Suggestion'


const Suggestions = ({
  error,
  onSuggestionClick,
  renderSuggestion,
  suggestions,
  value
}) => {
  const hasNoError = !error
  const hasNoSuggestion = !suggestions || !suggestions[0]
  if (hasNoError && hasNoSuggestion) return null


  return (
    <div className='suggestions'>
      {error
        ? (
          <div className="error">
            {error}
          </div>
        )
        : (
          <>
            <div className="instruction">
              Choisissez dans la liste :
            </div>
            {suggestions.map((suggestion, index) => (
              <Suggestion
                index={index}
                key={index}
                onClick={onSuggestionClick}
                render={renderSuggestion}
                suggestion={suggestion}
                value ={value} /> ))}
          </>
        )}
    </div>
  )
}

Suggestions.defaultProps = {
  error: null,
  renderSuggestion: null
}

Suggestions.propTypes = {
  error: PropTypes.string,
  onSuggestionClick: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.any)
}

export default Suggestions
