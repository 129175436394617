import React from 'react'

import NumberField from 'components/layout/form/fields/NumberField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const OtherWaterOpening = () => (
  <>
    <TextField
      label="Etiquette : "
      name="label"
      placeholder="Ex. : AO1"
    />
    <TextareaField
      label="Type : "
      name="openingType"
    />
    <NumberField
      label="Hauteur au sol : "
      name="fromGroundHeight"
      placeholder="Ex. : 0,95"
      unitLabel='m'
    />
    <TextField
      format={formatToUpperCase}
      label="Plancher ou T.N. de référence : "
      name="baseFlooring"
      placeholder="Ex. : N0"
    />
    <NumberField
      label="Longueur : "
      name="openingLength"
      placeholder="Ex. : 0,2"
      unitLabel='m'
    />
    <NumberField
      label="Largeur : "
      name="openingWidth"
      placeholder="Ex. : 0,2"
      unitLabel='m'
    />
    <RadiosField
      isBoolean
      label="Protection existante "
      name="hasProtection"
    />
    <TextareaField
      label="Commentaire ouvrant : "
      name="comment"
    />
  </>
)

export default OtherWaterOpening
