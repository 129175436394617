import { RegularPolygon } from 'konva'

import styles from 'utils/styles'

import { distanceBetweenPoints } from '../../distance'
import patchify from '../../patchify'


const MIN_RADIUS = 20


export default config => {
  const {
    color,
    handleChange,
    item: { strokeWidth, type },
    layers,
    stage
  } = config

  let isPaint = false
  const measurementLayer = layers[1]
  let node

  stage.on('mousedown touchstart', event => {
    if (isPaint) return
    isPaint = true
    node = new RegularPolygon({
      ...stage.getPointerPosition(),
      radius: MIN_RADIUS,
      sides: 3,
      stroke: color
        ? styles[color.name]
        : 'black',
      strokeWidth
    })
    stage.isDrawingNode = true
    measurementLayer.add(node)
  })

  stage.on('mousemove touchmove', () => {
    if (!isPaint || !node) return
    node.setAttr('radius', distanceBetweenPoints(
      node.attrs,
      stage.getPointerPosition()
    ))
    measurementLayer.batchDraw()
  })

  stage.on('mouseup touchend', event => {
    if (!isPaint || !node) return
    isPaint = false
    const { radius, x, y } = node.attrs
    const pointPatch = {
      radius: Math.max(radius, MIN_RADIUS),
      type,
      x,
      y
    }
    stage.isDrawingNode = false
    handleChange(event, patchify(type, pointPatch, config))
  })
}
