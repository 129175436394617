import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showModal } from 'redux-react-modals'

import Spinner from 'components/layout/Spinner'
import selectOfflineActivitiesByDossierId from 'redux/selectors/selectOfflineActivitiesByDossierId'
import { fetchBlobFromApi } from 'utils/fetch'
import { ReactComponent as DownloadIcon } from 'utils/svgs/ico-30-cloud-download.svg'

import downloadReport from './downloadReport'
import OfflineModal from './OfflineModal'

const Report = () => {
  const dispatch = useDispatch()
  const { dossierId } = useParams()

  const [isPending, setIsPending] = useState(false)

  const offlineActivities = useSelector(state =>
    selectOfflineActivitiesByDossierId(state, dossierId))
  const emptyActivitiesOnDossier = !offlineActivities.length

   const handleReportGeneration = useCallback(() => {
    if (emptyActivitiesOnDossier) {
      setIsPending(true)
      fetchBlobFromApi(`/dossiers/${dossierId}/report`).then(async response => {
        if (response.status === 200) {
          const generatedReport = await response.blob()
          downloadReport(generatedReport, 'dossier_report.odt')
        } else {
          dispatch(showModal('main', <OfflineModal />))
        }
        setIsPending(false)
      }).catch(() => {
        dispatch(showModal('main', <OfflineModal />))
        setIsPending(false)
      })
    }
  }, [dispatch, setIsPending, dossierId, emptyActivitiesOnDossier])

  return (
    <div className="report">
      {isPending && <Spinner />}
      {isPending && <div className='shadow' />}
      <div className="top-bar">
        <button
          className="download-button"
          disabled={isPending || !emptyActivitiesOnDossier}
          onClick={handleReportGeneration}
          type="button"
        >
          <DownloadIcon />
          {'Télécharger le rapport'}
        </button>
        {!emptyActivitiesOnDossier && <p className='infos-backup-status'>
          {"Certaines modifications de ce dossier n'ont pas été sauvegardées."}
          <br />
          {"Merci de patienter pour télécharger le rapport."}
          </p>}
      </div>
    </div>
  )
}


export default Report
