import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { closeModal } from 'redux-react-modals'
import {
  activateData,
  requestData,
  selectEntityByActivityIdentifier
} from 'redux-thunk-data'

import LoadingButton from 'components/layout/LoadingButton'
import ToSketchMeasurement from 'components/layout/ToSketchMeasurement'
import { assignDrawing } from 'redux/reducers/drawing'
import selectMeasurementsWithIndexByDossierId from 'redux/selectors/selectMeasurementsWithIndexByDossierId'
import selectPhotoActivityByEntityIdentifier from 'redux/selectors/selectPhotoActivityByEntityIdentifier'
import { createStrictlyIncreasingDate } from 'utils/format_date'

import ItemComponentsByName from './items'


const camelize = text => {
    text = (text || '').replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '')
    return text.substr(0, 1).toUpperCase() + text.substr(1)
}


const MeasurementForm = ({
  activityIdentifier,
  formTitle,
  readOnly,
  withToSketchMeasurement
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const { dossierId, sketchActivityIdentifier } = useParams()

  const measurement = useSelector(state => {
    const measurements = selectMeasurementsWithIndexByDossierId(state, dossierId)
    return selectEntityByActivityIdentifier({ data: { measurements } }, activityIdentifier)
  }, [activityIdentifier, dossierId]) || {}
  const { index, item, type, ...formValues } = measurement
  const { itemName } = formValues
  delete formValues.activityIdentifier
  delete formValues.colorTag
  delete formValues.dateCreated
  delete formValues.dossierId
  const { label } = item || {}

  const { id: photoActivityId, patch } = useSelector(state =>
    selectPhotoActivityByEntityIdentifier(state, activityIdentifier), [activityIdentifier]) || {}
  const { photo } = patch || {}

  const { isPending } = useSelector(state => state.requests['/__activities__-measurements']) || {}

  const form = useForm({ defaultValues: formValues })
  const { formState, reset: formReset } = form
  const { isDirty: isFormDirty } = formState

  const handleTitleClick = useCallback(() => {
    history.push(pathname.replace('/donnees', ''))
    dispatch(assignDrawing({ uuids: [activityIdentifier]}))
  }, [activityIdentifier, dispatch, history, pathname])

  const handleCloseModal = useCallback(() =>
    dispatch(closeModal('main')), [dispatch])

  const handleSubmitMeasurement = useCallback(patch => {
    if (!isFormDirty) {
      handleCloseModal()
      return
    }

    const activity = {
      dateCreated: createStrictlyIncreasingDate(),
      entityIdentifier: activityIdentifier,
      localDossierId: dossierId,
      modelName: 'Measurement',
      patch: { ...patch },
    }
    if (sketchActivityIdentifier) {
      activity.patch.sketchActivityIdentifier = sketchActivityIdentifier
    }
    dispatch(activateData([activity]))
    formReset()
    handleCloseModal()
  }, [activityIdentifier, dispatch, dossierId, formReset, isFormDirty, handleCloseModal, sketchActivityIdentifier])


  useEffect(() => {
    if (!photoActivityId) return
    dispatch(requestData({
      apiPath: `/__activities__/${photoActivityId}?withAssetDataUrls=true`
    }))
  }, [dispatch, photoActivityId])

  useEffect(() => {
    if (!photo) return
    formReset({ photo })
  }, [photo, formReset])


  const componentName = camelize(itemName || type)
  const ItemComponent = ItemComponentsByName[componentName]

  if (!ItemComponent) {
    if (readOnly) return null
    return (
      <div className="measurement-form">
        <div>
          {'Pas de données à saisir pour cet élément'}
        </div>
        <div className="form-footer">
          <button
            className='cancel'
            onClick={handleCloseModal}
            type='button'
          >
            {'Fermer'}
          </button>
        </div>
      </div>
    )
  }

  return (
    <FormProvider
      {...form}
      readOnly={readOnly}
    >
      <form
        autoComplete="off"
        className="measurement-form"
        disabled={isPending}
        noValidate
        onSubmit={form.handleSubmit(handleSubmitMeasurement)}
      >
        {label && (
          <button
            className="title"
            onClick={readOnly ? handleTitleClick : null}
            type="button"
          >
            {`${formTitle || label} ${index && !formTitle ? `# ${index}` : ''}`.split('\n')
                                                    .map(line => (
                                                      <p key={line}>
                                                        {line}
                                                      </p>))}
          </button>)}

        {withToSketchMeasurement && (
          <ToSketchMeasurement measurement={measurement} />
        )}

        <div className="form-group">
          <ItemComponent />
        </div>

        {!readOnly && (
          <div className="form-footer">
            <button
              className='cancel'
              onClick={handleCloseModal}
              type='button'
            >
              {'Annuler'}
            </button>
            <LoadingButton
              className="submit"
              disabled={isPending}
              isLoading={isPending}
              type="submit"
            >
              {'Enregistrer'}
            </LoadingButton>
          </div>)}
      </form>
    </FormProvider>
  )
}


MeasurementForm.defaultProps = {
  formTitle: null,
  readOnly: false,
  withToSketchMeasurement: false
}


MeasurementForm.propTypes = {
  activityIdentifier: PropTypes.string.isRequired,
  formTitle: PropTypes.string,
  readOnly: PropTypes.bool,
  withToSketchMeasurement: PropTypes.bool
}


export default MeasurementForm
