/* eslint-disable react/jsx-no-bind */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { keepNumberKey } from 'utils/form'

import FieldError from '../FieldError'


const TextField = ({
  children,
  className,
  id,
  format,
  label,
  name,
  noAsterisk,
  notRegistered,
  onChange,
  pattern,
  placeholder,
  required,
  sublabel,
  type,
  validate,
  ...inputProps
}) => {
  const {
    errors,
    ...formContext
  } = useFormContext()
  const ref = useRef()
  const valueAsNumber = type === 'number'

  const rules = useMemo(() =>
    notRegistered ? {} : {
      pattern,
      required,
      setValueAs: value => {
        if (valueAsNumber) {
          if (value === null) return NaN
          return Number(value)
        }
        return format(value)
      },
      validate,
    }, [format, notRegistered, pattern, required, validate, valueAsNumber])

  const { disabled, readOnly , value : propValue} = { ...formContext, ...inputProps }
  const error = errors[name]

  const formatValue = useCallback(value => {
    if (valueAsNumber && isNaN(value)) return ''
    if (typeof value === 'undefined' || value === null) return ''
    return format(value)
  }, [format, valueAsNumber])

  const renderInput = useCallback(({ onChange: onReactHookFormChange, value }) => {
    const handleChange = event => {
      let value = event.target.value
      const isEmptyNumber = valueAsNumber && value === ''
      if (isEmptyNumber) {
        value = NaN
      }
      onReactHookFormChange(onChange(value))
    }
    return (
      <input
        {...inputProps}
        aria-describedby={`${name}Error`}
        aria-invalid={error ? 'true' : 'false'}
        className="field-entry"
        name={name}
        onChange={handleChange}
        placeholder={readOnly ? '' : placeholder}
        readOnly={readOnly}
        ref={ref}
        type={type}
        value={formatValue(propValue || value)}
      />)
  }, [error, formatValue, inputProps, name, onChange, placeholder, readOnly, type, valueAsNumber, propValue])

  useEffect(() => {
    const handleKeyPress = event => {
      if (valueAsNumber) keepNumberKey(event)
    }
    const inputElement = ref.current
    inputElement.addEventListener('keypress', handleKeyPress)
    return () => {
      inputElement.removeEventListener('keypress', handleKeyPress)
    }
  }, [ref, valueAsNumber])

  return (
    <div
      className={classnames(`${type}-field`, className, name, { readonly: readOnly })}
      id={id}
    >
      <label
        className={classnames("field-label", {disabled: disabled})}
        htmlFor={name}
      >
        <span>
          {label}
        </span>
        {required && !noAsterisk && !readOnly && (
          <span className="field-asterisk">
            {"*"}
          </span>)}
      </label>
      <div className="field-control">
        <div className="field-value">
          <div className="field-inner">
            <Controller
              defaultValue={valueAsNumber ? NaN : ''}
              name={name}
              render={renderInput}
              rules={rules}
            />
            {children}
          </div>
        </div>
        <FieldError name={name} />
      </div>
      {sublabel &&
        <div className="field-sublabel">
          {sublabel}
        </div>}
    </div>
  )
}


TextField.defaultProps = {
  children: [],
  className: null,
  format: value => value,
  id: null,
  label: '',
  noAsterisk: false,
  notRegistered: false,
  onChange: value => value,
  pattern: null,
  placeholder: 'Saisissez votre texte',
  required: false,
  sublabel: null,
  type: 'text',
  validate: null,
}

TextField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  format: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  notRegistered: PropTypes.bool,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default TextField
