import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { yesOrNoOptions } from 'utils/form'

import FieldError from '../../FieldError'
import Radios from './Radios'


const RadiosField = props => {
  const {
    className,
    disabled,
    id,
    isBoolean,
    label,
    name,
    noAsterisk,
    options,
    required,
    type
  } = props
  const {
    control,
    ...formContext
  } = useFormContext()
  const { readOnly } = formContext

  let radiosOptions = options
  
  if (isBoolean) {
    radiosOptions = yesOrNoOptions
  }

  const renderRadios = useCallback(({ onChange, value }) =>
    (<Radios
      disabled={disabled}
      isBoolean={isBoolean}
      name={name}
      onChange={onChange}
      options={radiosOptions}
      readOnly={readOnly}
      type={type}
      value={typeof value === 'undefined'
                ? ''
                : value}
     />), [disabled, isBoolean, name, radiosOptions, readOnly, type])

  const rules = useMemo(() => ({
    required,
  }), [required])


  return (
    <div
      className={classnames('radios-field', className, name)}
      id={id}
    >
      <div
        className={classnames('field-label', { empty: !label })}
        htmlFor={name}
      >
        <span>
          {label}
        </span>
        {required && !noAsterisk && (
          <span className="field-asterisk">
            {'*'}
          </span>)}
      </div>
      <div className="field-control">
        <div className="field-value">
          <div className="field-inner">
            <Controller
              control={control}
              defaultValue={' '}
              name={name}
              render={renderRadios}
              rules={rules}
            />
          </div>
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}

RadiosField.defaultProps = {
  className: '',
  disabled: false,
  id: null,
  isBoolean: false,
  label: '',
  noAsterisk: false,
  options: null,
  required: false,
  type: 'text'
}

RadiosField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isBoolean: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number])
  })),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  type: PropTypes.string,
}

export default RadiosField
