import { useMemo } from 'react'

const changedValuesByFieldNameFrom = (watchObj, dirtyFields, config = {}) => {
  const { changedValuesByFieldName={}, path } = config
  Object.keys(dirtyFields)
        .forEach(key => {
          const child = watchObj[key]
          const childPath = path ? `${path}.${key}` : key
          if (typeof child === 'object') {
            changedValuesByFieldNameFrom(watchObj[key],
                                         dirtyFields[key],
                                         {
                                           changedValuesByFieldName,
                                           path: childPath
                                         })
            return
          }
          changedValuesByFieldName[childPath] = watchObj[key]
        })
  return Object.keys(changedValuesByFieldName).length
    ? changedValuesByFieldName
    : null
}

export default form => {
  const { formState: { dirtyFields, isDirty }, watch } = form
  const watchObj = watch()

  return useMemo(() =>
    isDirty &&
    changedValuesByFieldNameFrom(watchObj, dirtyFields),
    [dirtyFields, isDirty, watchObj])
}
