import { formatToUpperCase } from 'utils/form'

const draggableOptions = [
    { label: 'non', value: 'no' },
    { label: 'oui, provisoirement', value: 'yes-temporary' },
    { label: 'oui, définitivement ', value: 'yes-pernamently' }
  ]

const ActivityStake = [
    {
        componentType : "TextField",
        label: "Etiquette : ",
        name : "label",
        placeholder : "Ex. : EA1"
    },
    {
        componentType : "TextField",
        label: "Nom de l'enjeu : ",
        name : "stakeName",
        placeholder : "Ex. : four, plonge, photocopieuse",
        type : "text"
    },
    {
        componentType : "NumberField",
        label: "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm'
    },
    {
        componentType : "TextField",
        format : {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0",
    },
    {
        componentType : "SelectField",
        label: "Déplaçable ? ",
        name : "isDraggableStake",
        options : draggableOptions,
        placeholder : "Ex. : oui, provisoirement",
    },
    {
        componentType : "TextareaField",
        label: "Commentaire : ",
        name : "comment", 
    },
    {
        componentType : "PictureField",
        folderPath : "photos",
        label: "Photo : ",
        name : "photo",
    }
]

export default ActivityStake