import React from 'react'
import { useFormContext } from 'react-hook-form'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const Boiler = () => {
  const { readOnly } = useFormContext()
  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : C1"
      />
      <NumberField
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <RadiosField
        isBoolean
        label="Arrimé"
        name="tiedUp"
      />
      <TextareaField
        label="Position rejet chaudière : "
        name="rejectionPosition"
      />
      <NumberField
        label="Hauteur rejet chaudière : "
        name="rejectionFromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher de réf. prise d'air / rejet : "
        name="rejectionBaseFlooring"
        placeholder="Ex. : N0"
      />
      <TextareaField
        label="Commentaire rejet chaudière : "
        name="rejectionComment"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}


export default Boiler
