export const isString = value => {
  if (typeof value !== 'string') return false
  return true
}


export const isEmpty = value => {
  if (!isString(value)) return false
  return value.trim().length === 0
}


export const yesOrNoOptions = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false }
]


export const yesOrNoOrDontKnowOptions = [
  { label: 'Oui', value: 'true' },
  { label: 'Non', value: 'false' },
  { label: 'Ne sait pas', value: 'don\'t know'}
]

export const yesOrNoOrNotCheckableOptions = [
  { label: 'Oui', value: 'true' },
  { label: 'Non', value: 'false' },
  { label: 'Non vérifiable', value: 'not checkable'}
]


export const keepNumberKey = event => {
  const isNumberKey = event.which >= 48 && event.which <= 57
  if (isNumberKey) return
  // lang = fr for the key
  // but target value is encoded into en decimal format
  if (event.key === ',') {
    const canEnterDecimalSep = event.target.value
                               && event.target.value.length
                               && !event.target.value.includes('.')
    if (canEnterDecimalSep) return
  }
  if (event.key === '-') {
    const canEnterMinus = !event.target.value
    if (canEnterMinus) return
  }
  event.preventDefault()
}


export const validatePositiveNumber = { positiveNumber: value => !value || value >= 0 }

export const formatToUpperCase = value => value?.toUpperCase()

export const formatFirstLetterToUpperCase = value => value?.charAt(0).toUpperCase() + value?.slice(1)