import { formatToUpperCase } from 'utils/form'

const ElecMeter = [
    {
        componentType: "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : EC1"
    },
    {
        componentType : "NumberField",
        label: "Hauteur au sol : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType : "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType : "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
]

export default ElecMeter