const {
    NODE_ENV,
    REACT_APP_ALABRI_URL,
    REACT_APP_API_SUBDOMAIN,
    REACT_APP_COMPOSITION,
    REACT_APP_CADASTRE_LAYER_PROVIDER,
    REACT_APP_VERSION,
    REACT_APP_GEOSERVER_URL,
    REACT_APP_MACHINE_ENV,
    REACT_APP_NAME,
    REACT_APP_SENTRY_SERVER_URL = '',
    REACT_APP_SENTRY_SAMPLE_RATE = 0.0,
    REACT_APP_SAVING_TIME_INTERVAL,
    REACT_APP_SAVING_REQUEST_THRESHOLD,
    REACT_APP_SAVING_PAYLOAD_THRESHOLD,
    REACT_APP_ACTIVATE_IF_MODIFIED_SINCE,
    REACT_APP_ACTIVATE_DUPLICATION,
} = process.env

export const APP_NAME = REACT_APP_NAME || 'unnamed'
export const VERSION = REACT_APP_VERSION ? `v${REACT_APP_VERSION}` : ''

export const IS_DEBUG = true
export const IS_DEVELOPMENT = NODE_ENV === 'development'
export const IS_TESTING = REACT_APP_MACHINE_ENV === 'testing'
export const MACHINE_ENV = REACT_APP_MACHINE_ENV

const LOCALHOST_API_URL =
  REACT_APP_COMPOSITION === 'test-end2end'
    ? 'https://nginx-test-end2end:82'
    : 'http://localhost:80'
const TLD = document.location.hostname.replace(/^.*?\./, '')
const REMOTE_API_URL = `${document.location.protocol}//${REACT_APP_API_SUBDOMAIN}.${TLD}`
export const API_URL = (REACT_APP_API_SUBDOMAIN && REMOTE_API_URL) || LOCALHOST_API_URL
export const API_THUMBS_URL = `${API_URL}/storage/thumbs`

const LOCALHOST_ROOT_PATH = 'http://localhost:3000/'
export const ROOT_PATH = `${window.location.protocol}//${document.location.host}` || LOCALHOST_ROOT_PATH
export const ROOT_ASSETS_PATH = `${ROOT_PATH}/static/assets`
export const ROOT_LOGO_ICONS_PATH = `${ROOT_PATH}/static/icons`

const LOCALHOST_GEOSERVER_URL = 'http://localhost/geoserver'
export const GEOSERVER_URL = REACT_APP_GEOSERVER_URL || LOCALHOST_GEOSERVER_URL

export const IS_STANDALONE = !!window.navigator.standalone
export const IS_SAFARI = !IS_STANDALONE && typeof window.navigator.standalone !== 'undefined'

export const SENTRY_SAMPLE_RATE = REACT_APP_SENTRY_SAMPLE_RATE
export const SENTRY_SERVER_URL = REACT_APP_SENTRY_SERVER_URL

const LOCALHOST_ALABRI_URL = 'http://localhost:81'
export const ALABRI_URL = REACT_APP_ALABRI_URL || LOCALHOST_ALABRI_URL
export const CADASTRE_LAYER_PROVIDER = REACT_APP_CADASTRE_LAYER_PROVIDER

export const SAVING_TIME_INTERVAL = REACT_APP_SAVING_TIME_INTERVAL

export const SAVING_REQUEST_THRESHOLD = REACT_APP_SAVING_REQUEST_THRESHOLD
export const SAVING_PAYLOAD_THRESHOLD = REACT_APP_SAVING_PAYLOAD_THRESHOLD
export const ACTIVATE_IF_MODIFIED_SINCE = REACT_APP_ACTIVATE_IF_MODIFIED_SINCE
export const ACTIVATE_SKETCH_DUPLICATION = REACT_APP_ACTIVATE_DUPLICATION
export const IS_SKETCH_DUPLICATION_ENABLED = ACTIVATE_SKETCH_DUPLICATION === 'true'