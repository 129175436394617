import PropTypes from 'prop-types'
import React, { useCallback} from 'react'
import { useDispatch } from 'react-redux'

import { assignBackup } from 'redux/reducers/backup'
import { createStrictlyIncreasingDate } from 'utils/format_date'

import findUnitFromTimeInDozensOfSeconds from './hooks/utils/formatTime'

const ModalBackupStatus = ({ timeSinceOldestActivityWasCreated }) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(assignBackup({
      timerStartBackupModal: createStrictlyIncreasingDate(),
      usingBackupModal: false
    }))
  }, [dispatch])

  const findUnitFromTimeSinceUpdate = useCallback(() => {
    return findUnitFromTimeInDozensOfSeconds(timeSinceOldestActivityWasCreated)
  }, [timeSinceOldestActivityWasCreated])

  return (
    <div className="backup-status-modal">
      <h4 className="title">
        {"Hum hum"}
      </h4>
      <div className="infos">
        {`Votre travail est bien enregistré sur cet appareil, mais la sauvegarde dans le cloud n'a pas pu se faire depuis plus de ${findUnitFromTimeSinceUpdate()}.`}
        <br />
        <br />
        {"→ Si vous êtes hors réseau, revenez dans une zone de couverture 4G ou WiFi."}
        <br />
        <br />
        {"→ Si le problème persiste en WiFi, contactez le support."}
      </div>
      <div className="controls">
        <button
          className="ok"
          onClick={handleClick}
          type="button"
        >
          {"J'ai compris."}
        </button>
      </div>
    </div>
  )
}

ModalBackupStatus.propTypes = {
  timeSinceOldestActivityWasCreated: PropTypes.number.isRequired
}

export default ModalBackupStatus
