import PropTypes from 'prop-types'
import React from 'react'

import Field from 'components/layout/form/Field'


const Annex = ({ number }) => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id={`annex${number}2`}
    >
      {`Annexe A${number} - II`}
    </h1>
    <Field name={`annex${number}SubmergedStakes`} />
  </div>
)

Annex.propTypes = {
  number: PropTypes.number.isRequired
}

export default Annex
