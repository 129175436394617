import centroid from '@turf/centroid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GeoJSON } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import AddressComboBox from 'components/layout/AddressComboBox'
import Main from 'components/layout/Main'
import ResponsiveZoomControl from 'components/layout/ResponsiveZoomControl'
import Sig from 'components/layout/Sig'
import Spinner from 'components/layout/Spinner'
import SwitchLayerControl from 'components/layout/SwitchLayerControl'
import { OSM_TILE, SATELLITE_TILE } from 'utils/leaflet'

import { API_ENDPOINTS } from '../api_endpoints'
import { getInitialPosition } from './_domain/programmes'
import requestEligibilities from './_hooks/requestEligibility'
import eligibilities from './_selectors/eligibilities'
import requests from './_selectors/requests'
import CtaTooltip from './CtaTooltip/CtaTooltip'

const DEFAULT_PROGRAMME = 'nantes'
const INITIAL_ZOOM = 14
const MAP_MAX_ZOOM = 20
const MAP_MIN_ZOOM = 5
const ZOOM_POSITION = 17

const Eligibility = () => {
  const [query, setQuery] = useQueryParams({
    departmentCode: StringParam,
    interopKey: StringParam,
    lat: StringParam,
    lon: StringParam,
    programme: StringParam,
  })
  const { departmentCode, interopKey, lat, lon, programme } = query
  const shouldDisplayTooltip =
    (lat && lon && programme) || (departmentCode && interopKey && programme)
  const [isClosed, setIsClosed] = useState(shouldDisplayTooltip)
  const [tooltipPosition, setTooltipPosition] = useState(null)
  const position = getInitialPosition(programme)
  const eligibility = useSelector(
    state =>
      eligibilities.selectEligibilityByLatAndLonOrDepartmentCodeAndInteropKey(
        state,
        lat,
        lon,
        departmentCode,
        interopKey
      ),
    [departmentCode, interopKey, lat, lon]
  )
  const { isFail, isPending } =
    useSelector(state =>
      requests.selectRequestByEndpointName(state, API_ENDPOINTS.eligibilities)
    ) || {}
  const { zoom } = useSelector(state => state.map)
  const { parcel } = eligibility || {}
  const { feature } = parcel || {}

  const currentZoom = useMemo(() => {
    if (tooltipPosition && zoom) {
      if (zoom < ZOOM_POSITION) {
        return ZOOM_POSITION
      }
      return zoom
    }
    return INITIAL_ZOOM
  }, [tooltipPosition, zoom])

  const handleRedirectToPosition = useCallback(
    nextPosition => {
      const [lat, lon, interopKey, departmentCode] = nextPosition
      setQuery({
        departmentCode: departmentCode,
        interopKey: interopKey,
        lat: lat,
        lon: lon,
        programme: programme,
      })
      setIsClosed(true)
    },
    [programme, setIsClosed, setQuery]
  )

  const handleEligibilityFromAddress = useCallback(
    (_event, nextPosition) => {
      handleRedirectToPosition(nextPosition)
    },
    [handleRedirectToPosition]
  )

  const handleEligibilityFromMap = useCallback(
    event => {
      const hasClickedOnSomeOverlay = event.originalEvent.srcElement.id !== 'sig'
      if (hasClickedOnSomeOverlay || isPending) return

      const { lat, lng } = event.latlng

      const nextPosition = [lat, lng]
      handleRedirectToPosition(nextPosition)
    },
    [handleRedirectToPosition, isPending]
  )

  const handleOpenAddress = useCallback(() => {
    setQuery({
      departmentCode: undefined,
      interopKey: undefined,
      lat: undefined,
      lon: undefined,
      programme: programme,
    })
    setIsClosed(false)
  }, [programme, setQuery])

  const handleCloseAddress = useCallback(() => {
    setIsClosed(true)
  }, [])

  useEffect(() => {
    if (!eligibility) return

    if (!feature) {
      if (lat && lon) {
        setTooltipPosition([parseFloat(lat), parseFloat(lon)])
      }
      return
    }

    const centroidCoordinates = centroid(feature).geometry.coordinates
    const [centroidLongitude] = centroidCoordinates
    const topLatitude = feature['bbox'][3]
    setTooltipPosition([topLatitude, centroidLongitude])
  }, [eligibility, feature, lat, lon])

  useEffect(() => {
    if (!lat && !lon && !departmentCode && !interopKey) return

    setQuery({
      departmentCode: departmentCode,
      interopKey: interopKey,
      lat: lat,
      lon: lon,
      programme: programme ? programme : DEFAULT_PROGRAMME,
    })
  }, [departmentCode, interopKey, lat, lon, programme, setQuery])

  requestEligibilities(departmentCode, interopKey, lat, lon, programme)
  return (
    <Main name="eligibility">
      <AddressComboBox
        isClosed={isClosed}
        onChange={handleEligibilityFromAddress}
        onCloseClick={handleCloseAddress}
        onOpenClick={handleOpenAddress}
        title="Vérifiez votre éligibilité"
      />
      <Sig
        center={tooltipPosition || position}
        doubleClickZoom={!isPending}
        dragging={!isPending}
        maxZoom={MAP_MAX_ZOOM}
        minZoom={MAP_MIN_ZOOM}
        onClick={handleEligibilityFromMap}
        scrollWheelZoom={!isPending}
        zoom={currentZoom}
      >
        <SwitchLayerControl 
          firstTile={OSM_TILE} 
          secondTile={SATELLITE_TILE} 
        />
        <ResponsiveZoomControl />
        {isPending ? (
          <Spinner />
        ) : (
          tooltipPosition && (
            <>
              {isClosed && (
                <CtaTooltip
                  eligibility={eligibility}
                  isFail={isFail}
                  onCloseClick={handleOpenAddress}
                  onModifyClick={handleOpenAddress}
                  position={tooltipPosition}
                  programme={programme}
                />
              )}
              {feature && (
                <GeoJSON
                  className="parcel-polygon"
                  data={feature}
                />
              )}
            </>
          )
        )}
      </Sig>
    </Main>
  )
}

export default Eligibility
