import React from 'react'

import NumberField from 'components/layout/form/fields/NumberField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'


const Trap = () => (
  <>
    <TextField
      label="Etiquette : "
      name="label"
      placeholder="Ex. : EA1"
    />
    <NumberField
      label="Longueur : "
      name="trapLength"
      placeholder="Ex. : 0,2"
      unitLabel='m'
    />
    <NumberField
      label="Largeur : "
      name="trapWidth"
      placeholder="Ex. : 0,2"
      unitLabel='m'
    />
    <TextareaField
      label="Localisation et commentaire :"
      name="comment"
    />
  </>
)

export default Trap
