import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'
import selectFirstMeasurementByDossierIdAndItemName from 'redux/selectors/selectFirstMeasurementByDossierIdAndItemName'
import selectLowestMeasurementByDossierIdAndItemName from 'redux/selectors/selectLowestMeasurementByDossierIdAndItemName'

const ExternalArrangement = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="external-arrangement2"
    >
      {'Aménagements extérieurs II'}
    </h1>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <MeasurementBlocks
      itemName="swimming-pool"
      select={selectFirstMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            label="Côte de la margelle"
            name="swimmingPoolSurveyorRef"
            withInlineForm
          />
          <Property
            label="Piscine ou bassin inondé(e)"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="tank"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne ou cuve"
            name="altitude"
          />
          <Property
            label="Citerne ou cuve inondée"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="outdoor-tank"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne ou cuve"
            name="altitude"
          />
          <Property
            label="Citerne ou cuve inondée"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="well"
      select={selectFirstMeasurementByDossierIdAndItemName}
      showHasItem
    />
    <MeasurementTables name="floating-elements">
      {() => (
        <Table>
          <Column name="floatingElementName" />
          <Column name="tiedUp" />
        </Table>
      )}
    </MeasurementTables>
    <TextareaField
      label="Observations : "
      name="externalArrangementComment"
    />
    <TextareaField
      label="Préconisations : "
      name="externalArrangementRecommendation"
    />
  </div>
)

export default ExternalArrangement
