// from https://codesandbox.io/s/react-loading-button-7brkb?file=/src/Loader.js:49-76
import classnames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import Icon from 'components/layout/Icon'


const LoadingButton = ({
  className,
  isLoading,
  isPrimary,
  children,
  ...props
}) => {
  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [showLoader, setShowLoader] = useState(false)

  const style = useMemo(() =>
    showLoader
    ? {
        height: `${height}px`,
        width: `${width}px`
      }
    : {}
  , [height, showLoader, width])

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true)
    }

    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 400)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isLoading, showLoader])

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height)
    }
  }, [children, ref])

  return (
    <button
      {...props}
      className={classnames(className, 'button loading-button', {
        'is-primary': isPrimary
      })}
      ref={ref}
      style={style}
    >
      {showLoader
        ? <div className="loader" />
        : (children || <Icon name="la-sync" />)}
    </button>
  )
}

export default LoadingButton
