import { ReactComponent as MezzaAtticEntrance } from './ico-30-accesMezzaComble.svg'
import { ReactComponent as Ventilation } from './ico-30-aeration.svg'
import { ReactComponent as ArrangeBackward } from './ico-30-arrangeBackward.svg'
import { ReactComponent as ArrangeForward } from './ico-30-arrangeForward.svg'
import { ReactComponent as Arrow } from './ico-30-arrow.svg'
import { ReactComponent as Elevator } from './ico-30-ascenseur.svg'
import { ReactComponent as OtherStake } from './ico-30-autreEnjeu.svg'
import { ReactComponent as OtherWaterOpening } from './ico-30-autreOuvrant.svg'
import { ReactComponent as AirConditioner } from './ico-30-blocClim.svg'
import { ReactComponent as Camera } from './ico-30-camera.svg'
import { ReactComponent as Boiler } from './ico-30-chaudiere.svg'
import { ReactComponent as HotWaterTank } from './ico-30-chauffeEau.svg'
import { ReactComponent as Circle } from './ico-30-circle.svg'
import { ReactComponent as ColorSwatch } from './ico-30-colorSwatch.svg'
import { ReactComponent as Tank } from './ico-30-cuve-exterieure.svg'
import { ReactComponent as Dots } from './ico-30-dots.svg'
import { ReactComponent as Duplicate } from './ico-30-duplicate.svg'
import { ReactComponent as WatercourseCanalizedValleysNaturalArtificialBed } from './ico-30-eauCours.svg'
import { ReactComponent as WatercourseOtherValley } from './ico-30-eauCoursAutre.svg'
import { ReactComponent as WatercourseUncoveredCanalisedValley } from './ico-30-eauCoursCanalise.svg'
import { ReactComponent as WatercourseIntermittentValley } from './ico-30-eauCoursIntermittent.svg'
import { ReactComponent as WatercourseCoveredCanalisedValley } from './ico-30-eauCoursInvisible.svg'
import { ReactComponent as WaterSurface } from './ico-30-eauSurface.svg'
import { ReactComponent as WaterSurfaceIntrmt } from './ico-30-eauSurfaceIntrmt.svg'
import { ReactComponent as ElecMeter } from './ico-30-elecCompteur.svg'
import { ReactComponent as ElecSwitch } from './ico-30-elecInterrupteur.svg'
import { ReactComponent as ElecPlug } from './ico-30-elecPrise.svg'
import { ReactComponent as ElecRadiator } from './ico-30-elecRadiateur.svg'
import { ReactComponent as ElecBoar } from './ico-30-elecTableau.svg'
import { ReactComponent as ActivityStake } from './ico-30-enjeuActivite.svg'
import { ReactComponent as InformaticEquipment } from './ico-30-equipementInfo.svg'
import { ReactComponent as Window } from './ico-30-fenetre.svg'
import { ReactComponent as ElectroGroup } from './ico-30-groupeElectro.svg'
import { ReactComponent as Line } from './ico-30-line.svg'
import { ReactComponent as Stairs } from './ico-30-marchesEscalier.svg'
import { ReactComponent as Wall } from './ico-30-mur.svg'
import { ReactComponent as NorthDirection } from './ico-30-nord.svg'
import { ReactComponent as FloatingElement } from './ico-30-objetFlottant.svg'
import { ReactComponent as SwimmingPool } from './ico-30-piscine.svg'
import { ReactComponent as TNPoint } from './ico-30-pointTN.svg'
import { ReactComponent as Polyline } from './ico-30-polyline.svg'
import { ReactComponent as Portal } from './ico-30-portail.svg'
import { ReactComponent as FrenchDoor } from './ico-30-porte-fenetre.svg'
import { ReactComponent as Door } from './ico-30-porte.svg'
import { ReactComponent as Well } from './ico-30-puits.svg'
import { ReactComponent as Trap } from './ico-30-regard.svg'
import { ReactComponent as NetworkEquipment } from './ico-30-reseauEquipmt.svg'
import { ReactComponent as NetworkPlug } from './ico-30-reseauPrise.svg'
import { ReactComponent as Selector } from './ico-30-selector.svg'
import { ReactComponent as FlowDirection } from './ico-30-sensEcoulement.svg'
import { ReactComponent as LevelThreshold } from './ico-30-seuilniveau.svg'
import { ReactComponent as Shapes } from './ico-30-shapes.svg'
import { ReactComponent as Square } from './ico-30-square.svg'
import { ReactComponent as Symbols } from './ico-30-symbols.svg'
import { ReactComponent as TextMinor } from './ico-30-text-minor.svg'
import { ReactComponent as TextPhe } from './ico-30-text-phe.svg'
import { ReactComponent as TextRegular } from './ico-30-text-regular.svg'
import { ReactComponent as TextTitle } from './ico-30-text-title.svg'
import { ReactComponent as Text } from './ico-30-text.svg'
import { ReactComponent as TrapDoor } from './ico-30-trappeVisite.svg'
import { ReactComponent as Trash } from './ico-30-trash.svg'
import { ReactComponent as Triangle } from './ico-30-triangle.svg'
import { ReactComponent as ShopFront } from './ico-30-vitrine.svg'
import { ReactComponent as Warning } from './ico-30-warning.svg'


export default {
  'ico-30-accesMezzaComble.svg': MezzaAtticEntrance,
  'ico-30-aeration.svg': Ventilation,
  'ico-30-arrangeBackward.svg': ArrangeBackward,
  'ico-30-arrangeForward.svg': ArrangeForward,
  'ico-30-arrow.svg': Arrow,
  'ico-30-ascenseur.svg': Elevator,
  'ico-30-autreEnjeu.svg': OtherStake,
  'ico-30-autreOuvrant.svg': OtherWaterOpening,
  'ico-30-blocClim.svg': AirConditioner,
  'ico-30-camera.svg': Camera,
  'ico-30-chaudiere.svg': Boiler,
  'ico-30-chauffeEau.svg': HotWaterTank,
  'ico-30-circle.svg': Circle,
  'ico-30-colorSwatch.svg': ColorSwatch,
  'ico-30-cuve-exterieure.svg': Tank,
  'ico-30-dots.svg': Dots,
  'ico-30-duplicate.svg': Duplicate,
  'ico-30-eauCours.svg': WatercourseCanalizedValleysNaturalArtificialBed,
  'ico-30-eauCoursAutre.svg': WatercourseOtherValley,
  'ico-30-eauCoursCanalise.svg':WatercourseUncoveredCanalisedValley,
  'ico-30-eauCoursIntermittent.svg': WatercourseIntermittentValley,
  'ico-30-eauCoursInvisible.svg': WatercourseCoveredCanalisedValley,
  'ico-30-eauSurface.svg': WaterSurface,
  'ico-30-eauSurfaceIntrmt.svg': WaterSurfaceIntrmt,
  'ico-30-elecCompteur.svg': ElecMeter,
  'ico-30-elecInterrupteur.svg': ElecSwitch,
  'ico-30-elecPrise.svg': ElecPlug,
  'ico-30-elecRadiateur.svg': ElecRadiator,
  'ico-30-elecTableau.svg': ElecBoar,
  'ico-30-enjeuActivite.svg': ActivityStake,
  'ico-30-equipementInfo.svg': InformaticEquipment,
  'ico-30-fenetre.svg': Window,
  'ico-30-groupeElectro.svg': ElectroGroup,
  'ico-30-line.svg': Line,
  'ico-30-marchesEscalier.svg': Stairs,
  'ico-30-mur.svg': Wall,
  'ico-30-nord.svg': NorthDirection,
  'ico-30-objetFlottant.svg': FloatingElement,
  'ico-30-piscine.svg': SwimmingPool,
  'ico-30-pointTN.svg': TNPoint,
  'ico-30-polyline.svg': Polyline,
  'ico-30-portail.svg': Portal,
  'ico-30-portal.svg': Portal,
  'ico-30-porte-fenetre.svg': FrenchDoor,
  'ico-30-porte.svg': Door,
  'ico-30-puits.svg': Well,
  'ico-30-regard.svg': Trap,
  'ico-30-reseauEquipmt.svg': NetworkEquipment,
  'ico-30-reseauPrise.svg': NetworkPlug,
  'ico-30-selector.svg': Selector,
  'ico-30-sensEcoulement.svg': FlowDirection,
  'ico-30-seuilniveau.svg': LevelThreshold,
  'ico-30-shapes.svg': Shapes,
  'ico-30-square.svg': Square,
  'ico-30-symbols.svg': Symbols,
  'ico-30-text-minor.svg': TextMinor,
  'ico-30-text-phe.svg': TextPhe,
  'ico-30-text-regular.svg': TextRegular,
  'ico-30-text-title.svg': TextTitle,
  'ico-30-text.svg': Text,
  'ico-30-trappeVisite.svg': TrapDoor,
  'ico-30-trash.svg': Trash,
  'ico-30-triangle.svg': Triangle,
  'ico-30-vitrine.svg': ShopFront,
  'ico-30-warning.svg': Warning,
}
