import PropTypes from 'prop-types'
import React from 'react'

const AddressNotEligible = ({ programmeEligibility }) => {
  if (programmeEligibility.code === 'eaux-et-vilaine' && programmeEligibility.isCityEligible) {
    return (
      <div className="info">
        <p>
          {'Éligibilité à vérifier.'}
        </p>
        <br />
        <p>
          {'Vous souhaitez vous renseigner sur votre exposition au risque ? Vous avez déjà été impacté par une inondation ?'}
        </p>
        <br />
        <p>
          {'Contactez-nous en complétant le '} 
          {' '}
          <a 
            href={programmeEligibility.eligibilityFormUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {'formulaire en ligne'}
          </a>
          {'.'}
        </p>
      </div>
    )
  }

  return (
    <div className="info">
      {'Si votre bien a été impacté lors d\'une inondation, n\'hésitez pas à '}
      <a
        href={`mailto:${programmeEligibility.informationEmail}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {'nous contacter par mail'}
      </a>
      {' pour signaler votre situation et obtenir davantage d\'informations.'}
    </div>
  )
}

AddressNotEligible.propTypes = {
  programmeEligibility: PropTypes.shape().isRequired,
}

export default AddressNotEligible
