import { Arrow } from 'konva'

import { createLineCreator } from './line'


const arrowCreator = createLineCreator(Arrow)


export default (positionPatch, config) => {
  const node = arrowCreator(positionPatch, config)

  if (!node) return

  node.setAttr('fill', node.attrs.stroke)

  return node
}
