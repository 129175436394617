import { allCoordsNotEmpty } from '../../distance'


export default (pointPatch, config) => {
  const { centerX, centerY, labelX, labelY, points, x, y, ...patch } = pointPatch
  const { konvaPointToLatLng } = config

  if (points && points.filter(p => p).length) {
    const pairs = points.reduce((result, value, index, array) => {
      if (index % 2 === 0)
        result.push(array.slice(index, index + 2))
      return result
    }, [])

    const positions = pairs.map(pair => {
      const { lat, lng: lon } = konvaPointToLatLng({
        x: pair[0],
        y: pair[1]
      })
      return [lat, lon]
    })

    patch.positions = positions
    if (allCoordsNotEmpty(x, y)) {
      const { lat, lng: lon } = konvaPointToLatLng({ x, y })
      Object.assign(patch, { lat, lon })
    }
  }

  if (allCoordsNotEmpty(centerX, centerY, labelX, labelX)) {
    const centerPosition = konvaPointToLatLng({ x: centerX, y: centerY })
    const { lat: centerLat, lng: centerLon } = centerPosition
    const labelPosition = konvaPointToLatLng({ x: labelX, y: labelY })
    const { lat: labelLat, lng: labelLon } = labelPosition
    Object.assign(patch, {
      labelDiffLat: labelLat - centerLat,
      labelDiffLon: labelLon - centerLon
    })
  }
  return patch
}
