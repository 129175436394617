import { combineReducers } from 'redux'
import { modals } from 'redux-react-modals'

import background_processes from './background_processes'
import data from './data'
import debug from './debug'
import drawing from './drawing'
import map from './map'
import menu from './menu'
import navigation from './navigation'
import notification from './notification'
import requests from './requests'

const rootReducer = combineReducers({
    background_processes,
    data,
    debug,
    drawing,
    map,
    menu,
    modals,
    navigation,
    notification,
    requests,
})

export default rootReducer
