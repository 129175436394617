import classnames from 'classnames'
import React from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { closeDebug, showDebug } from 'redux/reducers/debug'
import { closeMenu, showMenu } from 'redux/reducers/menu'
import svgsByName from 'utils/svgs'


const Dots = svgsByName['ico-30-dots.svg']

const DEBUG_CLICKS_COUNT = 3


const Toggle = () => {
  const dispatch = useDispatch()
  const isDebugActive = useSelector(state => state.debug.isActive)
  const isMenuActive = useSelector(state => state.menu.isActive)

  const handleDebugState = useCallback((event) => {
    event.preventDefault()
 
    if (event.detail === DEBUG_CLICKS_COUNT) {
      dispatch(isDebugActive
        ? closeDebug()
        : showDebug())
      const nextDebugState = isDebugActive
        ? 'inactive'
        : 'active'
      alert(`DEBUG mode is now ${nextDebugState}`)
    }

    if (!isMenuActive) {
      dispatch(showMenu())
    } else {
      // For keyboard users.
      // Not used for mouseclicks
      // instead we capture clicks via dismiss overlay
      dispatch(closeMenu())
    }
  }, [isDebugActive, isMenuActive, dispatch])

  return (
    <div className={classnames('toggle', { 'is-active': isMenuActive })}>
      <NavLink
        className="link"
        onClick={handleDebugState}
        to='#footer'
      >
        <Dots />
      </NavLink>
    </div>
  )
}

export default Toggle
