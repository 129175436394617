import React from 'react'

import Footer from 'components/layout/Footer'
import Main from 'components/layout/Main'

import BasicAuthForm from './BasicAuthForm'
import Oauth2Link from './Oauth2Link'


const Signin = () => (
  <>
    <Main name="signin">
      <div>
        <h4
          className="welcome"
          data-testid="welcome"
        >
          {'Bienvenue !'}
        </h4>
        <p className="tip">
          {'Utilisez les identifiants qui vous ont été fournis.'}
          <br />
          <span className="mandatory">
            {'Tous les champs sont obligatoires.'}
          </span>
        </p>
        <BasicAuthForm />
        <Oauth2Link />
      </div>
    </Main>
    <Footer />
  </>
)


export default Signin
