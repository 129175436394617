import { allCoordsNotEmpty, distanceBetweenPositions } from '../../distance'


export default (pointPatch, config) => {
  const { centerX, centerY, height, labelX, labelY, width, x, y, ...patch } = pointPatch
  const { konvaPointToLatLng } = config

  if (allCoordsNotEmpty(x, y)) {
    let northWestPosition
    let northEastPosition
    let southWestPosition

    if (height && width) {
      if (height > 0) {
        if (width > 0) {
          northEastPosition = konvaPointToLatLng({ x: x + width, y })
          northWestPosition = konvaPointToLatLng({ x, y })
          southWestPosition = konvaPointToLatLng({ x, y: y + height })
        } else {
          northEastPosition = konvaPointToLatLng({ x, y })
          northWestPosition = konvaPointToLatLng({ x: x + width, y })
          southWestPosition = konvaPointToLatLng({ x: x + width, y: y + height })
        }
      } else {
        if (width > 0) {
          northEastPosition = konvaPointToLatLng({ x: x + width, y: y + height })
          northWestPosition = konvaPointToLatLng({ x, y: y + height })
          southWestPosition = konvaPointToLatLng({ x, y: y })
        } else {
          northEastPosition = konvaPointToLatLng({ x, y: y + height })
          northWestPosition = konvaPointToLatLng({ x: x + width, y: y + height })
          southWestPosition = konvaPointToLatLng({ x: x + width, y })
        }
      }
      patch.height = distanceBetweenPositions(
          [northWestPosition.lat, northWestPosition.lng],
          [southWestPosition.lat, southWestPosition.lng]
        )
      patch.width = distanceBetweenPositions(
          [northWestPosition.lat, northWestPosition.lng],
          [northEastPosition.lat, northEastPosition.lng]
        )
    } else {
      northWestPosition = konvaPointToLatLng({ x, y })
    }

    const { lat, lng: lon } = northWestPosition
    Object.assign(patch, { lat, lon })
  }


  if (allCoordsNotEmpty(centerX, centerY, labelX, labelY)) {
    const centerPosition = konvaPointToLatLng({ x: centerX, y: centerY })
    const { lat: centerLat, lng: centerLon } = centerPosition
    const labelPosition = konvaPointToLatLng({ x: labelX, y: labelY })
    const { lat: labelLat, lng: labelLon } = labelPosition
    Object.assign(patch, {
      labelDiffLat: labelLat - centerLat,
      labelDiffLon: labelLon - centerLon
    })
  }

  return patch
}
