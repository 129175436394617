/* eslint-disable react/no-multi-comp, react/display-name,react/prop-types */
import React from 'react'

import { DOSSIER_ENTRY_NAME_RE, ID_RE } from 'utils/router'

import Navigation from './Navigation'


export default [
  {
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/(${DOSSIER_ENTRY_NAME_RE})`,
    render: ({ match: { params: { programmeId } } }) => (
      <Navigation
        iconName="ico-30-folder.svg"
        label="Changer de dossier"
        to={`/diagnostiquer/programmes/${programmeId}/dossiers`}
      />)
  },
  {
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/(${DOSSIER_ENTRY_NAME_RE})`,
    render: () => (
      <Navigation
        iconName="ico-30-program-switch.svg"
        label="Changer de programmes"
        to="/diagnostiquer/programmes"
      />)
  }
]
