import { Rect } from 'konva'
import destination from '@turf/destination'

import styles from 'utils/styles'

import {
  allCoordsNotEmpty,
  distanceBetweenPoints,
  toPointFeature
} from '../../distance'
import { selectColorByTag } from '../../selectors'


export default (positionPatch, config) => {
  const { latLngToKonvaPoint } = config
  const {
    colorTag,
    height,
    labelDiffLat,
    labelDiffLon,
    lat,
    lon,
    width,
  } = positionPatch

  const northWestPosition = [lat, lon]

  if (typeof lat === 'undefined' ||
      typeof lon === 'undefined'
  ) {
    console.error('Invalid properties for rectangle : ', { lat, lon })
    return
  }

  const northWestFeature = toPointFeature(northWestPosition)

  const northEastPoint = destination(northWestFeature, width, 0)
  const southWestPoint = destination(northWestFeature, height, 90)

  const northEastPosition = northEastPoint.geometry.coordinates
  const southWestPosition = southWestPoint.geometry.coordinates

  const northEastKonvaPoint = latLngToKonvaPoint(northEastPosition)
  const northWestKonvaPoint = latLngToKonvaPoint(northWestPosition)
  const southWestKonvaPoint = latLngToKonvaPoint(southWestPosition)

  let labelX, labelY
  if (allCoordsNotEmpty(labelDiffLat, labelDiffLon)) {
    const labelPosition = [lat + labelDiffLat, lon + labelDiffLon]
    const labelKonvaPoint = latLngToKonvaPoint(labelPosition)
    labelX = labelKonvaPoint.x
    labelY = labelKonvaPoint.y
  }

  const color = selectColorByTag(colorTag)
  const stroke = color
    ? styles[color.name]
    : 'black'

  const node = new Rect({
    ...northWestKonvaPoint,
    height: distanceBetweenPoints(northWestKonvaPoint,
                                  southWestKonvaPoint),
    labelX,
    labelY,
    stroke,
    width: distanceBetweenPoints(northWestKonvaPoint,
                                 northEastKonvaPoint)
   })

   return node
}
