import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'


const SwitchButton = ({
  className,
  initialState,
  onChange,
  renderLeft,
  renderRight
}) => {
  const [isLeft, setIsLeft] = useState(
    typeof initialState === 'undefined'
      ? true
      : initialState
  )

  const handleClick = useCallback(event => {
    const nextIsLeft = !isLeft
    onChange(event, nextIsLeft)
    setIsLeft(initialState)
  }, [isLeft, onChange, setIsLeft, initialState])


  useEffect(() => {
    setIsLeft(!!initialState)
  }, [initialState, setIsLeft])


  return (
    <button
      className={classnames('switch-button', className)}
      onClick={handleClick}
      type="button"
    >
      <div className={classnames('slider', { 'is-right': !isLeft })}>
        {isLeft ? renderLeft() : renderRight()}
      </div>
      <div className={classnames('behind', { 'is-right': !isLeft })}>
        {!isLeft ? renderLeft() : renderRight()}
      </div>
    </button>
  )
}
SwitchButton.defaultProps = {
  className: null,
}

SwitchButton.propTypes = {
  className: PropTypes.string,
  initialState: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  renderLeft: PropTypes.func.isRequired,
  renderRight: PropTypes.func.isRequired,
}

export default SwitchButton
