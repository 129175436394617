import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { closeNotification } from 'redux/reducers/notification'

const DuplicationFailedModal = ({closeModal, dispatch}) => {

  const handleOkClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
  }, [dispatch, closeModal])

  return (
    <div className="duplication-failed-modal">
      <div className="title" >
        {'La duplication a échoué. Veuillez réessayer.'}
      </div>
      <div className="controls">
        <button
          className="ok"
          onClick={handleOkClick}
          type="button"
        >
          Ok
        </button>
      </div>
    </div>
  )
}

DuplicationFailedModal.propTypes = {
  closeModal : PropTypes.func.isRequired,
  dispatch : PropTypes.func.isRequired
}

export default DuplicationFailedModal
