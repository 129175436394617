import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectEntityByActivityIdentifier,
  selectEntitiesByKeyAndJoin
} from 'redux-thunk-data'


const ActivitiesDebug = ({ activityIdentifier }) => {
  const entity = useSelector(state =>
    selectEntityByActivityIdentifier(state, activityIdentifier),
    [activityIdentifier])

  const activities = useSelector(state =>
    selectEntitiesByKeyAndJoin(state,
                               '__activities__',
                               { key: 'entityIdentifier', value: activityIdentifier}),
                               [activityIdentifier])

  return (
    <div className="activities-debug">
      <div>
        {'Entity :'}
        <pre>
          {JSON.stringify(entity, null, 2)}
        </pre>
      </div>
      <div>
        {'Activities :'}
        <pre>
          {JSON.stringify(activities, null, 2)}
        </pre>
      </div>
    </div>
  )
}

ActivitiesDebug.propTypes = {
  activityIdentifier: PropTypes.string.isRequired
}


export default ActivitiesDebug
