import { materialOptions } from 'utils/diagnostic'
import { formatToUpperCase } from 'utils/form'

const Window = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : P-1",
    },
    {
        componentType : "NumberField",
        label : "Largeur : ",
        name : "windowWidth",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm'
    },
    {
        componentType : "SelectField",
        label : "Matériau : ",
        name : "material",
        options : materialOptions,
        placeholder : "Ex. : bois"
    },
    {
        componentType : "RadiosField",
        isBoolean: true,
        label : "Présence d'un volet roulant électrique ",
        name : "hasElectricalShutter",
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Présence d'une grille ou de barreaux ",
        name : "hasRailings",
    },
    {
        componentType : "NumberField",
        label : "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm'
    },
    {
        componentType : "TextField",
        format : {formatToUpperCase},
        label : "Plancher ou T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0"
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Batardeau existant ",
        name : "hasBatardeau",
    },
    {
        componentType : "NumberField",
        label : "Hauteur du batardeau : ",
        name : "batardeauHeight",
        placeholder : "Ex. : 0,5",
        unitLabel : 'm'
    },
    {
        componentType : "TextareaField",
        label : "Commentaire sur le batardeau existant (efficacité, type de batardeau) : ",
        name : "batardeauComment",
    },
    {
        componentType : "TextareaField",
        label : "Commentaire ouvrant : ",
        name : "comment",
    },
    {
        componentType : "PictureField",
        folderPath : "photos",
        label : "Photo : ",
        name : "photo",
    },
]

export default Window