import { createSelector } from 'reselect'

import tools from 'utils/drawing/tools'


export default createSelector(
  state => state.drawing,
  drawing => {
    const { itemBehindUuids } = drawing
    if (itemBehindUuids && itemBehindUuids.colorTags) return itemBehindUuids.colorTags
    const toolsWithItems = tools.filter(tool => tool.pans?.length)
    for (const toolWithItem of toolsWithItems) {
      const item = drawing[toolWithItem.name]
      if (item?.colorTags) return item.colorTags
    }
  }
)
