import patchify from './patchify'


export default (node, {
  handleChange,
  transformerNode,
  type
}, config) => {
  const { stage } = config

  let draggableNode

  if (['line', 'arrow'].includes(type)) {
    draggableNode = transformerNode
  } else {
    draggableNode = node
  }

  if (!draggableNode) return

  const logDragEvent = event => {
    if (!window.dragEvents) { window.dragEvents = [] }
    window.dragEvents.push({
      ...event,
      timeStamp: event.evt?.timeStamp
    })
  }

  setTimeout(() =>
    draggableNode.setAttr('draggable', true), 150)

  draggableNode.on('dragstart', event => {
    logDragEvent(event)
    stage.isDraggingNode = true
    stage.draggingPointerPosition = null
    stage.draggingNode = null
  })

  draggableNode.on('dragmove', event => {
    logDragEvent(event)
    stage.draggingPointerPosition = stage.getPointerPosition()
    stage.draggingNode = event.currentTarget
  })

  draggableNode.on('dragend', event => {
    logDragEvent(event)
    let { points, x, y } = event.currentTarget.attrs

    if (stage.isFreezingDraggingNode) {
      stage.isFreezingDraggingNode = false
    }
    stage.draggingPointerPosition = null

    const patch = {}

    if (['line', 'arrow'].includes(type)) {
      const [x1, y1, x2, y2] = node.points()
      patch.points = [x1 + x,
                      y1 + y,
                      x2 + x,
                      y2 + y]
    } else {
      Object.assign(patch, { points, x, y })
    }

    if (!event.hasJustDragendTransform) {
      handleChange(event, patchify(type, patch, config))
    }
    stage.isDraggingNode = false
  })

  node.draggableNode = draggableNode

  return draggableNode
}
