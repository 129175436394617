import PropTypes from 'prop-types'
import React from 'react'

const AddressOutsidePerimeter = ({ eligibilityInformation }) => {
  return (
    <div className="info">
      {'Consultez la'}
      <a
        href={eligibilityInformation}
        rel="noopener noreferrer"
        target="_blank"
      >
        {' liste des communes éligibles'}
      </a>
      {' au programme de diagnostics.'}
    </div>
  )
}

AddressOutsidePerimeter.propTypes = {
  eligibilityInformation: PropTypes.string.isRequired,
}

export default AddressOutsidePerimeter
