import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectLowestMeasurementByDossierIdAndItemName from 'redux/selectors/selectLowestMeasurementByDossierIdAndItemName'


const Heating = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="heating2"
    >
      {'Chauffage II'}
    </h1>
    <div className="entry-subtitle">
      <div>
        {'Climatisation réversible, PAC'}
      </div>
    </div>
    <MeasurementBlocks
      itemName="air-conditioner"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            extraLabel=" du groupe extérieur"
            name="altitude"
          />
          <Property
            label="Groupe extérieur inondé"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <div className="entry-subtitle">
      <div>
        {'Chaudière'}
      </div>
    </div>
    <MeasurementBlocks
      itemName="boiler"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la chaudière"
            name="altitude"
          />
          <Property
            label="Chaudière inondée"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <div className="entry-subtitle">
      <div>
        {'Citerne ou cuve intérieure'}
      </div>
    </div>
    <MeasurementBlocks
      itemName="tank"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            label="Localisation"
            name="comment"
          />
          <Property
            extraLabel=" de la citerne ou cuve"
            name="altitude"
          />
          <Property
            label="Citerne ou cuve intérieure inondée"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="indoor-tank"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            label="Localisation"
            name="comment"
          />
          <Property
            extraLabel=" de la citerne ou cuve"
            name="altitude"
          />
          <Property
            label="Citerne ou cuve intérieure inondée"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <div className="entry-subtitle">
      <div>
        {'Chauffe-eau'}
      </div>
    </div>
    <MeasurementBlocks
      itemName="hot-water-tank"
      select={selectLowestMeasurementByDossierIdAndItemName}
      showHasItem
    >
      {() => (
        <Block>
          <Property
            extraLabel=" du chauffe-eau"
            name="altitude"
          />
          <Property
            label="Chauffe-eau inondé"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="heatingComment"
    />
    <TextareaField
      label="Préconisations : "
      name="heatingRecommendation"
    />
  </div>
)

export default Heating
