import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import selectFieldByName from 'redux/selectors/selectFieldByName'

import MeasurementsContext from './MeasurementsContext'
import ToForm from './ToForm'


const renderValue = value => {
  if (value === false) {
    return 'Non'
  }
  if (value === true) {
    return 'Oui'
  }
  if (!value && isNaN(value)) {
    return '-'
  }
  return value
}


const Column = ({ name, toForm, suffixLabelColumn, label }) => {
  const { columnsCount, measurements } = useContext(MeasurementsContext)

  const fieldName = name && `sketches[*].measurements[*].${name}`
  const field = useSelector(state =>
    fieldName && selectFieldByName(state, fieldName), [fieldName])

  let columnLabel = undefined
  if( label !== undefined ){
    columnLabel = label
  } else if( suffixLabelColumn !== undefined ){
    columnLabel = field?.label + suffixLabelColumn 
  } else {
    columnLabel = field?.label
  }

  return (
    <div className={classnames('measurement-column', `col-gap-1of${columnsCount}`)}>
      <div className="measurement-column-label">
        {columnLabel}
      </div>
      {measurements.map(measurement => (
        <div
          className={classnames('measurement-column-content', {
            'description': name === 'flooringDescription',
            'form': toForm,
            'value': name && name !== 'flooringDescription',
          })}
          key={measurement.activityIdentifier}
        >
          {toForm && <ToForm measurement={measurement} />}
          {name && renderValue(measurement[name])}
        </div>
      ))}
    </div>
  )
}


Column.defaultProps = {
  label: undefined,
  name: null,
  suffixLabelColumn: undefined,
  toForm: null
}

Column.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  suffixLabelColumn: PropTypes.string,
  toForm: PropTypes.bool
}


export { MeasurementsContext }
export default Column
