import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'redux-react-modals'

import useInitialScrollToForPWA from 'components/hooks/useInitialScrollToForPWA'
import Notification from 'components/layout/Notification'

const Main = ({ Tag, children, name, withFullHeight }) => {
  const ref = useRef()
  const [withFooter, setWithFooter] = useState()
  const [withHeader, setWithHeader] = useState()

  useEffect(() => {
    setWithHeader(ref.current.previousElementSibling?.classList.contains('header'))
    setWithFooter(ref.current.nextElementSibling?.classList.contains('footer'))
  }, [ref, setWithFooter, setWithHeader])

  useInitialScrollToForPWA()

  return (
    <Tag
      className={classnames('main', name, {
        'fullheight': withFullHeight,
        'with-footer': withFooter,
        'with-header': withHeader
      })}
      ref={ref}
    >
      <Modal name="main" />
      <Modal name="error" />
      <Notification />
      {children}
    </Tag>
  )
}

Main.defaultProps = {
  Tag: 'main',
  name: null,
  withFullHeight: false
}

Main.propTypes = {
  Tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  withFullHeight: PropTypes.bool
}

export default Main
