import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import ToForm from 'components/layout/MeasurementTables/Table/Rows/Column/ToForm'
import ToSketchMeasurement from 'components/layout/ToSketchMeasurement'
import selectFieldByName from 'redux/selectors/selectFieldByName'

import MeasurementContext from '../MeasurementContext'


const isEmptyValueFrom = value => {
  if (value == null) return true
  if (value.toString && value.toString() === "NaN") return true
  return false
}


const Property = ({ extraLabel, label, name, withInlineForm }) => {
  const { measurement } = useContext(MeasurementContext)

  const fieldName = `sketches[*].measurements[*].${name}`

  const field = useSelector(state =>
    selectFieldByName(state, fieldName))


  const displayedValue = useMemo(() => {
    let value = (measurement || {})[name]

    if ( field?.values ){
      value = field.values[value]
    }

    if (typeof value === 'undefined'
        || value === null
        || (typeof value === 'string' && !value.trim())) {
      return
    }

    if (value === true) return 'Oui'
    if (value === false) return 'Non'

    return value
  }, [field, measurement, name])


  // TODO : directly use Field to handle format of the value
  // return (
  //  <Field
  //    label={`${label || field?.label || ''}${extraLabel} : `}
  //    name={fieldName}
  //    notRegistered
  //    readOnly
  //    value={value}
  //  />
  //)

  const propertyLabel = `${label || field?.label || ''}${extraLabel} : `

  return (
    <div className="measurement-property">
      <label className="measurement-property-label">
        {propertyLabel}
      </label>
      <div className="measurement-property-value">
        {isEmptyValueFrom(displayedValue)
          ? '-'
          : (
            <>
              {displayedValue}
              <span className="unit">
                {field?.unit || ''}
              </span>
              {!withInlineForm && <ToSketchMeasurement measurement={measurement} />}
              {withInlineForm &&
              <ToForm
                measurement={measurement}
              />}
            </>
           )}
      </div>
    </div>
  )
}


Property.defaultProps = {
  extraLabel: '',
  label: '',
  withInlineForm: false,
}


Property.propTypes = {
  extraLabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  withInlineForm: PropTypes.bool,
}

export default Property
