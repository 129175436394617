import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'redux-react-modals'


const ConfirmDeleteSketchModal = ({ name, onConfirm }) => {
  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    dispatch(closeModal('main'))
  }, [dispatch])

  const handleConfirm = useCallback(() => {
    dispatch(closeModal('main'))
    setTimeout(onConfirm)
  }, [dispatch, onConfirm])


  return (
    <div className="confirm-or-cancel-modal">
      <div>
        <div className="title">
          {`Confirmez-vous la suppression de la vue «${name}» ?`}
          <br />
        </div>
        <div className="controls">
          <button
            className="confirm"
            onClick={handleConfirm}
            type="button"
          >
            {'Supprimer'}
          </button>
          <button
            className="cancel"
            onClick={handleCancel}
            type="button"
          >
            {'Annuler'}
          </button>
        </div>
      </div>
    </div>
  )
}


ConfirmDeleteSketchModal.propTypes = {
    name: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired
}


export default ConfirmDeleteSketchModal
