import { queue } from 'd3-queue'
import L from 'leaflet'

import styles from 'utils/styles'

const cacheBusterDate = +new Date()

// explore here https://codepen.io/epidemiks/details/vKZQOz
export const OSM_TILE = {
  attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  icon: 'la-image',
  url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
}

export const SATELLITE_TILE = {
  attribution: '&copy;<a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  icon: 'la-map',
  url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
}

export const ZOOM_DELTA = 0.25

export const MIN_SIG_SIZE = {
  size: parseInt(styles['min-width-app']),
}

export const SIG_SIZE = {
  height: parseInt(styles['tablet-width']),
  width: parseInt(styles['tablet-width'])
}

export const IMAGE_SIZE = {
  height: 2 * SIG_SIZE.height,
  width: 2 * SIG_SIZE.width
}

export const SCALED_DISTANCE_IN_METERS_BY_ZOOM_INDEX = [
  3000000.,
  3000000.,
  3000000.,
  2000000.,
  1000000.,
  300000., // zoom 5
  200000.,
  50000.,
  30000.,
  20000.,
  10000.,  // zoom 10
  5000.,
  2000.,
  1000.,
  500.,
  300.,    // zoom 15
  100.,
  50.,
  20.,
  20.,
  10.,    // zoom 20
  5.,
  2.,
  1.,
  1.,
  1.
]

export const scaleFrom = (zoom, metersCountPerPixel) => {
  const distanceInMeters = SCALED_DISTANCE_IN_METERS_BY_ZOOM_INDEX[parseInt(zoom)] || 1.
  const width = distanceInMeters / metersCountPerPixel

  let distance = distanceInMeters
  let unit = 'm'

  if (distance >= 1000) {
    distance = distance / 1000.
    unit = 'km'
  }

  return {
    distance,
    unit,
    width
  }
}

export const leafletImage = (map, callback, options = {}) => {
  const mapSize = map.getSize()
  const sizeRatio = {
    x: IMAGE_SIZE.width / mapSize.x,
    y: IMAGE_SIZE.height / mapSize.y
  }
  const layerQueue = new queue(1)

  const canvas = document.createElement('canvas')
  canvas.height = IMAGE_SIZE.height
  canvas.width = IMAGE_SIZE.width
  const ctx = canvas.getContext('2d')

  // dummy canvas image when loadTile get 404 error
  // and layer don't have errorTileUrl
  const dummycanvas = document.createElement('canvas')
  dummycanvas.width = 1
  dummycanvas.height = 1
  const dummyctx = dummycanvas.getContext('2d')
  dummyctx.fillStyle = 'rgba(0,0,0,0)'
  dummyctx.fillRect(0, 0, 1, 1)

  // layers are drawn in the same order as they are composed in the DOM:
  // tiles, paths, and then markers
  const zIndexsortedTileLayers = []
  map.eachLayer(layer => layer instanceof L.TileLayer && zIndexsortedTileLayers.push(layer))
  zIndexsortedTileLayers.sort((l1, l2) =>
    parseInt(l1.options.zIndex || 0) - parseInt(l2.options.zIndex || 0) > 0
      ? 1
      : -1)
  zIndexsortedTileLayers.forEach(drawTileLayer)

  map.eachLayer(drawEsriDynamicLayer)

  if (map._pathRoot) {
    layerQueue.defer(handlePathRoot, map._pathRoot)
  } else if (map._panes) {
    const canvases = map._panes.overlayPane.getElementsByTagName('canvas')
    if (canvases) {
      for (let i = 0; i < canvases.length; i++) {
        layerQueue.defer(handlePathRoot, canvases[i])
      }
    }
  }
  map.eachLayer(drawMarkerLayer)
  layerQueue.awaitAll(layersDone)


  function drawTileLayer(l) {
    if (l instanceof L.TileLayer) layerQueue.defer(handleTileLayer, l)
    else if (l._heat) layerQueue.defer(handlePathRoot, l._canvas)
  }

  function drawMarkerLayer(l) {
    if (l instanceof L.Marker && l.options.icon instanceof L.Icon) {
      layerQueue.defer(handleMarkerLayer, l)
    }
  }

  function drawEsriDynamicLayer(l) {
    if (!L.esri) return

    if (l instanceof L.esri.DynamicMapLayer) {
      layerQueue.defer(handleEsriDymamicLayer, l)
    }
  }

  function done() {
    callback(null, canvas)
    canvas.height = 0
    canvas.width = 0
  }

  function layersDone(err, layers) {
    if (err) throw err
    layers.forEach(function (layer) {
      if (layer && layer.canvas) {
        ctx.drawImage(layer.canvas, 0, 0)
        layer.canvas.height = 0
        layer.canvas.width = 0
      }
    })
    done()
  }

  function handleTileLayer(layer, callback) {
    const canvas = document.createElement('canvas')

    canvas.height = IMAGE_SIZE.height
    canvas.width = IMAGE_SIZE.width

    const ctx = canvas.getContext('2d')

    const tileQueue = new queue(1)
    if (!Object.keys(layer._tiles).length) return callback()

    const zoom = map.getZoom()

    let mostNorthernY = Number.MAX_VALUE
    let mostWesternX = Number.MAX_VALUE
    let tileSize = 0
    let tiles = []

    Object.keys(layer._tiles).forEach((tileKey) => {
      const tile = layer._tiles[tileKey]
      let [x, y] = tileKey.split(':')
      if (!tiles[x]) { tiles[x] = {} }
      tiles[x][y] = tile
      const tileBounds = layer._tileCoordsToBounds(tile.coords)
      const tileNorthWestPoint = map.latLngToContainerPoint(tileBounds.getNorthWest(), zoom)
      if (tileNorthWestPoint.x < mostWesternX) { mostWesternX = tileNorthWestPoint.x }
      if (tileNorthWestPoint.y < mostNorthernY) { mostNorthernY = tileNorthWestPoint.y }
      const tileSouthWestPoint = map.latLngToContainerPoint(tileBounds.getSouthWest(), zoom)
      tileSize = Math.max(tileSize, tileSouthWestPoint.x - tileNorthWestPoint.x, tileSouthWestPoint.y - tileNorthWestPoint.y)
    })

    Object.keys(tiles).sort().forEach((x, x_index) => {
      Object.keys(tiles[x]).sort().forEach((y, y_index) => {
        const tile = tiles[x][y]
        const url = addCacheBusterStringIfNoCache(layer.getTileUrl(tile.coords))
        const tileNorthWestPoint = { x: mostWesternX + x_index * tileSize, y: mostNorthernY + y_index * tileSize }
        tileQueue.defer(loadTile, url, tileNorthWestPoint, tileSize)
      })
    })

    tileQueue.awaitAll(tileQueueFinish)

    function loadTile(url, tilePos, tileSize, callback) {
      const im = new Image()
      im.crossOrigin = ''
      im.onload = function () {
        callback(null, {
          img: this,
          pos: tilePos,
          size: tileSize
        })
      }
      im.onerror = function (e) {
        // use canvas instead of errorTileUrl if errorTileUrl get 404
        if (layer.options.errorTileUrl !== '' && e.target.errorCheck === undefined) {
          e.target.errorCheck = true
          e.target.src = layer.options.errorTileUrl
        } else {
          callback(null, {
            img: dummycanvas,
            pos: tilePos,
            size: tileSize + 0.5
          })
        }
      }
      im.src = url
    }

    function tileQueueFinish(_err, data) {
      data.forEach(drawTile)
      callback(null, { canvas })
    }

    function drawTile(d) {
      const x = sizeRatio.x * d.pos.x
      const y = sizeRatio.y * d.pos.y
      const sizeX = sizeRatio.x * d.size
      const sizeY = sizeRatio.y * d.size
      ctx.drawImage(d.img, x, y, sizeX, sizeY)
    }
  }

  function handlePathRoot(root, callback) {
    const bounds = map.getPixelBounds()
    const origin = map.getPixelOrigin()
    const canvas = document.createElement('canvas')
    canvas.height = IMAGE_SIZE.height
    canvas.width = IMAGE_SIZE.width
    const ctx = canvas.getContext('2d')
    const opacity = root.style.opacity ? root.style.opacity : 1
    const pos = L.DomUtil.getPosition(root).subtract(bounds.min).add(origin)
    try {
      if (opacity !== 1) ctx.globalAlpha = opacity

      const x = sizeRatio.x * pos.x
      const y = sizeRatio.y * pos.y
      const sizeX = canvas.width - (2 * x)
      const sizeY = canvas.height - (2 * y)

      ctx.drawImage(root, x, y, sizeX, sizeY)

      if (opacity !== 1) ctx.globalAlpha = 1
      callback(null, { canvas })
    } catch (e) {
      console.error('Element could not be drawn on canvas', root) // eslint-disable-line no-console
    }
  }

  function handleMarkerLayer(marker, callback) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const pixelBounds = map.getPixelBounds()
    const minPoint = new L.Point(pixelBounds.min.x, pixelBounds.min.y)
    /* eslint-disable no-useless-escape */
    const pixelPoint = map.project(marker.getLatLng())
    const isBase64 = /^data\:/.test(marker._icon.src)
    const url = isBase64 ? marker._icon.src : addCacheBusterStringIfNoCache(marker._icon.src)
    const im = new Image()
    const options = marker.options.icon.options
    let size = options.iconSize
    const pos = pixelPoint.subtract(minPoint)
    const anchor = L.point(options.iconAnchor || (size && size.divideBy(2, true)))

    if (size instanceof L.Point) size = [size.x, size.y]

    canvas.height = IMAGE_SIZE.height
    canvas.width = IMAGE_SIZE.width

    const x = Math.round(sizeRatio.x * (pos.x - size[0] + anchor.x))
    const y = Math.round(sizeRatio.y * (pos.y - anchor.y))
    const sizeX = sizeRatio.x * size[0]
    const sizeY = sizeRatio.y * size[1]

    im.crossOrigin = ''

    im.onload = function () {
      ctx.drawImage(this, x, y, sizeX, sizeY)
      callback(null, { canvas })
    }

    im.src = url

    if (isBase64) im.onload()
  }

  function handleEsriDymamicLayer(dynamicLayer, callback) {
    const canvas = document.createElement('canvas')
    canvas.height = IMAGE_SIZE.height
    canvas.width = IMAGE_SIZE.width

    const ctx = canvas.getContext('2d')

    const im = new Image()
    im.crossOrigin = ''
    im.src = addCacheBusterStringIfNoCache(dynamicLayer._currentImage._image.src)

    im.onload = function () {
      ctx.drawImage(im, 0, 0)
      callback(null, { canvas })
    }
  }

  function addCacheBusterStringIfNoCache(url) {
    if (!options.noCache) {
      return url
    }
    // workaround for https://github.com/mapbox/leaflet-image/issues/84
    if (!url) return url
    // If it's a data URL we don't want to touch this.
    if (isDataURL(url) || url.indexOf('mapbox.com/styles/v1') !== -1) {
      return url
    }
    return url + ((url.match(/\?/)) ? '&' : '?') + 'cache=' + cacheBusterDate
  }

  function isDataURL(url) {
    /* eslint-disable no-useless-escape */
    const dataURLRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
    return !!url.match(dataURLRegex)
  }
}

