import { formatToUpperCase } from 'utils/form'

const HotWaterTank = [
    {
        componentType: "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : CE1",
    },
    {
        componentType: "NumberField",
        label: "Hauteur au sol : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType: "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType: "RadiosField",
        isBoolean: true,
        label: "Arrimé ",
        name: "tiedUp",
    },
    {
        componentType: "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
    {
        componentType: "PictureField",
        folderPath: "photos",
        label: "Photo : ",
        name: "photo",
    },
]

export default HotWaterTank