import createDragger from '../dragger'
import createLabelizer from '../labelizer'
import createRescaler from '../rescaler'
import createSelector from '../selector'
import createTransformer from '../transformer'
import nodeCreatorsByType from './creators'

const nodify = (type, positionPatch, config) => {
  const {
    figureScale,
    layers,
    onChange,
    onClick,
    onDoubleClick,
    stage,
    tool,
    uuids
  } = config
  const {
    colorTag,
    index,
    item: nodeItem,
    label,
    rotation,
    toolName,
    scaleX,
    scaleY,
    tool: nodeTool,
    uuid
  } = positionPatch

  if (!nodeItem && !nodeTool) return
 
  /* eslint-disable no-unused-vars */
  const {
    clickable,
    colorTag: tbd1,
    doubleClickable,
    labelable,
    itemName: tbd2,
    label: tbd3,
    name: tbd4,
    toolName: tbd5,
    rescaleEnabled,
    resizeEnabled,
    rotateEnabled,
    resizeHomothetic,
    scaleX: forcedScaleX,
    scaleY: forcedScaleY,
    svg: tdb6,
    type: tdb7,
    ...nodeAttrs
  } = (nodeItem || nodeTool)

  const handleChange = async (event, patch) => {
    stage.isFrozen = true
    await onChange(event, uuid, patch)
    stage.isFrozen = false
  }

  const measurementLayer = layers[1]

  const existingNode = stage.find(`#${uuid}`)[0]
  if (existingNode) {
    existingNode.moveToTop()
    let transformerNodeChosen = null
    if (!existingNode.transformerNode) {
      transformerNodeChosen = createTransformer(existingNode, {
                                                figureScale,
                                                handleChange,
                                                rescaleEnabled,
                                                resizeEnabled,
                                                resizeHomothetic,
                                                rotateEnabled,
                                                type
                                                }, config)
    } else {
      transformerNodeChosen = existingNode.transformerNode
    }
    let labelizerChosen = null
    if (existingNode.labelizerNode)
      labelizerChosen = existingNode.labelizerNode
    else {
      labelizerChosen = labelable && createLabelizer(existingNode, {
                                                    colorTag,
                                                    handleChange,
                                                    index,
                                                    label,
                                                    toolName,
                                                    type
                                                    }, config)
    }
    if (uuids.includes(uuid)) {
      if (tool && tool.name === 'selector') {
        createDragger(existingNode, {
                      handleChange,
                      transformerNode : transformerNodeChosen,
                      type
                      }, config)
      }
      if (transformerNodeChosen){
        measurementLayer.add(transformerNodeChosen)
        transformerNodeChosen.moveToTop()
      }
    } else if (labelizerChosen) {
      measurementLayer.add(labelizerChosen)
    }
    return
  }
  const node = nodeCreatorsByType[type](positionPatch, {...config, nodeItem, nodeTool})
  
  if (!node) return

  node.setAttrs({ id: uuid, ...nodeAttrs})

  createRescaler(node, {
    figureScale,
    forcedScaleX,
    forcedScaleY,
    rescaleEnabled,
    resizeEnabled,
    rotation,
    scaleX,
    scaleY,
    toolName,
    type
  })

  const labelizerNode = labelable && createLabelizer(node, {
    colorTag,
    handleChange,
    index,
    label,
    toolName,
    type
   }, config)

  if (tool && tool.name === 'selector') {
    createSelector(node, {
      clickable,
      doubleClickable,
      onClick,
      onDoubleClick,
      uuid
    }, config)
  }

  measurementLayer.add(node)

  if (uuids.includes(uuid)) {
    const transformerNode = createTransformer(node, {
      figureScale,
      handleChange,
      rescaleEnabled,
      resizeEnabled,
      resizeHomothetic,
      rotateEnabled,
      type
    }, config)
    if (tool && tool.name === 'selector') {
      createDragger(node, {
        handleChange,
        transformerNode,
        type
      }, config)
    }
    if (transformerNode) measurementLayer.add(transformerNode)
  } else if (labelizerNode) {
    measurementLayer.add(labelizerNode)
  }
}


export default ( measurements, config) => {
  if (!measurements) return
  measurements.forEach(measurement => {
    /* eslint-disable no-unused-vars */
    const {
      dossierId: tbd1,
      id: tbd2,
      sketchActivityIdentifier: tbd3,
      type,
      ...positionPatch
    } = measurement
    positionPatch.uuid = positionPatch.activityIdentifier
    delete positionPatch.activityIdentifier
    nodify(type, positionPatch, config)
  })
}
