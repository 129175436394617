import createCachedSelector from 're-reselect'

import selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier from './selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier'


const mapArgsToCacheKey = (state, dossierId, sketchActivityIdentifier, toolName) => `${dossierId || ''} ${sketchActivityIdentifier || ''} ${toolName || ''}`


export default createCachedSelector(
  selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier,
  (state, dossierId, sketchActivityIdentifier, toolName) => toolName,
  (measurements, toolName) => (measurements || []).filter(measurement =>
        measurement.toolName === toolName)
  )(mapArgsToCacheKey)
