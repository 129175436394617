import React from 'react'
import { useFormContext } from 'react-hook-form'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { materialOptions } from 'utils/diagnostic'
import { formatToUpperCase } from 'utils/form'

const Window = () => {
  const { readOnly } = useFormContext()


  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : P-1"
      />
      <NumberField
        label="Largeur : "
        name="windowWidth"
        placeholder="Ex. : 0,2"
        unitLabel='m'
      />
      <SelectField
        label="Matériau : "
        name="material"
        options={materialOptions}
        placeholder="Ex. : bois"
      />
      <RadiosField
        isBoolean
        label="Présence d'un volet roulant électrique "
        name="hasElectricalShutter"
      />
      <RadiosField
        isBoolean
        label="Présence d'une grille ou de barreaux "
        name="hasRailings"
      />
      <NumberField
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <RadiosField
        isBoolean
        label="Batardeau existant "
        name="hasBatardeau"
      />
      <NumberField
        label="Hauteur du batardeau : "
        name="batardeauHeight"
        placeholder="Ex. : 0,5"
        unitLabel='m'
      />
      <TextareaField
        label="Commentaire sur le batardeau existant (efficacité, type de batardeau) : "
        name="batardeauComment"
      />
      <TextareaField
        label="Commentaire ouvrant : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}

export default Window
