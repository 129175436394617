import classnames from 'classnames'

import styles from 'styles/index.scss'
import { IS_SAFARI, IS_STANDALONE } from 'utils/config'

document.documentElement.setAttribute('data-browser', navigator.userAgent)
document.body.className = classnames({
  'safari': IS_SAFARI,
  'standalone': IS_STANDALONE,
  'web': !IS_SAFARI && !IS_STANDALONE
})

export default styles