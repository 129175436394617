import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import selectLowestMeasurementByDossierIdAndItemName from 'redux/selectors/selectLowestMeasurementByDossierIdAndItemName'
import selectRefugeMeasurementsByDossierId from 'redux/selectors/selectRefugeMeasurementsByDossierId'
import selectTrapMeasurementsByDossierId from 'redux/selectors/selectTrapMeasurementsByDossierId'


export default () => {
  const { dossierId } = useParams()

  const { dossierParcels } = useSelector(state =>
    selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}

  const parcelsValue = useMemo(() =>
    (dossierParcels || []).sort((dp1, dp2) =>
      (""+dp1.isComplementary+dp1.code).localeCompare(dp2.isComplementary+dp2.code))
                                       .map(dp => dp.parcel.code)
                                       .join(', '), [dossierParcels])

   const { firstRefugeMeasurement, lowestElectricalMeasurement, trapMeasurements } = useSelector(state => ({
     firstRefugeMeasurement: (selectRefugeMeasurementsByDossierId(state, dossierId) || [])[0],
     lowestElectricalMeasurement: selectLowestMeasurementByDossierIdAndItemName(state, dossierId, 'elec-'),
     trapMeasurements: (selectTrapMeasurementsByDossierId(state, dossierId) || []),
   }), [dossierId])


  return useCallback((state, initial) => {
    if (!initial) return
    initial.parcels = parcelsValue

    initial.refugeSpacePresentFromMeasurements = typeof firstRefugeMeasurement !== 'undefined'
      ? 'Oui'
      : 'Non'
    if (lowestElectricalMeasurement) {
      initial.electricalLowestAltitude = lowestElectricalMeasurement.altitude
      initial.electricalLowestBaseFlooring = lowestElectricalMeasurement.baseFlooring
    }
    initial.wasteWaterOutdoorTrapPresent = typeof trapMeasurements[0] !== 'undefined'
      ? 'Oui'
      : 'Non'
    initial.wasteWaterOutdoorTrapLocation = trapMeasurements.length > 0
      ? trapMeasurements.map(m => m.comment).join('\n')
      : ''

    return initial
  }, [firstRefugeMeasurement, lowestElectricalMeasurement, parcelsValue, trapMeasurements])
}
