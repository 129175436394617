export const SHOW_NAVIGATION = 'SHOW_NAVIGATION'
export const CLOSE_NAVIGATION = 'CLOSE_NAVIGATION'


const initialState = { isActive: false }


export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NAVIGATION:
      return Object.assign({}, state, { isActive: true })
    case CLOSE_NAVIGATION:
      return Object.assign({}, state, { isActive: false })
    default:
      return state
  }
}


export const closeNavigation = () => ({ type: CLOSE_NAVIGATION })


export const showNavigation = () => ({ type: SHOW_NAVIGATION })
