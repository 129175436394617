import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

const DossierHero = () => {
  const { dossierId } = useParams()
  const {
    addressId,
    dehumanizedId,
    number: dossierNumber,
    specialistId,
  } =  useSelector(state => selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}
  const { firstName, lastName } = useSelector(state => selectEntityByKeyAndId(state, 'contacts', specialistId)) || {}
  const { city, number: addressNumber, postalCode, street, streetType } = useSelector(state => selectEntityByKeyAndId(state, 'addresses', addressId)) || {}

  if (!dossierNumber) return null

  return (
    <div className="dossier-hero">
      {dossierNumber}
      {dehumanizedId && (
        `(${dehumanizedId})`
        )}
      {firstName && (
        ` // ${firstName} ${lastName} `
      )}
      {addressNumber && (
        `${addressNumber} ${streetType} ${street}, ${postalCode} ${city}`
      )}
    </div>
  )
}

export default DossierHero