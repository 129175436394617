import imageCompression from 'browser-image-compression'
import { Group, Layer, Rect, Stage, Text } from 'konva'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import { LocalAsset } from 'redux/persistor'
import selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier from 'redux/selectors/selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier'
import { calculateHeight, distribute } from 'utils/drawing/konvaExtensions'
import populate from 'utils/drawing/populate'
import colorTool from 'utils/drawing/tools/colorTool'
import figureTool from 'utils/drawing/tools/figureTool'
import styles from 'utils/styles'



const colorsLegend = [
  {
    legendIndex: 0,
    legendLabel: 'À protéger (logement)',
    name: 'deep-cerulean'
  },
  {
    legendIndex: 1,
    legendLabel: 'À protéger (partie commune)',
    name: 'studio'
  },
  {
    legendIndex: 2,
    legendLabel: 'À protéger (activité économique)',
    name: 'zest'
  },
  { name: 'gray' }
]

const figuresLegendFirstList = [
  {
    name: 'flow-direction',
    tool: figureTool
  },
  {
    label: 'Mesure d’altitude en mètres NGF',
    name: 'tn-point',
    tool: figureTool
  },
  {
    name: 'mezza-attic-entrance',
    tool: figureTool
  },
  {
    label: 'Escalier vers étage ou sous-sol (sens de montée)',
    name: 'stairs',
    tool: figureTool
  },
  {
    name: 'level-threshold',
    tool: figureTool
  },
  {
    name: 'elevator',
    tool: figureTool
  },
  {
    label: 'Élément pouvant être emporté',
    name: 'floating-element',
    tool: figureTool
  },
  {
    name: 'well',
    tool: figureTool
  },
  {
    name: 'trap',
    tool: figureTool
  },
  {
    name: 'trap-door',
    tool: figureTool
  },
  {
    name: 'swimming-pool',
    tool: figureTool
  },
  {
    name: 'wall-partition',
    tool: figureTool
  }
]

const figuresLegendSecondList = [
  {
    label: 'Portail ou portillon',
    name: 'portal',
    tool: figureTool
  },
  {
    name: 'door',
    tool: figureTool
  },
  {
    name: 'french-door',
    tool: figureTool
  },
  {
    name: 'window',
    tool: figureTool
  },
  {
    name: 'shop-front',
    tool: figureTool
  },
  {
    name: 'ventilation',
    tool: figureTool
  },
  {
    name: 'other-water-opening',
    tool: figureTool
  },
  {
    name: 'air-conditioner',
    tool: figureTool
  },
  {
    name: 'tank',
    tool: figureTool
  },
  {
    name: 'electro-group',
    tool: figureTool
  },
  {
    name: 'other-stake',
    tool: figureTool
  },
  {
    name: 'boiler',
    tool: figureTool
  },
  {
    name: 'hot-water-tank',
    tool: figureTool
  },
  {
    name: 'elec-meter',
    tool: figureTool
  },
  {
    name: 'elec-switch',
    tool: figureTool
  },
  {
    name: 'elec-plug',
    tool: figureTool
  },
  {
    name: 'elec-radiator',
    tool: figureTool
  },
  {
    name: 'elec-boar',
    tool: figureTool
  },
  {
    name: 'activity-stake',
    tool: figureTool
  },
  {
    name: 'informatic-equipment',
    tool: figureTool
  },
  {
    name: 'network-equipment',
    tool: figureTool
  },
  {
    name: 'network-plug',
    tool: figureTool
  }
]

const toolItemsWithLegend = (legend, tool) => {
  let items = []
  legend.forEach(legendItem => {
    (tool || legendItem.tool).pans.forEach(pan => {
      const toolItem = pan.items.find(item => item.name === legendItem.name)
      if (toolItem) {
        items.push({ ...toolItem, ...legendItem })
      }
    })
  })
  return items
}

const SnaphotWithLegend = ({ backgroundLocalAsset, stage }) => {
  const { dossierId, sketchActivityIdentifier } = useParams()

  const measurements = useSelector(state =>
    selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier(state, dossierId, sketchActivityIdentifier))

  const usedColorToolItems = useMemo(() => toolItemsWithLegend(colorsLegend, colorTool)
    .filter(item => measurements.find(measurement => measurement.colorTag === item.tag
      || item.tag === 'no-recommendation')),
    [measurements])

  let usedFigureToolFirstPart = useMemo(() => toolItemsWithLegend(figuresLegendFirstList)
    .filter(item => measurements.find(measurement => measurement.itemName === item.name)),
    [measurements])

  usedFigureToolFirstPart = usedFigureToolFirstPart.concat(
    { label: 'Mur / cloison', name: 'wall-partition', svg: 'ico-30-mur.svg', tool: colorTool }
  )

  let usedFigureToolSecondPart = useMemo(() => toolItemsWithLegend(figuresLegendSecondList)
    .filter(item => measurements.find(measurement => measurement.itemName === item.name)),
    [measurements])

  let usedFigureToolItems = usedFigureToolFirstPart.concat(usedFigureToolSecondPart)

  const waterCourseFigureToolItems = {
    'eauCoursAutreDefault': {
      label: 'Cours d\'eau ou vallon autre',
      name: 'watercourse-other-valley',
      svg: 'ico-30-eauCoursAutre.svg',
      tool: colorTool
    },
    'eauCoursCanaliseDefault': {
      label: 'Cours d\'eau ou vallon canalisé non couvert',
      name: 'watercourse-uncovered-canalised-valley',
      svg: 'ico-30-eauCoursCanalise.svg',
      tool: colorTool
    },
    'eauCoursDefault': {
      label: 'Cours d\'eau permanent',
      name: 'watercourse-canalized-valleys-natural-artificial-bed ',
      svg: 'ico-30-eauCours.svg',
      tool: colorTool
    },
    'eauCoursIntermittentDefault': {
      label: 'Cours d\'eau intermittent',
      name: 'watercourse-intermittent-valley',
      svg: 'ico-30-eauCoursIntermittent.svg',
      tool: colorTool
    },
    'eauCoursInvisibleDefault': {
      label: 'Cours d\'eau ou vallon canalisé couvert',
      name: 'watercourse-covered-canalised-valley',
      svg: 'ico-30-eauCoursInvisible.svg',
      tool: colorTool
    },
    'eauSurfaceDefault': {
      label: 'Surface en eau permanente',
      name: 'permanent-surface-water',
      svg: 'ico-30-eauSurface.svg',
      tool: colorTool
    },
    'eauSurfaceIntrmtDefault': {
      label: 'Surface en eau intermittente',
      name: 'intermittent-surface-water',
      svg: 'ico-30-eauSurfaceIntrmt.svg',
      tool: colorTool
    }
  }


  const { programmeId } = useParams()
  const { code } = useSelector(state =>
    selectEntityByKeyAndId(state, 'programmes', programmeId)) || {}


  if (code === 'ardeche') {
    usedFigureToolItems = [waterCourseFigureToolItems['eauCoursDefault'],
    waterCourseFigureToolItems['eauCoursIntermittentDefault'],
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  } else if (code === 'calavon-coulon') {
    usedFigureToolItems = [waterCourseFigureToolItems['eauCoursDefault'],
    waterCourseFigureToolItems['eauCoursIntermittentDefault'],
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  } else if (code === 'capl') {
    usedFigureToolItems = [{
      ...waterCourseFigureToolItems['eauCoursDefault'],
      label: 'Cours d\'eau ou vallon à lit artificialisé naturel'
    },
    waterCourseFigureToolItems['eauCoursInvisibleDefault'],
    waterCourseFigureToolItems['eauCoursCanaliseDefault'],
    waterCourseFigureToolItems['eauCoursAutreDefault'],
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  } else if (code === 'CASA') {
    usedFigureToolItems = [
    {
      ...waterCourseFigureToolItems['eauCoursDefault'],
      label: 'Cours d\'eau ou vallon permanent'
    },
    {
      ...waterCourseFigureToolItems['eauCoursIntermittentDefault'],
      label: 'Cours d\'eau ou vallon intermittent'
    },
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  } else if (code === 'nantes') {
    usedFigureToolItems = [{
      ...waterCourseFigureToolItems['eauCoursDefault'],
      label: 'Cours d\'eau permanent à écoulement libre'
    },
    {
      ...waterCourseFigureToolItems['eauCoursInvisibleDefault'],
      label: 'Cours d\'eau permanent souterrain'
    },
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  } else if (code === 'nim-habitat' || code === 'nim-eco') {
    usedFigureToolItems = [{
      ...waterCourseFigureToolItems['eauCoursInvisibleDefault'],
      label: 'Cadereaux principaux'
    }].concat(usedFigureToolItems)
  } else if (code === 'tarn-amont') {
    usedFigureToolItems = [waterCourseFigureToolItems['eauCoursDefault'],
    waterCourseFigureToolItems['eauCoursIntermittentDefault'],
    waterCourseFigureToolItems['eauSurfaceDefault'],
    waterCourseFigureToolItems['eauSurfaceIntrmtDefault']].concat(usedFigureToolItems)
  }

  const flowDirection = usedFigureToolItems.find(item => item.name === 'flow-direction')

  if (flowDirection) {
    usedFigureToolItems = usedFigureToolItems.filter(item => item.name !== flowDirection.name)
    usedFigureToolItems.unshift(flowDirection)
  }


  const LEGEND_CONFIG = {
    colorLegendRectHeight: 23,
    colorLegendRectSpacingWithText: 17,
    colorLegendRectWidth: 45,
    disclaimersFrameHeight: 60,
    figureFrameAdditionalBottomPadding: 10,
    figureToolColumns: 4,
    figureToolIconSize: 36,
    figureToolIconSpacingWithText: 24,
    frameColor: 'black',
    frameHorizontalPadding: 21,
    frameThickness: 1.5,
    frameVerticalPadding: 24,
    legendFont: {
      fontFamily: styles['font-family-sans-serif'],
      fontSize: 24,
      fontStyle: 'normal 400',
      stroke: '#363636',
      strokeWidth: 0,
    },
    mapSize: 507 * 3,
    minHorizontalMarginBetweenColumns: 10,
    minVerticalMarginBetweenFigureToolLegends: 22,
    openingsFrameHeight: 60,
    sketchPadding: 0,
  }
  LEGEND_CONFIG.disclaimerFont = {
    fontSize: 22,
    ...LEGEND_CONFIG.legendFont,
    fontStyle: 'italic 600',
  }
  LEGEND_CONFIG.openingsFont = {
    ...LEGEND_CONFIG.legendFont,
    fontStyle: 'italic 400',
  }

  const backgroundDataUrl = backgroundLocalAsset?.dataUrl
  const hasBackgroundLoaded = typeof backgroundDataUrl !== 'undefined'


  const createLegendColorGroups = useCallback((usedColorToolItems) => {
    return usedColorToolItems.map((item) => {
      const colorHex = (item.name && styles[item.name]) || item.name
      const colorGroup = new Group({})
      const colorRect = new Rect({
        cornerRadius: 5,
        fill: colorHex,
        height: LEGEND_CONFIG.colorLegendRectHeight,
        width: LEGEND_CONFIG.colorLegendRectWidth,
        y: (LEGEND_CONFIG.openingsFrameHeight - LEGEND_CONFIG.colorLegendRectHeight) / 2,
      })
      colorGroup.add(colorRect)

      const colorText = new Text({
        height: LEGEND_CONFIG.openingsFrameHeight,
        text: item.legendLabel || item.label,
        ...LEGEND_CONFIG.openingsFont,
        verticalAlign: 'middle',
        x: colorRect.x()
          + LEGEND_CONFIG.colorLegendRectWidth
          + LEGEND_CONFIG.colorLegendRectSpacingWithText,
      })
      colorGroup.add(colorText)
      return colorGroup
    })
  }, [LEGEND_CONFIG])

  const createLegendFigureGroups = useCallback((usedFigureToolItems) => {
    let figureGroups = []
    let columns = Math.min(LEGEND_CONFIG.figureToolColumns, usedFigureToolItems.length)
    const maxColumnWidth = (LEGEND_CONFIG.mapSize
      - LEGEND_CONFIG.frameHorizontalPadding * 2
      - LEGEND_CONFIG.minHorizontalMarginBetweenColumns * (columns - 1))
      / columns
    let maxTextWidth = maxColumnWidth - LEGEND_CONFIG.figureToolIconSize - LEGEND_CONFIG.figureToolIconSpacingWithText

    usedFigureToolItems.forEach(item => {
      const svgElement = document.getElementById(item.svg)
      const figureGroup = new Group({
        height: LEGEND_CONFIG.figureToolIconSize,
      })

      const svgGroup = new Group({
        height: LEGEND_CONFIG.figureToolIconSize,
        scaleX: LEGEND_CONFIG.figureToolIconSize / 25,
        scaleY: LEGEND_CONFIG.figureToolIconSize / 25,
        strokeWidth: LEGEND_CONFIG.figureToolIconSize / 25,
      })

      populate(svgElement.querySelector('g'),
        svgGroup,
        { strokeScaleEnabled: true })
      //{ fill: colorHex, stroke: colorHex })

      figureGroup.add(svgGroup)

      const figureText = new Text({
        text: item.legendLabel || item.label,
        ...LEGEND_CONFIG.legendFont,
        x: LEGEND_CONFIG.figureToolIconSize + LEGEND_CONFIG.figureToolIconSpacingWithText,
        y: LEGEND_CONFIG.figureToolIconSize - LEGEND_CONFIG.legendFont.fontSize * 1.07,
      })

      figureText.width(maxTextWidth)

      figureGroup.add(figureText)
      figureGroups.push(figureGroup)
    })
    return figureGroups
  }, [LEGEND_CONFIG])

  const placeFigureGroups = useCallback((figureGroups, figureLegendGroup) => {

    const figureGroupsHeights = figureGroups.map(fg => calculateHeight(fg))

    const sumHeightsFigureGroups = figureGroupsHeights.reduce((acc, curr) => acc + curr, 0)

    const heightByColumn = Math.ceil(sumHeightsFigureGroups / LEGEND_CONFIG.figureToolColumns)

    function calculateHeightByColumn(columnizedFigureGroup) {
      return columnizedFigureGroup.reduce((a, v) => a + calculateHeight(v), 0)
    }

    let columnizedFigureGroups = [[]]
    let columnIndex = 0
    let currHeightColumnizedFigureGroup = 0
    figureGroups.forEach(figureGroup => {
      if (currHeightColumnizedFigureGroup >= heightByColumn) {
        columnIndex += 1
        columnizedFigureGroups[columnIndex] = []
        currHeightColumnizedFigureGroup = 0
      }
      columnizedFigureGroups[columnIndex].push(figureGroup)
      currHeightColumnizedFigureGroup = calculateHeightByColumn(columnizedFigureGroups[columnIndex])
    })

    function heighestColumnizedFigureGroup(colFigGrp) {
      let highestColumnHeight = 0
      let highestColumnLength = 0
      colFigGrp.forEach((item) => {
        if (highestColumnHeight < calculateHeightByColumn(item)) {
          highestColumnHeight = calculateHeightByColumn(item)
          highestColumnLength = item.length
        }
      })
      return [highestColumnHeight, highestColumnLength]
    }

    function indexOfLongestColumn(colFigGrp) {
      let value = 0
      let indexColumn = 0
      colFigGrp.forEach((item, index) => {
        if (value <= item.length) {
          value = item.length
          indexColumn = index
        }
        return indexColumn
      })
    }

    if (columnizedFigureGroups.length === 4) {
      if (columnizedFigureGroups[3].length
        <= (columnizedFigureGroups[2].length / 2)) {
        let popFigureGroup
        let indexPopFigureGroup = indexOfLongestColumn(columnizedFigureGroups)
        while (indexPopFigureGroup < 3) {
          popFigureGroup = columnizedFigureGroups[indexPopFigureGroup].pop()
          columnizedFigureGroups[indexPopFigureGroup + 1].push(popFigureGroup)
          indexPopFigureGroup++
        }
      }
    }

    const columnHeightWithMargins = heighestColumnizedFigureGroup(columnizedFigureGroups)[0]
      + 2 * LEGEND_CONFIG.frameVerticalPadding
      + (heighestColumnizedFigureGroup(columnizedFigureGroups)[1] - 1)
      * LEGEND_CONFIG.minVerticalMarginBetweenFigureToolLegends

    let columnGroups = []
    const lastColumnizedFigureGroup = columnizedFigureGroups.pop()
    columnizedFigureGroups.forEach((item, index) => {
      columnGroups[index] = new Group({ height: columnHeightWithMargins })
      distribute(columnGroups[index],
        item,
        'vertical',
        { padding: LEGEND_CONFIG.frameVerticalPadding })
    })

    const lastColumnHeightWithMargins = calculateHeightByColumn(lastColumnizedFigureGroup)
      + 2 * LEGEND_CONFIG.frameVerticalPadding
      + (lastColumnizedFigureGroup.length - 1)
      * LEGEND_CONFIG.minVerticalMarginBetweenFigureToolLegends

    columnGroups.push(new Group({ height: lastColumnHeightWithMargins }))
    distribute(columnGroups[columnGroups.length - 1],
      lastColumnizedFigureGroup,
      'vertical',
      { padding: LEGEND_CONFIG.frameVerticalPadding })

    distribute(figureLegendGroup,
      columnGroups,
      'horizontal',
      { padding: LEGEND_CONFIG.frameHorizontalPadding })
  }, [LEGEND_CONFIG])

  const addFramedGroup = useCallback((container, config) => {
    const group = new Group({ x: config.x, y: config.y })
    const frame = new Rect({
      ...config,
      height: config.height,
      stroke: LEGEND_CONFIG.frameColor,
      strokeWidth: LEGEND_CONFIG.frameThickness,
      width: config.width,
      x: 0,
      y: 0,
    })
    group.add(frame)
    container.add(group)
    return group
  }, [LEGEND_CONFIG])

  const addLegendLayer = useCallback((konvaStage) => {
    const legendLayer = new Layer()
    konvaStage.add(legendLayer)

    const background = new Rect({
      fill: 'white',
      height: konvaStage.width() * 1.6,
      width: konvaStage.width(),
      x: 0,
      y: 0
    })

    legendLayer.add(background)

    const frameWidth = LEGEND_CONFIG.mapSize + (LEGEND_CONFIG.frameThickness * 2) / 2
    const frameX = LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness / 2

    const sketchGroup = addFramedGroup(legendLayer, {
      height: LEGEND_CONFIG.mapSize + (LEGEND_CONFIG.frameThickness * 2) / 2,
      width: frameWidth,
      x: frameX,
      y: LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness / 2,
    })

    const disclaimerGroup = addFramedGroup(legendLayer, {
      height: LEGEND_CONFIG.disclaimersFrameHeight,
      stroke: '#b7b7b7',
      strokeWidth: 1,
      width: frameWidth,
      x: frameX,
      y: sketchGroup.y() + calculateHeight(sketchGroup),
    })

    const measurementDisclaimer = new Text({
      height: LEGEND_CONFIG.disclaimersFrameHeight,
      text: '* Toutes les cotes sont exprimées en mètres NGF.',
      ...LEGEND_CONFIG.disclaimerFont,
      verticalAlign: 'middle'
    })

    const scaleDisclaimer = new Text({
      height: LEGEND_CONFIG.disclaimersFrameHeight,
      text: 'Croquis sans échelle',
      ...LEGEND_CONFIG.disclaimerFont,
      verticalAlign: 'middle'
    })

    distribute(disclaimerGroup,
      [measurementDisclaimer, scaleDisclaimer],
      'horizontal',
      { padding: LEGEND_CONFIG.frameHorizontalPadding })

    const openingsGroup = addFramedGroup(legendLayer, {
      height: LEGEND_CONFIG.openingsFrameHeight,
      stroke: '#b7b7b7',
      strokeWidth: 1,
      width: frameWidth,
      x: LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness / 2,
      y: disclaimerGroup.y() + calculateHeight(disclaimerGroup),
    })

    legendLayer.add(openingsGroup)

    const openingsTitle = new Text({
      height: LEGEND_CONFIG.openingsFrameHeight,
      text: 'Couleurs des ouvrants :',
      verticalAlign: 'middle',
      x: LEGEND_CONFIG.frameHorizontalPadding,
      ...LEGEND_CONFIG.openingsFont,
    })

    distribute(openingsGroup,
      [openingsTitle].concat(createLegendColorGroups(usedColorToolItems)),
      'horizontal',
      { padding: LEGEND_CONFIG.frameHorizontalPadding })

    const figureLegendGroup = new Group({
      stroke: '#b7b7b7',
      strokeWidth: 1,
      width: LEGEND_CONFIG.mapSize,
      x: LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness / 2,
      y: openingsGroup.y() + calculateHeight(openingsGroup),
    })

    placeFigureGroups(createLegendFigureGroups(usedFigureToolItems),
      figureLegendGroup)

    legendLayer.add(figureLegendGroup)

    const figureFrame = new Rect({
      height: calculateHeight(figureLegendGroup) + LEGEND_CONFIG.figureFrameAdditionalBottomPadding,
      stroke: 'black',
      strokeWidth: LEGEND_CONFIG.frameThickness,
      width: frameWidth,
      x: frameX,
      y: openingsGroup.y() + calculateHeight(openingsGroup),
    })

    legendLayer.add(figureFrame)

    konvaStage.height(figureFrame.y()
      + calculateHeight(figureFrame)
      + (LEGEND_CONFIG.frameThickness / 2)
      + 1 // Arnaud: for some reason, we sometime miss one px of the border :-(
      + LEGEND_CONFIG.sketchPadding)

    return legendLayer
  }, [addFramedGroup, createLegendColorGroups, createLegendFigureGroups,
    placeFigureGroups, usedColorToolItems, usedFigureToolItems, LEGEND_CONFIG])

  const postSnapshot = useCallback(async () => {
    if (!hasBackgroundLoaded ||
      !stage ||
      stage.isDraggingNode ||
      stage.isDrawingNode ||
      stage.isTransformingNode ||
      stage.isTextingNode) {
      return
    }
    
    await new Promise(resolve => setTimeout(resolve))

    const layers = stage.getLayers()

    const snapshotWidth = LEGEND_CONFIG.mapSize + 2 * LEGEND_CONFIG.sketchPadding + 2 * LEGEND_CONFIG.frameThickness

    const snapshotStage = new Stage({
      container: 'snapshot-with-legend',
      height: snapshotWidth * 1.6,
      width: snapshotWidth,
    })

    addLegendLayer(snapshotStage)

    const snapshotLayers = layers.map(layer =>
      layer.clone({
        clip: {
          height: stage.height(),
          width: stage.width(),
          x: 0,
          y: 0,
        },
        listening: false,
        scaleX: LEGEND_CONFIG.mapSize / stage.width(),
        scaleY: LEGEND_CONFIG.mapSize / stage.height(),
        x: LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness,
        y: LEGEND_CONFIG.sketchPadding + LEGEND_CONFIG.frameThickness,
      }))
    
    const measurementSnapshotLayer = snapshotLayers[1]

    const transformerNodes = measurementSnapshotLayer.children.filter(
      child => child.className === 'Transformer')
    transformerNodes.forEach(node => node.hide())
    const groupsOfNodesWithTransformerNodes = measurementSnapshotLayer.children.filter(group => group.attrs.nodeId)
    groupsOfNodesWithTransformerNodes.forEach(group => group.children.forEach(childNode => {
      const isTransformerNode = !childNode.attrs.id
      if (isTransformerNode)
        childNode.hide()
    }))
    snapshotLayers.forEach(snapshotLayer => snapshotStage.add(snapshotLayer))

    const snapshotCanvas = snapshotStage.toCanvas()
    const snapshotFile = await imageCompression.canvasToFile(snapshotCanvas, 'image/png', 'snapshot', Date.now())

    const snapshotContainer = document.getElementById('snapshot-with-legend')
    if (snapshotContainer) {
      snapshotLayers.forEach(layer => {
        layer.clear()
        if (snapshotContainer.contains(layer.canvas._canvas)) {
          snapshotContainer.querySelector('[role="presentation"]')
            .removeChild(layer.canvas._canvas)
        }
      })
      snapshotStage.width(0)
      snapshotStage.height(0)
    }

    if (!snapshotFile) return

    return snapshotFile
    
  }, [addLegendLayer, hasBackgroundLoaded, stage, LEGEND_CONFIG])

  window.postSnapshot = postSnapshot

  return <div id='snapshot-with-legend' />
}

SnaphotWithLegend.defaultProps = {
  backgroundLocalAsset: null,
  stage: null
}

SnaphotWithLegend.propTypes = {
  backgroundLocalAsset: PropTypes.instanceOf(LocalAsset),
  stage: PropTypes.instanceOf(Stage)
}

export default SnaphotWithLegend
