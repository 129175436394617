import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldError from '../FieldError'
import TextField from './TextField'

const SelectField = props => {
  const {
    className,
    id,
    label,
    name,
    noAsterisk,
    options,
    placeholder,
    required,
    sublabel,
    type,
    validate,
    ...selectProps
  } = props
  const {
    register,
    watch,
    ...formContext
  } = useFormContext()
  const { readOnly } = { ...formContext, ...selectProps }
  const valueAsNumber = type === 'number'
  let value = watch(name)
  if (typeof value === 'undefined') {
    value = ''
  }

  const optionsWithPlaceholder = useMemo(() =>
    [{ label: placeholder, value: '' }].concat(options), [options, placeholder])

  const { label: selectedLabel } = useMemo(() =>
    options?.find(option => option.value === value), [options, value]) || {}

  if (!options) return null

  if (readOnly) {
    return selectedLabel ? (
      <TextField
        label={label}
        name={name}
        notRegistered
        readOnly
        sublabel={sublabel}
        value={selectedLabel}
      />
    ) : null
  }

  return (
    <div
      className={classnames('select-field', className, name)}
      id={id}
    >
      <div
        className={classnames('field-label', { empty: !label })}
        htmlFor={name}
      >
        <span>
          {label}
        </span>
        {required && !noAsterisk && (
          <span className="field-asterisk">
            {'*'}
          </span>)}
      </div>
      <div className="field-control">
        <div className="field-value">
          <div className="field-inner">
            <select
              {...selectProps}
              className={classnames('field-entry', {
                'is-placeholder': value === ''
              })}
              defaultValue={value}
              name={name}
              ref={register({ required, validate, valueAsNumber })}
            >
              {optionsWithPlaceholder.map(option => (
                <option
                  id={option.value}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}

SelectField.defaultProps = {
  className: '',
  disabled: false,
  id: null,
  label: '',
  noAsterisk: false,
  options: null,
  placeholder: 'Choisissez',
  required: false,
  sublabel: null,
  type: null,
  validate: null
}

SelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  sublabel: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func
}

export default SelectField
