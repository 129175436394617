import createCachedSelector from 're-reselect'

import selectMeasurementsWithComputedPropertiesByDossierId from './selectMeasurementsWithComputedPropertiesByDossierId'

const mapArgsToCacheKey = (state, dossierId, itemName) => `${dossierId || ''}${itemName || ''}`


export default createCachedSelector(
  selectMeasurementsWithComputedPropertiesByDossierId,
  (state, dossierId, itemName) => itemName,
  (measurements, itemName) => {
    if (!measurements) return
    return measurements.find(measurement =>
      measurement.itemName && measurement.itemName.startsWith(itemName))
  }
)(mapArgsToCacheKey)
