import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldError from '../FieldError'


const CheckboxesField = props => {
  const {
    className,
    disabled,
    id,
    label,
    name,
    noAsterisk,
    options,
    required,
  } = props
  const {
    register,
    watch,
    ...formContext
  } = useFormContext()
  const { readOnly, setValue } = formContext
  let value = watch(name)
  if (typeof value === 'undefined') {
    value = ''
  }

  const handleChange = useCallback(event => {
    setValue(name, event.target.value)
  }, [name, setValue])


  return (
    <div
      className={classnames('radios-field', className, name)}
      id={id}
    >
      <div
        className={classnames('field-label', { empty: !label })}
        htmlFor={name}
      >
        <span>
          {label}
        </span>
        {required && !noAsterisk && (
          <span className="field-asterisk">
            {'*'}
          </span>)}
      </div>
      <div className="field-control">
        <div className="field-value">
          <div className="field-inner">
            <input
              name={name}
              ref={register({ required })}
              type="hidden"
            />
            {options.map(({ label, value: optionValue }, index) => (
              <div
                className="radio"
                key={index}
              >
                <input
                  checked={optionValue === value}
                  className="field-entry"
                  disabled={readOnly || disabled}
                  onChange={handleChange}
                  type='checkbox'
                  value={optionValue}
                />
                {label}
              </div>))}
          </div>
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}

CheckboxesField.defaultProps = {
  className: '',
  disabled: false,
  id: null,
  label: '',
  noAsterisk: false,
  options: null,
  required: false
}

CheckboxesField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

export default CheckboxesField
