import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import Annex from './Annex'
import AnnexesDoorFrames from './AnnexesDoorFrames'
import AnnexesVentilations from './AnnexesVentilations'
import AssemblyDescription from './AssemblyDescription'
import Basement from './Basement'
import WasteWater from './WasteWater'
import Evacuation from './Evacuation'
import Electricity from './Electricity'
import ExternalArrangement from './ExternalArrangement'
import Heating from './Heating'
import Household from './Household'
import PerceptionAndRiskCulture from './PerceptionAndRiskCulture'
import PrincipalDoorFrames from './PrincipalDoorFrames'
import RefugeSpace from './RefugeSpace'
import PrincipalVentilations from './PrincipalVentilations'


const Visit = () => {
  const { dossierId } = useParams()
  const { outbuildingsCount } = useSelector(state =>
    selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}
  return (
    <>
      <Household />
      <AssemblyDescription />
      <PerceptionAndRiskCulture />
      <Evacuation />
      <RefugeSpace />
      <Basement />
      <PrincipalDoorFrames />
      <PrincipalVentilations />
      <WasteWater />
      <Electricity />
      <Heating />
      {Array(outbuildingsCount).fill()
                               .map((_, i) => i+1)
                               .map(i => (<Annex key={i} number={i} />))}
      <AnnexesDoorFrames />
      <AnnexesVentilations />
      <ExternalArrangement />
    </>
  )
}

export default Visit
