import { Group } from 'konva'

import styles from 'utils/styles'

import { allCoordsNotEmpty } from '../../distance'
import populate from '../../populate'
import { selectColorByTag } from '../../selectors'


export default (positionPatch, config) => {
  const { latLngToKonvaPoint } = config
  const {
    colorTag,
    labelDiffLat,
    labelDiffLon,
    lat,
    lon,
    scaleX,
    scaleY,
    svg
  } = positionPatch

  if (!allCoordsNotEmpty(lat,lon)) {
    console.error('Invalid properties for symbol : ', { lat, lon })
    return
  }

  const position = [lat, lon]
  const centerKonvaPoint = latLngToKonvaPoint(position)

  let labelX, labelY
  if (allCoordsNotEmpty(labelDiffLat, labelDiffLon)) {
    const labelPosition = [lat + labelDiffLat, lon + labelDiffLon]
    const labelKonvaPoint = latLngToKonvaPoint(labelPosition)
    labelX = labelKonvaPoint.x
    labelY = labelKonvaPoint.y
  }

  const node = new Group({
    ...centerKonvaPoint,
    labelX,
    labelY,
    scaleX,
    scaleY
  })

  const svgElement = document.getElementById(svg)

  if (!svgElement) return

  const colorHex = colorTag && styles[selectColorByTag(colorTag).name]
  populate(svgElement.querySelector('g#Symbols'),
           node,
           { fill: colorHex, stroke: colorHex })
  return node
}
