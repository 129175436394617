import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useDropzone } from 'react-dropzone'

const Dropzone = ({ children, className, disabled, onDrop }) => {
  const {getRootProps, getInputProps} = useDropzone({
    disabled,
    multiple: true,
    onDrop
  })
  return (
    <div
      className={classnames(className, { disabled })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

Dropzone.defaultProps = {
  disabled: false,
}

Dropzone.propTypes = {
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired
}

export default Dropzone
