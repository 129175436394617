import createCachedSelector from 're-reselect'

import selectMeasurementsWithIndexByDossierId from './selectMeasurementsWithIndexByDossierId'


const mapArgsToCacheKey = (state, dossierId, sketchActivityIdentifier) => `${dossierId || ''} ${sketchActivityIdentifier || ''}`


const typesOrderedByDepth = [
  'polygon',
  'rectangle',
  'triangle',
  'circle',
  'line',
  'arrow',
  'symbol',
  'text'
]


export default createCachedSelector(
  selectMeasurementsWithIndexByDossierId,
  (state, dossierId, sketchActivityIdentifier) => sketchActivityIdentifier,
  (measurements, sketchActivityIdentifier) => {
    if (!measurements) return

    const depthMeasurements = measurements
      .filter(measurement => measurement.sketchActivityIdentifier === sketchActivityIdentifier)
      .map(measurement => {
          let depth = measurement.depth
          if (typeof depth === 'undefined' || depth === null) {
            depth = typesOrderedByDepth.indexOf(measurement.type)
          }
          return {
            ...measurement,
            depth
          }
        })

    depthMeasurements.sort((m1, m2) => m1.depth < m2.depth ? -1:1)

    return depthMeasurements
  }
)(mapArgsToCacheKey)
