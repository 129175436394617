import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'

import Icon from 'components/layout/Icon'

const OverlayDownloadArchive = ({hasDownloadedArchiveOnce, isDisabled, isDownloading}) => {

    const [textDownloadStatus, setTextDownloadStatus] = useState('Téléchargement en préparation.')
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if (isDisabled) {
            setShowLoader(true)
            setTextDownloadStatus('Téléchargement en préparation.')
        } else if (!hasDownloadedArchiveOnce || isDownloading) {
            setShowLoader(true)
            setTextDownloadStatus('Téléchargement en cours.')
        } else {
            setShowLoader(false)
            setTextDownloadStatus('Téléchargement terminé.')
        }
    }, [isDisabled, isDownloading, hasDownloadedArchiveOnce])

    return (
      <div className="overlay-download-archive">
        { textDownloadStatus }
        { showLoader && <Icon name="ico-30-loading.svg" /> }
      </div>
    )
}

OverlayDownloadArchive.propTypes = {
    hasDownloadedArchiveOnce: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isDownloading : PropTypes.bool.isRequired,
}
export default OverlayDownloadArchive
