import PropTypes from 'prop-types'

export default PropTypes.shape({
  bbox: PropTypes.arrayOf(PropTypes.number).isRequired,
  commune: PropTypes.string,
  contenance: PropTypes.number,
  created: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  numero: PropTypes.string,
  prefixe: PropTypes.string,
  section: PropTypes.string,
  updated: PropTypes.string
})
