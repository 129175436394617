import { formatToUpperCase } from 'utils/form'

const OtherStake = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : AE1",
    },
    {
        componentType: "TextField",
        label : "Nom : ",
        name : "name",
        placeholder : "Ex. : une cabane au fond du jardin",
    },
    {
        componentType: "NumberField",
        label : "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm',
    },
    {
        componentType: "TextField",
        format : {formatToUpperCase},
        label : "Plancher ou T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0",
    },
    {
        componentType: "TextareaField",
        label : "Commentaire : ",
        name : "comment",
    },
]

export default OtherStake
