import React, { useCallback, useState } from 'react'
import { TileLayer } from 'react-leaflet'

import Icon from 'components/layout/Icon'


const SwichLayerControl = ({ firstTile, secondTile }) => {
  const [isFirst, setIsFirst] = useState(true)

  const handleSwitch = useCallback(event => {
    event.stopPropagation()
    setIsFirst(!isFirst)
  }, [isFirst])

  const tileLayerProps = isFirst
    ? firstTile
    : secondTile

  return (
    <>
      <button
        className='switch-layer-control'
        onClick={handleSwitch}
        type="button"
      >
        <Icon name={tileLayerProps.icon} />
      </button>
      <TileLayer {...tileLayerProps} />
    </>
  )
}

export default SwichLayerControl
