import React from 'react'

import ToSketchTool from 'components/layout/ToSketchTool'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'


const AnnexesVentilations = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="annexes-ventilations"
    >
      {'Ventilations Annexe(s) I'}
    </h1>
    <p className="entry-info">
      {'Les ventilations qui apparaissent ici sont celles dont les étiquettes commencent par la lettre A.'}
    </p>
    <MeasurementTables name="annexes-ventilations">
      {measurement => (
        <Table>
          <Column name="fromGroundHeight" />
          <Column name="baseFlooring" />
        </Table>)}
    </MeasurementTables>
    <ToSketchTool name="figure">
      {'Ajouter une ventilation au croquis'}
    </ToSketchTool>
  </div>
)

export default AnnexesVentilations
