import React from 'react'
import { useSelector } from 'react-redux'

const Notification = () => {
  const notification = useSelector(state => state.notification) || {}
  const { text } = notification

  if (!notification) return null

  return (
    <div className="notification">
      {text}
    </div>
  )
}


export default Notification
