import { useCallback } from 'react'
import { useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId, selectEntityByActivityIdentifier} from 'redux-thunk-data'

import selectMeasurementsWithIndexByDossierIdAndRowsName from 'components/layout/MeasurementTables/Table/Rows/selectMeasurementsWithIndexByDossierIdAndRowsName'
import selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName from 'redux/selectors/selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName'

import { createCsvContent } from './utils/createCsv'
import { generateFilename } from './utils/formatText'

const useCreateArchive = () => {

    const { programmeId, dossierId , sketchActivityIdentifier } = useParams()
    const { name : sketchName } =  useSelector(state => selectEntityByActivityIdentifier(state, sketchActivityIdentifier))
    const { name : programmeName } = useSelector(state =>
        selectEntityByKeyAndId(state, 'programmes', programmeId)) || {}
    const { number: dossierNumber } =  useSelector(state => selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}

    const surveyorMeasurements = useSelector(state =>
        selectMeasurementsWithIndexByDossierIdAndRowsName(state, dossierId, 'floorings-and-surveyor-studies'),
        [dossierId, 'floorings-and-surveyor-studies'])

    const figureMeasurements = useSelector(state => selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName(state, dossierId, sketchActivityIdentifier, 'figure'))

    const handleGeneratedFilename = useCallback((extension) => {
        return generateFilename(programmeName, dossierNumber, sketchName, extension)
    }, [programmeName, dossierNumber, sketchName])

    const handleCSVFromDataGeneration = useCallback(() => {
        const csv = createCsvContent(figureMeasurements, surveyorMeasurements)
        let generatedCSV = new Blob([csv], { type: 'text/csv;charset=utf-8,' })
        const csvFilename =  handleGeneratedFilename('.csv') || 'erreur-nom-de-fichier.csv'
        if (csv && csvFilename) return {csvFilename, generatedCSV}
        return {csvFilename : null, generatedCSV : null}
    }, [figureMeasurements, handleGeneratedFilename, surveyorMeasurements])

    const handleSnapshotGeneration = useCallback(async() => {
        if (window.postSnapshot){
            const generatedSnapshot = await window.postSnapshot().catch(() => false)
            const snapshotFilename = handleGeneratedFilename('.png') || 'erreur-nom-de-fichier.png'
            if (generatedSnapshot && snapshotFilename) return {generatedSnapshot, snapshotFilename}
        }
        return {generatedSnapshot : null, snapshotFilename : null}
    },[handleGeneratedFilename])

    return {handleCSVFromDataGeneration, handleGeneratedFilename, handleSnapshotGeneration}
}

export default useCreateArchive