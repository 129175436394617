import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { mergeData } from 'redux-thunk-data'

import { requestActivitiesWithLocalAssets } from 'redux/reducers/data'
import selectAllActivitiesToSave from 'redux/selectors/selectAllActivitiesToSave'
import selectDateModifiedByDossierId from'redux/selectors/selectDateModifiedByDossierId'
import { SAVING_REQUEST_THRESHOLD, SAVING_PAYLOAD_THRESHOLD, ACTIVATE_IF_MODIFIED_SINCE } from 'utils/config'

import { DOSSIER_NORMALIZER } from './useGetDossierWithDiagnostic'

const usePostOfflineActivities = (config={}) => {

  const dispatch = useDispatch()
  const { dossierId } = useParams()
  const hashmapAllActivitiesToSave = useSelector(selectAllActivitiesToSave)
  
  const dateLastModification = selectDateModifiedByDossierId(dossierId)
  const requestsThreshold = parseInt(SAVING_REQUEST_THRESHOLD)
  const payloadThreshold = parseInt(SAVING_PAYLOAD_THRESHOLD)
  
  const stringToBoolean = useCallback((string) => {
    return (string === 'false' || string === 'undefined' || string === 'null' || string === '0') ? false : !!string
  },[])
  
  const allowIfModifiedSince = stringToBoolean(ACTIVATE_IF_MODIFIED_SINCE)
  
  const basicSuccess = useCallback((state, action) => {
    if (!allowIfModifiedSince) return
    const dossierReturned = action.payload.data[0].dossiers[0]
    const typeOfUpdate = Object.keys(dossierReturned).length > 2 ? 'dossier' : 'date'
    if (!hashmapAllActivitiesToSave[dossierReturned.id]) return
    if (action.payload.data.length && ((hashmapAllActivitiesToSave[dossierReturned.id].length <= payloadThreshold) || typeOfUpdate === 'date')){
      dispatch(mergeData(action.payload.data[0], {isMergingArray: true, isMergingDatum: true, normalizer: {"dossiers": {normalizer: DOSSIER_NORMALIZER}}}))
    }
  }, [dispatch, hashmapAllActivitiesToSave, payloadThreshold, allowIfModifiedSince])
  
  const { handleFail, handleSuccess=basicSuccess} = config
  
  const handlePostOfflineActivities = useCallback(() => {
      if (!Object.keys(hashmapAllActivitiesToSave).length) return
      const dossierIdsSubset = Object.keys(hashmapAllActivitiesToSave).slice(0, requestsThreshold)
      for (let dossierIdFromList of dossierIdsSubset) {
        const activitiesSubset = hashmapAllActivitiesToSave[dossierIdFromList].slice(0, payloadThreshold)
        const ifModifiedSince = (dossierIdFromList === dossierId && allowIfModifiedSince) ? `&returnDossierIfModifiedSince=${dateLastModification}` : ''
        dispatch(requestActivitiesWithLocalAssets({
          apiPath: `/__activities__/${dossierIdFromList}?withoutAssetDataUrls=true${ifModifiedSince}`,
          body: activitiesSubset,
          handleFail,
          handleSuccess,
          method: 'POST',
          tag: `/__activities__/${dossierIdFromList}`
        }))
      }}, [dispatch, handleFail, handleSuccess, hashmapAllActivitiesToSave, requestsThreshold, payloadThreshold, dateLastModification, dossierId, allowIfModifiedSince])

  return {handlePostOfflineActivities}
}

export default usePostOfflineActivities
