const Well = [
    {
        componentType: "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : EA1",
    },
    {
        componentType: "RadiosField",
        isBoolean : true,
        label : "Fermeture ",
        name : "hasLocking",
    },
    {
        componentType: "TextareaField",
        label : "Commentaire : ",
        name : "comment",
    },
]

export default Well