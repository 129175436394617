export const UPDATE_DUPLICATION_STATE = 'UPDATE_DUPLICATION_STATE'

const initialState = {
  isDuplicating: false,
  reference: {
    dossierId: null,
    onlyBackgroundSketch: true,
    programmeId: null,
    sketchesActivityIdentifier: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DUPLICATION_STATE:
      return { ...state, ...action.patch }
    default:
      return state
  }
}

export const assignDuplication = patch => ({ patch, type: UPDATE_DUPLICATION_STATE })
