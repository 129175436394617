import {CADASTRE_LAYER_PROVIDER} from './config'

const getCadastreDatasets = () => {
    const datasets = []

    if (CADASTRE_LAYER_PROVIDER === 'datagouv') {
        datasets.push({ dataset: 'building_cadastres' })
        datasets.push({ dataset: 'parcel_cadastres' })
    } else {
        datasets.push({ dataset: 'cadastre_batiments' })
        datasets.push({ dataset: 'cadastre_parcelles' })
    }
    return datasets
} 
const DEFAULT_LAYERS = [
    ...getCadastreDatasets(),
    { dataset: 'water_stream' },
    { dataset: 'water_surface' }
]


export const getLayers = (programme) => {
    const layers = [...DEFAULT_LAYERS]

    switch (programme) {
        case undefined:
            return layers
        case 'capl':
            layers.push({ dataset: 'vallons_capl' })
            break
        case 'casa':
            layers.push({ dataset: 'vallons_casa' })
            break
        case 'nantes':
            layers.push({ dataset: 'cours_nantes' })
            layers.push({ dataset: 'cours_nantes_st' })
            break
        case 'tarn-amont':
            layers.push({ dataset: 'troncon_tarn' })
            layers.push({ dataset: 'surface_tarn' })
            break
        default:
            if (programme.startsWith('nim')) {
                layers.push({ dataset: 'cadereaux_nimes' })
            }
            break
    }
    return layers
}

export const getLayersFilteredByInseeCode = inseeCode => layers => {
    if (inseeCode == null) return layers

    const filteredLayers = layers.filter(layer => {
        const postalCodeFromLayerName = layer.name.split('__')[2]
        const firstTwoNumbersOfInseeCode = inseeCode.substring(0, 2)
        return postalCodeFromLayerName.startsWith(firstTwoNumbersOfInseeCode)
    })

    return filteredLayers.length === 0 ? layers : filteredLayers
}