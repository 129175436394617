import React from 'react'

import Framing from './Framing'
import InfosBar from './InfosBar'


const Creation = () => (
  <div className="creation">
    <InfosBar />
    <Framing />
  </div>
)

export default Creation
