import React from 'react'
import { useSelector } from 'react-redux'

import ActivitiesDebug from 'components/layout/ActivitiesDebug'


const MeasurementDebug = () => {
  const uuids = useSelector(state => state.drawing.uuids) || []
  const lastSelectedActivityIdentifier = uuids.slice(-1)[0]

  if (!lastSelectedActivityIdentifier) return null

  return (
    <ActivitiesDebug activityIdentifier={lastSelectedActivityIdentifier} />
  )
}

export default MeasurementDebug
