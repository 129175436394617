import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ToForm from 'components/layout/MeasurementTables/Table/Rows/Column/ToForm'
import selectMeasurementsWithIndexByDossierIdAndRowsName from 'components/layout/MeasurementTables/Table/Rows/selectMeasurementsWithIndexByDossierIdAndRowsName'

const renderValue = value => {
  if (value === false) {
    return 'Non'
  }
  if (value === true) {
    return 'Oui'
  }
  if (!value && isNaN(value)) {
    return '-'
  }
  return value
}


const SurveyorDataTable = ({ children, name }) => {
  const { dossierId } = useParams()
  const columns = children
  
  const measurements = useSelector(state =>
    selectMeasurementsWithIndexByDossierIdAndRowsName(state, dossierId, name),
    [dossierId, name])
  
  const columnLabels = columns.map(col => col.props.label)

  return (
    <div className="measurements-surveyor-rows" >
      <div className='measurement-row'>
        {columnLabels.map(label => (
          <div
            className="measurement-column-content label"
            key={`label-${label}`}
          >
            {label}
          </div>
        ))}
      </div>
      {measurements.map(measurement => (
        <div
          className='measurement-row'
          key={`${measurement.activityIdentifier}-surveyor-data-table`}
        >
          {columns.map(col => (
            <div
              className={classnames('measurement-column-content', {
                'description': col.props.name === 'flooringDescription',
                'form': col.props.toForm,
                'value': col.props.name && col.props.name !== 'flooringDescription',
              })}
              key={`${measurement.activityIdentifier}-${col.props.name}`}
            >
              {col.props.name === 'flooringDescription' &&
                <span>
                  {renderValue(measurement[col.props.name])}
                </span>}
              {col.props.toForm && <ToForm
                formTitle={measurement.surveyorName && `Levé ${measurement.surveyorName}`}
                measurement={measurement}
                                   />}
              {col.props.name && col.props.name !== 'flooringDescription' && renderValue(measurement[col.props.name])}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}


SurveyorDataTable.defaultProps = {
  name: null
}

SurveyorDataTable.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  name: PropTypes.string
}

export default SurveyorDataTable
