import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Table, { Column, Rows, selectMeasurementsWithIndexByDossierIdAndRowsName } from './Table'
import MeasurementContext from './MeasurementContext'


const { Provider } = MeasurementContext


const MeasurementTables = ({ children, name }) => {
  const { dossierId } = useParams()

  const measurements = useSelector(state =>
    selectMeasurementsWithIndexByDossierIdAndRowsName(state,
                                                      dossierId,
                                                      name))

  const values = useMemo(() =>
    measurements.map(measurement => ({
      measurement,
      tableName: name
    })), [measurements, name])


  return values.map(value => (
    <Provider
      key={value.measurement.activityIdentifier}
      value={value}
    >
      {children(value.measurement)}
    </Provider>
  ))
}

MeasurementTables.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}


export { Column, Table, Rows }
export default MeasurementTables
