import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { assignDrawing } from 'redux/reducers/drawing'

import Icon from './Icon'


const ToSketchTool = ({ children, name }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { dossierId, programmeId } = useParams()

  const handleClick = useCallback(() => {
    dispatch(assignDrawing({
      toolName: name
    }))
    const pathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`
    history.push(pathname)
  }, [dispatch, dossierId, history, name, programmeId])


  return (
    <div className="to-sketch-tool">
      <button
        onClick={handleClick}
        type="button"
      >
        <Icon name="ico-30-sketch2.svg" />
        {children}
      </button>
    </div>
  )
}


ToSketchTool.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  name: PropTypes.string.isRequired
}

export default ToSketchTool
