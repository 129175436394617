import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'
import { selectCurrentUser } from 'with-react-redux-login'

import Menu from 'components/layout/Header/Menu'
import Logo from 'components/layout/Logo'
import Marque from 'components/layout/Marque'
import SignoutButton from 'components/layout/SignoutButton'
import { ROOT_ASSETS_PATH } from 'utils/config'


const SIGN_PATHNAMES = ['/connection']


const Header = ({ info, withLogo, withMenu }) => {
  const { pathname } = useLocation()
  const isSignPathname = SIGN_PATHNAMES.includes(pathname)
  const { programmeId } = useParams()
  const isDiagnostician = pathname.includes('/diagnostiquer')


  const currentUser = useSelector(selectCurrentUser)
  const showSignin = !currentUser && !isSignPathname
  const showSignout = currentUser && !isSignPathname && !withMenu

  const { code, name } = useSelector(state =>
    selectEntityByKeyAndId(state, 'programmes', programmeId)) || {}

  const programmeCodeSpecificLogo = [
    "nim-habitat",
    "nim-eco",
    "nimalabri_nimes",
    "nimalabri_metropole",
    "nantes",
  ]
  const programmeLogo = programmeCodeSpecificLogo.includes(code) ? `${ROOT_ASSETS_PATH}/${code}_logo.png` : `${ROOT_ASSETS_PATH}/default_logo.png`

  const handleProgrammeClick = useCallback(() => window.location.reload(), [])

  return (
    <header className={classnames('header', {
      'diagnostician-header': isDiagnostician,
      'programme-header': programmeId })}
    >
      <div className={isDiagnostician ? 'diagnostician-header-container': 'header-container'}>
        {programmeId
        ? (
          <>
            <div
              className="left"
              onClick={handleProgrammeClick}
              onKeyPress={handleProgrammeClick}
              role="button"
              tabIndex={0}
            >
              <img
                alt="Logo"
                className="logo"
                src={programmeLogo}
              />
            </div>
            <div className="programme-name">
              {name}
            </div>
            <div className="separator" />
            {info}
          </>)
        : (isDiagnostician && <Marque />)}
        {withLogo && <Logo withLink />}
        {withMenu ? <Menu /> : <div />}
        {showSignin && (
        <NavLink
          className="to-signin"
          to="/connection"
        >
          {'S\'identifier'}
        </NavLink>
      )}
        {showSignout && (
        <SignoutButton>
          {'Se déconnecter'}
        </SignoutButton>
      )}
      </div>
    </header>
  )
}

Header.defaultProps = {
  info: null,
  withLogo: false,
  withMenu: false
}


Header.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withLogo: PropTypes.bool,
  withMenu: PropTypes.bool
}

export default Header
