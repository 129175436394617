import { yesNoOptions } from 'utils/diagnostic'
import { formatToUpperCase } from 'utils/form'


const tiedUpOptions = [
  { label: 'oui', value: 'yes' },
  { label: 'non', value: 'no' },
  { label: 'enterrée', value: 'burried' }
]

const tankTypeOptions = [
  { label: 'Intérieure', value: 'indoor-tank' },
  { label: 'Extérieure', value: 'outdoor-tank' }
]

const Tank = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : CI1",
    },
    {
        componentType : "RadiosField",
        label : "Type",
        name : "itemName",
        options : tankTypeOptions,
        placeholder : "Intérieure / extérieure",
    },
    {
        componentType : "NumberField",
        label : "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm',
    },
    {
        componentType : "TextField",
        format : {formatToUpperCase},
        label : "T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0",
    },
    {
        componentType : "SelectField",
        label : "Arrimée ? ",
        name : "tiedUp",
        options : tiedUpOptions,
        placeholder : "Ex. : oui",
    },
    {
        componentType : "SelectField",
        label : "Etanche ? ",
        name : "isWaterproof",
        options : yesNoOptions,
        placeholder : "Ex. : oui",
    },
    {
        componentType : "TextareaField",
        label : "Localisation : ",
        name : "comment",
    },
    {
        componentType : "PictureField",
        folderPath : "photos",
        label : "Photo : ",
        name : "photo",
    }
]

export default Tank
