export const SHOW_DEBUG = 'SHOW_DEBUG'
export const CLOSE_DEBUG = 'CLOSE_DEBUG'


const initialState = { isActive: false }


export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DEBUG:
      return Object.assign({}, state, { isActive: true })
    case CLOSE_DEBUG:
      return Object.assign({}, state, { isActive: false })
    default:
      return state
  }
}


export const closeDebug = () => ({ type: CLOSE_DEBUG })


export const showDebug = () => ({ type: SHOW_DEBUG })
