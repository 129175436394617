import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'


const EntryTitleNavigation = ({ id, isSelected, label }) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.replace(`#${id}`)
    document.querySelector(`#${id}`).scrollIntoView()
  }, [history, id])

  return (
    <button
      className={classnames('entry-title-navigation', { selected: isSelected })}
      onClick={handleClick}
      type="button"
    >
      {label}
    </button>
  )
}

export default EntryTitleNavigation
