import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { ID_RE, UUID_RE } from 'utils/router'

import Data from './Data'
import Drawing from './Drawing'
import SketchBar from './SketchBar'
import SketchesBar from './SketchesBar'

const Modification = () => {
 
  return (
    <div className="modification">
      <SketchesBar />
      <div className="drawing_or_data_container">
        <SketchBar />
        <Switch location={useLocation()} >
          <Route
            component={Drawing}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE})/(telecharger)?`}
          />
          <Route
            component={Data}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE})/donnees`}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Modification
