import React, { useEffect, useState, useCallback } from 'react'

const PreviewModal = () => {
 
  const [snapshot, setSnapshot] = useState()
  const generateSnapshot = useCallback(async() => {
    if (window.postSnapshot) {
      const generatedSnapshot = await window.postSnapshot()
                                      .then(snapshot => snapshot)
                                      .catch(e => e)
      const snapshotUrl = URL.createObjectURL(generatedSnapshot)
      setSnapshot(snapshotUrl)
    }
  },[])

  useEffect(() => {
    generateSnapshot()
  }, [generateSnapshot])

  return (
    <div className="preview-modal">
      <div className="preview-modal-title">
        {'Preview du Snapshot'}
      </div>
      {snapshot && (
        <img
          alt="preview"
          className="preview-modal-image"
          src={snapshot}
        />
      )}
    </div>
  )
}


export default PreviewModal
