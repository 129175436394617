import line from './line'


export default (positionPatch, config) => {
  const { fill } = positionPatch
  const node = line(positionPatch, config)

  if (!node) return

  node.setAttrs({
    closed: true,
    fill
  })

  return node
}
