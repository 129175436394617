import React from 'react'

import { Column } from 'components/layout/MeasurementTables'
import SurveyorDataTable from 'components/layout/SurveyorDataTable'


const FlooringAndSurveyorStudies = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="floorings-and-surveyor-studies"
    >
      {'Planchers et levés'}
    </h1>
    <SurveyorDataTable name="floorings-and-surveyor-studies">
      <Column
        label='Nom'
        name="flooringType"
      />
      <Column
        label='Descrip.'
        name='flooringDescription'
      />
      <Column
        label='Hauteur (m)'
        name="fromGroundHeight"
      />
      <Column
        label="Plancher de ref."
        name="baseFlooring"
      />
      <Column
        label='Cote (m NGF)'
        name="altitude"
      />
      <Column
        label="Hauteur d'eau (m)"
        name="waterHeight"
      />
      <Column
        label='Rapport'
        name="keepForReport"
      />
      <Column toForm />
    </SurveyorDataTable>
  </div>
)

export default FlooringAndSurveyorStudies
