import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import Icon from 'components/layout/Icon'
import LoadingButton from 'components/layout/LoadingButton'
import { callWithDebounce } from 'utils/calls'

import Suggestions from './Suggestions'
import isValueValidToCallAPI from './isValueValidToCallAPI'

const initialState = {
  debouncedValue: '',
  error: null,
  hasEnoughWords: false,
  isLoading: false,
  isSuggestionsOpen: true,
  suggestions: [],
  value: '',
}

const AddressComboBox = ({ isClosed,
  parse,
  onChange,
  onCloseClick,
  onOpenClick,
  title,
  validate }) => {
  const [state, setState] = useState(initialState)
  const {
    debouncedValue,
    error,
    hasEnoughWords,
    isLoading,
    isSuggestionsOpen,
    suggestions,
    value
  } = state

  const handleAddressChange = useCallback(event => {
    const nextValue = event.target.value

    const nextState = { value: nextValue }
    const hasEnoughWords = isValueValidToCallAPI(nextValue)
    if (!hasEnoughWords) {
      nextState.isLoading = false
      nextState.suggestions = []
    } else {
      nextState.isLoading = true
    }

    setState(previousState => ({ ...previousState, ...nextState }))
    callWithDebounce(1000)(() => {
      const nextState = { debouncedValue: nextValue, hasEnoughWords }
      if (!hasEnoughWords) {
        nextState.suggestions = []
      }
      setState(previousState => ({ ...previousState, ...nextState }))
    })

  }, [setState])


  const handleGeolocationClick = useCallback(event => {
    navigator.geolocation.getCurrentPosition(geolocationResult => {
      const position = [geolocationResult.coords.latitude,
      geolocationResult.coords.longitude]
      onChange(event, position)
    })
  }, [onChange])


  const handleSelectSuggestion = useCallback((event, suggestion) => {
    event.preventDefault()
    const validationError = validate(suggestion)
    if (validationError) {
      setState(previousState => ({ ...previousState, error: validationError }))
      return
    }
    const { geometry, properties: { id, postcode } } = suggestion
    const { coordinates } = geometry
    const position = [coordinates[1], coordinates[0], id, postcode]
    onChange(event, position, suggestion)
  }, [onChange, setState, validate])


  const handleSuggestionClick = useCallback(handleSelectSuggestion,
    [handleSelectSuggestion])


  const handleSuggestionSubmit = useCallback(event => {
    event.preventDefault()
    if (suggestions && suggestions[0]) {
      handleSelectSuggestion(event, suggestions[0])
    }
  }, [handleSelectSuggestion, suggestions])


  const handleSuggestionsClose = useCallback(event => {
    event.persist()
    if (!event.relatedTarget) return
    const blurFromSuggestionsList = event.relatedTarget.className === 'suggestion'
    if (blurFromSuggestionsList) return
    if (!isSuggestionsOpen) return
    setState(previousState => ({ ...previousState, isSuggestionsOpen: false }))
  }, [isSuggestionsOpen, setState])


  useEffect(() => {
    if (!hasEnoughWords) return
    fetch(`https://api-adresse.data.gouv.fr/search/?limit=10&q=${debouncedValue}`)
      .then(response => {
        setState(previousState => ({
          ...previousState,
          error: null,
        }))
        return response.json()
      })
      .catch((e) => {
        setState(previousState => ({
          ...previousState,
          isLoading: false,
          isSuggestionsOpen: true,
          error: e
        }))
      })
      .then(json =>
        setState(previousState => ({
          ...previousState,
          isLoading: false,
          isSuggestionsOpen: true,
          value: debouncedValue,
          ...parse(json ? json.features : [], debouncedValue)
        })))
  }, [
    hasEnoughWords,
    debouncedValue,
    parse,
    setState
  ])


  return (
    <form onSubmit={handleSuggestionSubmit}>
      {isClosed && (
        <button
          className="open"
          onClick={onOpenClick}
          type="button"
        >
          <Icon name="la-search" />
        </button>
      )}
      <div
        className={classnames('address-combo-box', { 'closed': isClosed })}
      >
        <button type="submit" />
        <button
          className="close"
          onClick={onCloseClick}
          type="button"
        >
          <Icon name="la-angle-double-left" />
        </button>
        <div className="title">
          {title}
        </div>
        <div className="subtitle">
          {'Cliquez sur votre adresse sur la carte, ou saisissez-la ci-dessous :'}
        </div>
        <div
          className="field"
          onBlur={handleSuggestionsClose}
          role="button"
        >
          <div className="underline">
            <LoadingButton
              className="search"
              isLoading={isLoading}
              isPrimary
            >
              <Icon name="la-search" />
            </LoadingButton>
            <input
              className="autocomplete"
              onChange={handleAddressChange}
              placeholder="Ex. : 3 rue Marie, Villiers-le-Lac"
              value={value}
            />
            {isSuggestionsOpen && (
              <Suggestions
                error={error}
                onSuggestionClick={handleSuggestionClick}
                suggestions={suggestions}
              />)}
          </div>
          <button
            className="geolocalizer"
            disabled={isLoading}
            onClick={handleGeolocationClick}
            title="Afficher mon emplacement actuel."
            type="button"
          >
            <Icon name="la-crosshairs" />
          </button>
        </div>
      </div>
    </form>
  )
}

AddressComboBox.defaultProps = {
  isClosed: false,
  parse: features => ({ suggestions: features }),
  validate: () => null,
}

AddressComboBox.propTypes = {
  isClosed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onOpenClick: PropTypes.func.isRequired,
  parse: PropTypes.func,
  title: PropTypes.string.isRequired,
  validate: PropTypes.func
}

export default AddressComboBox
