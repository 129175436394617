import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'


const PrincipalVentilations = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="principal-ventilations2"
    >
      {'Ventilations principales II'}
    </h1>
    <MeasurementTables name="principal-ventilations">
      {() => (
        <Table>
          <Column name="altitude" />
          <Column name="waterHeight" />
          <Column name="hasConcealment" />
        </Table>
      )}
    </MeasurementTables>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="principalVentilationsComment"
    />
    <TextareaField
      label="Préconisations : "
      name="principalVentilationsRecommendation"
    />
  </div>
)

export default PrincipalVentilations
