import localForage from 'localforage'
import { persistStore } from 'redux-persist'
import { v4 as uuidv4 } from 'uuid'

const dataUrlToBlob = async dataUrl => (await fetch(dataUrl)).blob()

const blobToDataURL = blob =>
  new Promise(resolve => {
    const file = new FileReader()
    file.onload = function (event) { resolve(event.target.result) }
    file.readAsDataURL(blob)
  })

export class LocalAsset {
  constructor(identifier) {
    this.identifier = identifier || uuidv4()
  }

  async exists() {
    return (await localForage.keys()).includes(this.identifier)
  }

  async read() {
    const dataUrl = await localForage.getItem(this.identifier)
    if (!dataUrl) {
      console.warn(`dataUrl should be here ${this.identifier}`)
      return
    }
    return blobToDataURL(dataUrl)
  }

  async write(dataUrl, config = {}) {
    const { checkFirst = false } = config
    if (checkFirst && await this.exists()) return
    return localForage.setItem(this.identifier, await dataUrlToBlob(dataUrl))
  }
}

const assetWithDataUrl = async obj => {
  if (obj.dataUrl || !obj.localIdentifier) return { ...obj }
  const localAsset = new LocalAsset(obj.localIdentifier)
  return {
    ...obj,
    dataUrl: await localAsset.read()
  }
}

const assetWithoutDataUrl = async obj => {
  if (!obj.dataUrl || !obj.localIdentifier) return { ...obj }
  const localAsset = new LocalAsset(obj.localIdentifier)
  await localAsset.write(obj.dataUrl, { checkFirst: true })
  return {
    ...obj,
    dataUrl: undefined
  }
}

export const parseObj = async (obj, config = {}) => {
  if (!obj) return obj
  const isArray = Array.isArray(obj)
  const values = isArray ? obj : [obj]
  const parsedValues = await Promise.all(values.map(async value => {
    if (typeof value !== 'object') return value
    if (Array.isArray(value)) {
      return await Promise.all(value.map(async subValue =>
        await parseObj(subValue, config)))
    }
    const localAssetItems = await Promise.all(
      Object.keys(value).map(async key => ({
        [key]: value[key]?.type === '__ASSET__'
          ? (config.readAssets && await assetWithDataUrl(value[key])) ||
          (config.writeAssets && await assetWithoutDataUrl(value[key])) ||
          { ...value[key] }
          : await parseObj(value[key], config)
      })))
    return localAssetItems.reduce((parsedValue, localAssetItem) => ({
      ...parsedValue,
      ...localAssetItem
    }), {})
  }))
  return isArray ? parsedValues : parsedValues[0]
}

export const createPersistor = store => persistStore(store)

export default createPersistor
