import classnames from 'classnames'
import React, { forwardRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import ActivitiesForm from 'components/layout/form/ActivitiesForm'

import Preparation from './Preparation'
import Treatment from './Treatment'
import useInitialDossierWithComputedProperties from './useInitialDossierWithComputedProperties'
import Visit from './Visit'



const COMPONENTS_BY_NAME = {
  Preparation,
  Treatment,
  Visit
}



export const Entry = forwardRef((props, ref) => {
  const { dossierId, entryName } = useParams()

  const processActivityWithLocalDossierId = useCallback(activity => ({
    ...activity,
    localDossierId: dossierId
  }), [dossierId])


  return (
    <div
      className="entry"
      ref={ref}
    >
      <ActivitiesForm
        processActivity={processActivityWithLocalDossierId}
        processInitial={useInitialDossierWithComputedProperties()}
        topId={dossierId}
        topStateKey="dossiers"
      >
        {Object.keys(COMPONENTS_BY_NAME).map(name => {
          const Component = COMPONENTS_BY_NAME[name]
          return (
            <div
              className={classnames('entry-tab',
                                    {'entry-hidden':
                                      entryName !== name.toLowerCase()})}
              key={name}
            >
              <Component />
            </div>
          )
        })}
      </ActivitiesForm>
    </div>
  )
})
