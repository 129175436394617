import { createSelector } from 'reselect'

import selectAllOfflineActivities from './selectAllOfflineActivities'

export default createSelector(
    selectAllOfflineActivities,
    createSelector(selectAllOfflineActivities,
        (activities) => {return [...new Set(activities.map(activity => activity.localDossierId))]}
    ),
    (activities, listOfDossierId) => {
        const hash = {}
        listOfDossierId.map(dossierId => (
            hash[dossierId] = (activities || []).filter(activity => activity.localDossierId === dossierId)
        ))
        return hash
    })