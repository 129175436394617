import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { showModal } from 'redux-react-modals'

import Icon from 'components/layout/Icon'
import selectLastSketchActivityIdentifierByDossierId from 'redux/selectors/selectLastSketchActivityIdentifierByDossierId'

import SubmitModal from './SubmitModal'

const CreationBar = ({ disabled }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { dossierId, programmeId } = useParams()
  const dossiersPathname = `/diagnostiquer/programmes/${programmeId}/dossiers`
  const lastSketchActivityIdentifier = useSelector(state => selectLastSketchActivityIdentifierByDossierId(state, dossierId))
  const hasAtLeastOneSketch = typeof lastSketchActivityIdentifier !== 'undefined'
  const firstSketchPathname = `${dossiersPathname}/${dossierId}/sketches/${lastSketchActivityIdentifier}`
  const handleFinishClick = useCallback(() => history.push(firstSketchPathname), [firstSketchPathname, history])
  const handleSketchSubmit = useCallback(() =>
    dispatch(
      showModal('main',
        <SubmitModal />,
        { isUnclosable: true })
    ), [dispatch]
  )

  return (
    <div className="creation-bar">
      <button
        className="create-sketch"
        disabled={disabled}
        onClick={handleSketchSubmit}
        type="button"
      >
        <span>
          Créer une vue
        </span>
        <Icon name="ico-30-circled-plus-white.svg" />
      </button>
      {hasAtLeastOneSketch && (
        <button
          className="done-with-sketches"
          onClick={handleFinishClick}
          type="button"
        >
          Terminer
        </button>)}
    </div>
  )
}

CreationBar.propTypes = {
  disabled: PropTypes.bool.isRequired
}

export default CreationBar
