/* eslint no-underscore-dangle: 0 */
import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import {
  API_URL,
  IS_DEVELOPMENT,
  IS_TESTING
} from 'utils/config'

import { createPersistor } from './persistor'
import rootReducer from './reducers'

const replaceAssetValue = (key, value) => {
  if (value?.type === '__ASSET__') {
    return {
      ...value,
      dataUrl: value?.dataUrl?.slice(0, 20)
    }
  }
  return value
}

const buildStoreEnhancer = (middlewares = []) => {
  const enhancers = []

  const useDevTools = (IS_DEVELOPMENT || IS_TESTING) &&
                      typeof window !== 'undefined' &&
                      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (useDevTools) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: {
        replacer: replaceAssetValue
      }
    })
    return composeEnhancers(...enhancers, applyMiddleware(...middlewares))
  }

  return compose(
    ...enhancers,
    applyMiddleware(...middlewares)
  )
}

export const configureStore = (initialState = {}) => {
  const thunkMiddleWare = thunk.withExtraArgument({ rootUrl: API_URL })

  const middlewares = [thunkMiddleWare]

  const storeEnhancer = buildStoreEnhancer(middlewares)

  const store = createStore(rootReducer, initialState, storeEnhancer)

  const persistor = createPersistor(store)

  return {
    persistor,
    store,
  }
}

export default configureStore
