import { Group } from 'konva'


const calculate = (node, sizeProp) =>  {
  const coordProp = (sizeProp === 'width' ? 'x' : 'y')
  let size = node[sizeProp]()
  if (node instanceof Group) {
    node.getChildren().forEach((child) => {
      let childMaxCoord = calculate(child, sizeProp) + child[coordProp]()
      if (size < childMaxCoord) { size = childMaxCoord }
    })
  }
  return size
}

export const calculateHeight = node => { return calculate(node, 'height') }
export const calculateWidth = node => { return calculate(node, 'width') }

export const distribute = (container, nodes, axis, options={}) => {
  let calculateSize 
  let coordProp
  if (axis === 'vertical') {
    calculateSize = calculateHeight
    coordProp = 'y'
  } else if (axis === 'horizontal') {
    calculateSize = calculateWidth
    coordProp = 'x'
  } else {
    throw Error(`Invalid value for axis: ${axis}`)
  }
  const { padding } = options
  const spacing = (calculateSize(container)
                     - nodes.reduce((a, v) => a + calculateSize(v), 0)
                     - 2 * (padding || 0))
                   / (nodes.length - 1)
  let lastCoord = padding || 0
  nodes.forEach(node => {
    node[coordProp](lastCoord)
    lastCoord += calculateSize(node) + spacing
    container.add(node)
  })
}
