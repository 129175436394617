import { natureColorTags, otherColorTags } from './colorTool'


const tool = {
  name: 'shape',
  svg: 'ico-30-shapes.svg',
  pans: [
    {
      name: 'Formes de dessin',
      items: [
        {
          label: 'Polygone',
          name: 'polygon',
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          label: 'Rectangle',
          name: 'rectangle',
          svg: 'ico-30-square.svg',
          type: 'rectangle'
        },
        {
          label: 'Cercle',
          name: 'circle',
          svg: 'ico-30-circle.svg',
          type: 'circle'
        },
        {
          label: 'Triangle',
          name: 'triangle',
          svg: 'ico-30-triangle.svg',
          type: 'triangle'
        }
      ]
    }
  ]
}


tool.pans.forEach(pan => pan.items = pan.items.map(item => ({
  ...item,
  clickable: true,
  colorTags: natureColorTags.concat(otherColorTags),
  resizeEnabled: true,
  rotateEnabled: true,
  strokeScaleEnabled: false,
  strokeWidth: 1
})))


export default tool
