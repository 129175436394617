import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryParamProvider } from 'use-query-params'

import UpdateMessage from 'components/layout/Alert/Alert'
import NotMatch from 'components/pages/NotMatch'
import { ServiceWorkerProvider } from 'components/providers/ServiceWorkerProvider'

import App from './App'
import FeaturedRoute from './FeaturedRoute'
import routes from './routes'

// eslint-disable-next-line react/prop-types
const Root = ({ store, persistor }) => {
  return (
    <Provider store={store}>
      <ServiceWorkerProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App>
                <UpdateMessage />
                <Switch>
                  {routes.map(route => (
                    <FeaturedRoute componentWhenDisabled={NotMatch} key={route.path} {...route} />
                  ))}
                  <Route component={NotMatch} />
                </Switch>
              </App>
            </QueryParamProvider>
          </BrowserRouter>
        </PersistGate>
      </ServiceWorkerProvider>
    </Provider>
  )
}

export default Root
