import { IS_SAFARI, IS_STANDALONE } from 'utils/config'


export const shouldGoToGoogleLogout = state => {
  const { isSuccess } = (state.requests || {})['/users/signout'] || {}
  if (!isSuccess) return false
  if (IS_SAFARI || IS_STANDALONE) {
    window.location.href = `https://accounts.google.com/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.origin}`
  }
  return false
}
