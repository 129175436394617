import React, { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import SwitchButton from 'components/layout/SwitchButton'


const SketchesOrDataSwitchButton = () => {
  const history = useHistory()
  const location = useLocation()
  const { dossierId, programmeId, sketchActivityIdentifier } = useParams()

  const renderLeft = useCallback(() => ('Plan'), [])

  const renderRight = useCallback(() => ('Données'), [])

  const handleChange = useCallback(async(event, isLeft) => {
    let nextPath = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches/${sketchActivityIdentifier}`
    if (!isLeft) {
      nextPath = `${nextPath}/donnees`
    }
    history.push(nextPath)
  }, [dossierId, history, programmeId, sketchActivityIdentifier])


  return (
    <SwitchButton
      initialState={!location.pathname.endsWith('donnees')}
      onChange={handleChange}
      renderLeft={renderLeft}
      renderRight={renderRight}
    />
  )
}
export default SketchesOrDataSwitchButton