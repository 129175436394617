import createCachedSelector from 're-reselect'

import selectMeasurementsWithIndexByDossierId from 'redux/selectors/selectMeasurementsWithIndexByDossierId'


const mapArgsToCacheKey = (state, dossierId, rowsName) =>
  `${dossierId || ''}${rowsName || ''}`


export default createCachedSelector(
  selectMeasurementsWithIndexByDossierId,
  (state, dossierId, rowsName) => rowsName,
  (measurements, rowsName) => {
    if (!rowsName || !measurements) return
    let filteredMeasurements = measurements

    if (rowsName.endsWith('door-frames')) {
      const keepAnnexes = rowsName.startsWith('annexes')
      filteredMeasurements = filteredMeasurements.filter(measurement =>
        measurement.panName === 'Ouvrants' &&
        measurement.itemName !== 'ventilation' &&
        (keepAnnexes
          ? (measurement?.label || '')[0] === 'A'
          : (measurement?.label || '')[0] !== 'A')
      )
      return filteredMeasurements
    }

    if (rowsName === 'floating-elements') {
      return filteredMeasurements.filter(measurement =>
        measurement.itemName === 'floating-element')
    }

    if (rowsName === 'floorings-and-surveyor-studies') {
      filteredMeasurements = filteredMeasurements.filter(measurement =>
        ['level-threshold', 'mezza-attic-entrance', 'stairs', 'surveyor-study'].includes(
          measurement.itemName))
      return filteredMeasurements
    }
    if (rowsName.endsWith('ventilations')) {
      const keepAnnexes = rowsName.startsWith('annexes')
      return filteredMeasurements.filter(measurement =>
          measurement.itemName === 'ventilation'  &&
          (keepAnnexes
            ? (measurement?.label || '')[0] === 'A'
            : (measurement?.label || '')[0] !== 'A'))
    }
  }
)(mapArgsToCacheKey)
