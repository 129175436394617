import JSZip from 'jszip'
import React from 'react'
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { showModal, closeModal } from 'redux-react-modals'


import useCreateArchive from 'components/hooks/useCreateArchive'
import DownloadFailedModal from 'components/pages/Diagnostician/Board/Sketches/Modification/SketchesBar/DownloadSnapshot/DownloadFailedModal'
import OngoingDownloadModal from 'components/pages/Diagnostician/Board/Sketches/Modification/SketchesBar/DownloadSnapshot/OngoingDownloadModal'
import { assignDownload } from 'redux/reducers/download'

const useDownloadFile = () => {
    const dispatch = useDispatch()
    const {handleGeneratedFilename, handleCSVFromDataGeneration, handleSnapshotGeneration} = useCreateArchive()

    const handleDownloadFile = useCallback((file, filename) => {
        const link = document.createElement('a')
        link.download = filename
        link.href = file
        link.click()
    }, [])

    const handleDownloadZipFile = useCallback((files) => {
        if (!files.length) return false
        const zip = new JSZip()
        const generatedZipName = handleGeneratedFilename('.zip') || 'erreur-nom-de-fichier.zip'
        files.forEach((fileInfo) => {
            zip.file(fileInfo.filename, fileInfo.file)
        })
        let result = zip.generateAsync({ type: "blob" }).then(blob => {
            const zipFile = URL.createObjectURL(blob)
            handleDownloadFile(zipFile, generatedZipName)
            URL.revokeObjectURL(zipFile)
            return true
        }).catch(() => false)
        return result
    }, [handleGeneratedFilename, handleDownloadFile])

    const handleGenerateArchiveAndDownloadWithUI = useCallback(async(withModals = true) => {
        dispatch(assignDownload({hasShownBlockingModal: false, isDownloading : true}))
        if (withModals) dispatch(showModal('main', 
          <OngoingDownloadModal
            closeModal={closeModal}
            dispatch={dispatch}
          />, { isUnclosable: true }))
        const {csvFilename, generatedCSV} = await handleCSVFromDataGeneration()
        const {snapshotFilename, generatedSnapshot} = await handleSnapshotGeneration()
        const filesToDownload = []
        if (generatedCSV && csvFilename) {
          filesToDownload.push({file : generatedCSV, filename : csvFilename})
        }
        if (generatedSnapshot && snapshotFilename) {
          filesToDownload.push({file : generatedSnapshot, filename : snapshotFilename})
        }
        const result = await handleDownloadZipFile(filesToDownload)
        dispatch(assignDownload({isDownloading : false}))
        if (withModals) {
          if (result) dispatch(closeModal('main'))
          else {
            dispatch(showModal('main', 
              <DownloadFailedModal
                closeModal={closeModal}
                dispatch={dispatch}
              />, { isUnclosable: true }))
          }
        }
    }, [handleCSVFromDataGeneration, handleSnapshotGeneration, handleDownloadZipFile, dispatch])

    return {handleDownloadFile, handleDownloadZipFile, handleGenerateArchiveAndDownloadWithUI}
}

export default useDownloadFile