import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'


const Elevator = () => (
  <>
    <TextareaField
      label="Localisation Machinerie : "
      name="machineryLocation"
    />
    <TextareaField
      label="Commentaire : "
      name="comment"
    />
  </>
)

export default Elevator
