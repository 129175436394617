import { format } from 'fecha'

export const camelize = text => {
    text = (text || '').replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '')
    return text.substr(0, 1).toUpperCase() + text.substr(1)
}

export const generateFilename = (programmeName, dossierNumber, sketchName, extension) => {
    const formatedSketchName = typeof sketchName === "string" ? sketchName.replace(' ', '_').replace(' #', '_#') : ''
    const timestamp = format(new Date(Date.now()), 'YYMMDD-HH[h]mm[m]ss[s]')
    const filename = `${programmeName}-${dossierNumber}-${formatedSketchName}-${timestamp}${extension}`
    return filename
}

export const duplicateSketchName = (originalName, availableSketchNames) => {
    if (!originalName || !availableSketchNames || !availableSketchNames.length) return false
    const prefixSketchName = originalName.split(' #')[0]
    const optionName = availableSketchNames.find(option => 'value' in option && option.value.includes(prefixSketchName))
    if (!optionName) return false
    return optionName.value
}