import { createSelector } from "reselect"

const selectRequestByEndpointName = createSelector(
    state => state.requests,
    (_state, endpointName) => endpointName,
    (requests, endpointName) => {
        if (!requests) return
        
        return requests[endpointName]
    }
)

export default {
    selectRequestByEndpointName
}