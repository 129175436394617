import PropTypes from 'prop-types'
import React from 'react'

import CreateDossierButton from './CreateDossierButton'

const AddressEligible = ({ address, availableProgrammes, currentUser, programmeEligibility, parcel }) => {
  const { isSuperAdmin, isDiagnostician } = currentUser || {}

  if (programmeEligibility.code === 'eaux-et-vilaine') {
    return (
      <>
        <div className="info">
          <p>
            {'Adresse éligible au diagnostic.'}
          </p>
          <p>
            <a
              className="action"
              href={programmeEligibility.eligibilityFormUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Prendre RDV'}
            </a>
          </p>
          {programmeEligibility.eligibilityInformation && (
          <p>
            <a
              className="action"
              href={programmeEligibility.eligibilityInformation}
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Consulter le PPRi de ma commune'}
            </a>
          </p>
          )}
        </div>
        <div />
      </>  
    )
  }

  return (
    <div>
      {availableProgrammes && (isSuperAdmin || isDiagnostician) ?
        <div>
          {availableProgrammes.map(programme =>
          (
            <CreateDossierButton
              address={address}
              key={programme['code']}
              parcel={parcel}
              programme={programme}
            />
          ))}
        </div>
      : (
        <>
          <div className="info">
            {"Quelle hauteur d'eau, comment s'y préparer ? Demandez un "}
            <b>
              {'diagnostic gratuit'}
            </b>
            {' : '}
          </div>
          <div>
            <a
              className="action"
              href={programmeEligibility.eligibilityFormUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Inscrivez-vous'}
            </a>
          </div>
        </>  
      )}
    </div>
  )
}

AddressEligible.propTypes = {
  address: PropTypes.shape().isRequired,
  availableProgrammes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  currentUser: PropTypes.shape().isRequired,
  parcel: PropTypes.shape().isRequired,
  programmeEligibility: PropTypes.shape().isRequired,
}

export default AddressEligible
