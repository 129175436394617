import { allCoordsNotEmpty, distanceBetweenPositions } from '../../distance'


export default (pointPatch, config) => {
  const { width, x, y, ...patch } = pointPatch
  const { konvaPointToLatLng } = config

  if (allCoordsNotEmpty(x, y)) {
    const westPosition = konvaPointToLatLng({ x, y })
    const { lat, lng: lon } = westPosition
    Object.assign(patch, { lat, lon })

    if (width) {
      const eastPosition = konvaPointToLatLng({ x: x + width, y })
      patch.width = distanceBetweenPositions(
        [westPosition.lat, westPosition.lng],
        [eastPosition.lat, eastPosition.lng]
      )
    }
  }

  return patch
}
