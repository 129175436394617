import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Column, { MeasurementsContext } from './Column'
import selectMeasurementsWithIndexByDossierIdAndRowsName from './selectMeasurementsWithIndexByDossierIdAndRowsName'

const { Provider } = MeasurementsContext


const Rows = ({ children, measurements: propsMeasurements, name }) => {
  const { dossierId } = useParams()
  const columnsCount = children.length

  const stateMeasurements = useSelector(state =>
    selectMeasurementsWithIndexByDossierIdAndRowsName(state, dossierId, name),
    [dossierId, name])
  const measurements = propsMeasurements || stateMeasurements

  const value = useMemo(() => ({
    columnsCount,
    measurements
  }), [columnsCount, measurements])

  return (
    <div className="measurements-rows" >
      <Provider value={value} >
        {children}
      </Provider>
    </div>
  )
}


Rows.defaultProps = {
  measurements: null,
  name: null
}

Rows.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  measurements: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string
}


export { Column, selectMeasurementsWithIndexByDossierIdAndRowsName }
export default Rows
