import { allCoordsNotEmpty } from '../../distance'

export default (pointPatch, config) => {
  const { centerX, centerY, labelX, labelY, type, x, y, ...patch } = pointPatch
  const { item, konvaPointToLatLng } = config
  const { svg } = item || {}

  if (allCoordsNotEmpty(x, y)) {
    const position = konvaPointToLatLng({ x, y })
    const { lat, lng: lon } = position
    Object.assign(patch, { centerLat: lat, centerLon: lon, lat, lon })
  }


  if (allCoordsNotEmpty(centerX, centerY, labelX, labelY)) {
    const centerPosition = konvaPointToLatLng({ x: centerX, y: centerY })
    const { lat: centerLat, lng: centerLon } = centerPosition
    const labelPosition = konvaPointToLatLng({ x: labelX, y: labelY })
    const { lat: labelLat, lng: labelLon } = labelPosition
    Object.assign(patch, {
      labelDiffLat: labelLat - centerLat,
      labelDiffLon: labelLon - centerLon
    })
  }

  const isMeasurementCreation = typeof type !== 'undefined'
  if (isMeasurementCreation) {
    Object.assign(patch, { svg, type })
  }

  return patch
}
