import createCachedSelector from 're-reselect'

import { selectItemByName, selectToolByName } from 'utils/drawing/selectors'

import selectMeasurementsWithNamedNotRemovedSketchByDossierId from './selectMeasurementsWithNamedNotRemovedSketchByDossierId'


const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  selectMeasurementsWithNamedNotRemovedSketchByDossierId,
  measurements => {
    if (!measurements) return
    const sortedMeasurements = measurements.map(measurement => ({
      ...measurement,
      item: selectItemByName(measurement.itemName),
      tool: selectToolByName(measurement.toolName)
    }))
    sortedMeasurements.sort((m1, m2) =>
      ((m1.item?.label || '') + m1.index).localeCompare((m2.item?.label || '') + m2.index))
    return sortedMeasurements
  })(mapArgsToCacheKey)
