import PropTypes from 'prop-types'

import geometryType from './geometryType'

const createGeojsonType = propertyType => PropTypes.shape({
  geometry: geometryType,
  id: PropTypes.string,
  properties: propertyType,
  type: PropTypes.string
})

export default createGeojsonType
