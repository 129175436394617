import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'


const PrincipalDoorFrames = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="principal-door-frames2"
    >
      {'Huisseries principales II'}
    </h1>
    <MeasurementTables name="principal-door-frames">
      {() => (
        <Table>
          <Column name="comment" />
          <Column
            name="altitude"
            suffixLabelColumn=" appui ou seuil"
          />
          <Column name="waterHeight" />
          <Column name="hasBatardeau" />
        </Table>
      )}
    </MeasurementTables>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="principalDoorFramesComment"
    />
    <TextareaField
      label="Préconisations : "
      name="principalDoorFramesRecommendation"
    />
  </div>
)

export default PrincipalDoorFrames
