import { selectEntitiesByKeyAndJoin } from 'redux-thunk-data'
import {createSelector} from 'reselect'



export default createSelector(
    state => state,
    (state, sketchActivityIdentifier) => sketchActivityIdentifier,
    (state, sketchActivityIdentifier) => {
        if (!state || !sketchActivityIdentifier) return
        const measurementEntitiesWithSketchActivityIdentifier = selectEntitiesByKeyAndJoin(state, 'measurements', { key: 'sketchActivityIdentifier', value: sketchActivityIdentifier })
        const measurements = (measurementEntitiesWithSketchActivityIdentifier || []).filter(entity => !entity.isSoftDeleted)
        return measurements
    }
)