import React from 'react'

import Field from 'components/layout/form/Field'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectFirstMeasurementByDossierIdAndItemName from 'redux/selectors/selectFirstMeasurementByDossierIdAndItemName'


const ExternalArgument = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="external-arrangement"
    >
      {'Aménagements extérieurs I'}
    </h1>
    <Field name='outdoorFencePresent' />
    <Field name='outdoorFenceIncludesWaterOutlets' />
    <MeasurementBlocks
      itemName="swimming-pool"
      select={selectFirstMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" piscine"
            name="gateHeight"
          />
          <Property name="markedLength" />
          <Property name="ppriMarked" />
          <Property name="otherMarking" />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="tank"
      select={selectFirstMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne / cuve"
            name="fromGroundHeight"
          />
          <Property
            label="T.N. de réf. de la citerne / cuve"
            name="baseFlooring"
          />
          <Property
            extraLabel=" citerne / cuve"
            name="tiedUp"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="outdoor-tank"
      select={selectFirstMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne / cuve"
            name="fromGroundHeight"
          />
          <Property
            label="T.N. de réf. de la citerne / cuve"
            name="baseFlooring"
          />
          <Property
            extraLabel=" citerne / cuve"
            name="tiedUp"
          />
        </Block>)}
    </MeasurementBlocks>
  </div>
)

export default ExternalArgument
