import { selectEntitiesByKeyAndJoin } from 'redux-thunk-data'
import { createSelector } from 'reselect'


export default createSelector(
  (state, dossierId) => selectEntitiesByKeyAndJoin(
    state,
    'sketches',
    { key: 'dossierId', value: dossierId }),
  sketches => sketches.filter(sketch => sketch.name && !sketch.isSoftDeleted))
