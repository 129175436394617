import { formatToUpperCase } from 'utils/form'

const boardTypeOptions = [
    { label: 'Disjoncteurs', value: 'circuit-breakers' },
    { label: 'Fusibles', value: 'fuses' },
    { label: 'Disjoncteurs et fusibles', value: 'circuit-breakers-and-fuses' },
    { label: 'Fusibles céramiques', value: 'ceramic-fuses' },
    { label: 'Hors norme', value: 'beyond-norm' },
    { label: 'Autre', value: 'other' },
  ]
  
const ElecBoar = [
    {
        componentType: "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : T1",
    },
    {
        componentType: "NumberField",
        label: "Hauteur au sol : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType: "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        className: "with-border",
        componentType: "SelectField",
        label: "Type de tableau : ",
        name: "boardType",
        options: boardTypeOptions,
        placeholder: "Choisissez dans la liste",
    },
    {
        componentType: "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
    {
        componentType: "PictureField",
        folderPath: "photos",
        label: "Photo : ",
        name: "photo",
    },
]

export default ElecBoar