import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectLowestMeasurementByDossierIdAndItemName from 'redux/selectors/selectLowestMeasurementByDossierIdAndItemName'


const Electricity = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="electricity2"
    >
      {'Electricité II'}
    </h1>
    <MeasurementBlocks
      itemName='elec-boar'
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" du tableau"
            name="altitude"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-meter"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" du compteur"
            name="altitude"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Côté de l'équipement électrique le plus bas (prise, interrupteur, boîtier…)"
            name="altitude"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName='elec-boar'
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Tableau inondé"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-switch"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Présence d'interrupteurs inondés"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-plug"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Présence de prises inondées"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-meter"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Compteur inondé"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="elec-"
      select={selectLowestMeasurementByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            label="Autre équipement électrique inondé"
            name="isUnderWater"
          />
        </Block>)}
    </MeasurementBlocks>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="electricalComment"
    />
    <TextareaField
      label="Préconisations : "
      name="electricalRecommendation"
    />
  </div>
)

export default Electricity
