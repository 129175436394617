import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'


const Radios = ({ disabled, isBoolean, name, readOnly, onChange, options, type, value }) => {
  const [selectedValue, setValue] = useState(value)

  const handleChange = useCallback(event => {
    let value = event.target.value
    if (type === 'number') {
      value = parseFloat(value)
    }
    if (isBoolean) {
      value = value === 'true'
    }
    setValue(value)
    onChange(value)
  }, [isBoolean, onChange, type])

  useEffect(() => {
    if (value !== '') { setValue(value) }
  }, [value])


  if (!options) return null

  return options.map(({ label, value: optionValue }, index) => (
    <div
      className="radio"
      key={index}
    >
      <input
        checked={optionValue === selectedValue}
        className="field-entry"
        disabled={readOnly || disabled}
        name={name}
        onChange={handleChange}
        type="radio"
        value={optionValue}
      />
      {label}
    </div>))
}


Radios.defaultProps = {
  disabled: false,
  isBoolean: false,
  options: null,
  readOnly: false,
  type: null,
  value: null
}


Radios.propTypes = {
  disabled: PropTypes.bool,
  isBoolean: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })),
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any
}

export default Radios
