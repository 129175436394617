import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import useGetDossierWithDiagnostic from 'components/hooks/useGetDossierWithDiagnostic'
import { AUDIT_ENTRY_NAME_RE, ID_RE, UUID_RE } from 'utils/router'

import Audit from './Audit'
import useHistoryBlock from './hooks/useHistoryBlock'
import Report from './Report'
import Sketches from './Sketches'


const Board = () => {
  const location = useLocation()
  const getDossierWithDiagnostic = useGetDossierWithDiagnostic()

  useHistoryBlock()
  
  useEffect(() => {
    getDossierWithDiagnostic()
  }, [getDossierWithDiagnostic]) 


  return (
    <div className="board">
      <Switch location={location}>
        <Route
          component={Audit}
          path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/audit/:entryName(${AUDIT_ENTRY_NAME_RE})?`}
        />
        <Route
          component={Sketches}
          path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE}|creation)?/(donnees|telecharger)?`}
        />
        <Route
          component={Report}
          path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/report/:entryName(${AUDIT_ENTRY_NAME_RE})?`}
        />
      </Switch>
    </div>
  )
}


export default Board
