import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import Icon from 'components/layout/Icon'

const OngoingDownloadModal = ({closeModal, dispatch}) => {

  const handleClick = useCallback(() => {
    dispatch(closeModal('main'))
  }, [dispatch, closeModal])

  return (
    <div className="ongoing-download-modal">
      <div className="title">
        {'Votre téléchargement est en cours de préparation.'}
        <br />
        {'Merci de patienter.'}
      </div>
      <div
        className="controls"
        style={{justifyContent : 'space-between', position : 'relative'}}
      >
        <button
          className="hide"
          onClick={handleClick}
          type="button"
        >
          {'Masquer'}
        </button>
        <Icon name="ico-30-loading.svg" />
      </div>
    </div>
  )
}

OngoingDownloadModal.propTypes = {
  closeModal : PropTypes.func.isRequired,
  dispatch : PropTypes.func.isRequired
}

export default OngoingDownloadModal

