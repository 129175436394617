import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Field from 'components/layout/form/Field'
import TextareaField from 'components/layout/form/fields/TextareaField'
import Icon from 'components/layout/Icon'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectRecommendedRefugeMeasurementByDossierId from 'redux/selectors/selectRecommendedRefugeMeasurementByDossierId'
import selectRefugeMeasurementsByDossierId from 'redux/selectors/selectRefugeMeasurementsByDossierId'


const RefugeSpace = () => {
  const { dossierId } = useParams()

  const refugeMeasurements = useSelector(state =>
    selectRefugeMeasurementsByDossierId(state, dossierId), [dossierId])
  const refugeOptions = useMemo(() => refugeMeasurements.map(m => ({
    label: `espace refuge ${m.index}`,
    value: m.activityIdentifier
  })), [refugeMeasurements])
  const hasAtLeastOneRefuge = refugeOptions && refugeOptions.length > 0


  return (
    <div className="entry-group">
      <h1
        className="entry-title"
        id="refuge2"
      >
        {'Espace refuge II'}
      </h1>
      {hasAtLeastOneRefuge ? (
        <Field
          label={(
            <div className="warning">
              <Icon name="ico-30-warning.svg" />
              <span>
                {'Plusieurs accès à un espace refuge figurent sur le croquis. Cochez celui préconisé pour la mise en sécurité des personnes'}
              </span>
            </div>
          )}
          name="refugeRecommendedMeasurementActivityIdentifier"
          options={refugeOptions}
        />) : (
          <div className="warning">
            <Icon name="ico-30-warning.svg" />
            <span>
              {'Vous n\'avez pas placé de refuge pour le moment.'}
            </span>
          </div>)}
      <MeasurementBlocks select={selectRecommendedRefugeMeasurementByDossierId}>
        {() => (
          <Block>
            <Property name="refugeSpaceUpToCode" />
            <Property
              extraLabel=" de l'espace refuge"
              name="baseFlooringAltitude"
            />
            <Property
              label="Cote du plancher de l'espace refuge"
              name="altitude"
            />
            <Property name="meanCeilingHeight" />
          </Block>)}
      </MeasurementBlocks>
      <div className="entry-subtitle">
        <div>
          {'Commentaires'}
        </div>
      </div>
      
      <TextareaField
        label="Observations : "
        name="shelterSpaceComment"
      />
      <TextareaField
        label="Commentaire ou justification de l'impossibilité : "
        name="shelterSpaceJustification"
      />
    </div>
  )
}

export default RefugeSpace
