import { allCoordsNotEmpty, distanceBetweenPositions } from '../../distance'


export default (pointPatch, config) => {
  const { konvaPointToLatLng } = config
  const { centerX, centerY, labelX, labelY, radius, x, y, ...patch } = pointPatch

  if (allCoordsNotEmpty(x, y)) {
    const centerPosition = konvaPointToLatLng({ x, y })
    const { lat, lng: lon } = centerPosition
    Object.assign(patch, { lat, lon })

    if (radius) {
      const onCirclePosition = konvaPointToLatLng({ x: x + radius, y })
      patch.radius = distanceBetweenPositions(
        [centerPosition.lat, centerPosition.lng],
        [onCirclePosition.lat, onCirclePosition.lng]
      )
    }
  }

  if (allCoordsNotEmpty(centerX, centerY, labelX, labelY)) {
    const centerPosition = konvaPointToLatLng({ x: centerX, y: centerY })
    const { lat: centerLat, lng: centerLon } = centerPosition
    const labelPosition = konvaPointToLatLng({ x: labelX, y: labelY })
    const { lat: labelLat, lng: labelLon } = labelPosition
    Object.assign(patch, {
      labelDiffLat: labelLat - centerLat,
      labelDiffLon: labelLon - centerLon
    })
  }

  return patch
}
