import React from 'react'

import Field from 'components/layout/form/Field'


const PerceptionAndRiskCulture = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="perception-and-risk-culture"
    >
      {'Perception et culture du risque'}
    </h1>
    <Field name="propertyFloodedInThePast" />
    <Field name="propertyFloodedInThePastWhichYears" />
    <Field name="propertyFloodedInThePastWaterLevel" />
    <Field name="propertyFloodedInThePastPersonWasAlerted" />
    <Field name="propertyFloodedInThePastMeansOfAlert" />
    <Field name="propertyFloodedInThePastBehaviorOfInterviewed" />
    <Field name="riskCultureInterviewedInformedOfFloodingRiskUponMovingIn" />
    <Field name="riskCultureInterviewedThinksPropertyAtRiskOfFlood" />
    <Field name="riskCultureInterviewedPreoccupiedByFloodingRisk" />
    <Field name="riskCultureComment" />
  </div>
)

export default PerceptionAndRiskCulture
