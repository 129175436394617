export const UPDATE_BACKUP_STATE = 'UPDATE_BACKUP_STATE'

const initialState = {
    shouldContinueToShowBackupStatus: false,
    timerStartBackupModal: undefined,
    usingBackupModal: false
}

export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_BACKUP_STATE:
        return {...state, ...action.patch}
      default:
        return state
    }
}
  
export const assignBackup = (patch) => ({patch, type: UPDATE_BACKUP_STATE })