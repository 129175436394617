import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import { Map } from 'react-leaflet'
import { useDispatch } from 'react-redux'

import { assignMap } from 'redux/reducers/map'
import { ZOOM_DELTA, MIN_SIG_SIZE } from 'utils/leaflet'
import styles from 'utils/styles'

const Sig = ({
  children,
  disabled,
  forcedSize,
  maxZoom,
  minZoom,
  onDragEnd,
  onZoomEnd,
  ...reactLeafletProps
}) => {
  const {
    doubleClickZoom,
    dragging,
    scrollWheelZoom,
    touchZoom
  } = reactLeafletProps
  const dispatch = useDispatch()
  const mapRef = useRef()

  const handleMapHandlers = useCallback(() => {
    if (!mapRef) return
    const map = mapRef.current.leafletElement
    if (disabled) {
      map.doubleClickZoom.disable()
      map.dragging.disable()
      map.scrollWheelZoom.disable()
      map.touchZoom.disable()
    }
    if (doubleClickZoom) map.doubleClickZoom.enable()
    if (dragging) map.dragging.enable()
    if (scrollWheelZoom) map.scrollWheelZoom.enable()
    if (touchZoom) map.touchZoom.enable()
  }, [disabled, doubleClickZoom, dragging, mapRef, scrollWheelZoom, touchZoom])

  const removeCanvasElement = useCallback(overlayPane => {
    [...overlayPane.children].forEach(child => {
      if (child.nodeName === 'CANVAS') {
        child.height = 0
        child.width = 0
        overlayPane.removeChild(child)
      }
    })
  }, [])

  const handleRemoveCanvasElement = useCallback(() => {
    const { overlayPane } = mapRef.current.leafletElement.getPanes()
    window.addEventListener('onpagehide' in window ? 'pagehide' : 'unload',
      () => removeCanvasElement(overlayPane),
      false)
    return () => {
      removeCanvasElement(overlayPane)
    }
  }, [mapRef, removeCanvasElement])

  const handleSetBounds = useCallback(() => {
    const bounds = mapRef.current.leafletElement.getBounds()
    const { lat: minLat, lng: minLon } = bounds.getSouthWest()
    const { lat: maxLat, lng: maxLon } = bounds.getNorthEast()
    const centerPosition = [(maxLat + minLat) / 2., (maxLon + minLon) / 2.]
    dispatch(assignMap({
      centerPosition,
      maxLat,
      maxLon,
      minLat,
      minLon
    }))
  }, [dispatch, mapRef])

  const handleSetLeafletElement = useCallback(() => {
    window.mapRef = mapRef
  }, [mapRef])

  const handleSetZoom = useCallback(() => {
    dispatch(assignMap({
      zoom: mapRef.current.leafletElement.getZoom()
    }))
  }, [dispatch, mapRef])

  const handleForcedResize = useCallback(() => {
    const el = mapRef.current.leafletElement._container.parentElement
    const drawing_container = el.parentElement
    const header = document.querySelector("div.diagnostician-header-container")
    const headerWidth = header ? header.clientWidth : 0
    let resizeDependencies = Math.max(headerWidth, MIN_SIG_SIZE.size)
    const scaleX = resizeDependencies / forcedSize.width
    const scaleY = resizeDependencies / forcedSize.height
    el.style.height = `${forcedSize.height}px`
    el.style.width = `${forcedSize.width}px`
    el.style.transform = `scale(${scaleX}, ${scaleY})`
    el.style.transformOrigin = `0 0`
    mapRef.current.leafletElement.invalidateSize()
    drawing_container.style.height = `${resizeDependencies + parseInt(styles['size-with-margin-sketches-bar'])}px`
  }, [forcedSize, mapRef])

  const handleSyncMapSizeWithContainer = useCallback(() => {
    setTimeout(() => {
      if (!mapRef.current) return
      mapRef.current.leafletElement.invalidateSize()
      handleSetBounds()
      if (!forcedSize) return
      handleForcedResize()
      window.addEventListener('resize', handleForcedResize)
    })
    return () => {
      forcedSize && window.removeEventListener('resize', handleForcedResize)
    }
  }, [forcedSize, handleForcedResize, handleSetBounds, mapRef])

  const handleDragEnd = useCallback(event => {
    handleSetBounds()
    if (onDragEnd) onDragEnd(event)
  }, [handleSetBounds, onDragEnd])

  const handleZoomEnd = useCallback(event => {
    handleSetBounds()
    handleSetZoom()
    if (onZoomEnd) onZoomEnd(event)
  }, [handleSetBounds, handleSetZoom, onZoomEnd])

  useEffect(handleMapHandlers, [handleMapHandlers])
  useEffect(handleRemoveCanvasElement, [handleRemoveCanvasElement])
  useEffect(handleSetBounds, [handleSetBounds])
  useEffect(handleSetLeafletElement, [handleSetLeafletElement])
  useEffect(handleSetZoom, [handleSetZoom])
  useEffect(handleSyncMapSizeWithContainer, [handleSyncMapSizeWithContainer])

  return (
    <Map
      className={classnames('sig', { disabled })}
      dragging
      id="sig"
      maxZoom={maxZoom}
      minZoom={minZoom}
      onDragEnd={handleDragEnd}
      onZoomEnd={handleZoomEnd}
      ref={mapRef}
      scrollWheelZoom
      touchZoom
      zoomControl={false}
      zoomDelta={ZOOM_DELTA}
      zoomSnap={ZOOM_DELTA}
      {...reactLeafletProps}
    >
      {disabled && <div className="disable-mask" />}
      {children}
    </Map>
  )
}

Sig.defaultProps = {
  center: [46.50, 2.407980],
  children: null,
  disabled: false,
  forcedSize: null,
  maxZoom: 24,
  minZoom: 3,
  onDragEnd: null,
  onZoomEnd: null,
  zoom: 6
}

Sig.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
  forcedSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  onDragEnd: PropTypes.func,
  onZoomEnd: PropTypes.func,
  zoom: PropTypes.number,
}

export default Sig
