import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import Icon from 'components/layout/Icon'
import { closeMenu } from 'redux/reducers/menu'


const Navigation = ({
  iconName,
  label,
  to,
  ...navLinkProps
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location


  const handleCloseMenu = useCallback(() => dispatch(closeMenu()), [dispatch])


  return (
    <div className="navigation">
      {to === pathname
        ? (<div className="current">
          <Icon name={iconName} />
          <span>
            {label}
          </span>
        </div>)
        : (<NavLink
            id={`see-${to}`}
            onClick={handleCloseMenu}
            to={to}
            {...navLinkProps}
           >
          <Icon name={iconName} />
          <span>
            {label}
          </span>
        </NavLink>)}
    </div>
  )
}

Navigation.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default Navigation
