import React from 'react'
import { useLeaflet } from 'react-leaflet'

import { scaleFrom } from 'utils/leaflet'


const Scale = () => {
  const { map } = useLeaflet()
  const mapHeight = map.getSize().y
  const mapWidth = map.getSize().x
  const zoom = map.getZoom()
  const maxDistanceInMeters = map.containerPointToLatLng([0, mapHeight])
                                 .distanceTo(map.containerPointToLatLng([mapWidth, mapHeight]))
  const { distance, unit, width } = scaleFrom(zoom, maxDistanceInMeters / mapWidth)


  return (
    <div className="scale">
      <div
        className="scale-line"
        style={{width}}
      >
        <div className="scale-left-value">
          {'0'}
        </div>
        <div className="scale-right-value">
          {distance}
          {unit}
        </div>
      </div>
    </div>
  )
}


export default Scale
