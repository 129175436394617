import { stringify } from 'query-string'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mergeData, requestData } from 'redux-thunk-data'

const parseLayerName = layerName => {
  const chunks = layerName.split('__')
  return {
    availability: chunks[5],
    country: chunks[0],
    dataset: chunks[1],
    date: chunks[4],
    provider: chunks[3],
    subdivision: chunks[2],
  }
}

const isActiveLayer = (layer, activatingLayerFilters) => {
  if (!layer.parse) { layer.parse = parseLayerName(layer.name) }
  return !!(activatingLayerFilters || []).find(layerFilter =>
    Object.keys(layerFilter)
      .every(key => layerFilter[key] === layer.parse[key]))
}

const processLayer = (layer, activatingLayerFilters) => ({
  ...layer,
  isActive: isActiveLayer(layer, activatingLayerFilters)
})

export default (config = {}) => {
  const {
    activatingLayerFilters,
    filterOnRequest,
    requestOnlyOnce
  } = config
  const dispatch = useDispatch()

  const otherActiveLayers = useSelector(state =>
    (state.data.layers || []).filter(layer => layer.isActive && !isActiveLayer(layer, activatingLayerFilters)),
    [activatingLayerFilters])

  useEffect(() => {
    if (filterOnRequest && activatingLayerFilters) {
      activatingLayerFilters.forEach(activatingLayerFilter => {
        dispatch(
          requestData({
            apiPath: `/layers?${stringify(activatingLayerFilter)}`,
            getDatumIdValue: layer => layer.name,
            process: layer => processLayer(layer, activatingLayerFilters),
            requestOnlyOnce
          }))
      })
      return
    }
    dispatch(
      requestData({
        apiPath: '/layers',
        getDatumIdValue: layer => layer.name,
        process: layer => processLayer(layer, activatingLayerFilters),
        requestOnlyOnce
      }))
  }, [activatingLayerFilters, dispatch, filterOnRequest, requestOnlyOnce])

  useEffect(() => {
    if (activatingLayerFilters && otherActiveLayers.length) {
      dispatch(mergeData({ layers: otherActiveLayers.map(l => ({ ...l, isActive: false })) }))
    }
  }, [activatingLayerFilters, dispatch, otherActiveLayers])
}