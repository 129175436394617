import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestData } from 'redux-thunk-data'
import { createSelector } from 'reselect'
import { selectCurrentUser } from 'with-react-redux-login'

import ProgrammeItem from './ProgrammeItem'


const selectSortedProgrammes = createSelector(
  state => state.data.programmes,
  programmes => {
    if (!programmes) return
    const sortedProgrammes = [...programmes]
    sortedProgrammes.sort((p1, p2) =>
      p1.name > p2.name
        ? 1
        : -1)
    return sortedProgrammes
  })


const Programmes = ({ setTransitionClassNames }) => {
  const dispatch = useDispatch()
  const { isSuccess } = useSelector(state =>
    state.requests['/users/current']) || {}

  const { firstName, isSuperAdmin, userProgrammes } = useSelector(selectCurrentUser) || {}
  const hasNoProgramm = isSuccess && !isSuperAdmin && !userProgrammes?.length

  const programmes = useSelector(selectSortedProgrammes)

  useEffect(() => {
    dispatch(requestData({ apiPath: '/programmes' }))
  }, [dispatch])


  if (!isSuccess) return null


  return (
    <div className="programmes">
      <div className="select-programme">
        <div className="info">
          {'Bonjour, '}
          {firstName}
          {' !'}
          <br />
        </div>
        {hasNoProgramm
          ? (
            <div className="sub-info">
              {'Oups vous n\'avez des droits sur aucun dispositif, demandez à la personne qui gère votre dispositif de vous en attribuer via la plateforme.'}
            </div>
          )
          : (
            <>
              <div className="sub-info">
                {'Sur quoi allez-vous travailler ?'}
              </div>
              <div className="programmes-list">
                {(programmes || []).map(programme => (
                  <ProgrammeItem
                    key={programme.id}
                    programme={programme}
                    setTransitionClassNames={setTransitionClassNames}
                  />
                ))}
              </div>
            </>)}
      </div>
    </div>
  )
}


Programmes.defaultProps = {
  setTransitionClassNames: null
}

Programmes.propTypes = {
  setTransitionClassNames: PropTypes.func
}

export default Programmes
