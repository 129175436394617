import { combineReducers } from 'redux'

import backup from './backup'
import download from './download'
import duplication from './duplication'

const backgroundProcessesReducer = combineReducers({
    backup,
    download,
    duplication
})

export default backgroundProcessesReducer