import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const selectDateModifiedByDossierId = createSelector(
    state => state.data.dossiers,
    (_state, dossierId) => dossierId,
    (dossiers, dossierId) => {
        if (!dossiers) return

        const dateOfLastModification = dossiers.filter((dossier) => dossier.id === dossierId)
                                               .map((dossier) => dossier.dateModified)
        return dateOfLastModification[0]
    })

export default (dossierId) => {
    return useSelector(state =>
        selectDateModifiedByDossierId(state, dossierId),
        [dossierId]
    )
}