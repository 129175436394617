import React from 'react'
import { NavLink } from 'react-router-dom'

import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'
import Main from 'components/layout/Main'

const LINKS = [
  {
    label: 'Vérifier l’éligibilité',
    to: '/eligibilite'
  },
  {
    label: 'Diagnostiquer',
    to: '/diagnostiquer',
  },
  {
    label: 'Créer un lien d’envoi de fichiers',
    to: '/telecharger',
  }
]

const Home = () => (
  <>
    <Header />
    <Main name="home">
      <div className="navigations">
        {LINKS.map(({label, to}) => (
          <NavLink
            className="navigation"
            key={to}
            to={to} 
          >
            {label}
          </NavLink>
        ))}
      </div>
    </Main>
    <Footer />
  </>
)

export default Home
