import { createSelector } from 'reselect'

import selectNamedNotSoftDeletedSketchesByDossierId from './selectNamedNotSoftDeletedSketchesByDossierId'


export default createSelector(
  selectNamedNotSoftDeletedSketchesByDossierId,
  sketches => {
    const sortedSketches = [...sketches]
    sortedSketches.sort((s1, s2) =>
      new Date(s1.dateCreated) > new Date(s2.dateCreated)
        ? 1
        : -1)
    const { activityIdentifier: lastSketchActivityIdentifier } = sortedSketches[0] || {}
    return lastSketchActivityIdentifier
  })
