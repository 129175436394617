import createCachedSelector from 're-reselect'

import tools from './tools'
import colorTool from './tools/colorTool'


export const selectColorByTag = createCachedSelector(
  tag => tag,
  tag => colorTool.pans.reduce((allItems, pan) => allItems.concat(pan.items), [])
                      .find(item => item.tag === tag))(tag => tag || '')


export const selectToolByName = createCachedSelector(
  name => name,
  name => tools.find(tool => tool.name === name)
)(name => name || '')


export const selectItemByName = createCachedSelector(
  name => name,
  name => {
    for (let tool of tools) {
      if (!tool.pans) continue
      for (let pan of tool.pans) {
        for (let item of pan.items) {
          if (item.name === name) {
            return item
          }
        }
      }
    }
  })(name => name || '')
