import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import Icon from 'components/layout/Icon'
import LoadingButton from 'components/layout/LoadingButton'
import { callWithDebounce } from 'utils/calls'

import Suggestions from './Suggestions'

const initialState = {
  debouncedValue: '',
  error: null,
  isLoading: false,
  isSuggestionsOpen: true,
  suggestions: [],
  valueValidated: false,
  value: '',
}


const ComboBox = ({
  defaultValue,
  onDebouncedInputChange,
  onInputChange,
  onSuggestionClick,
  renderSuggestion,
  suggestionToError,
  validate,
  ...inputProps
}) => {
  const [state, setState] = useState(initialState)
  const {
    debouncedValue,
    error,
    isLoading,
    isSuggestionsOpen,
    suggestions,
    value,
    valueValidated
  } = state

  const handleInputChange = useCallback(event => {
    const nextValue = event.target.value
    const valueValidated = validate(nextValue)

    const nextState = { value: nextValue }
    if (!valueValidated) {
      nextState.isLoading = false
      nextState.suggestions = []
    } else {
      nextState.isLoading = true
    }

    if (onInputChange) {
      onInputChange(event)
    }

    setState(previousState => ({ ...previousState, ...nextState }))

    callWithDebounce(1000)(() => {
      const nextState = { debouncedValue: nextValue, valueValidated }
      if (!valueValidated) nextState.suggestions = []
      setState(previousState => ({ ...previousState, ...nextState }))
    })

  }, [onInputChange, setState, validate])

  const handleSuggestionClick = useCallback((event, suggestion) => {
    event.preventDefault()
    const validationError = suggestionToError(suggestion)
    if (validationError) {
      setState(previousState => ({...previousState, error: validationError}))
      return
    }
    const nextValue = onSuggestionClick(event, suggestion)
    if (nextValue) setState(previousState =>
      ({...previousState, isSuggestionsOpen: false, value: nextValue }))
  }, [onSuggestionClick, setState, suggestionToError])

  const handleSuggestionsClose = useCallback(event => {
    event.persist()
    if (!event.relatedTarget) return
    const blurFromSuggestionsList = event.relatedTarget.className === 'suggestion'
    if (blurFromSuggestionsList) return
    if (!isSuggestionsOpen) return
    setState(previousState => ({...previousState, isSuggestionsOpen: false}))
  }, [isSuggestionsOpen, setState])


  useEffect(() => {
    if (!defaultValue) return
    setState({...initialState, value: defaultValue })
  }, [defaultValue, setState])

  useEffect(() => {
    if (!valueValidated) return
    async function doAsyncInputChange () {
      const suggestions = await onDebouncedInputChange(debouncedValue)
      setState(previousState => ({
        ...previousState,
        isLoading: false,
        suggestions,
        value: debouncedValue
      }))
    }
    doAsyncInputChange()
  }, [
    debouncedValue,
    onDebouncedInputChange,
    setState,
    valueValidated
  ])


  return (
    <div className="combo-box">
      <div
        className="field"
        onBlur={handleSuggestionsClose}
      >
        <div className="underline">
          <LoadingButton isLoading={isLoading}>
            <Icon name="la-search" />
          </LoadingButton>
          <input
            className="autocomplete"
            onChange={handleInputChange}
            value={value}
            {...inputProps}
          />
          {isSuggestionsOpen && (<Suggestions
            error={error}
            onSuggestionClick={handleSuggestionClick}
            renderSuggestion={renderSuggestion}
            suggestions={suggestions}
            value={value}
          />)}
        </div>
      </div>
    </div>
  )
}


ComboBox.defaultProps = {
  onInputChange: null,
  renderSuggestion: null,
  suggestionToError: suggestion => null,
  validate: value => value
}

ComboBox.propTypes = {
  onDebouncedInputChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  onSuggestionClick: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  suggestionToError: PropTypes.func,
  validate: PropTypes.func,
}

export default ComboBox
