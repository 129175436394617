import React from 'react'

import NumberField from 'components/layout/form/fields/NumberField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const ElecMeter = () => (
  <>
    <TextField
      label="Etiquette : "
      name="label"
      placeholder="Ex. : EC1"
    />
    <NumberField
      label="Hauteur au sol : "
      name="fromGroundHeight"
      placeholder="Ex. : 0,95"
      unitLabel='m'
    />
    <TextField
      format={formatToUpperCase}
      label="Plancher ou T.N. de référence : "
      name="baseFlooring"
      placeholder="Ex. : N0"
    />
    <TextareaField
      label="Commentaire : "
      name="comment"
    />
  </>
)

export default ElecMeter
