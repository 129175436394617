import PropTypes from 'prop-types'
import React from 'react'

import Field from 'components/layout/form/Field'


const AddressForm = ({ prefixFieldName }) => (
  <>
    <Field name={`${prefixFieldName}number`} />
    <Field name={`${prefixFieldName}streetType`} />
    <Field name={`${prefixFieldName}street`} />
    <Field name={`${prefixFieldName}complement`} />
    <Field name={`${prefixFieldName}postalCode`} />
    <Field name={`${prefixFieldName}city`} />
  </>
)

AddressForm.propTypes = {
  prefixFieldName: PropTypes.string.isRequired
}

export default AddressForm
