const Elevator = [
    {
        componentType: "TextareaField",
        label: "Localisation Machinerie : ",
        name: "machineryLocation",
    },
    {
        componentType: "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    }
]
  
export default Elevator
  