import React from 'react'

import Field from 'components/layout/form/Field'
import TextareaField from 'components/layout/form/fields/TextareaField'


const Evacuation = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="evacuation2"
    >
      {'Evacuation II'}
    </h1>
    <Field name="evacuationHomeWouldBeStrandedByFlood" />
    <Field name="evacuationRescueByFootPossible" />
    <Field name="evacuationRescueByHelicopterPossible" />
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="evacuationComment"
    />
    <TextareaField
      label="Préconisations : "
      name="evacuationRecommendation"
    />
  </div>
)

export default Evacuation
