import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { closeModal } from 'redux-react-modals'
import {
  requestData
} from 'redux-thunk-data'

import { closeMenu } from 'redux/reducers/menu'
import requests from 'redux/reducers/requests'
import { shouldGoToGoogleLogout } from 'utils/google'


const SignoutButton = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSignoutClick = useCallback(() => {
    dispatch(requestData({
      apiPath: '/users/signout',
      handleSuccess: (state, action) => {
        dispatch(closeMenu())
        if (!shouldGoToGoogleLogout(requests(state, action))) {
          history.push('/connection')
        }
      },
      name: 'signout',
      stateKey: null
    }))
    dispatch(closeModal('main'))
  }, [dispatch, history])


  return (
    <button
      className="signout-button"
      onClick={handleSignoutClick}
      type="button"
    >
      {children}
    </button>
  )
}


SignoutButton.defaultProps = {
  children: null
}

SignoutButton.propTypes = {
  children: PropTypes.node
}

export default SignoutButton
