import PropTypes from 'prop-types'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import Icon from 'components/layout/Icon'

const setDangerousArrayKeyIndex = index => `field_error_${index}`


const selectLastRequestErrors = createSelector(
  state => state.requests,
  requests =>
    (Object.keys(requests)
          .filter(key => requests[key]?.isFail)
          .map(errorRequestKey => requests[errorRequestKey])
          .sort((request1, request2) =>
            new Date(request1.date) < new Date(request2.date)
            ? 1
            : -1)[0] || {}).errors)


const formMessageFromType = type => {
  switch(type) {
    case 'required':
      return 'Ce champ est obligatoire.'
    case 'positiveNumber':
      return 'La valeur doit être positive.'
    default:
      return type
        ? `"${type}" erreur avec ce champ.`
        : undefined
  }
}


const frenchMessageFrom = message => {
  switch(message) {
    case 'Wrong identifier':
      return 'Mauvais identifiant.'
    default:
      return message
  }
}


const FieldError = ({ name }) => {
  const { errors: formErrors } = useFormContext()
  const { message, type } = formErrors[name] || {}
  const formErrorMessage = message || formMessageFromType(type)

  const requestErrorMessages = (useSelector(state =>
    selectLastRequestErrors(state)) || {})[name]

  const errorMessages = formErrorMessage
    ? [formErrorMessage]
    : requestErrorMessages

  return (
    <span className="field-error">
      {(errorMessages && (
        <span className="icon-and-messages">
          <Icon name="ico-30-warning.svg" />
          <span className="messages">
            {errorMessages.map((errorMessage, index) => (
              <span
                className="message"
                key={setDangerousArrayKeyIndex(index)}
              >
                {frenchMessageFrom(errorMessage)}
              </span>
            ))}
          </span>
        </span>
      )) ||
        null}
    </span>
  )
}


FieldError.propTypes = {
  name: PropTypes.string.isRequired
}

export default FieldError
