export const getTimeSinceDateInDozensOfSeconds = (date) => {
  if (!date) return 0
  return Math.round((new Date(Date.now()) - new Date(date)) / 10000)
}

const calculateTimeUnit = (timeInDozensOfSeconds, timeUnit) => {
    return Math.trunc(timeInDozensOfSeconds / timeUnit)
}

export default (timeInDozensOfSeconds) => {
    if (timeInDozensOfSeconds >= 60 && timeInDozensOfSeconds < 360)
      return `${calculateTimeUnit(timeInDozensOfSeconds, 60) * 10}\u00A0minutes`
    else if (timeInDozensOfSeconds >= 360 && timeInDozensOfSeconds < 8640)
      return `${calculateTimeUnit(timeInDozensOfSeconds, 360)}\u00A0heure(s)`
    else if (timeInDozensOfSeconds >= 8640)
      return `${calculateTimeUnit(timeInDozensOfSeconds, 8640)}\u00A0jour(s)`
    return "10\u00A0minutes"
}