import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Spinner from 'components/layout/Spinner'

import { Entry } from './Entry'
import Glossary from './Glossary'
import SubTabs from './SubTabs'


const Audit = () => {
  const entryRef = useRef()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { entryName, dossierId } = useParams()

  const { isPending: isDossierRequestPending } = useSelector(state =>
    state.requests[`/dossiers-diagnostic/${dossierId}`]) || {}
    

  useEffect(() => {
    if (entryName === '__transition__') {
      history.replace(`${pathname.slice(0, -entryName.length)}${search.slice(1)}`)
    }
  }, [entryName, history, pathname, search])


  return (
    <div className="audit">
      <div className="top-bar">
        <SubTabs />
        {isDossierRequestPending && <Spinner />}
        <div className="sep" />
      </div>
      <div className="glossary-and-entry">
        <Glossary entryRef={entryRef} />
        <div className="gap" />
        <Entry
          ref={entryRef}
        />
      </div>
    </div>
  )
}


export default Audit
