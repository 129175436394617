import React, { useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showModal, closeModal } from 'redux-react-modals'

import usePrevious from 'components/hooks/usePrevious'
import { assignBackup } from 'redux/reducers/backup'
import selectIsAnyRequestPending from 'redux/selectors/requests/selectIsAnyRequestPending'
import selectStatusOfLastSavingAttempt from 'redux/selectors/requests/selectStatusOfLastSavingAttempt'
import selectTimecodeOldestOfflineActivity from 'redux/selectors/requests/selectTimecodeOldestOfflineActivity'
import selectAllOfflineActivities from 'redux/selectors/selectAllOfflineActivities'
import selectInfosModalBackupStatus from 'redux/selectors/selectInfosModalBackupStatus'

import ModalBackupStatus from '../ModalBackupStatus'
import { findBackupToastContent } from './utils/chooseLayout'
import { getTimeSinceDateInDozensOfSeconds } from './utils/formatTime'

const timeLimitBeforeActivitiesBecomesOld = 3
const timeLimitBeforeModalShouldBeShowed = 60

const useBackupLayout = () => {
    const dispatch = useDispatch()
    
    const timecodeOldestUnsavedActivity = useSelector(selectTimecodeOldestOfflineActivity)
    const timeSinceOldestActivityWasCreated = getTimeSinceDateInDozensOfSeconds(timecodeOldestUnsavedActivity)
    const activitiesAreOld = timeSinceOldestActivityWasCreated >= timeLimitBeforeActivitiesBecomesOld
    const activitiesAreVeryOld = timeSinceOldestActivityWasCreated >= timeLimitBeforeModalShouldBeShowed

    const emptyActivities = !useSelector(selectAllOfflineActivities).length
    // c'est un "ou bien" dans le cas ou le user est actif meme pendant les tentatives de sauvegardes
    const isBackupDoingGood = emptyActivities || !activitiesAreOld

    const lastBackupFailed = !useSelector(selectStatusOfLastSavingAttempt)
    const isBackupComplicated = lastBackupFailed && activitiesAreOld

    const {shouldContinueToShowBackupStatus, timerStartBackupModal, usingBackupModal} = useSelector(selectInfosModalBackupStatus)
    const timeSinceLastBackupModalWasClosed = timerStartBackupModal ? getTimeSinceDateInDozensOfSeconds(timerStartBackupModal) : 0

    const canShowModalAgain = !timerStartBackupModal || timeSinceLastBackupModalWasClosed >= timeLimitBeforeModalShouldBeShowed
    const shouldShowModal = activitiesAreVeryOld && canShowModalAgain
    
    const handleGlobalBackupNotifications = useCallback(() => {
        if (!shouldContinueToShowBackupStatus) {
            if (isBackupComplicated)  dispatch(assignBackup({ shouldContinueToShowBackupStatus: true }))
        } else if (isBackupDoingGood) {
            setTimeout(() => {
                dispatch(assignBackup({ shouldContinueToShowBackupStatus: false }))
            }, 5000)
        }
    }, [shouldContinueToShowBackupStatus, isBackupDoingGood, isBackupComplicated, dispatch])

    useEffect(() => handleGlobalBackupNotifications(),[handleGlobalBackupNotifications])

    const handleShowBackupModal = useCallback(() => {
        if (!shouldContinueToShowBackupStatus) return
        if (!usingBackupModal) {
            if (shouldShowModal) dispatch(assignBackup({ timerStartBackupModal : null, usingBackupModal: true }))
        } else if (isBackupDoingGood) dispatch(assignBackup({ timerStartBackupModal : null, usingBackupModal: false }))
    }, [shouldContinueToShowBackupStatus, isBackupDoingGood, shouldShowModal, usingBackupModal, dispatch])
    
    useEffect(() => handleShowBackupModal(),[handleShowBackupModal])

    const previouslyStateOfUsingBackupModal = usePrevious(usingBackupModal)
    const shouldShowBackupModal = usingBackupModal && previouslyStateOfUsingBackupModal === false

    const handleDispatchShowBackupModal = useCallback(() => {
        if (shouldShowBackupModal) {
            dispatch(showModal('main',
              <ModalBackupStatus
                timeSinceOldestActivityWasCreated={timeSinceOldestActivityWasCreated}
              />, {isUnclosable : true}))
        }
    }, [shouldShowBackupModal, timeSinceOldestActivityWasCreated, dispatch])

    useEffect(() => handleDispatchShowBackupModal(),[handleDispatchShowBackupModal])

    const shouldHideBackupModal = !usingBackupModal && previouslyStateOfUsingBackupModal === true
    const handleDispatchHideBackupModal = useCallback(() => {
        if (shouldHideBackupModal) dispatch(closeModal('main'))
    }, [shouldHideBackupModal, dispatch])

    useEffect(() => handleDispatchHideBackupModal(),[handleDispatchHideBackupModal])

    const isAnyBackupRequestPending = useSelector(selectIsAnyRequestPending)

    const handleBackupToastContent = useCallback(() => {
        return findBackupToastContent(isBackupDoingGood, isAnyBackupRequestPending)
    }, [isAnyBackupRequestPending, isBackupDoingGood])

    const showBackupToast = shouldContinueToShowBackupStatus && !usingBackupModal

    return {handleBackupToastContent, showBackupToast}
}

export default useBackupLayout
