import React from 'react'

import Field from 'components/layout/form/Field'


const Basement = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="basement"
    >
      {'Sous-sol I'}
    </h1>
    <Field name='basement' />
    <Field name='basementType' />
    <Field name='basementHasVentsWithGrills' />
  </div>
)

export default Basement
