import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { closeNotification } from 'redux/reducers/notification'

const DownloadFailedModal = ({closeModal, dispatch}) => {

  const handleOkClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
  }, [dispatch, closeModal])

  return (
    <div className="download-failed-modal">
      <div className="title">
        Le téléchargement a échoué. Veuillez réessayer.
      </div>
      <div className="controls">
        <button
          className="ok"
          onClick={handleOkClick}
          type="button"
        >
          Ok
        </button>
      </div>
    </div>
  )
}

DownloadFailedModal.propTypes = {
  closeModal : PropTypes.func.isRequired,
  dispatch : PropTypes.func.isRequired
}

export default DownloadFailedModal