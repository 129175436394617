export default (node, {
  figureScale,
  forcedScaleX,
  forcedScaleY,
  rescaleEnabled: rescaleByFigureEnabled,
  rotation,
  scaleX,
  scaleY
}) => {
  let nodeScaleX = forcedScaleX || scaleX || 1
  let nodeScaleY = forcedScaleY || scaleY || 1

  if (rescaleByFigureEnabled) {
    nodeScaleX = figureScale * nodeScaleX
    nodeScaleY = figureScale * nodeScaleY
  }

  node.setAttrs({
    scaleX: nodeScaleX,
    scaleY: nodeScaleY
  })

  node.setAttrs({ rotation })

  return node
}
