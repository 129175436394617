import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'redux-react-modals'


const PhotoModal = () => {
  const dispatch = useDispatch()


  const handleClick = useCallback(() =>
    dispatch(closeModal('main')), [dispatch])


  return (
    <div className="photo-modal">
      <div>
        <div className="title">
          Photo enregistrée !
        </div>
        <div>
          <button
            onClick={handleClick}
            type="button"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

export default PhotoModal
