import styles from 'utils/styles'

import { allColorTags } from './colorTool'


const tool =  {
  name: 'info',
  pans: [
    {
      items: [
        {
          colorTags: allColorTags,
          label: 'Titre',
          name: 'text-title',
          svg: 'ico-30-text-title.svg'
        },
        {
          colorTags: allColorTags,
          label: 'Texte courant',
          name: 'text-regular',
          svg: 'ico-30-text-regular.svg'
        },
        {
          colorTags: allColorTags,
          label: 'Texte secondaire',
          name: 'text-minor',
          svg: 'ico-30-text-minor.svg'
        },
        {
          colorTags: [],
          label: 'PHE',
          name: 'text-phe',
          svg: 'ico-30-text-phe.svg'
        }
      ],
      name: 'Texte'
    }
  ],
  svg: 'ico-30-text.svg'
}

tool.pans[0].items = tool.pans[0].items.map(item => ({
  align: 'center',
  clickable: true,
  doubleClickable: true,
  fill: styles[`${item.name}_font-color`],
  fontSize: parseInt((styles[`${item.name}_font-size`] || '12').replace('px', '')),
  fontStyle: styles[`${item.name}_font-style`],
  panName: tool.pans[0].name,
  resizeEnabled: true,
  rotateEnabled: true,
  strokeWidth: 0,
  type: 'text',
  ...item
}))


export default tool
