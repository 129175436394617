import styles from './styles'


export const baseFlooringOptions =  [
  { label: 'N0', value: 'N0' },
  { label: 'N0-1', value: 'N0-1' }
]


export const materialOptions = [
  { label: 'acier', value: 'steel' },
  { label: 'alu', value: 'alu' },
  { label: 'bois', value: 'wood' },
  { label: 'métal', value: 'metal' },
  { label: 'PVC', value: 'PVC' }
]


export const yesNoOptions = [
  { label: 'oui', value: 'yes' },
  { label: 'non', value: 'no' },
  { label: 'non vérifiable', value: 'no verifiable' }
]


export const sketchPrefixNames = [
  'Vue générale',
  'Enjeux extérieurs',
  'RDC',
  'Sous-sol',
  'Autre',
  'Annexe'
]


export const heavyBuildingPolygonStyle = {
  color: 'black',
  fillColor: styles['laser-lemon'],
  fillOpacity: 1,
  opacity: 1,
  weight: 3
}

export const lightBuildingPolygonStyle = {
  color: 'black',
  fillColor: styles['milan'],
  fillOpacity: 1,
  opacity: 1,
  weight: 1
}


export const parcelPolygonBorder = {
  color: 'black',
  dashArray: '6, 3',
  dashOffset: 'none',
  fillColor: 'transparent',
  fillOpacity: 0,
  lineCap: 'butt',
  lineJoin: 'arcs',
  opacity: 1,
  weight: 3,
}


export const parcelPolygonFill = {
  fillColor: 'white',
  fillOpacity: 1,
  opacity: 0,
}


export const testimoniesYears = [
  '1988',
  '2002',
  '2005',
  '2014'
]
