import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import MeasurementForm from  'components/layout/MeasurementForm'
import selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName from 'redux/selectors/selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName'


const Data = () => {
  const { dossierId, sketchActivityIdentifier } = useParams()


  const figureMeasurements = useSelector(state =>
    selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifierAndToolName(state, dossierId, sketchActivityIdentifier, 'figure'))


  if (!figureMeasurements || !figureMeasurements.length) {
    return (
      <div className="data">
        <div className="empty">
          {'Retrouvez ici les données saisies au double-clic sur les figurés.'}
        </div>
      </div>
    )
  }

  return (
    <div className="data">
      {figureMeasurements.map(figureMeasurement => (
        <MeasurementForm
          activityIdentifier={figureMeasurement.activityIdentifier}
          key={figureMeasurement.activityIdentifier}
          placeholder="Non renseigné"
          readOnly
        />
      ))}
    </div>
  )
}

export default Data
