import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

const FolderForm = () => {
  const history = useHistory()


  const [folderId, setFolderId] = useState()


  const handleChange = useCallback(
    event => setFolderId(event.target.value),
    [setFolderId]
  )

  const handleSubmit= useCallback(event => {
    event.preventDefault()
    history.push(`/telecharger/${folderId}`)
  }, [folderId, history])


  return (
    <form
      className="folder-form"
      onSubmit={handleSubmit}
    >
      <div className="sub-cta">
        Entrez l'identifiant du dossier google
        dans lequel les fichiers seront chargés.
      </div>
      <input
        name="folderId"
        onChange={handleChange}
      />
      <button type="submit">
        Soumettre
      </button>
    </form>
  )
}

export default FolderForm
