import { createElement, useCallback, useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { showModal } from 'redux-react-modals'

import { assignDownload } from 'redux/reducers/download'
import { DOSSIER_ENTRIES, UUID_RE } from 'utils/router'

import ConfirmLeaveSketchWhileDownloading from '../ConfirmLeaveSketchWhileDownloading'

const isDossierPathname = pathname =>
  DOSSIER_ENTRIES.find(path => pathname.includes(path.name))

const re = new RegExp(`sketches/${UUID_RE}$`, 'i')
const isSketchPathname = pathname => re.test(pathname)

export default () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [isShowingModal, setIsShowingModal] = useState(false)
  const { hasShownBlockingModal, isDownloading } = useSelector(state => state.background_processes.download)

  const shouldBlockWhenLeaveSketch = useCallback(nextLocation => {
    const currentlyOnSketch = isSketchPathname(location.pathname)
    const tryingToLeaveCurrentSketch = currentlyOnSketch && (location.pathname !== nextLocation.pathname)
    const tryingToLeaveCurrentDossier = isDossierPathname(location.pathname) && !isDossierPathname(nextLocation.pathname)
    if (!isShowingModal && tryingToLeaveCurrentSketch && window.postSnapshot && isDownloading && !hasShownBlockingModal) {
      setIsShowingModal(true)
      dispatch(showModal('main', createElement(ConfirmLeaveSketchWhileDownloading,
                                      {
                                        onCancel: () => {
                                          setIsShowingModal(false)
                                        },
                                        onConfirm: () => {
                                          if (tryingToLeaveCurrentDossier) history.push(nextLocation.pathname)
                                          else history.push(nextLocation)
                                          dispatch(assignDownload({hasShownBlockingModal : true }))
                                          setIsShowingModal(false)
                                        },
                                      }, null), { isUnclosable: true }))
      return true
    }
    return false
  }, [dispatch, isShowingModal, history, location.pathname, setIsShowingModal, isDownloading, hasShownBlockingModal])

  return useEffect(() => {
    const unblock = history.block(nextLocation => {
      return !shouldBlockWhenLeaveSketch(nextLocation)
    })

    return unblock
  }, [history, shouldBlockWhenLeaveSketch])
}
