// inspiration from https://github.com/CartoDB/Leaflet.CanvasLayer/blob/gh-pages/leaflet_canvas_layer.js
import { Layer, Stage } from 'konva'
import L from 'leaflet'
import { MapLayer, withLeaflet } from 'react-leaflet'

import { callWithDebounce } from 'utils/calls'

const KonvaLayer = withLeaflet(class KonvaLayer extends MapLayer {
  // konvaPoint.x is proportional to lng
  // konvaPoint.y is inversely proportional to lat
  konvaPointToLatLng = konvaPoint => {
    const { initialZoom, leaflet: { map } } = this.props
    return map.unproject({
      x: this.northWestLayerPoint.x + konvaPoint.x,
      y: this.northWestLayerPoint.y + konvaPoint.y
    }, initialZoom)
  }

  latLngToKonvaPoint = latLng => {
    const { initialZoom, leaflet: { map } } = this.props
    const layerPoint = map.project(latLng, initialZoom)
    return {
      x: layerPoint.x - this.northWestLayerPoint.x,
      y: layerPoint.y - this.northWestLayerPoint.y
    }
  }

  createLeafletElement() {
    return null
  }

  componentDidMount() {
    this._createStage()
  }

  _createStage() {
    const {
      initialZoom,
      layersCount = 1,
      leaflet: { map },
      maxLat,
      maxLon,
      minLat,
      minLon
    } = this.props
    const canAnimate = map.options.zoomAnimation && L.Browser.any3d
    const zoomClass = `leaflet-zoom-${canAnimate ? 'animated' : 'hide'}`
    this.northWestLayerPoint = map.project([maxLat, minLon], initialZoom)
    this.southEastLayerPoint = map.project([minLat, maxLon], initialZoom)
    this.stage = new Stage({
      container: '.leaflet-pane leaflet-overlay-pane',
      height: this.southEastLayerPoint.y - this.northWestLayerPoint.y,
      width: this.southEastLayerPoint.x - this.northWestLayerPoint.x
    })
    this.mapSize = map.getSize()
    this.initialSizeRatio = this.mapSize.x / this.stage.width()
    this.distanceInMeters = this.initialSizeRatio * L.latLng(maxLat, maxLon).distanceTo(L.latLng(maxLat, minLon))
    this.layers = [...Array(layersCount).keys()].map(() => {
      const layer = new Layer()
      this.stage.add(layer)
      layer.canvas._canvas.classList.add(zoomClass)
      return layer
    })
    this._updateTransform(map.getCenter(), map.getZoom())
    this.configure()
    this.redraw()
  }

  componentWillUnmount() {
    this._removeCanvasElement()
  }

  componentDidUpdate() {
    this.props.leaflet.map.invalidateSize()
    this.redraw()
  }

  configure() {
    const { leaflet: { layerContainer, map } } = this.props

    map.on('viewreset', this.redraw)
    if (map.options.zoomAnimation && L.Browser.any3d) {
      map.on('zoomanim', this._onAnimZoom, this)
    }

    map.on('resize', () => {
      if (!this.hasAlreadyResized) {
        this.hasAlreadyResized = true
        this._removeCanvasElement()
        this._createStage()
      } else {
        callWithDebounce(1000)(() => {
          this._removeCanvasElement()
          this._createStage()
        })
      }
    })

    map.on('click', () => {
      if (this.stage.hasAlreadyClicked) {
        map.doubleClickZoom.disable()
      } else if (layerContainer.options.doubleClickZoom) {
        map.doubleClickZoom.enable()
      }
    })

    window.addEventListener(
      'onpagehide' in window ? 'pagehide' : 'unload',
      () => this._removeCanvasElement(),
      false
    )
  }

  _onAnimZoom(event) {
    this._updateTransform(event.center, event.zoom)
  }

  _removeCanvasElement() {
    const { overlayPane } = this.props.leaflet.map.getPanes()
    if (this.layers) {
      // https://stackoverflow.com/questions/52532614/total-canvas-memory-use-exceeds-the-maximum-limit-safari-12
      this.layers.forEach(layer => {
        layer.clear()
        if (overlayPane && overlayPane.contains(layer.canvas._canvas)) {
          overlayPane.querySelector('[role="presentation"]')
            .removeChild(layer.canvas._canvas)
        }
      })
    }
    if (this.stage) {
      this.stage.width(0)
      this.stage.height(0)
    }
  }

  _updateTransform(nextCenterLatLng, nextZoom) {
    if (!this._centerLatLng) {
      this._centerLatLng = nextCenterLatLng
    }
    const { initialZoom, leaflet: { map } } = this.props
    const topLeft = map.containerPointToLayerPoint([0, 0])
    L.DomUtil.setPosition(this.stage.content, topLeft)
    const scale = this.initialSizeRatio * map.getZoomScale(nextZoom, initialZoom)
    const position = L.DomUtil.getPosition(this.stage.content)
    const viewHalf = map.getSize().multiplyBy(-0.5 / this.initialSizeRatio)
    const currentCenterPoint = map.project(this._centerLatLng, nextZoom)
    const destCenterPoint = map.project(nextCenterLatLng, nextZoom)
    const centerOffset = destCenterPoint.subtract(currentCenterPoint)
    const topLeftOffset = viewHalf.multiplyBy(-scale)
      .add(position)
      .add(viewHalf)
      .subtract(centerOffset)
    L.DomUtil.setTransform(this.stage.content, topLeftOffset, scale)
    
    if (initialZoom === nextZoom) {
      this._centerLatLng = nextCenterLatLng
    }
  }

  async redraw() {
    if (this.stage.isFrozen) return
    if (this.props.onRedraw) {
      await this.props.onRedraw(
        this.stage,
        this.layers,
        {
          distanceInMeters: this.distanceInMeters,
          konvaPointToLatLng: this.konvaPointToLatLng,
          latLngToKonvaPoint: this.latLngToKonvaPoint
        }
      )
    }
    this.layers.forEach(layer => layer.draw())
  }

  reset = () => {
    const { leaflet: { map }, initialZoom } = this.props
    this._updateTransform(map.getCenter(), initialZoom)
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.onRedraw !== this.props.onRedraw
  }

  render() {
    return null
  }
})

export default KonvaLayer
