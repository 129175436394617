import React from 'react'


const InfosBar = () => (
  <div className="infos-bar">
    Préparez vos fonds de carte en zoomant ci-dessous, puis créez les vues correspondantes.
  </div>
)


export default InfosBar
