import { Arrow } from 'konva'

import styles from 'utils/styles'

import patchify from '../../patchify'


const MIN_X = 25
const MIN_Y = 25
const TYPE = 'arrow'


export default config => {
  const {
    color,
    handleChange,
    item,
    layers,
    stage,
    tool,
  } = config
  const {
    dash,
    pointerLength,
    pointerWidth,
    strokeWidth
  } = item || tool

  let isPaint = false
  let node
  const measurementLayer = layers[1]

  stage.on('mousedown touchstart', event => {
    if (isPaint) return
    isPaint = true
    const { x, y } = stage.getPointerPosition()
    const stroke = color
      ? styles[color.name]
      : 'black'
    node = new Arrow({
      dash,
      fill: stroke,
      pointerLength,
      pointerWidth,
      points: [x, y, x + MIN_X, y + MIN_Y],
      stroke,
      strokeWidth
    })
    stage.isDrawingNode = true
    measurementLayer.add(node)
  })

  stage.on('mousemove touchmove', () => {
    if (!isPaint || !node) return
    const { x, y } = stage.getPointerPosition()
    const newPoints = node.points()
                          .slice(0, 2)
                          .concat([x, y])
    node.points(newPoints)
    measurementLayer.batchDraw()
  })

  stage.on('mouseup touchend', event => {
    if (!isPaint || !node) return
    isPaint = false
    const points = [...node.attrs.points]
    if (Math.abs(points[2]-points[0]) < MIN_X) {
      points[2] = points[0] + Math.sign(points[2]-points[0]) * MIN_X
    }
    if (Math.abs(points[3]-points[1]) < MIN_Y) {
      points[3] = points[1] + Math.sign(points[3]-points[1]) * MIN_Y
    }
    const positionPatch = {
      points,
      type: TYPE
    }
    stage.isDrawingNode = false
    handleChange(event, patchify(TYPE, positionPatch, config))
  })
}
