import _ from 'lodash'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const LAYER_Z_INDEX = 3000

export const processLayers = ({ inseeCode, layers }) => {
    const filteredLayers = layers
        .filter(layer => !_.isEmpty(layer.parse))
        .filter(layer => layer.parse.subdivision.startsWith(inseeCode.substring(0, 2)))
        .filter(layer => layer.isActive)

    const layersGroupedByDataset = _.chain(filteredLayers)
        .groupBy(({ parse: { dataset } }) => { return dataset })

    let latestLayerInEachDataset = []
    for (const datasetLayers of layersGroupedByDataset) {
        let latestLayer = datasetLayers.sort((l1, l2) => {return l2.parse.date < l1.parse.date ? -1 : 1})[0]
        latestLayerInEachDataset.push({name: latestLayer.name,
                                       workspace: latestLayer.workspace,
                                       zIndex: latestLayer.zIndex || LAYER_Z_INDEX})
        }
    return latestLayerInEachDataset
}


const activeLayersByInseeCode = createSelector(
    state => state.data.layers,
    (_state, inseeCode) => inseeCode,
    (layers, inseeCode) => processLayers({ inseeCode, layers })
)

export default (inseeCode) => {
    return useSelector(state =>
        activeLayersByInseeCode(state, inseeCode),
        [inseeCode]
    )
}
