import React from 'react'

import Field from 'components/layout/form/Field'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectMeasurementsByDossierIdAndItemName from 'redux/selectors/selectMeasurementsByDossierIdAndItemName'


const Electricity = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="electricity"
    >
      {'Électricité I'}
    </h1>
    <Field name='electricalSeparateNetworks' />
    <Field name='electricalEmergencyShutdownPossible' />
    <Field name='electricalEmergencyShutdownComment' />
    <br />
    <MeasurementBlocks
      itemName='elec-'
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {measurement => (
        <Block withTitle>
          <Property name="fromGroundHeight" />
          <Property name="baseFlooring" />
          {measurement.itemName === 'elec-boar' && <Property name="boardType" />}
        </Block>)}
    </MeasurementBlocks>
  </div>
)

export default Electricity
