import {
  natureColorTags,
  otherColorTags,
  wallPartitionColor
} from './colorTool'

import styles from 'utils/styles'


const tool =  {
  name: 'stroke',
  svg: 'ico-30-line.svg',
  legends: [
    {name: 'wall-partition'},
  ],
  pans: [
    {
      name: 'Trait',
      items: [
        {
          colorTags: [wallPartitionColor.tag],
          label: 'Mur, Cloison',
          name: 'wall-partition'
        },
        {
          colorTags: natureColorTags.concat(otherColorTags),
          label: 'Autre',
          name: 'other'
        },
      ]
    }
  ]
}

tool.pans[0].items = tool.pans[0].items.map(item => ({
  clickable: true,
  hitStrokeWidth: 12,
  name: 'line',
  persistent: true,
  resizeEnabled: true,
  strokeWidth: parseInt((styles[`${item.name}_stroke-width`] || '12').replace('px', '')),
  svg: 'ico-30-line.svg',
  type: 'line',
  ...item
}))


export default tool
