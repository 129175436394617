import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByActivityIdentifier } from 'redux-thunk-data'

import usePrevious from 'components/hooks/usePrevious'
import { LocalAsset } from 'redux/persistor'
import selectIsDrawingWithDraggableSketch from 'redux/selectors/selectIsDrawingWithDraggableSketch'
import {
  addBackgroundImage,
  addNorthDirection,
  addScale
} from 'utils/drawing/background'

import KonvaLayer from './KonvaLayer'
import SnapshotWithLegend from './SnapshotWithLegend'

const SketchLayer = props => {
  const { backgroundLocalAsset, onDraw } = props
  const { sketchActivityIdentifier } = useParams()
  const konvaLayerRef = useRef() || {}
  const { current } = konvaLayerRef || {}
  const { props: konvaLayerProps, reset, stage } = current || {}
  const { leaflet } = konvaLayerProps || {}
  const { map } = leaflet || {}
  const backgroundDataUrl = backgroundLocalAsset?.dataUrl
  const isDrawingWithDraggableSketch = useSelector(selectIsDrawingWithDraggableSketch)
  const { zoom: mapZoom } = useSelector(state => state.map)
  const { zoom: sketchZoom } = useSelector(state => selectEntityByActivityIdentifier(state, sketchActivityIdentifier))
  const isDraggingEnabled = isDrawingWithDraggableSketch && mapZoom > sketchZoom
  const isMinZoom = mapZoom === sketchZoom
  const previousIsMinZoom = usePrevious(isMinZoom)
  const drawing = useSelector(state => state.drawing)
  const isChangingColor = usePrevious(drawing.tool.name) === 'color'
  const isChangingFigureScale = usePrevious(drawing.figureScale) !== drawing.figureScale

  const handleRedraw = useCallback(async (stage, layers, utils) => {
    if (stage.isDrawingNode) return
    const backgroundLayer = layers[0]
    const measurementLayer = layers[1]
    await addBackgroundImage(backgroundLayer, backgroundDataUrl)
    addNorthDirection(backgroundLayer)
    addScale(backgroundLayer, sketchZoom, utils.distanceInMeters)
    backgroundLayer.draw()
    const noMeasurementIsCurrentlySelected = !drawing.uuids.length
    if (noMeasurementIsCurrentlySelected || isChangingColor || isChangingFigureScale){
      measurementLayer.destroyChildren()
    } else {
      const measurementNodeNotToDestroy = measurementLayer.getChildren().filter(child => drawing.uuids.includes(child.attrs.id || child.attrs.nodeId))
      const idsTransformerNodesNotToDestroy = measurementNodeNotToDestroy.map(node => {
        if (node.transformerNode)
          return node.transformerNode._id
        return false
      })
      const idsNodes = measurementNodeNotToDestroy.map(node => node._id)
      const idsNotToDestroy = idsNodes.concat(idsTransformerNodesNotToDestroy).filter(id => id !== false)
      const childrenToDestroy = measurementLayer.getChildren().filter(child => !idsNotToDestroy.includes(child._id))
      childrenToDestroy.forEach(child => child.destroy())
    }
    onDraw(stage, layers, utils)
  }, [backgroundDataUrl, sketchZoom, onDraw, drawing, isChangingColor, isChangingFigureScale])

  useEffect(() => {
    if (map && isDraggingEnabled) {
      map.dragging.enable()
    } else if (map) {
      map.dragging.disable()
    }
  }, [isDraggingEnabled, map])

  useEffect(() => {
    if (!reset) return
    if (isMinZoom && !previousIsMinZoom) {
      reset()
    }
  }, [isMinZoom, previousIsMinZoom, reset])

  return (
    <>
      <SnapshotWithLegend
        backgroundLocalAsset={backgroundLocalAsset}
        stage={stage}
      />
      <KonvaLayer
        {...props}
        layersCount={2}
        onRedraw={handleRedraw}
        ref={konvaLayerRef}
      />
    </>
  )
}

SketchLayer.defaultProps = {
  backgroundLocalAsset: null
}

SketchLayer.propTypes = {
  backgroundLocalAsset: PropTypes.instanceOf(LocalAsset),
  onDraw: PropTypes.func.isRequired,
}

export default SketchLayer
