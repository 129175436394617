import createCachedSelector from 're-reselect'


const mapArgsToCacheKey = (state, name) => name || ''


export default createCachedSelector(
  state => state.data.fields,
  (state, name) => name,
  (fields, name) => (fields || []).find(field =>
    field.name === name)
)(mapArgsToCacheKey)
