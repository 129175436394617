import React from 'react'

import Logo from 'components/layout/Logo'
import { VERSION } from 'utils/config'


const Footer = () => (
  <footer className="footer">
    <Logo withLink />
    <div className="version">
      {VERSION}
    </div>
  </footer>
)

export default Footer
