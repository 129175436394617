import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { closeModal } from 'redux-react-modals'

import { assignDrawing } from 'redux/reducers/drawing'

import Icon from './Icon'


const ToSketchMeasurement = ({ measurement }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { dossierId, programmeId } = useParams()

  const { activityIdentifier, sketchActivityIdentifier } = measurement || {}

  const handleClick = useCallback(() => {
    dispatch(closeModal('main'))
    dispatch(assignDrawing({
      uuids: [activityIdentifier]
    }))
    const pathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches/${sketchActivityIdentifier}`
    history.push(pathname)
  }, [activityIdentifier, dispatch, dossierId, history, programmeId, sketchActivityIdentifier])

  if (!sketchActivityIdentifier) return null

  return (
    <button
      className="to-sketch-measurement"
      disabled={!activityIdentifier}
      onClick={handleClick}
      type="button"
    >
      <Icon name="ico-30-sketch2.svg" />
    </button>
  )
}

ToSketchMeasurement.propTypes = {
  measurement: PropTypes.shape({
    activityIdentifier: PropTypes.string,
    sketchActivityIdentifier: PropTypes.string
  }).isRequired
}

export default ToSketchMeasurement
