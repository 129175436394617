import React from 'react'

import Field from 'components/layout/form/Field'
import TextareaField from 'components/layout/form/fields/TextareaField'


const WasteWater = () => (
  <div className="entry-group waste-water">
    <h1
      className="entry-title"
      id="clean-up"
    >
      {'Assainissement'}
    </h1>
    <Field name='wasteWaterHandlingType' />
    <Field name='wasteWaterPumpPresent' />
    <Field name='wasteWaterCheckValvePresent' />
    <Field
      name='wasteWaterOutdoorTrapPresent'
      readOnly
    />
    <TextareaField
      label='Localisation et commentaire :'
      name='wasteWaterOutdoorTrapLocation'
      readOnly
    />
  </div>
)

export default WasteWater
