import imageCompression from 'browser-image-compression'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { closeModal, showModal } from 'redux-react-modals'
import { activateData } from 'redux-thunk-data'

import useCreateNewSketch from 'components/hooks/useCreateNewSketch' 
import SelectField from 'components/layout/form/fields/SelectField'
import LoadingButton from 'components/layout/LoadingButton'
import selectSketchNameOptionsByDossierId from 'redux/selectors/selectSketchNameOptionsByDossierId'
import { leafletImage } from 'utils/leaflet'

import AskForOneMoreModal from './AskForOneMoreModal'


const SubmitModal = () => {
  const dispatch = useDispatch()
  const { dossierId } = useParams()
  const form = useForm()
  const [backgroundDataUrl, setBackgroundDataUrl] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isLoading = isSubmitting || !backgroundDataUrl
  const { maxLat, maxLon, minLat, minLon, zoom } = useSelector(state => state.map)
  const sketchNameOptions = useSelector(state => selectSketchNameOptionsByDossierId(state, dossierId))
  const {handleCreateNewSketchWithBackground} = useCreateNewSketch({
    dossierId,
    sketch : { background : {dataUrl : backgroundDataUrl}, maxLat, maxLon, minLat, minLon, zoom}
  })

  const handleCancelClick = useCallback(() => dispatch(closeModal('main')), [dispatch])

  const handleSketchSubmit = useCallback(async ({ sketchName }) => {
    setIsSubmitting(true)
    const { activity } = await handleCreateNewSketchWithBackground(sketchName)
    if (!activity) return
    dispatch(activateData([activity]))
    setIsSubmitting(false)
    dispatch(
      showModal('main', <AskForOneMoreModal />, { isUnclosable: true })
    )
  }, [dispatch, handleCreateNewSketchWithBackground, setIsSubmitting])

  useEffect(() => {
    leafletImage(window.mapRef.current.leafletElement, (error, canvas) => {
      if (error) {
        console.warn(error)
        setImageError('L\'image n\'a pas pu être créée. Veuillez réessayer.')
        return
      }

      (async () => {
        const backgroundFile = await imageCompression.canvasToFile(canvas, 'image/png', 'background', Date.now())
        const dataUrl = await imageCompression.getDataUrlFromFile(backgroundFile)
        if (dataUrl && !dataUrl.startsWith('data:image')) {
          setImageError('L\'image n\'a pas été faite correctement. Veuillez réessayer.')
          return
        }
        setBackgroundDataUrl(dataUrl)
      })()
    })
  }, [setBackgroundDataUrl, setImageError])

  const isDisabled = isSubmitting || !form.formState.isDirty

  return (
    <div className="submit-modal">
      <div className="question">
        {'Que représente cette vue ?'}
      </div>
      {backgroundDataUrl && (
        <img
          alt="preview"
          className="preview"
          src={backgroundDataUrl}
        />)}
      <FormProvider {...form}>
        <form
          autoComplete="off"
          disabled={isLoading}
          noValidate
          onSubmit={form.handleSubmit(handleSketchSubmit)}
        >
          {backgroundDataUrl
            ? (
              <SelectField
                className="with-border"
                name="sketchName"
                options={sketchNameOptions}
                placeholder="Choisissez dans la liste."
              />
            )
            : (
              <div className="image-loading">
                {'Chargement en cours, merci de patienter...'}
              </div>
            )}
          <div className="controls">
            <button
              className="cancel-sketch"
              onClick={handleCancelClick}
              type="button"
            >
              {'Annuler'}
            </button>
            <LoadingButton
              disabled={isDisabled}
              isLoading={isLoading}
              isPrimary
              type="submit"
            >
              {'Créer'}
            </LoadingButton>
          </div>
          {imageError && (<div className="error">
            {imageError}
          </div>)}
        </form>
      </FormProvider>
    </div>
  )
}

export default SubmitModal
