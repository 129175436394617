import React from 'react'

import NumberField from 'components/layout/form/fields/NumberField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import Icon from 'components/layout/Icon'
import { formatToUpperCase } from 'utils/form'

const LevelThreshold = () => (
  <>
    <TextField
      label="Nom du plancher intermédiaire : "
      name="flooring"
    />
    <TextField
      label="Description : "
      name="flooringDescription"
    />
    <NumberField
      label="Hauteur au sol : "
      name="fromGroundHeight"
      placeholder="Ex. : 0,95"
      sublabel={(
        <div className="warning">
          <Icon name="ico-30-warning.svg" />
          <span>
            {'Si le plancher de référence est plus haut, saisissez un nombre négatif. Ex. : "-0,23"'}
          </span>
        </div>
      )}
      unitLabel='m'
    />
    <TextField
      format={formatToUpperCase}
      label="Plancher ou T.N. de référence : "
      name="baseFlooring"
      placeholder="Ex. : N0"
    />
    <TextareaField
      label="Commentaire : "
      name="comment"
    />
  </>
)

export default LevelThreshold
