import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { requestData, selectEntityByKeyAndId } from 'redux-thunk-data'

import ComboBox from 'components/layout/ComboBox'
import selectLastSketchActivityIdentifierByDossierId from 'redux/selectors/selectLastSketchActivityIdentifierByDossierId'
import { ReactComponent as Back } from 'utils/svgs/ico-30-back.svg'

const Dossiers = () => {
  const [dossier, setDossier] = useState()
  const dispatch = useDispatch()
  const history = useHistory()
  const { programmeId } = useParams()
  const { name: programmeName } = useSelector(state => selectEntityByKeyAndId(state, 'programmes', programmeId)) || {}
  const lastSketchActivityIdentifier = useSelector(state => selectLastSketchActivityIdentifierByDossierId(state, dossier?.id))

  const handleInputChange = useCallback(() => setDossier(null), [setDossier])

  const handleDebouncedInputChange = useCallback(debouncedValue =>
    new Promise(resolve =>
      dispatch(requestData({
        apiPath: `/dossiers?keywords=${debouncedValue}&programmeId=${programmeId}`,
        handleFail: state => resolve(
          state.data.dossiers?.filter(dossier => dossier.programmeId === programmeId && dossier.number.startsWith(debouncedValue))),
        handleSuccess: (_state, action) => resolve(action.payload.data),
        isMergingDatum: true,
      }))), [dispatch, programmeId])

  const handlePreviousClick = useCallback(() => history.push('/diagnostiquer/programmes'), [history])

  const handleSuggestionClick = useCallback((_event, dossier) => {
    const { number } = dossier
    setDossier(dossier)
    return number
  }, [setDossier])

  const handleSubmit = useCallback(event => {
    event.preventDefault()
    if (dossier != null) {
      const sketchPathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossier.id}/sketches/${lastSketchActivityIdentifier || 'creation'}`
      history.push(sketchPathname)
    }
  }, [dossier, history, lastSketchActivityIdentifier, programmeId])

  const renderSuggestion = useCallback(({ suggestion: { number } }) => number, [])

  return (
    <div className="dossiers">
      <div className="select-dossier">
        <div className="title">
          <button
            className="to-previous"
            onClick={handlePreviousClick}
            type="button"
          >
            <Back />
          </button>
          <span>
            {programmeName}
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="title">
            Choisissez dans la liste ou collez un numéro de diagnostic ci-dessous :
          </div>
          <div className="subtitle">
            NUMERO DE DOSSIER :
          </div>
          <ComboBox
            onDebouncedInputChange={handleDebouncedInputChange}
            onInputChange={handleInputChange}
            onSuggestionClick={handleSuggestionClick}
            placeholder="Ex. : 037-BL0132-2"
            renderSuggestion={renderSuggestion}
          />
          <div className="subinfo">
            Ce numéro figure sur la tâche Google Agenda et sur la plateforme.
          </div>
          <button
            disabled={!dossier}
            type="submit"
          >
            Ouvrir
          </button>
        </form>
      </div>
    </div>
  )
}

export default Dossiers
