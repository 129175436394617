import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { activateData, selectEntityByKeyAndJoin} from 'redux-thunk-data'

import useCreateNewSketch from 'components/hooks/useCreateNewSketch'
import selectEntitiesNotSoftDeletedBySketchActivityIdentifier from 'redux/selectors/selectEntitiesNotSoftDeletedBySketchActivityIdentifier'

import { duplicateItems, findPathnameAfterDuplication } from './utils/duplicateItems'

const useDuplicateSketch = (config = {}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { programmeId : currentProgrammeId, dossierId: currentDossierId } = useParams()

    const { dossierId, programmeId, sketchActivityIdentifier } = config
    const sketch = useSelector(state => selectEntityByKeyAndJoin(state, 'sketches', { key: 'activityIdentifier', value: sketchActivityIdentifier }),[sketchActivityIdentifier])
    const { handleCreateNewSketchWithBackground } = useCreateNewSketch({ dossierId, sketch})

    const measurements = useSelector(state => selectEntitiesNotSoftDeletedBySketchActivityIdentifier(state, sketchActivityIdentifier),[sketchActivityIdentifier])
    
    const handleDuplicateItemsOfAlreadyExistingSketch = useCallback((newSketchActivityIdentifier) => {
        return duplicateItems(dossierId, measurements, newSketchActivityIdentifier)
    }, [measurements, dossierId])

    const redirectAfterDuplication = useCallback((newSketchActivityIdentifier) => {
        const isDuplicationIsForCurrentDossier = currentProgrammeId === programmeId && dossierId === currentDossierId
        if (!isDuplicationIsForCurrentDossier) return false

        const redirectPathnameAfterDuplication = findPathnameAfterDuplication(programmeId, dossierId, newSketchActivityIdentifier)
        history.push(redirectPathnameAfterDuplication)
    }, [currentProgrammeId, currentDossierId, programmeId, dossierId, history])
    
    const getAllDuplicationActivities = useCallback(async(onlyBackgroundSketch = true) => {
        const infosNewSketch = await handleCreateNewSketchWithBackground()
        if (!infosNewSketch) return false
        const {activity : createdSketchActivity, uuid : newSketchActivityIdentifier} = infosNewSketch
        if (!newSketchActivityIdentifier) return false
        const duplicationActivities = [createdSketchActivity]
        if (!onlyBackgroundSketch) {
            const duplicatedItemsActivities = handleDuplicateItemsOfAlreadyExistingSketch(newSketchActivityIdentifier)
            if (!duplicatedItemsActivities.length) return false
            duplicationActivities.push(...duplicatedItemsActivities)
        }
        return { duplicationActivities, newSketchActivityIdentifier }
    },[handleDuplicateItemsOfAlreadyExistingSketch, handleCreateNewSketchWithBackground])

    const handleSketchDuplication = useCallback(async(onlyBackgroundSketch = true) => {

        const result = await getAllDuplicationActivities(onlyBackgroundSketch)
        if (!result) return false

        dispatch(activateData(result.duplicationActivities))

        redirectAfterDuplication(result.newSketchActivityIdentifier)

        return true
    }, [dispatch, redirectAfterDuplication, getAllDuplicationActivities])

    return {getAllDuplicationActivities, handleDuplicateItemsOfAlreadyExistingSketch, handleSketchDuplication, redirectAfterDuplication}
}

export default useDuplicateSketch