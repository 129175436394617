import React from 'react'
import { useFormContext } from 'react-hook-form'

import CheckboxField from 'components/layout/form/fields/CheckboxField'
import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const AirConditioner = () => {
  const { readOnly, watch } = useFormContext()
  const fromGroundHeightNotMeasured = watch('fromGroundHeightNotMeasured')

  return (
    <div className='air-conditioner'>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : EA1"
      />
      <NumberField
        disabled={fromGroundHeightNotMeasured}
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
        {...(fromGroundHeightNotMeasured && { value: '' })}
      />
      <CheckboxField
        label="Non mesurée"
        name="fromGroundHeightNotMeasured"
      />
      {fromGroundHeightNotMeasured && (
        <TextareaField
          label="Expliquez pourquoi : "
          name="fromGroundHeightNotMeasuredReason"
          placeholder="Ex. : Hors de portée"
          {...(!fromGroundHeightNotMeasured && { value: '' })}
        />
      )}
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
    </div>
  )
}

export default AirConditioner
