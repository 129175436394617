import PropTypes from 'prop-types'
import React from 'react'

import Icon from './Icon'


const Marque = ({ white }) => (
  <Icon
    className="marque"
    name={white ? 'logo app w.svg' : 'logo_diagnostic.png'}
  />
)


Marque.defaultProps = {
  white: null
}

Marque.propTypes = {
  white: PropTypes.bool
}

export default Marque
