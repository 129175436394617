import React from 'react'
import { useSelector } from 'react-redux'

import FigureScaleSwitchButton from './FigureScaleSwitchButton'
import SketchesOrDataSwitchButton from './SketchesOrDataSwitchButton'
import SnapshotPreviewButton from './SnapshotPreviewButton'


const SketchBar = () => {
  const isDebugActive = useSelector(state => state.debug.isActive)

  return (
    <div className="sketch-bar">
      <SketchesOrDataSwitchButton />
      <FigureScaleSwitchButton />
      {isDebugActive && <SnapshotPreviewButton />}
    </div>
  )
}

export default SketchBar