import { createSelector } from 'reselect'


export default createSelector(
  state => state.drawing.figure,
  state => state.drawing.shape,
  state => state.drawing.tool,
  state => state.drawing.uuids,
  (figure, shape, tool, uuids) => {
    if (uuids.length > 0) return false
    if (figure) return figure.type === 'symbol'
    if (shape) return false
    if (tool) return ['color', 'selector'].includes(tool.name)
    return true
  }
)
