import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showModal } from 'redux-react-modals'

import PreviewModal from './PreviewModal'


const SnapshotPreviewButton = () => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() =>
    dispatch(showModal('main',
      <PreviewModal />, { extraClassName: 'preview' })), [dispatch])

  return (
    <button
      className="snapshot-preview-button"
      onClick={handleClick}
      type="button"
    >
      { 'Preview' }
    </button>
  )
}

export default SnapshotPreviewButton
