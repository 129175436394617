import React from 'react'

import Field from 'components/layout/form/Field'


const BuildingDescription = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="building-description"
    >
      {'Descriptif du bâti II'}
    </h1>
    <Field name="buildingDescriptionComment" />
  </div>
)

export default BuildingDescription
