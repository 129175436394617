import { scrollTo } from 'utils/scroll'

export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

const initialState = null


export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_NOTIFICATION:
      return initialState
    case SHOW_NOTIFICATION:
      scrollTo(0, 250, 'easeInOutQuad')
      return action.notification
    default:
      return state
  }
}


export const closeNotification = () => ({ type: CLOSE_NOTIFICATION })


export const showNotification = notification => ({
  notification,
  type: SHOW_NOTIFICATION,
})
