import { RegularPolygon } from 'konva'
import destination from '@turf/destination'

import styles from 'utils/styles'

import {
  allCoordsNotEmpty,
  distanceBetweenPoints,
  toPointFeature
} from '../../distance'
import { selectColorByTag } from '../../selectors'


export default (positionPatch, config) => {
  const { latLngToKonvaPoint } = config
  const {
    colorTag,
    labelDiffLat,
    labelDiffLon,
    lat,
    lon,
    radius
  } = positionPatch

  if (!allCoordsNotEmpty(lat, lon, radius)) {
    console.error('Invalid properties for circle : ', { lat, lon, radius })
    return
  }

  const centerPosition = [lat, lon]
  const onTrianglePoint = destination(
    toPointFeature(centerPosition),
    radius,
    90
  )
  const onTrianglePosition = onTrianglePoint.geometry.coordinates
  const centerKonvaPoint = latLngToKonvaPoint(centerPosition)
  const onTriangleKonvaPoint = latLngToKonvaPoint(onTrianglePosition)

  let labelX, labelY
  if (allCoordsNotEmpty(labelDiffLat, labelDiffLon)) {
    const labelPosition = [lat + labelDiffLat, lon + labelDiffLon]
    const labelKonvaPoint = latLngToKonvaPoint(labelPosition)
    labelX = labelKonvaPoint.x
    labelY = labelKonvaPoint.y
  }

  const color = selectColorByTag(colorTag)
  const stroke = color
    ? styles[color.name]
    : 'black'

  const node = new RegularPolygon({
    ...centerKonvaPoint,
    labelX,
    labelY,
    radius: distanceBetweenPoints(centerKonvaPoint,
                                  onTriangleKonvaPoint),
    sides: 3,
    stroke
   })

   return node
}
