import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { callWithDebounce } from 'utils/calls'

import EntryTitleNavigation from './EntryTitleNavigation'


const Glossary = ({ entryRef, titleRefs }) => {
  const history = useHistory()
  const { hash } = useLocation()
  const { entryName, dossierId } = useParams()
  
  const [elements, setElements] = useState()

  const { isSuccess } = useSelector(state =>
    state.requests[`/dossiers-diagnostic/${dossierId}`]) || {}

  useEffect(() => {
    if (!entryRef.current) return
    const nextElements = [...entryRef.current.querySelectorAll('.entry-title')]
                                     .filter(element =>
                                        !element.closest('.entry-tab')
                                                .classList
                                                .contains('entry-hidden'))
    setElements(nextElements)

  }, [entryName, entryRef, isSuccess, setElements])

  useEffect(() => {
    if (!elements || !elements[0]) return
    history.replace(`#${elements[0].id}`)
  }, [elements, history])

  useEffect(() => {
    if (!entryRef || !elements) return
    const entryElement = entryRef.current
    const scrollListener = entryElement.addEventListener('scroll', event => {
      callWithDebounce(100)(() =>{
        const offset = entryElement.scrollTop + (entryElement.clientHeight/2)
        const reversedElements = [...elements].reverse()
        const selectedElement = reversedElements.find(element =>
          offset > element.offsetTop - element.clientHeight
        ) || elements[0]
        if (!hash || !selectedElement) return
        if (selectedElement.id !== hash.slice(1)) {
          history.replace(`#${selectedElement.id}`)
        }
      })
    })
    return () => {
      entryElement.removeEventListener('scroll', scrollListener)
    }
  }, [elements, entryRef, hash, history])


  return (
    <div className="glossary">
      {elements?.map(element => (
        <EntryTitleNavigation
          id={element.id}
          key={element.id}
          label={element.innerHTML}
          isSelected={hash === `#${element.id}`}
        />
      ))}
    </div>
  )
}


export default Glossary
