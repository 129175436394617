const FloatingElement = [
    {
        componentType: "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : EA1",
    },
    {
        componentType: "TextField",
        label: "Nom de l'objet : ",
        name: "floatingElementName",
        placeholder: "Ex. : stock de bois",
    },
    {
        componentType: "RadiosField",
        isBoolean: true,
        label: "Arrimé ",
        name: "tiedUp",
    },
    {
        componentType: "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
    {
        componentType: "PictureField",
        folderPath: "photos",
        label: "Photo : ",
        name: "photo",
    },
]
  
export default FloatingElement
  