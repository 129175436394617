import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestData } from 'redux-thunk-data'

import { API_ENDPOINTS } from 'components/pages/api_endpoints'

export default (departmentCode, interopKey, lat, lon, programme) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (departmentCode && interopKey && programme) {
            dispatch(
                requestData({
                    apiPath: `${API_ENDPOINTS.eligibilities}?departmentCode=${departmentCode}&interopKey=${interopKey}&programme=${programme}`,
                    process: eligibility => ({
                        ...eligibility,
                        departmentCode,
                        id: `${departmentCode}/${interopKey}`,
                        interopKey
                    }),
                    tag: '/eligibilities',
                })
            )
        }
        else if (lat && lon && programme) {
            dispatch(
                requestData({
                    apiPath: `${API_ENDPOINTS.eligibilities}?lat=${lat}&lon=${lon}&programme=${programme}`,
                    process: eligibility => ({
                        ...eligibility,
                        id: `${lat}/${lon}`,
                        lat,
                        lon
                    }),
                    tag: '/eligibilities',
                })
            )
        }
        else {
            return
        }
        
    }, [dispatch, departmentCode, interopKey, lat, lon, programme])
}