import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'redux-react-modals'


const ConfirmLeaveSketchWhileDownloading = ({ onCancel, onConfirm }) => {
  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    dispatch(closeModal('main'))
    setTimeout(onCancel)
  }, [dispatch, onCancel])

  const handleConfirm = useCallback(() => {
    dispatch(closeModal('main'))
    setTimeout(onConfirm)
  }, [dispatch, onConfirm])


  return (
    <div className="confirm-or-cancel-modal">
      <div>
        <div className="title">
          {'Attention, cette action risque de créer des erreurs dans les fichiers en cours de téléchargement.'}
          <br />
          <br />
          {'Souhaitez-vous continuer ?'}
        </div>
        <div className="controls">
          <button
            className="confirm"
            onClick={handleConfirm}
            type="button"
          >
            {'Continuer'}
          </button>
          <button
            className="cancel"
            onClick={handleCancel}
            type="button"
          >
            {'Annuler'}
          </button>
        </div>
      </div>
    </div>
  )
}


ConfirmLeaveSketchWhileDownloading.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}


export default ConfirmLeaveSketchWhileDownloading
