import { requestData } from 'redux-thunk-data'
import withLogin from 'with-react-redux-login'

import { redirectPathnameFromJustLoggedUser } from 'utils/signin'


export default withLogin({
  handleSuccess: (state, action, ownProps) => {
    const { history } = ownProps
    const nextPathname = redirectPathnameFromJustLoggedUser(action.payload.datum)
    history.push(nextPathname)
  },
  isRequired: false,
  requestData,
})
