import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import ToSketchMeasurement from 'components/layout/ToSketchMeasurement'

import MeasurementContext from '../MeasurementContext'
import Property from './Property'


const Block = ({ children, withTitle }) => {
  const { measurement } = useContext(MeasurementContext)
  const { index, item, label } = measurement
  const { label: itemLabel } = item || {}

  return (
    <div className="measurement-block">
      {withTitle && (
        <>
          <div className="measurement-block-title">
            {`${label || index || ''} - ${itemLabel}`}
          </div>
          <ToSketchMeasurement measurement={measurement} />
        </>)}
      <div className={classnames('measurement-block-properties', { 'with-title': withTitle })}>
        {children}
      </div>
    </div>
  )
}


Block.defaultProps = {
  withTitle: false
}


Block.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  withTitle: PropTypes.bool
}

export { Property }
export default Block
