import classnames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'


const tabs = [
  {
      label: 'Audit',
      path: 'audit'
  },
  {
      label: 'Croquis',
      path: 'sketches'
  },
  {
      label: 'Rapport',
      path: 'report'
  }
]


const Tabs = () => {
  const location = useLocation()
  const { dossierId, programmeId } = useParams()


  const { includesWebappAudit } = useSelector(state =>
    selectEntityByKeyAndId(state, 'programmes', programmeId)) || {}
  const auditTab = tabs[0]
  const reportTab = tabs[2]
  auditTab.disabled = !includesWebappAudit
  reportTab.disabled = !includesWebappAudit


  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map(tab => {
        const isSelected = location.pathname.includes(tab.path)
        return (
          <NavLink
            className={classnames('tab', {
              'is-disabled': tab.disabled,
              'is-selected': isSelected
            })}
            key={tab.path}
            to={
              tab.disabled || isSelected
                ? '#'
                : `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/${tab.path}`
              }
          >
            {tab.label}
          </NavLink>
        )})}
      </div>
    </div>
  )
}


export default Tabs
