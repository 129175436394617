import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import LoadingButton from 'components/layout/LoadingButton'
import { ReactComponent as Camera } from 'utils/svgs/ico-30-camera.svg'

import FieldError from '../FieldError'


const PictureField = props => {
  const {
    disabled,
    folderPath,
    id,
    label,
    name,
    noAsterisk,
    required,
    type,
    validate,
    ...inputProps
  } = props
  const {
    register,
    setValue,
    watch,
    ...formContext
  } = useFormContext()
  const { readOnly } = { ...formContext, ...inputProps }
  const inputRef = useRef()
  const { dataUrl, identifier } = watch(name) || {}

  const handleChange = useCallback(event => {
    if (!event.target.files[0]) return
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setValue(name, {
        dataUrl: reader.result,
        folderPath,
        type: '__ASSET__'
      })
    })
    reader.readAsDataURL(event.target.files[0])
  }, [folderPath, name, setValue])


  const handleClick = useCallback(event => {
    inputRef.current.click(event)
  }, [inputRef])


  const inputRegisterRef = useCallback(event => {
    inputRef.current = event
    return register({ name }, { required, validate })
  }, [inputRef, name, register, required, validate])

  // const isDisabled =   /*disabled || readOnly*/
  const isDisabled = true


  return (
    <div
      className={classnames('picture-field', name, { readonly: readOnly })}
      id={id}
    >
      <label
        className="field-label"
        htmlFor={name}
      >
        <span>{label}</span>
        {required && !readOnly && noAsterisk && (
          <span className="field-asterisk">
            {"*"}
          </span>
        )}
      </label>
      <div className="field-control">
        <div className="field-value">
          {!readOnly && (<div className="field-inner">
            <input
              {...inputProps}
              className="field-entry"
              disabled={isDisabled}
              name={name}
              onChange={handleChange}
              readOnly={readOnly}
              ref={inputRegisterRef}
              type="file"
            />
            <button
              className="cta"
              disabled={true}
              onClick={handleClick}
              type="button"
            >
              <span>
                {
                  identifier
                    ? 'Remplacer la photo'
                    : 'Ajouter une photo'
                }
              </span>
              <Camera />
            </button>
          </div>)}
          <div className="picture">
          {dataUrl
            ? (<img
              alt={name}
              src={dataUrl}
            />)
            : (
              <LoadingButton
                className="placeholder"
                disabled={isDisabled}
                isLoading={identifier}
                type="button"
              />
            )}
          </div>
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}


PictureField.defaultProps = {
  disabled: false,
  id: null,
  noAsterisk: false,
  required: false,
  validate: () => true,
}

PictureField.propTypes = {
  disabled: PropTypes.bool,
  folderPath: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  required: PropTypes.bool,
  validate: PropTypes.func,
}

export default PictureField
