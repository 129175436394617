import React from 'react'

import LoadingButton from 'components/layout/LoadingButton'


const Spinner = () => (
  <div className="spinner">
    <LoadingButton isLoading />
  </div>
)

export default Spinner
