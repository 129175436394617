import PropTypes from 'prop-types'
import React from 'react'

import TextField from 'components/layout/form/fields/TextField'


const NumberField = ({ placeholder, unitLabel, ...TextFieldProps}) => (
  <TextField
    {...TextFieldProps}
    inputMode="numeric"
    pattern="[0-9]*"
    placeholder={placeholder}
    type="number"
  >
    {unitLabel && (<span className="unit">
      {unitLabel}
    </span>)}
  </TextField>
)

NumberField.defaultProps = {
  placeholder: 'Saisir',
  unitLabel: null
}

NumberField.propTypes = {
  placeholder: PropTypes.string,
  unitLabel: PropTypes.string
}

export default NumberField
