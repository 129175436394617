import uniq from 'lodash.uniq'
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { showModal } from 'redux-react-modals'
import { activateData, selectEntityByActivityIdentifier } from 'redux-thunk-data'
import { v4 as uuidv4 } from 'uuid'

import useDownloadFile from 'components/hooks/useDownloadFile'
import useLocalAsset from 'components/hooks/useLocalAsset'
import MeasurementForm from 'components/layout/MeasurementForm'
import ResponsiveZoomControl from 'components/layout/ResponsiveZoomControl'
import Sig from 'components/layout/Sig'
import SketchLayer from 'components/layout/SketchLayer'
import Spinner from 'components/layout/Spinner'
import { assignDrawing } from 'redux/reducers/drawing'
import selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier from 'redux/selectors/selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier'
import draw from 'utils/drawing/draw'
import { selectColorByTag } from 'utils/drawing/selectors'
import { initialSelectorTool } from 'utils/drawing/tools'
import { createStrictlyIncreasingDate } from 'utils/format_date'
import { SIG_SIZE } from 'utils/leaflet'

import MeasurementDebug from './MeasurementDebug'
import OverlayDownloadArchive from './OverlayDownloadArchive'
import Palette from './Palette'
import ToolsBar from './ToolsBar'

const Drawing = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { dossierId, sketchActivityIdentifier } = useParams()
  const isDebugActive = useSelector(state => state.debug.isActive)
  const sketch = useSelector(state => selectEntityByActivityIdentifier(state, sketchActivityIdentifier))
  const {
    background,
    minLat,
    minLon,
    maxLat,
    maxLon,
    zoom: sketchZoom
  } = sketch || {}
  const frameCenterPosition = minLat &&
    minLon &&
    maxLat &&
    maxLon &&
    [(maxLat + minLat) / 2., (maxLon + minLon) / 2.]
  const isSketchPending = typeof sketch === 'undefined'
  const drawing = useSelector(state => state.drawing)
  const measurements = useSelector(state => selectMeasurementsWithDepthAndIndexByDossierIdAndSketchActivityIdentifier(state, dossierId, sketchActivityIdentifier))
  const { isPending: isDossierRequestPending, isSuccess: isDossierRequestSuccess } = useSelector(state => state.requests[`/dossiers-diagnostic/${dossierId}`]) || {}
  const backgroundLocalAsset = useLocalAsset(background, { assetsAreLocalWritten: isDossierRequestSuccess })
  const { dataUrl: backgroundDataUrl } = backgroundLocalAsset || {}
  const isDisabled = !backgroundDataUrl || isSketchPending
  const isDownloading = useSelector(state => state.background_processes.download.isDownloading)
  const [hasDownloadedArchiveOnce, setHasDownloadedArchiveOnce] = useState(false)
  
  const handleDraw = useCallback((stage, layers, utils) => 
    draw(measurements, {
      ...drawing,
      ...utils,
      isNodesListening: !isDisabled,
      layers,
      onChange: (_event, uuid, patch) => {
        const activity = {
          dateCreated: createStrictlyIncreasingDate(),
          entityIdentifier: uuid,
          localDossierId: dossierId,
          modelName: 'Measurement',
          patch: { ...patch },
        }
        if (!uuid) {
          const { tool: selectedTool } = drawing
          const { name: selectedToolName } = selectedTool
          const { panName, persistent } = (drawing[selectedToolName] || selectedTool)
          if (panName) {
            activity.patch.panName = panName
          }
          activity.patch.sketchActivityIdentifier = sketchActivityIdentifier
          activity.entityIdentifier = uuidv4()
          if (!persistent) {
            dispatch(
              assignDrawing({
                [selectedToolName]: null,
                tool: initialSelectorTool
              }))
          }
        }
        dispatch(activateData([activity]))
        return activity.entityIdentifier
      },
      onClick: (_event, uuidOruuids) => {
        const { tool: selectedTool } = drawing
        const { name: selectedToolName } = selectedTool
        const { persistent } = (drawing[selectedToolName] || selectedTool)
        if (!uuidOruuids || !uuidOruuids.length) return
        const uuids = Array.isArray(uuidOruuids)
          ? uuidOruuids
          : [uuidOruuids]

        const nextDrawing = {
          uuids: uuids.filter(uuid => !drawing.uuids.includes(uuid))
        }
        if (!persistent) {
          nextDrawing.tool = initialSelectorTool
        }

        const selectedMeasurements = measurements.filter(measurement =>
          uuids.includes(measurement.activityIdentifier))
        const selectedTools = uniq(selectedMeasurements.map(measurement =>
          measurement.tool))
        if (selectedTools.length === 1) {
          nextDrawing.toolBehindUuids = selectedTools[0]
        }
        const selectedItems = uniq(selectedMeasurements.map(measurement =>
          measurement.item))
        if (selectedItems.length === 1) {
          nextDrawing.itemBehindUuids = selectedItems[0]
        }

        if (selectedMeasurements.length === 1) {
          nextDrawing.color = selectColorByTag(selectedMeasurements[0].colorTag)
        }
        dispatch(assignDrawing(nextDrawing))
      },
      onDoubleClick: (_event, uuid) => {
        document.body.style.cursor = 'default'
        dispatch(
          showModal(
            'main',
            <MeasurementForm activityIdentifier={uuid} />,
            { isUnclosable: true }
          )
        )
      },
      stage
  }), [dispatch, dossierId, drawing, isDisabled, measurements, sketchActivityIdentifier])

  const {handleGenerateArchiveAndDownloadWithUI} = useDownloadFile()

  useEffect(() => {
    if (!location.pathname.endsWith('telecharger') && hasDownloadedArchiveOnce){
      setHasDownloadedArchiveOnce(false)
    } else if (location.pathname.endsWith('telecharger') && !isDownloading && !hasDownloadedArchiveOnce && !isDisabled) {
      setHasDownloadedArchiveOnce(true)
      handleGenerateArchiveAndDownloadWithUI(false)
    }
  }, [location, isDownloading, dispatch, handleGenerateArchiveAndDownloadWithUI, hasDownloadedArchiveOnce, setHasDownloadedArchiveOnce, isDisabled])

  return (
    <div className="drawing">
      <Palette />
      <ToolsBar />
      {location.pathname.endsWith('telecharger') &&
      <OverlayDownloadArchive
        hasDownloadedArchiveOnce={hasDownloadedArchiveOnce} 
        isDisabled={isDisabled}
        isDownloading={isDownloading}
      />}
      <div className="sig-container">
        <div className="shadow" />
        {isDossierRequestPending && <Spinner />}
        {isDebugActive && <MeasurementDebug />}
        {frameCenterPosition && sketchZoom && (
          <Sig
            center={frameCenterPosition}
            disabled={isDisabled}
            doubleClickZoom={false}
            forcedSize={SIG_SIZE}
            minZoom={sketchZoom}
            preferCanvas
            zoom={sketchZoom}
          >
            <ResponsiveZoomControl />
            <SketchLayer
              backgroundLocalAsset={backgroundLocalAsset}
              initialZoom={sketchZoom}
              maxLat={maxLat}
              maxLon={maxLon}
              minLat={minLat}
              minLon={minLon}
              onDraw={handleDraw}
              sketchZoom={sketchZoom}
            />
          </Sig>)}
      </div>
    </div>
  )
}

export default Drawing