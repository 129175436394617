import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'

import Root from 'components/root'
import { configureStore } from 'redux/store'
import { MACHINE_ENV, SENTRY_SAMPLE_RATE, SENTRY_SERVER_URL } from 'utils/config'
import 'utils/styles'
import 'utils/touchmove'

import { version } from '../package.json'

const { store, persistor } = configureStore()

if (SENTRY_SERVER_URL) {
  Sentry.init({
    dsn: SENTRY_SERVER_URL,
    environment: MACHINE_ENV,
    integrations: [new BrowserTracing()],
    release: 'v' + version,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
  })
  Sentry.setTag('component', 'webapp')
}

ReactDOM.render(<Root 
  persistor={persistor} 
  store={store} />, 
  document.getElementById('root')
)
