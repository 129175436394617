import { createSelector } from "reselect"

export default createSelector(
    state => state.requests,
    (requests) => {
        if (!requests) return
        const activityPendingStatus = Object.keys(requests).map(key => {
            if (key.indexOf('/__activities__/') === 0)
                return requests[key].isPending
            return false
        })
        const requestPending = activityPendingStatus.filter(status => status)
        if (requestPending.length > 0)
            return true
        return false
    }
)