import { Group } from 'konva'

import styles from 'utils/styles'

import patchify from '../../patchify'
import populate from '../../populate'


export default config => {
  const {
    color,
    handleChange,
    item: { svg, type },
    layers,
    stage
  } = config

  const measurementLayer = layers[1]
  let node

  stage.on('click tap', event => {
    const { x, y } = stage.getPointerPosition()
    node = new Group({
      x: x - 15,
      y: y - 15
    })
    const colorHex = color && styles[color.name]
    populate(document.getElementById(svg).querySelector('g#Symbols'),
             node,
             { fill: colorHex, stroke: colorHex })
    measurementLayer.add(node)
    measurementLayer.batchDraw()
    const pointPatch = {
      x: node.attrs.x,
      y: node.attrs.y,
      type
    }
    handleChange(event, patchify(type, pointPatch, config))
  })
}
