import React from 'react'
import { useFormContext } from 'react-hook-form'

import CheckboxField from 'components/layout/form/fields/CheckboxField'
import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'

import MeasurementField from '../MeasurementField'

const openingTypeOptions = [
  { label: 'Vélux à pivot central', value: 'central pivot' },
  { label: 'Vélux projection', value: 'projection' },
  { label: 'Terrasse', value: 'patio' },
  { label: 'Balcon', value: 'balcony' },
  { label: 'Fenêtre', value: 'window' }
]


const MezzaAtticEntrance = () => {
  const { readOnly, watch } = useFormContext()
  const toRefuge = watch('toRefuge')
  const showRefugePrincipalEntranceComment = watch('refugePrincipalEntrance') === 3

  return (
    <>
      <TextField
        label="Description : "
        name="flooringDescription"
      />
      <NumberField
        label="Hauteur : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,2"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher de l'étage desservi : "
        name="flooring"
        placeholder="Ex. : N1"
      />
      <br />
      <br />
      <div>
        {'Refuge:'}
        <CheckboxField
          label="Ces marches conduisent à un étage ou potentiel refuge "
          name="toRefuge"
        />
        {toRefuge && (
          <>
            <MeasurementField name="refugeSpaceType" />
            <MeasurementField name="refugeSpaceWithElectricity" />
            <MeasurementField name="refugeSpaceArea" />
            <MeasurementField name="refugePrincipalEntrance" />
            {showRefugePrincipalEntranceComment && <MeasurementField name="refugePrincipalEntranceComment" />}
            <MeasurementField name="refugeEvacuationPossibleViaOpening" />
            <MeasurementField name="refugeSpaceUpToCode" />
          </>
        )}
      </div>
      <br />
      <br />
      <div>
        {'Ouvrant Evacuation :'}
        <SelectField
          label="Type d'ouverture : "
          name="openingType"
          options={openingTypeOptions}
          placeholder="Choisissez le type"
        />
        <RadiosField
          isBoolean
          label="Présence d'un volet roulant électrique "
          name="hasElectricShutter"
        />
        <NumberField
          label="Hauteur de l'ouvrant d'évacuation : "
          name="openingLength"
          placeholder="Ex. : 0,2"
          unitLabel='m'
        />
        <NumberField
          label="Largeur de l'ouvrant d'évacuation : "
          name="openingWidth"
          placeholder="Ex. : 0,2"
          unitLabel='m'
        />
      </div>
      <br />
      <br />
      <div>
        {'Hauteur sous plafond à l\'étage :'}
        <NumberField
          label="Min : "
          name="minCeilingHeight"
          placeholder="Ex. : 0,2"
          unitLabel='m'
        />
        <NumberField
          label="Max : "
          name="maxCeilingHeight"
          placeholder="Ex. : 0,2"
          unitLabel='m'
        />
      </div>
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}

export default MezzaAtticEntrance
