import React, { useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showModal, closeModal } from 'redux-react-modals'

import useDuplicateSketch from 'components/hooks/useDuplicateSketch'
import { assignDuplication } from 'redux/reducers/duplication'

import DuplicationFailedModal from '../DuplicationFailedModal'

const useDuplication = () => {
    const dispatch = useDispatch()
    const { programmeId, dossierId, sketchActivityIdentifier, onlyBackgroundSketch } = useSelector(state => state.background_processes.duplication.reference)
    const { handleSketchDuplication } = useDuplicateSketch({dossierId, programmeId, sketchActivityIdentifier})
    const [duplicationHasBeenTrigger, setDuplicationHasBeenTriggered] = useState(false)

    const handleDuplicationError = useCallback(() => {
        dispatch(showModal('main', 
          <DuplicationFailedModal
            closeModal={closeModal}
            dispatch={dispatch}
          />, { isUnclosable: true }))
    }, [dispatch])

    const handleEndOfDuplication = useCallback((duplicationSuccess = false) => {
        const reference = {
            dossierId : null,
            onlyBackgroundSketch : true,
            programmeId : null,
            sketchActivityIdentifier : null
        }
        dispatch(assignDuplication({isDuplicating: false, reference }))
        setDuplicationHasBeenTriggered(false)
        if (!duplicationSuccess) return handleDuplicationError()
    }, [dispatch, handleDuplicationError, setDuplicationHasBeenTriggered])

    return useEffect(() => {
        if (!programmeId || !dossierId || !sketchActivityIdentifier || duplicationHasBeenTrigger) return
        setDuplicationHasBeenTriggered(true)
        handleSketchDuplication(onlyBackgroundSketch, dossierId).then((duplicationSuccess) => {
            handleEndOfDuplication(duplicationSuccess)
        }).catch(() => {
            handleEndOfDuplication()
        })
    }, [programmeId, dossierId, sketchActivityIdentifier, onlyBackgroundSketch,
        handleSketchDuplication, handleEndOfDuplication,
        duplicationHasBeenTrigger, setDuplicationHasBeenTriggered])
}

export default useDuplication
