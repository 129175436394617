import PropTypes from 'prop-types'
import React, {useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Icon from 'components/layout/Icon'
import { assignDuplication } from 'redux/reducers/duplication'
import selectEntitiesNotSoftDeletedBySketchActivityIdentifier from 'redux/selectors/selectEntitiesNotSoftDeletedBySketchActivityIdentifier'
import { IS_SKETCH_DUPLICATION_ENABLED } from 'utils/config'

const DuplicateSketch = ({ isDownloading }) => {
    const dispatch = useDispatch()
    const { programmeId, dossierId, sketchActivityIdentifier } = useParams()
    const { isDuplicating } = useSelector(state => state.background_processes.duplication)
    const measurements = useSelector(state => selectEntitiesNotSoftDeletedBySketchActivityIdentifier(state, sketchActivityIdentifier),[sketchActivityIdentifier])

    const handleDuplicationClick = useCallback(() => {
        if (isDuplicating || !programmeId || !dossierId || !sketchActivityIdentifier) return
        const reference = {
            dossierId,
            onlyBackgroundSketch: !measurements.length,
            programmeId,
            sketchActivityIdentifier
        }
        dispatch(assignDuplication({isDuplicating : true, reference}))
    }, [programmeId, dossierId, sketchActivityIdentifier, isDuplicating, dispatch, measurements])

    if (!IS_SKETCH_DUPLICATION_ENABLED) return null

    return (
      <button
        disabled={isDuplicating || isDownloading}
        onClick={handleDuplicationClick}
        type="button"
      >
        <div className='icon-container'>
          <Icon name="ico-30-duplicate-file.svg" />
        </div>
        Dupliquer
      </button>
    )
}

  
DuplicateSketch.propTypes = {
    isDownloading: PropTypes.bool.isRequired,
}

export default DuplicateSketch