import createCachedSelector from 're-reselect'

import selectMeasurementsByDossierIdAndItemName from './selectMeasurementsByDossierIdAndItemName'

const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  selectMeasurementsByDossierIdAndItemName,
  measurements => {
    if (!measurements) return
    if (!measurements.length) return

    const lowestAltitude = measurements.reduce((min, m) => {
      if (!min) return m.altitude
      return m.altitude <= min ? m.altitude : min
    }, measurements[0].altitude)

    return measurements.find(m =>
      m.altitude === lowestAltitude)
  }
)(mapArgsToCacheKey)
