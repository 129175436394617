import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { activateData, selectValueByWalk } from 'redux-thunk-data'
import { v4 as uuidv4 } from 'uuid'

import useFormChange from 'components/hooks/useFormChange'
import useResetFormWhenFormActivitiesAreUpToDate from 'components/hooks/useResetFormWhenFormActivitiesAreUpToDate'
import selectInitialFormByTop from 'redux/selectors/selectInitialFormByTop'
import { callWithDebounce } from 'utils/calls'
import {createStrictlyIncreasingDate} from 'utils/format_date'

const noop = activity => activity


const ActivitiesForm = ({ children,
                          topActivityIdentifier,
                          topId,
                          topStateKey,
                          processActivity=noop,
                          processInitial=noop }) => {
  const dispatch = useDispatch()
  const form = useForm({ mode: 'onBlur' })
  const { formState, reset } = form
  const { isDirty } = formState

  const [ allowDebounce, setAllowDebounce ] = useState(true)
  const [ isDebounceActive, setIsDebounceActive] = useState(false)
  const [ isDebounceCallbackActive, setIsDebounceCallbackActive] = useState(false)
  const formChange = useFormChange(form)

  const actualFormValues = useSelector(state =>
    processInitial(state, selectInitialFormByTop(state,
      { activityIdentifier: topActivityIdentifier, id: topId, key: topStateKey })),
    [topActivityIdentifier, topId, topStateKey])

  const formActivityIdentifier = useSelector(state =>
    selectValueByWalk(state,
                      {
                        path: 'activityIdentifier',
                        topActivityIdentifier,
                        topId,
                        topKey: topStateKey,
                      })) || topActivityIdentifier

  const data = useSelector(state => state.data)

  const fields = useSelector(state => state.data.fields)
  
  const {handleResetWhenFormActivitiesAreUpToDate} = useResetFormWhenFormActivitiesAreUpToDate({
    actualFormValues,
    formChange,
    isDebounceActive,
    isDebounceCallbackActive,
    reset,
    setAllowDebounce
  })

  const setParentActivitiesFrom = useCallback((field, activity, activitiesByEntityIdentifier) => {
    const { dateCreated, entityIdentifier } = activity
    const chunks = field.name.split('.')
    const { modelName } = field
    let parentIndex = 1
    let previousParentActivity
    let keepParsing = true
    while (keepParsing && parentIndex < chunks.length + 1) {
      const parentEntityPath = chunks.slice(0, -parentIndex - 1).join('.')
      const parentKey = parentIndex < chunks.length
                        ? chunks.slice(-parentIndex - 1)[0]
                        : ''
      const parentField = fields.find(field => {
        if (!field.name) return false
        if (!parentEntityPath) return !field.name.includes('.')
        const parentChunks = field.name.split(`${parentEntityPath}.`)
        if (parentChunks.length === 1) return false
        return !parentChunks.slice(1)[0].includes('.')
      })
      if (!parentField) {
        console.warn('Could not find the parent field that is needed to know the model name of this activity.')
        return
      }
      const parentModelName = parentField.modelName

      let parentEntityIdentfier = parentEntityPath
          ? selectValueByWalk({ data },
                              {
                                path: parentEntityPath,
                                topActivityIdentifier: formActivityIdentifier,
                                topId,
                                topKey: topStateKey
                              })?.activityIdentifier
          : formActivityIdentifier

      const parentPatch = {
        [parentKey]: {
          stateKey: pluralize((previousParentActivity?.modelName || modelName).toLowerCase(), 2),
          type: '__normalizer__'
        },
        [`${parentKey}ActivityIdentifier`]: previousParentActivity?.entityIdentifier || entityIdentifier
      }

      let parentActivity
      if (parentEntityIdentfier) {
        parentActivity = activitiesByEntityIdentifier[parentEntityIdentfier]
        keepParsing = false
      } else {
        parentEntityIdentfier = uuidv4()
      }

      if (!parentActivity) {
        // FIXME : parent date created should not be younger than it's children activity - Elise & Arnaud
        let parentDateCreated = new Date(new Date(previousParentActivity?.dateCreated || dateCreated).getTime() + 1).toISOString()
        parentActivity = {
          dateCreated: parentDateCreated,
          entityIdentifier: parentEntityIdentfier,
          modelName: parentModelName,
          patch: {}
        }
      }

      parentActivity.patch = {
        ...parentActivity.patch,
        ...parentPatch
      }
      activitiesByEntityIdentifier[parentEntityIdentfier] = parentActivity

      previousParentActivity = parentActivity
      parentIndex++
    }
  }, [data, fields, formActivityIdentifier, topId, topStateKey])
  
  useEffect(() => {
    if (!isDirty) {
      setAllowDebounce(true)
    }
  }, [isDirty, setAllowDebounce])


  useEffect(() => {
    handleResetWhenFormActivitiesAreUpToDate()
  }, [handleResetWhenFormActivitiesAreUpToDate])

  const createActivitiesForFormInput = useCallback(() => {
    const activitiesByEntityIdentifier = {}
    const entityIdentifiersByEntityPath = {}
    Object.keys(formChange)
            .forEach(fieldName => {
              const field = fields.find(field => field.name === fieldName)
              if (!field) {
                console.warn(`field was not found for ${fieldName}`)
                return
              }
              let entityIdentifier = formActivityIdentifier || uuidv4()
              let needsSetParentActivities = false
              let patchKey = fieldName

              if (fieldName.includes('.')) {
                const chunks = fieldName.split('.')
                patchKey = chunks.slice(-1)[0]
                const entityPath = chunks.slice(0, -1).join('.')

                entityIdentifier = entityIdentifiersByEntityPath[entityPath]
                if (!entityIdentifier) {
                  entityIdentifier = selectValueByWalk({ data },
                                                       {
                                                         path: entityPath,
                                                         topActivityIdentifier: formActivityIdentifier,
                                                         topId,
                                                         topKey: topStateKey
                                                       })?.activityIdentifier
                }
                if (!entityIdentifier) {
                  entityIdentifier = uuidv4()
                  needsSetParentActivities = true
                }
                entityIdentifiersByEntityPath[entityPath] = entityIdentifier
              }

              let activity = activitiesByEntityIdentifier[entityIdentifier]
              const additionalPatch = { [patchKey]: formChange[fieldName] }
              if (activity) {
                activity.patch = { ...activity.patch, ...additionalPatch }
              } else {
                const { modelName } = field
                const dateCreated = createStrictlyIncreasingDate()
                activity = {
                  dateCreated,
                  entityIdentifier,
                  modelName,
                  patch: additionalPatch
                }
              }
              if (needsSetParentActivities) {
                setParentActivitiesFrom(field, activity, activitiesByEntityIdentifier)
              }

              activitiesByEntityIdentifier[activity.entityIdentifier] = activity
            })
            
      if (Object.keys(activitiesByEntityIdentifier).length) {
        const activities = Object.values(activitiesByEntityIdentifier)
                                 .map(processActivity)
        setAllowDebounce(false)
        dispatch(activateData(activities))
      }
  }, [formChange, formActivityIdentifier, data, fields, dispatch, processActivity, setAllowDebounce, setParentActivitiesFrom, topId, topStateKey])

  useEffect(() => {
    if (!allowDebounce) return
    if (!formChange) return
    if (isDebounceCallbackActive) return
    if (!isDebounceActive)
      setIsDebounceActive(true)
    callWithDebounce(2000)(() => {
      setIsDebounceCallbackActive(true)
      setIsDebounceActive(false)
      createActivitiesForFormInput()
      setIsDebounceCallbackActive(false)
    })
  }, [allowDebounce, formChange, isDebounceActive, isDebounceCallbackActive, createActivitiesForFormInput, setIsDebounceActive, setIsDebounceCallbackActive])


  return (
    <FormProvider {...form}>
      <form autoComplete="off">
        <input
          name="activityIdentifier"
          type="hidden"
        />
        {children}
      </form>
    </FormProvider>
  )
}


ActivitiesForm.defaultProps = {
  processActivity: noop,
  processInitial: noop,
  topActivityIdentifier: null,
  topId: null,
  topStateKey: null
}


ActivitiesForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  processActivity: PropTypes.func,
  processInitial: PropTypes.func,
  topActivityIdentifier: PropTypes.string,
  topId: PropTypes.string,
  topStateKey: PropTypes.string
}


export default ActivitiesForm
