import createCachedSelector from 're-reselect'

import selectMeasurementsWithComputedPropertiesByDossierId from './selectMeasurementsWithComputedPropertiesByDossierId'

const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  selectMeasurementsWithComputedPropertiesByDossierId,
  measurements => {
    if (!measurements) return
    return measurements.filter(measurement =>
      measurement.itemName === 'trap')
  }
)(mapArgsToCacheKey)
