import { formatToUpperCase } from 'utils/form'

const LevelThreshold = [
    {
        componentType: "TextField",
        label : "Nom du plancher intermédiaire : ",
        name : "flooring",
    },
    {
        componentType: "TextField",
        label : "Description : ",
        name : "flooringDescription",
    },
    {
        componentType: "NumberField",
        label : "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        sublabel: true,
        unitLabel : 'm',
    },
    {
        componentType: "TextField",
        format : {formatToUpperCase},
        label : "Plancher ou T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0",
    },
    {
        componentType: "TextareaField",
        label : "Commentaire : ",
        name : "comment",
    },
]

export default LevelThreshold