import localForage from 'localforage'
import { persistReducer } from 'redux-persist'
import {
  createDataReducer,
  requestActivities,
  requestData
} from 'redux-thunk-data'

import { parseObj } from 'redux/persistor'
import { APP_NAME, IS_DEVELOPMENT } from 'utils/config'

export const PERSIST_KEY = `${APP_NAME}-webapp-data`

export const dataPersistConfig = {
  debug: IS_DEVELOPMENT,
  key: PERSIST_KEY,
  storage: localForage,
  whitelist: [
    '__activities__',
    'addresses',
    'contacts',
    'deprecatedMeasurements',
    'dossiers',
    'features',
    'fields',
    'measurements',
    'layers',
    'programmes',
    'schemaVersion',
    'sketches',
    'users'
  ],
}

const initialState = {}

const dataReducer = createDataReducer(initialState)

const updateSchema = (state) => {
  let updatedState = { ...state }
  if (!updatedState.schemaVersion) {
    if (updatedState.measurements) {
      updatedState.measurements = updatedState.measurements.map(m =>
        ({ ...m, index: undefined }))
    }
    updatedState.schemaVersion = 1
  }
  if (updatedState.schemaVersion === 1) {
    if (updatedState.measurements) {
      const deprecatedMeasurements = []
      const keptMeasurements = []
      updatedState.measurements.forEach(m => {
        if (!m.dateCreated) {
          const relatedActivities = (updatedState.__activities__ || []).filter(a =>
            a.entityIdentifier === m.activityIdentifier)
          relatedActivities.sort((a1, a2) =>
            new Date(a1.dateCreated) > new Date(a2.dateCreated) ? 1 : -1)
          const firstActivity = relatedActivities[0]
          if (firstActivity) {
            m.dateCreated = firstActivity.dateCreated
            keptMeasurements.push(m)
          } else {
            deprecatedMeasurements.push(m)
          }
        } else {
          keptMeasurements.push(m)
        }
      })
      updatedState.measurements = keptMeasurements
      updatedState.deprecatedMeasurements = deprecatedMeasurements
    }
    updatedState.schemaVersion = 2
  }
  if (updatedState.schemaVersion === 2) {
    // Migrate updatedState to version 3 here
  }
  return updatedState
}

const schemaUpdatingDataReducer = (state = initialState, action) => {
  const nextState = dataReducer(state, action)
  if (action.type === 'persist/REHYDRATE' &&
    typeof action.payload !== 'undefined') {
    return updateSchema(nextState)
  }
  return nextState
}

const defaultProcess = entity => entity

const _requestWithLocalAssets = requestMethod => config => async (dispatch, getState, defaultConfig) => {
  const { body, process = defaultProcess } = config
  let configWithLocalAssets = { ...config }
  if (body) {
    configWithLocalAssets.body = await parseObj(body, { readAssets: true })
  }
  configWithLocalAssets.process = async entity =>
    await process(await parseObj(entity, { writeAssets: true }))

  return requestMethod(configWithLocalAssets)(dispatch, getState, defaultConfig)
}

export const requestActivitiesWithLocalAssets = _requestWithLocalAssets(requestActivities)
export const requestDataWithLocalAssets = _requestWithLocalAssets(requestData)

export default persistReducer(dataPersistConfig, schemaUpdatingDataReducer)
