import React, { useCallback, useState } from 'react'

import Icon from 'components/layout/Icon'

import TextField from './TextField'


const PasswordField = props => {
  const [hidden, setHidden] = useState(true)
  const status = hidden ? '' : '-close'


  const handleToggleVisibility = useCallback(() => {
    setHidden(!hidden)
  }, [hidden, setHidden])


  return (
    <TextField
      {...props}
      type={hidden ? 'password' : 'text'}
    >
      <button
        className="mask"
        onClick={handleToggleVisibility}
        type="button"
      >
        <Icon name={`ico-eye${status}.svg`} />
      </button>
    </TextField>
  )
}

export default PasswordField
