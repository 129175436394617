import { requestData } from 'redux-thunk-data'
import withLogin from 'with-react-redux-login'

import { API_URL } from 'utils/config'
import { shouldGoToGoogleLogout } from 'utils/google'

export default withLogin({
  handleFail: (state, action, ownProps) => {
    shouldGoToGoogleLogout(state)
    const { location: { pathname, search } } = ownProps
    const { payload } = action
    const { errors, status } = payload
    const authError = errors.find(error => error.auth)
    if (status === 401 && authError) {
      const from = encodeURIComponent(`${window.location.origin}${pathname}${search}`)
      window.location.href = `${API_URL}/users/signin/oauth2?from=${from}`
    }
  },
  isRequired: true,
  requestData
})