import debounce from 'lodash.debounce'
import { createSelector } from 'reselect'

export const callPreventedWhenDoubleEvent = (callback, timeout = 400) => {
  let successiveCallsCount = 0
  return (...args) => {
    const cancelBecauseItIsDoubleCall = successiveCallsCount > 1
    if (cancelBecauseItIsDoubleCall) return
    successiveCallsCount = successiveCallsCount + 1
    setTimeout(() => {
      setTimeout(() => { successiveCallsCount = 0 }, timeout - 10)
      const cancelBecauseItIsDoubleCall = successiveCallsCount > 1
      if (cancelBecauseItIsDoubleCall) return
      callback(...args)
    }, timeout)
  }
}

export const callWithDebounce = createSelector(
  timeout => timeout,
  timeout =>
    debounce(callback => callback(), timeout)
)
