import classnames from 'classnames'
import createCachedSelector from 're-reselect'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectCurrentUser } from 'with-react-redux-login'

import programmeType from 'components/propTypes/programmeType'

const mapArgsToCacheKey = (_state, programmeId) => programmeId || ''
const selectHasRightByProgrammeId = createCachedSelector(
  selectCurrentUser,
  (_state, programmeId) => programmeId,
  (currentUser, programmeId) =>
    currentUser?.userProgrammes?.map(up => up.programmeId).includes(programmeId)
)(mapArgsToCacheKey)

const ProgrammeItem = ({ programme }) => {
  const { id, isActive, name } = programme
  const history = useHistory()
  const programmePathname = isActive ? `/diagnostiquer/programmes/${id}/dossiers` : '#'
  const { isSuperAdmin } = useSelector(selectCurrentUser) || {}
  const hasRight = useSelector(state => selectHasRightByProgrammeId(state, id))
  const handleProgrammClick = useCallback(() =>
    history.push(programmePathname), [history, programmePathname]
  )
  let isDisabled = true
  let info
  if (!isActive) {
    info = 'Bientôt'
  } else if (!isSuperAdmin && !hasRight) {
    info = 'Non autorisé'
  } else {
    isDisabled = false
  }

  return (
    <button
      className={classnames('programme-item', { 'soon': isDisabled })}
      onClick={handleProgrammClick}
      type="button"
    >
      {name}
      {info &&
        <span className="info">
          {info}
        </span>}
    </button>
  )
}

ProgrammeItem.propTypes = {
  programme: programmeType.isRequired,
}

export default ProgrammeItem
