import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'

import Dropzone from 'components/layout/Dropzone'
import Logo from 'components/layout/Logo'
import Main from 'components/layout/Main'
import { VERSION } from 'utils/config'

import FilesForm from './FilesForm'
import FolderForm from './FolderForm'


const Uploader = () => {
  const { folderId } = useParams()

  const isDropzoneDisabled = useSelector(state => {
    const { isPending, isSuccess } = state.requests['/files'] || {}
    const isDropzoneDisabled = typeof folderId === 'undefined'
      || isPending
      || isSuccess
    return isDropzoneDisabled
  }, [folderId])

  const [files, setFiles] = useState()

  const handleDrop = useCallback(newFiles => {
    let nextFiles = newFiles
    if (files) {
      nextFiles = nextFiles.concat(files)
    }
    setFiles(nextFiles)
  }, [files])

  const hasNoFile = typeof files === 'undefined'

  return (
    <Main name="uploader">
      <div className="container">
        <Logo withName />
        <div className="title">
          ENVOI DE FICHIERS <span className="version">{VERSION}</span>
        </div>
        <Dropzone
          className={classnames('dropzone', { 'centered': hasNoFile })}
          disabled={isDropzoneDisabled}
          onDrop={handleDrop}
        >
          <div className="box">
            <Switch>
              <Route
                component={FolderForm}
                exact
                path="/telecharger"
              />
              <Route
                path="/telecharger/:folderId"
                render={() => (
                  <FilesForm
                    files={files}
                    setFiles={setFiles}
                  />
                )}
              />
            </Switch>
          </div>
        </Dropzone>
      </div>
    </Main>
  )
}


export default Uploader
