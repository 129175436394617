
const SwimmingPool = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : EA1",
    },
    {
        componentType : "SelectField",
        label : "Côte de la margelle : ",
        name : "swimmingPoolSurveyorId",
        options : "surveyorData",
        placeholder : "Choisissez dans la liste",
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Balisée conformément au PPRi ",
        name : "ppriMarked",
    },
    {
        componentType : "NumberField",
        label : "Hauteur barrière piscine : ",
        name : "gateHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm',
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Autre protection ",
        name : "otherProtection",
    },
    {
        componentType : "TextField",
        label : "Autre balisage ?",
        name : "otherMarking",
    },
    {
        componentType : "TextField",
        label : "Longueur à baliser : ",
        name : "markedLength",
        placeholder : "Ex. : 10x5 m",
    },
    {
        componentType : "TextareaField",
        label : "Commentaire : ",
        name : "comment",
    },
    {
        componentType : "PictureField",
        folderPath : "photos",
        label : "Photo : ",
        name : "photo",
    },
]

export default SwimmingPool