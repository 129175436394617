import { Rect } from 'konva'

import styles from 'utils/styles'

import patchify from '../../patchify'

const MIN_HEIGHT = 30
const MIN_WIDTH = 60


export default config => {
  const {
    color,
    handleChange,
    item: { type },
    layers,
    stage
  } = config

  let isPaint = false
  const measurementLayer = layers[1]
  let node

  stage.on('mousedown touchstart', event => {
    if (isPaint) return
    isPaint = true
    node = new Rect({
      ...stage.getPointerPosition(),
      height: MIN_HEIGHT,
      stroke: color
        ? styles[color.name]
        : 'black',
      width: MIN_WIDTH
    })
    stage.isDrawingNode = true
    measurementLayer.add(node)
  })

  stage.on('mousemove touchmove', () => {
    if (!isPaint || !node) return
    const { x, y } = stage.getPointerPosition()
    node.setAttrs({
      height: y - node.attrs.y,
      width: x - node.attrs.x
    })
    measurementLayer.batchDraw()
  })

  stage.on('mouseup touchend', event => {
    if (!isPaint || !node) return
    isPaint = false
    const { height, width, x, y } = node.attrs
    const pointPatch = {
      height: height
        ? Math.sign(height) * Math.max(Math.abs(height), MIN_HEIGHT)
        : MIN_HEIGHT,
      type,
      width: width
        ? Math.sign(width) * Math.max(Math.abs(width), MIN_WIDTH)
        : MIN_WIDTH,
      x,
      y
    }
    stage.isDrawingNode = false
    handleChange(event, patchify(type, pointPatch, config))
  })
}
