import classnames from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import selectNamedNotSoftDeletedSketchesByDossierId from 'redux/selectors/selectNamedNotSoftDeletedSketchesByDossierId'

import SketchItem from './SketchItem'

const creationSketch = { activityIdentifier: 'creation' }


const SketchesBar = () => {
  const { dossierId } = useParams()


  const sketches = useSelector(state =>
    selectNamedNotSoftDeletedSketchesByDossierId(state, dossierId))

  const [disabledScroll, setDisabledScroll] = useState(false)
  
  return (
    <div className="sketches-bar">
      <div className={classnames("scroller", {"disable-scroll": disabledScroll})}>
        {sketches.map((sketch, index) => (
          <SketchItem
            key={sketch.activityIdentifier}
            previousSketch={sketches[index-1]}
            setDisabledScroll={setDisabledScroll}
            sketch={sketch}
          />
        ))}
      </div>
      <SketchItem sketch={creationSketch} />
    </div>
  )
}

export default SketchesBar
