import centroid from '@turf/centroid'
import L from 'leaflet'
import React, { useCallback, useMemo, useState } from 'react'
import { Marker, TileLayer } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectEntityByKeyAndId } from 'redux-thunk-data'
import { createSelector } from 'reselect'

import useLayers from 'components/hooks/useLayers'
import ResponsiveZoomControl from 'components/layout/ResponsiveZoomControl'
import Scale from 'components/layout/Scale'
import Sig from 'components/layout/Sig'
import Spinner from 'components/layout/Spinner'
import WMSTileLayers from 'components/layout/WMSTileLayers'
import { getLayers } from 'utils/layers'
import { OSM_TILE, SIG_SIZE } from 'utils/leaflet'
import { ReactComponent as NorthDirection } from 'utils/svgs/ico-30-nord.svg'

import CreationBar from './CreationBar'
import MainParcelTile from './MainParcelTile'

const selectMainParcelByDossierId = createSelector(
  (state, dossierId) => selectEntityByKeyAndId(state, 'dossiers', dossierId),
  dossier => {
    if (!dossier || !dossier.dossierParcels || !dossier.dossierParcels.length) return
    return dossier.dossierParcels.find(dossierParcel =>
      dossierParcel.isComplementary === 0).parcel
  })

const Framing = () => {
  const { dossierId } = useParams()
  const dossier = useSelector(state => selectEntityByKeyAndId(state, 'dossiers', dossierId)) || {}
  const { programme = {} } = dossier
  const { code } = programme
  const layers = useMemo(() => getLayers(code), [code])

  useLayers({
    activatingLayerFilters: layers,
    filterOnRequest: true
  }, [layers])

  const [creationDisabled, setCreationDisabled] = useState(true)
  const { isPending: isDossierRequestPending } = useSelector(state => state.requests[`/dossiers-diagnostic/${dossierId}`]) || {}
  const { dossierParcels = [], inseeCode } = dossier
  const { feature: mainParcelFeature } = useSelector(state => selectMainParcelByDossierId(state, dossierId)) || {}
  const mainParcelPosition = mainParcelFeature && centroid(mainParcelFeature).geometry.coordinates.reverse()
  const { properties } = mainParcelFeature || {}
  const { numero, prefixe, section } = properties || {}
  const mainParcelLabel = `<nobr>${section} ${prefixe} ${numero}</nobr>`
  const mainParcelLabelIcon = useMemo(() => {
    const svg = `<svg xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    width='30'
                    height='30'
                  >
                    <text color="black" style="font-size:14px;">
                      ${mainParcelLabel}
                    </text>
                  </svg>`

    return L.icon({
      className: 'main-parcel-marker',
      iconAnchor: [5, 5],
      iconSize: [10, 10],
      iconUrl: 'data:image/svg+xml;base64,' + btoa(svg),
      shadowSize: [12, 10],
    })
  }, [mainParcelLabel])
  const handleLayersLoad = useCallback(() => setCreationDisabled(false), [setCreationDisabled])
  const showSpinner = isDossierRequestPending || creationDisabled

  return (
    <div className="framing">
      <CreationBar disabled={creationDisabled} />
      <div className="sig-container">
        <div className="shadow" />
        {showSpinner && <Spinner />}
        <NorthDirection className="north-direction" />
        {mainParcelPosition && (
          <Sig
            center={mainParcelPosition}
            doubleClickZoom={false}
            forcedSize={SIG_SIZE}
            preferCanvas
            touchZoom={false}
            zoom={20}
          >
            <WMSTileLayers
              inseeCode={inseeCode}
              onLayersLoad={handleLayersLoad}
            />
            <Scale />
            <TileLayer
              {...OSM_TILE}
              zIndex={0}
            />
            <ResponsiveZoomControl />
            <MainParcelTile dossierParcels={dossierParcels} />
            <Marker
              icon={mainParcelLabelIcon}
              position={mainParcelPosition}
              zIndex={4}
            />
          </Sig>)}
      </div>
    </div>
  )
}

export default Framing
