import PropTypes from 'prop-types'
import React from 'react'

const Toast = ({ content }) => {
  if (!content) return
  
  const {icon, text} = content
  
  return (
    <div className='toast'>
      <div className='container-icon'>
        <img
          alt={`icon-${icon}`}
          className={`icon-${icon}`}
          // on require ici pour gérer les situations sans wifi
          src={require(`../../../public/static/assets/ico-30-${icon}.png`)}
        />
      </div>
      <p className={`toast-text-${icon}`} >
        {text}
      </p>
    </div>
  )
}


Toast.propTypes = {
  content : PropTypes.shape({
    icon : PropTypes.string.isRequired,
    text : PropTypes.string.isRequired,
  }).isRequired
}

export default Toast
