import React from 'react'
import { useFormContext } from 'react-hook-form'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const draggableOptions = [
  { label: 'non', value: 'no' },
  { label: 'oui, provisoirement', value: 'yes-temporary' },
  { label: 'oui, définitivement ', value: 'yes-pernamently' }
]


const ActivityStake = () => {
  const { readOnly } = useFormContext()

  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : EA1"
      />
      <TextField
        label="Nom de l'enjeu : "
        name="stakeName"
        placeholder="Ex. : four, plonge, photocopieuse"
        type="text"
      />
      <NumberField
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <SelectField
        label="Déplaçable ? "
        name="isDraggableStake"
        options={draggableOptions}
        placeholder="Ex. : oui, provisoirement"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}


export default ActivityStake
