import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestData } from 'redux-thunk-data'
import { processCurrentUser } from 'with-react-redux-login'

import useLocationURL from 'components/hooks/useLocationURL'
import PasswordField from 'components/layout/form/fields/PasswordField'
import TextField from 'components/layout/form/fields/TextField'
import LoadingButton from 'components/layout/LoadingButton'
import { redirectPathnameFromJustLoggedUser } from 'utils/signin'


const BasicAuth = () => {
  const dispatch = useDispatch()
  const form = useForm()
  const history = useHistory()
  const locationURL = useLocationURL()
  const from = locationURL.searchParams.get('from')


  const { isPending } = useSelector(state => state.requests['/users/signin']) || {}


  const handleSignin = data =>
    dispatch(requestData({
      apiPath: '/users/signin',
      body: data,
      handleSuccess: (state, action) => {
        const nextPathname = from
          ? decodeURIComponent(from)
          : redirectPathnameFromJustLoggedUser(action.payload.datum)
        history.push(nextPathname)
      },
      method: 'POST',
      process: processCurrentUser,
    }))


  return (
    <FormProvider {...form}>
      <form
        autoComplete="off"
        className="basic-auth-form"
        disabled={isPending}
        noValidate
        onSubmit={form.handleSubmit(handleSignin)}
      >
        <TextField
          data-testid="identifier"
          id="identifier"
          label="E-mail : "
          name="identifier"
          noAsterisk
          placeholder="Saisissez votre e-mail"
          required
          type="email"
        />
        <PasswordField
          data-testid="password"
          id="password"
          label="Mot de passe : "
          name="password"
          noAsterisk
          placeholder="Saisissez votre mot de passe"
          required
        />
        <LoadingButton
          className="submit"
          data-testid="submit"
          disabled={isPending || !form.formState.isDirty}
          isLoading={isPending}
          type="submit"
        >
          {'Connexion'}
        </LoadingButton>
      </form>
    </FormProvider>
  )
}

export default BasicAuth
