import colorTool, { allColorTags } from './colorTool'
import figureTool from './figureTool'
import infoTool from './infoTool'
import lineTool from './lineTool'
import shapeTool from './shapeTool'


export const initialSelectorTool = {
  name: 'selector',
  svg: 'ico-30-selector.svg'
}


const tools = [
  initialSelectorTool,
  {
    clickable: true,
    name: 'duplicate',
    svg: 'ico-30-duplicate.svg'
  },
  {
    clickable: true,
    name: 'backward',
    svg: 'ico-30-arrangeBackward.svg'
  },
  {
    clickable: true,
    name: 'forward',
    svg: 'ico-30-arrangeForward.svg'
  },
  lineTool,
  shapeTool,
  figureTool,
  {
    clickable: true,
    colorTags: allColorTags,
    hitStrokeWidth: 12,
    name: 'arrow',
    pointerLength: 12,
    pointerWidth: 6,
    resizeEnabled: true,
    strokeWidth: 1,
    svg: 'ico-30-arrow.svg'
  },
  infoTool,
  {
    disabled: true,
    name: 'camera',
    svg: 'ico-30-camera.svg'
  },
  colorTool,
  {
    name: 'trash',
    svg: 'ico-30-trash.svg'
  }
]

export default tools
