import createCachedSelector from 're-reselect'
import { selectEntityByKeyAndId, selectEntityByKeyAndJoin } from 'redux-thunk-data'

import selectNamedNotSoftDeletedSketchesByDossierId from './selectNamedNotSoftDeletedSketchesByDossierId'


const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  state => state.data.measurements,
  selectNamedNotSoftDeletedSketchesByDossierId,
  state => state.data.dossiers,
  (state, dossierId) => dossierId,
  (measurements, sketches, dossiers, dossierId) =>
    (measurements || []).map(measurement => {
      if (measurement.isSoftDeleted) return null
      if (measurement.dossierId) {
        const dossier = selectEntityByKeyAndId({ data: { dossiers } },
                                               'dossiers',
                                               measurement.dossierId)
        if (dossier !== undefined && dossier.id === dossierId) {
          return { ...measurement, dossier }
        }
        return null
      }
      if (!measurement.sketchActivityIdentifier) return null
      const sketch = selectEntityByKeyAndJoin({ data: { sketches } },
                                              'sketches',
                                              {
                                                key: 'activityIdentifier',
                                                value: measurement.sketchActivityIdentifier
                                              })
      if (!sketch) return null
      const dossier = selectEntityByKeyAndId({ data: { dossiers } },
                                             'dossiers',
                                             sketch.dossierId)
      return {...measurement, dossier, sketch }
    }).filter(m => m)
)(mapArgsToCacheKey)
