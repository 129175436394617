import { measurementFieldsByItemName } from 'utils/csv/csv_infos'
import { formatToUpperCase, yesOrNoOptions } from 'utils/form'

import { camelize } from "./formatText"

export const handleCsvTitle = (measurement) => {
    const {index, item : {label}} = measurement
    return `"TITRE : ","${label}${index ? ` # ${index}` : ''}"\n`
}

export const handleOptionFromSurveyorData = (initialValueForCsv, surveyorMeasurements) => {
    if (!surveyorMeasurements) return
    const wantedSurveyorMeasurement = (surveyorMeasurements).filter(surveyorMeasurement => surveyorMeasurement.id === initialValueForCsv)
    if (wantedSurveyorMeasurement.length) {
        const csvValueOfWantedSurveyorMeasurement = wantedSurveyorMeasurement[0].surveyorName
        const commentOfWantedSurveyorMeasurement = wantedSurveyorMeasurement[0].surveyorComment
        if (commentOfWantedSurveyorMeasurement)
            return csvValueOfWantedSurveyorMeasurement.concat('-', commentOfWantedSurveyorMeasurement)
        return csvValueOfWantedSurveyorMeasurement
    }
}

export const handleValueFromOptions = (options, initialValueForCsv) => {
    if (!options) {
        options = yesOrNoOptions
        const isTrueOrFalseAsString = typeof initialValueForCsv === 'string' && ['true', 'false'].includes(initialValueForCsv)
        if (isTrueOrFalseAsString) initialValueForCsv = initialValueForCsv === 'true'
    }
    const selectedOption = options.filter(options => options.value === initialValueForCsv)
    if (selectedOption.length) return selectedOption[0].label
}

export const handleFieldWithDependencies = (field, measurement) => {
    const dependencies = field.dependsOn
    if (dependencies){
        const areDependenciesMet = dependencies.map(dependency => {
            if (dependency === "showRefugePrincipalEntranceComment")
                return measurement["refugePrincipalEntrance"] === 3
            return !!measurement[dependency]
        })
        return !areDependenciesMet.includes(false)
    }
    return true
}

export const handleCsvValue = (field, measurement, surveyorMeasurements) => {
    const isCheckboxDisabled = ('disabled' in field) && measurement[field.disabled]
    if (isCheckboxDisabled) return 'Non mesurée'

    const initialValueForCsv = measurement[field.name]
    const valueIsNotSet = (!initialValueForCsv && initialValueForCsv !== false)
    if (valueIsNotSet) return 'N/A'
    
    const valueIsBoolean = [true, false, 'true', 'false'].includes(initialValueForCsv)
    const fieldWithOptions = ('options' in field) && Array.isArray(field.options)
    let valueFromOptions = null
    if (fieldWithOptions || valueIsBoolean)
        valueFromOptions = handleValueFromOptions(field.options, initialValueForCsv)
    else if (field.options === "surveyorData")
        valueFromOptions = handleOptionFromSurveyorData(initialValueForCsv, surveyorMeasurements)

    const csvValue = valueFromOptions || initialValueForCsv
    const units = ('unitLabel' in field) ? field.unitLabel : ''
    return `${csvValue}${units}`
}

export const findInfosRelatedToItem = (measurement, measurementFieldsRelatedToItem, surveyorMeasurements) => {
    const csvTitleForItem = handleCsvTitle(measurement)
    const csvLabelsAndValuesForItem = []
    const fieldsToSkip = []
    measurementFieldsRelatedToItem.forEach(field =>  {
        if (field.componentType === "PictureField") return

        if (field.label) {
            const areAllDependenciesMet = handleFieldWithDependencies(field, measurement)
            if (!areAllDependenciesMet) return

            if (field.disabled) fieldsToSkip.push(field.disabled)
            if (fieldsToSkip.length && fieldsToSkip.includes(field.name)) return

            if ('subComponent' in field) {
                const csvLabel = formatToUpperCase(field.label)
                csvLabelsAndValuesForItem.push(`"${csvLabel}"\n`)
            } else {
                const csvLabel = field.label
                const csvValue = handleCsvValue(field, measurement, surveyorMeasurements)
                csvLabelsAndValuesForItem.push(`"${csvLabel}","${csvValue}"\n`)
            }
        }
    })
    const csvInfosOfItem = csvLabelsAndValuesForItem.join('')
    const csvContentOfItem = csvTitleForItem.concat(csvInfosOfItem)
    return csvContentOfItem
}

export const createCsvContent = (figureMeasurements, surveyorMeasurements) => {
    let csv = ''
    const indexLastElement = figureMeasurements.length - 1
    figureMeasurements.forEach((measurement, idx) => {
        const {itemName, type} = measurement
        const componentName = camelize(itemName || type)
        const measurementFieldsRelatedToItem = measurementFieldsByItemName[componentName]
        if (measurementFieldsRelatedToItem) {
            const csvContentOfItem = findInfosRelatedToItem(measurement, measurementFieldsRelatedToItem, surveyorMeasurements)
            csv += csvContentOfItem
            if (indexLastElement !== idx) { 
                csv += '---\n'
            }
        }
    })
    return csv
}