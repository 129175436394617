import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { AUDIT_ENTRIES } from 'utils/router'


const SubTabs = () => {
  const { dossierId, entryName, programmeId } = useParams()
  const prePath = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/audit`


  return (
    <div className="sub-tabs">
      {AUDIT_ENTRIES.map(({ label, name }) => (
        entryName === name
        ? (
          <div
            className="sub-tab current"
            key={name}
          >
            <span>
              {label}
            </span>
          </div>
        )
        : (
          <NavLink
            className="sub-tab"
            key={name}
            to={`${prePath}/__transition__?${name}`}
          >
            {label}
          </NavLink>
        )))}
    </div>
  )
}

export default SubTabs
