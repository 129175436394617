export const UPDATE_DOWNLOAD_STATE = 'UPDATE_DOWNLOAD_STATE'

const initialState = {
    hasShownBlockingModal: false,
    isDownloading : false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DOWNLOAD_STATE:
            return  {...state, ...action.patch}
        default:
            return state
    }
}
  
export const assignDownload = (patch) => ({ patch , type: UPDATE_DOWNLOAD_STATE})
