import { Line } from 'konva'

import styles from 'utils/styles'

import { allCoordsNotEmpty } from '../../distance'
import { selectColorByTag } from '../../selectors'


export const createLineCreator = Creator => (positionPatch, config) => {
  const { latLngToKonvaPoint } = config
  const {
    colorTag,
    labelDiffLat,
    labelDiffLon,
    lat,
    lon,
    positions
  } = positionPatch

  if (!positions) {
    console.error('positions is undefined for this line/polygon measurement')
    return
  }

  let refKonvaPoint = {}
  if (allCoordsNotEmpty(lat, lon)) {
    refKonvaPoint = latLngToKonvaPoint([lat, lon])
  }

  const points = positions.reduce((aggregations, position) => {
    const { x, y } = latLngToKonvaPoint(position)
    return aggregations.concat([x, y])
  }, [])


  let labelX, labelY
  if (allCoordsNotEmpty(labelDiffLat, labelDiffLon)) {
    const centerLat = ((positions[0][0] + positions[1][0]) / 2)
    const centerLon = ((positions[0][1] + positions[1][1]) / 2)
    const labelPosition = [centerLat + labelDiffLat, centerLon + labelDiffLon]
    const labelKonvaPoint = latLngToKonvaPoint(labelPosition)
    labelX = labelKonvaPoint.x
    labelY = labelKonvaPoint.y
  }

  const color = selectColorByTag(colorTag)
  const stroke = color
    ? styles[color.name]
    : 'black'

  const node = new Creator({
    ...refKonvaPoint,
    labelX,
    labelY,
    points,
    stroke
   })

   return node
}

export default createLineCreator(Line)
