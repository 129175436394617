import React from 'react'

import ContactForm from 'components/layout/ContactForm'
import Field from 'components/layout/form/Field'


const Household = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="household"
    >
      {'Foyer'}
    </h1>
    <div className="entry-subtitle">
      <div>
        {'Occupant principal'}
      </div>
    </div>
    <Field name="propertyOccupationType" />
    <ContactForm
      labelSuffix=" de l'occupant :"
      prefixFieldName='occupier.'
    />
    <div className="entry-subtitle">
      <div>
        {'Occupation'}
      </div>
    </div>
    <Field name="propertyOccupiersCount" />
    <Field name="propertyOccupiersReducedMobilityCount" />
    <Field name="propertyOccupantArrivalDate" />
  </div>
)

export default Household
