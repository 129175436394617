import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { requestDataWithLocalAssets } from 'redux/reducers/data'

export const DOSSIER_NORMALIZER = {
  address: 'addresses',
  measurements: 'measurements',
  occupier: 'contacts',
  owner: {
    normalizer: {
      address: 'addresses'
    },
    stateKey: 'contacts'
  },
  sketches: {
    normalizer: {
      measurements: 'measurements'
    },
    stateKey: 'sketches'
  },
  specialist: {
    normalizer: {
      address: 'addresses'
    },
    stateKey: 'contacts'
  },
}

export default () => {
  const dispatch = useDispatch()
  const { dossierId } = useParams()

  return useCallback(() => {
    dispatch(requestDataWithLocalAssets({
      apiPath: `/dossiers/${dossierId}?withDiagnostic=true`,
      normalizer: DOSSIER_NORMALIZER,
      requestOnlyOnce: true,
      tag: `/dossiers-diagnostic/${dossierId}`,
    }))
  }, [dispatch, dossierId])
}
