/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect } from 'react-router-dom'

import withRedirectHomeWhenLoggedIn from 'components/hocs/withRedirectHomeWhenLoggedIn'
import withRequiredLogin from 'components/hocs/withRequiredLogin'
import Diagnostician from 'components/pages/Diagnostician'
import Eligibility from 'components/pages/Eligibility/Eligibility'
import Home from 'components/pages/Home/Home'
import Signin from 'components/pages/Signin'
import Uploader from 'components/pages/Uploader'
import { AUDIT_ENTRY_NAME_RE, ID_RE, UUID_RE } from 'utils/router'

export default [
  {
    exact: true,
    path: '/',
    render: () => <Redirect to="/connection" />,
  },
  {
    component: Eligibility,
    exact: true,
    path: '/eligibilite',
    title: 'Eligibilité',
  },
  {
    component: withRedirectHomeWhenLoggedIn(Home),
    exact: true,
    path: '/home',
    title: 'Home',
  },
  {
    exact: true,
    path: '/diagnostiquer',
    render: () => <Redirect to="/diagnostiquer/programmes" />
  },
  {
    component: withRequiredLogin(Diagnostician),
    exact: true,
    path: `/diagnostiquer/programmes`,
    title: 'Diagnostic Programmes',
  },
  {
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})`,
    render: ({ match: { params: { programmeId } } }) =>
      <Redirect to={`/diagnostiquer/programmes/${programmeId}/dossiers`} />
  },
  {
    component: withRequiredLogin(Diagnostician),
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers`,
    title: 'Diagnostic Dossiers',
  },
  {
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})`,
    render: ({ match: { params: { dossierId, programmeId } } }) =>
      <Redirect to={`/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`} />
  },
  {
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/audit`,
    render: ({ match: { params: { dossierId, programmeId } } }) =>
      <Redirect to={`/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/audit/preparation`} />
  },
  {
    component: withRequiredLogin(Diagnostician),
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})?/(dossiers)?/:dossierId(${ID_RE})/audit/:entryName(${AUDIT_ENTRY_NAME_RE})?`,
    title: 'Diagnostic Audit',
  },
  {
    component: withRequiredLogin(Diagnostician),
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})?/(dossiers)?/:dossierId(${ID_RE})/report`,
    title: 'Diagnostic Report',
  },
  {
    component: withRequiredLogin(Diagnostician),
    exact: true,
    path: `/diagnostiquer/programmes/:programmeId(${ID_RE})?/(dossiers)?/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE}|creation)?/(donnees|telecharger)?`,
    title: 'Diagnostic Sketches',
  },
  {
    component: withRedirectHomeWhenLoggedIn(Signin),
    exact: true,
    path: '/connection',
    title: 'Connection',
  },
  {
    component: Uploader,
    exact: true,
    path: '/telecharger/:folderId?',
    title: 'Uploader',
  },
]
