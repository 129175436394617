import { formatToUpperCase} from 'utils/form'

const Boiler = [
    {
        componentType : "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : C1",
    },
    {
        componentType : "NumberField",
        label: "Hauteur au sol : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType : "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label: "Arrimé",
        name: "tiedUp",
    },
    {
        componentType : "TextareaField",
        label: "Position rejet chaudière : ",
        name: "rejectionPosition",
    },
    {
        componentType : "NumberField",
        label: "Hauteur rejet chaudière : ",
        name: "rejectionFromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType : "TextField",
        format: {formatToUpperCase},
        label: "Plancher de réf. prise d'air / rejet : ",
        name: "rejectionBaseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType : "TextareaField",
        label: "Commentaire rejet chaudière : ",
        name: "rejectionComment",
    },
    {
        componentType : "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
    {
        componentType: "PictureField",
        folderPath: "photos",
        label: "Photo : ",
        name: "photo",
    }
]

export default Boiler