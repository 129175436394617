import classnames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import selectNamedNotSoftDeletedSketchesByDossierId from 'redux/selectors/selectNamedNotSoftDeletedSketchesByDossierId'
import { ID_RE, UUID_RE } from 'utils/router'

import Creation from './Creation'
import Modification from './Modification'


const Sketches = () => {
  const history = useHistory()
  const location = useLocation()
  const { dossierId, programmeId, sketchActivityIdentifier } = useParams()
  const didNotDecideYetIfFramingOrDrawing = typeof sketchActivityIdentifier === 'undefined'
  const isFramingPage = sketchActivityIdentifier === 'creation'
  const timeout = useMemo(() => ({
    enter: 1200,
    exit: 0
  }), [])


  const dossier = useSelector(state =>
    selectEntityByKeyAndId(state, 'dossiers', dossierId))

  const sketches = useSelector(state =>
    selectNamedNotSoftDeletedSketchesByDossierId(state, dossierId))


  useEffect(() => {
    if (!didNotDecideYetIfFramingOrDrawing || !dossier) return
    let nextPath = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`
    nextPath = `${nextPath}/${sketches.length ? sketches[0].activityIdentifier : 'creation'}`
    history.push(nextPath)
  }, [didNotDecideYetIfFramingOrDrawing, dossier, dossierId, history, programmeId, sketches])

  return (
    <div className={classnames('sketches',
      isFramingPage ? 'framing-sketches' : 'drawing-sketches')}
    >
      <TransitionGroup className="transition">
        <CSSTransition
          classNames="fade"
          key={location.pathname}
          timeout={timeout}
        >
          <Switch location={location}>
            <Route
              component={Creation}
              exact
              path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(creation)`}
            />
            <Route
              component={Modification}
              exact
              path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE})/(camera|donnees|telecharger)?`}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default Sketches
