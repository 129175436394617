import PropTypes from 'prop-types'
import React from 'react'

import { ROOT_ASSETS_PATH } from 'utils/config'
import svgsByName from 'utils/svgs'

const Icon = ({ className, name, path, ...imgProps }) => {
  const SVGComponent = svgsByName[name]
  if (SVGComponent) {
    return <SVGComponent className={className || 'icon'} />
  }
  if (name.startsWith('la')) {
    return (
      <i
        alt={name} 
        className={`las ${name} ${className}`}
      />
    )
  }
  return (
    <img
      {...imgProps}
      alt={name}
      className={className || 'icon'}
      src={`${path}/${name}`}
    />
  )
}

Icon.defaultProps = {
  className: null,
  path: ROOT_ASSETS_PATH
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  path: PropTypes.string
}

export default Icon
