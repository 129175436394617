export const DuplicatingToastContent = {
    icon: "loading",
    text: "Duplication en cours..."
}

export const BackupState = {
    default : "warning",
    done : "up_to_date",
    ongoing : "loading",
}

export const BackupToastContent = {
    loading: {
        icon: "loading",
        text: "Tentative de sauvegarde..."
    },
    up_to_date: {
        icon: "tick",
        text: "Tous les dossiers ont bien été sauvegardés."
    },
    warning: {
        icon: "warning",
        text: "Des modifications n'ont pas été sauvegardées. Nouvel essai..."
    }
}