import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { selectCurrentUser } from 'with-react-redux-login'

import Icon from 'components/layout/Icon'
import SignoutButton from 'components/layout/SignoutButton'
import { closeMenu } from 'redux/reducers/menu'
import { VERSION } from 'utils/config'

import routes from './routes'
import Toggle from './Toggle'


const Menu = () => {
  const dispatch = useDispatch()


  const currentUser = useSelector(selectCurrentUser)
  const isActive = useSelector(state => state.menu.isActive)


  const handleCloseMenu = useCallback(() => dispatch(closeMenu()), [dispatch])

  const handleNavigationsClick = useCallback(event => {
    event.nativeEvent.stopImmediatePropagation()
    event.stopPropagation()
  }, [])


  return (
    <>
      <Toggle />
      <div
        className={classnames({ showing: isActive }, 'menu')}
        onClick={handleCloseMenu}
        onKeyDown={null}
        role="button"
        tabIndex="0"
      >
        <div
          className="navigations"
          onClick={handleNavigationsClick}
          onKeyDown={null}
          role="button"
          tabIndex="0"
        >
          <div className="version">
            {VERSION}
          </div>
          {routes.map((route, index) => (
            <Route
              {...route}
              key={index}
            />))}
          {currentUser && (
            <div className="navigation">
              <SignoutButton>
                <Icon name="ico-30-exit.svg" />
                <span>
                  {'Se déconnecter'}
                </span>
              </SignoutButton>
            </div>
          )}
        </div>
      </div>
      {isActive && <div className="overlay" />}
    </>
  )
}

export default Menu
