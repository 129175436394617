import { Circle, Group, Line, Path, Rect } from 'konva'


const shapeNodesByName = {
  Circle,
  Group,
  Line,
  Path,
  Rect
}


const numberKeys = [
  'height',
  'strokeWidth',
  'width',
  'x',
  'y'
]


const nodeToDom = {
  dash: 'stroke-dasharray',
  fill: 'fill',
  stroke: 'stroke',
  strokeWidth: 'stroke-width',
  transform: 'transform'
}


const attributesToAttrs = attributes => {
  const attrs = {}
  const a = [...attributes]
  a.forEach(attribute => {
    const { name, value } = attribute

    let setName = name
    let setValue = value

    if (name === 'd') {
      setName = 'data'
    } else if (name === 'r') {
      setName = 'radius'
      setValue = parseInt(setValue, '10')
    } else if (name === 'cx') {
      setName = 'x'
    } else if (name === 'cy') {
      setName = 'y'
    } else if (name === 'rx') {
      setName = 'x'
    } else if (name === 'ry') {
      setName = 'y'
    } else if (name === 'stroke-dasharray') {
      setName = 'dash'
      setValue =  value.split(',')
    } else if (name === 'stroke-width') {
      setName = 'strokeWidth'
    }

    attrs[setName] = setValue

  })
  return attrs
}


const formatAttrs = attrs => {
  Object.keys(attrs).forEach(key => {
    if (numberKeys.includes(key)) {
      attrs[key] = parseInt(attrs[key], '10')
    }
  })
}


const populate = (element, groupNode, config) => {
  const childElements = [...element.children]
  childElements.forEach(childElement => {
    const { attributes, tagName } = childElement
    const attrs = attributesToAttrs(attributes)

    if (childElement.attributes.id?.value === 'bg-w') {
      const parentAttributes = childElement.parentNode.attributes
      if (parentAttributes.fill) {
        attrs.fill = parentAttributes.fill.value
      }
      if (parentAttributes.transform) {
        const [x, y] = parentAttributes.transform.value
                              .replace('translate(', '')
                              .replace(')', '')
                              .split(',')
        attrs.x = parseInt(x, '10')
        attrs.y = parseInt(y, '10')
      }
    }

    if (['circle', 'polygon', 'rect'].includes(tagName)) {
      const parentAttributes = childElement.parentNode.attributes
      if (!attrs.fill && parentAttributes.fill) {
        attrs.fill = config.fill || parentAttributes.fill.value
      }
    }

    if (['line', 'path', 'polyline'].includes(tagName)) {
      attrs.hitStrokeWidth = 12
      if (!config.strokeScaleEnabled) {
        attrs.strokeScaleEnabled = false
      }

      const parentAttributes = childElement.parentNode.attributes
      const parentParentAttributes = childElement.parentNode.parentNode.attributes
      Object.keys(nodeToDom)
            .forEach(nodeKey => {
              const configValue = config[nodeKey]
              const domKey = nodeToDom[nodeKey]
              const nodeValue = attrs[nodeKey]
              const parentAttribute = parentAttributes[domKey]

              const shouldPassParentAttribute = !nodeValue && parentAttribute
              if (shouldPassParentAttribute) {
                attrs[nodeKey] = config[nodeKey] || parentAttribute.value
                if (nodeKey === 'dash') {
                  attrs[nodeKey] = attrs[nodeKey].split(',')
                }
                return
              }

              if (
                nodeKey === 'dash' ||
                (nodeKey === 'stroke' && tagName !== 'path') ||
                nodeKey === 'strokeWidth'
              ) {
                const parentParentAttribute = parentParentAttributes[domKey]
                const shouldPassParentParentAttribute = !nodeValue && parentParentAttribute
                if (shouldPassParentParentAttribute) {
                  attrs[nodeKey] = config[nodeKey] || parentParentAttribute.value.split(',')
                  return
                }
              }

              if (nodeKey === 'transform' && childElement.attributes.transform) {
                const transform = childElement.attributes.transform.value
                const scaleMatch = transform.match(/scale\((-?\d+\.?\d*),?\s*(-?\d+[.]?\d*)?\)/)
                if (scaleMatch) {
                  attrs.scaleX = parseFloat(scaleMatch[1])
                  attrs.scaleY = parseFloat(scaleMatch[2])
                }
              }

              const shouldPassConfig = nodeValue && configValue && nodeValue !== configValue
              if (shouldPassConfig) {
                attrs[nodeKey] = configValue
              }
            })

        if (tagName === 'line') {
          attrs.points = [attrs.x1, attrs.y1, attrs.x2, attrs.y2].map(parseFloat)
          delete attrs.x1
          delete attrs.y1
          delete attrs.x2
          delete attrs.y2
        }
    } else if (['polyline', 'polygon'].includes(tagName)) {
      if (tagName === 'polygon') {
        attrs.closed = true
      }
      attrs.points = childElement.attributes.points.value.split(' ').map(parseFloat)
    }

    formatAttrs(attrs)

    let shapeNodeName
    if (tagName === 'g') {
      shapeNodeName = 'Group'
    } else if (['polyline', 'polygon'].includes(tagName)) {
      shapeNodeName = 'Line'
    } else {
      shapeNodeName = `${tagName[0].toUpperCase()}${tagName.slice(1)}`
    }
    const ShapeNode = shapeNodesByName[shapeNodeName]

    const childNode = ShapeNode && new ShapeNode({...attrs})

    if (tagName === 'g') {
      populate(childElement, childNode, config)
    }

    if (childNode) groupNode.add(childNode)
  })
}

export default populate
