import ActivityStake from './ActivityStake'
import AirConditioner from './AirConditioner'
import Boiler from './Boiler'
import Door from './Door'
import ElecBoar from './ElecBoar'
import ElecMeter from './ElecMeter'
import ElectroGroup from './ElectroGroup'
import Elevator from './Elevator'
import FloatingElement from './FloatingElement'
import HotWaterTank from './HotWaterTank'
import LevelThreshold from './LevelThreshold'
import MezzaAtticEntrance from './MezzaAtticEntrance'
import OtherStake from './OtherStake'
import OtherWaterOpening from './OtherWaterOpening'
import Portal from './Portal'
import SurveyorStudy from './SurveyorStudy'
import SwimmingPool from './SwimmingPool'
import Tank from './Tank'
import Text from './Text'
import Trap from './Trap'
import Ventilation from './Ventilation'
import Well from './Well'
import Window from './Window'


export default {
  ActivityStake,
  AirConditioner,
  Boiler,
  Door,
  ElecBoar,
  ElecMeter,
  ElecPlug: ElecMeter,
  ElecRadiator: ElecMeter,
  ElecSwitch: ElecMeter,
  ElectroGroup,
  Elevator,
  FloatingElement,
  FrenchDoor: Door,
  HotWaterTank,
  IndoorTank: Tank,
  InformaticEquipment: ElectroGroup,
  LevelThreshold,
  MezzaAtticEntrance,
  NetworkEquipment: ElectroGroup,
  NetworkPlug: ElecMeter,
  OtherStake,
  OtherWaterOpening,
  OutdoorTank: Tank,
  Portal,
  ShopFront: Door,
  Stairs: MezzaAtticEntrance,
  SurveyorStudy,
  SwimmingPool,
  Tank,
  TextMinor: Text,
  TextPhe: Text,
  TextRegular: Text,
  TextTitle: Text,
  Trap,
  TrapDoor: Trap,
  Ventilation,
  Well,
  Window
}
