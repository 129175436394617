import PropTypes from 'prop-types'
import React from 'react'

const DefaultWhenDisabled = ({ featureName }) => (
  <div>
    {featureName}
    {'should be active to see this section.'}
  </div>
)

DefaultWhenDisabled.defaultProps = {
  featureName: null,
}

DefaultWhenDisabled.propTypes = {
  featureName: PropTypes.string
}

export default DefaultWhenDisabled
