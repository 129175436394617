import React from 'react'

import NumberField from 'components/layout/form/fields/NumberField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatFirstLetterToUpperCase } from 'utils/form'

const SurveyorStudy = () => (
  <>
    <TextField
      label="Type géomètre :"
      name="surveyorName"
      readOnly
    />
    <TextField
      label="Description géomètre :"
      name="surveyorComment"
      readOnly
    />
    <TextField
      format={formatFirstLetterToUpperCase}
      label="Nom du plancher pour le rapport :"
      name="flooring"
    />
    <TextareaField
      label="Description pour le rapport :"
      name="flooringDescription"
    />
    <NumberField
      label="Cote : "
      name="altitude"
      readOnly
      unitLabel='m'
    />
    <NumberField
      label="Hauteur d'eau : "
      name="waterHeight"
      readOnly
      unitLabel='m'
    />
    <RadiosField
      isBoolean
      label="Inclure ce plancher au rapport"
      name="keepForReport"
    />
  </>
)
 
export default SurveyorStudy
