import React from 'react'

import TextField from 'components/layout/form/fields/TextField'


const Text = () => (
  <TextField
    label="Modifiez le texte : "
    name="text"
    type="search"
  />
)

export default Text
