
const Portal = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : P1",
    },
    {
        componentType : "NumberField",
        label : "Largeur : ",
        name : "portalWidth",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm',
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Batardeau existant ",
        name : "hasBatardeau",
    },
    {
        componentType : "NumberField",
        label : "Hauteur du batardeau : ",
        name : "batardeauHeight",
        placeholder : "Ex. : 0,5",
        unitLabel : 'm',
    },
    {
        componentType : "TextareaField",
        label : "Commentaire sur le batardeau existant (efficacité, type de batardeau) : ",
        name : "batardeauComment",
    },
    {
        componentType : "PictureField",
        folderPath : "photos",
        label : "Photo : ",
        name : "photo",
    },
    {
        componentType : "TextareaField",
        label : "Commentaire ouvrant : ",
        name : "comment",
    },
]
  
export default Portal