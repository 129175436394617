const Text = [
    {
        componentType : "TextField",
        label : "Modifiez le texte : ",
        name : "text",
        type : "search",
    }
]

export default Text
  