import { Circle, Group, Transformer } from 'konva'

import patchify from './patchify'


const anchorCornerRadius = () => 12
const anchorFill = 'transparent'
const anchorSize = () => 12
const anchorStrokeWidth = 1
const borderStroke = '#0000FF'


export default (node, {
  figureScale,
  handleChange,
  resizeEnabled,
  rotateEnabled,
  rescaleEnabled,
  resizeHomothetic,
  type
}, config) => {
  const {
    layers,
    stage
  } = config

  let transformerNode
  const measurementLayer = layers[1]

  if (['line', 'arrow'].includes(type)) {

    if (!resizeEnabled) return

    const points = node.points()
    const anchor1 = new Circle({
      draggable: true,
      fill: anchorFill,
      isAnchor: true,
      radius: anchorSize(type) / 2,
      stroke: borderStroke,
      strokeWidth: anchorStrokeWidth,
      x: points[0],
      y: points[1]
    })

    const anchor2 = new Circle({
      draggable: true,
      fill: anchorFill,
      isAnchor: true,
      radius: anchorSize(type) / 2,
      stroke: borderStroke,
      strokeWidth: anchorStrokeWidth,
      x: points[2],
      y: points[3]
    })

    /* eslint-disable no-inner-declarations */
    function handleLineMove() {
      stage.isTransformingNode = true
      node.points([
        anchor1.x(),
        anchor1.y(),
        anchor2.x(),
        anchor2.y(),
      ])
      measurementLayer.batchDraw()
    }
    anchor1.on('dragmove', handleLineMove)
    anchor2.on('dragmove', handleLineMove)

    function handleLineChange(event) {
      event.hasJustDragendTransform = true
      const [x1, y1, x2, y2] = node.points()
      const { x=0, y=0 } = transformerNode.attrs
      const patch = {
        points: [x1 + x,
                 y1 + y,
                 x2 + x,
                 y2 + y]
      }
      handleChange(event, patchify(type, patch, config))
      stage.isTransformingNode = false
    }
    anchor1.on('dragend', handleLineChange)
    anchor2.on('dragend', handleLineChange)

    transformerNode = new Group()
    transformerNode.setAttr('nodeId', node.attrs.id)
    transformerNode.add(node)
    transformerNode.add(anchor1)
    transformerNode.add(anchor2)

  } else {

    if (!resizeEnabled && !rotateEnabled) return

    transformerNode = new Transformer({
      anchorCornerRadius: anchorCornerRadius(type),
      anchorFill,
      anchorSize: anchorSize(type),
      anchorStrokeWidth,
      borderDash: [2,2],
      borderEnabled: true,
      borderStroke,
      resizeEnabled: !!resizeEnabled,
      rotateEnabled: !!rotateEnabled
    })

    if (resizeHomothetic) {
      transformerNode.setAttr(
        'enabledAnchors',
        ['top-left', 'top-right', 'bottom-left', 'bottom-right']
      )
    }

    if (resizeEnabled && type === 'text') {
      transformerNode.setAttr(
        'enabledAnchors',
        ['middle-left', 'middle-right']
      )
      node.on('transform', ()  => {
        node.setAttrs({
          scaleX: 1,
          width: node.width() * node.scaleX()
        })
      })
    }
    
    node.on('transformstart', ()  => {
      stage.isTransformingNode = true
    })

    transformerNode.nodes([node])

    node.on('transformend', event => {
      const { labelX, labelY, points, rotation, scaleX, scaleY, width, x, y } = event.currentTarget.attrs
      const patch = { labelX, labelY, points, rotation, scaleX, scaleY, width, x, y }
      if (rescaleEnabled) {
        patch.scaleX = patch.scaleX / figureScale
        patch.scaleY = patch.scaleY / figureScale
      }
      stage.isTransformingNode = false
      handleChange(event, patchify(type, patch, config))
    })
  }

  node.transformerNode = transformerNode

  return transformerNode
}
