import { formatToUpperCase } from 'utils/form'

const OtherWaterOpening = [
    {
        componentType : "TextField",
        label : "Etiquette : ",
        name : "label",
        placeholder : "Ex. : AO1",
    },
    {
        componentType : "TextareaField",
        label : "Type : ",
        name : "openingType",
    },
    {
        componentType : "NumberField",
        label : "Hauteur au sol : ",
        name : "fromGroundHeight",
        placeholder : "Ex. : 0,95",
        unitLabel : 'm',
    },
    {
        componentType : "TextField",
        format : {formatToUpperCase},
        label : "Plancher ou T.N. de référence : ",
        name : "baseFlooring",
        placeholder : "Ex. : N0",
    },
    {
        componentType : "NumberField",
        label : "Longueur : ",
        name : "openingLength",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm',
    },
    {
        componentType : "NumberField",
        label : "Largeur : ",
        name : "openingWidth",
        placeholder : "Ex. : 0,2",
        unitLabel : 'm',
    },
    {
        componentType : "RadiosField",
        isBoolean : true,
        label : "Protection existante ",
        name : "hasProtection",
    },
    {
        componentType : "TextareaField",
        label : "Commentaire ouvrant : ",
        name : "comment",
    },
]

export default OtherWaterOpening