import { createSelector } from "reselect"

export default createSelector(
    state => state.requests,
    (requests) => {
        if (!requests) return
        // renvoi in tableau avec les requests activities sur chaque dossier ['/__activities__/dossier1', '/__activities__/dossier2']
        const activityKeys = Object.keys(requests).filter(key => key.indexOf('/__activities__/') === 0)
        const sortedArray = (activityKeys || []).sort((a, b) => { return new Date(requests[b].date) - new Date(requests[a].date) })
        if(!activityKeys.length)
            return false
        const succes = requests[sortedArray[0]].isSuccess
        return succes
    })