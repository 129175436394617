import PropTypes from 'prop-types'
import React, { useCallback } from 'react'


const Suggestion = ({
  index,
  onClick,
  suggestion,
  render,
  value
}) => {
  const handleClick = useCallback(
    event => onClick(event, suggestion),
    [onClick, suggestion]
  )

  return (
    <button
      className='suggestion'
      onClick={handleClick}
    >
      {render ? render({ index, suggestion, value }) : suggestion}
    </button>
  )
}


Suggestion.defaultProps = {
  render: null
}

Suggestion.propsTypes = {
  onClick: PropTypes.func.isRequired,
  render: PropTypes.func,
  suggestion: PropTypes.any.isRequired
}


export default Suggestion
