const PROGRAMMES = {
  BAIE_DE_SOMME: 'baie-de-somme',
  EAUX_ET_VILAINE: 'eaux-et-vilaine',
  NANTES: 'nantes'
}

const POSITIONS = {
  BAIE_DE_SOMME: [50.2495, 1.6296],
  EAUX_ET_VILAINE: [47.48330, -1.75000],
  NANTES: [47.2, -1.55],
}

export const getInitialPosition = (programme) => {
  if (programme === PROGRAMMES.NANTES) {
    return POSITIONS.NANTES
  } else if (programme === PROGRAMMES.BAIE_DE_SOMME) {
    return POSITIONS.BAIE_DE_SOMME
  } else if (programme === PROGRAMMES.EAUX_ET_VILAINE) {
      return POSITIONS.EAUX_ET_VILAINE
  }
  return POSITIONS.NANTES
}
