const downloadReport = (generatedReport, filename) => {
  const blob = new Blob([generatedReport], { type: 'application/vnd.oasis.opendocument.text' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = filename
  link.href = url
  link.click()
}

export default downloadReport
