import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import { requestData } from 'redux-thunk-data'

import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'
import Main from 'components/layout/Main'
import { AUDIT_ENTRY_NAME_RE, ID_RE, UUID_RE } from 'utils/router'
import { disableScroll, enableScroll } from 'utils/scroll'
import styles from 'utils/styles'


import BackgroundProcesses from './BackgroundProcesses'
import Board from './Board'
import DossierHero from './DossierHero'
import Dossiers from './Dossiers'
import Programmes from './Programmes'
import Tabs from './Tabs'


const Diagnostician = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { programmeId, dossierId } = useParams()

  useEffect(() => {
    document.body.style.minWidth = `${parseInt(styles['min-width-app'])}px`
  },[])
  
  useEffect(() => {
    disableScroll()
    if (programmeId) {
      dispatch(requestData({
        apiPath: `/programmes/${programmeId}`,
        normalizer: {
          fields: 'fields'
        },
        requestOnlyOnce: true,
      }))
    }
    return enableScroll
  }, [dispatch, programmeId])


  return (
    <>
      <Header
        info={<DossierHero />}
        withMenu={!!programmeId}
      />
      <Main
        name="diagnostician"
        withFullHeight
      >
        <BackgroundProcesses />
        {dossierId && <Tabs />}
        <Switch location={location}>
          <Route
            component={Programmes}
            exact
            path="/diagnostiquer/programmes"
          />
          <Route
            component={Dossiers}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers`}
          />
          <Route
            component={Board}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/audit/:entryName(${AUDIT_ENTRY_NAME_RE})?`}
          />
          <Route
            component={Board}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/sketches/:sketchActivityIdentifier(${UUID_RE}|creation)?/(donnees|telecharger)?`}
          />
          <Route
            component={Board}
            exact
            path={`/diagnostiquer/programmes/:programmeId(${ID_RE})/dossiers/:dossierId(${ID_RE})/report`}
          />
        </Switch>
      </Main>
      {!dossierId && <Footer />}
    </>
  )
}

export default Diagnostician
