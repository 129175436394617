import React from 'react'

import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'
import ToSketchTool from 'components/layout/ToSketchTool'


const AnnexesDoorFrames = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="annexes-door-frames"
    >
      {'Huisseries annexe(s) I'}
    </h1>
    <div className="entry-info">
      {'Les huisseries qui apparaissent ici sont celles dont les étiquettes commencent par la lettre A.'}
    </div>
    <MeasurementTables name="annexes-door-frames">
      {measurement => (
        <Table>
          <Column name="comment" />
          <Column name="hasElectricalShutter" />
          <Column name="openingWidth" />
          <Column name="fromGroundHeight" />
          <Column name="baseFlooring" />
        </Table>)}
    </MeasurementTables>
    <ToSketchTool name="figure">
      {'Ajouter une huisserie au croquis'}
    </ToSketchTool>
  </div>
)


export default AnnexesDoorFrames
