import React from 'react'

import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'
import ToSketchTool from 'components/layout/ToSketchTool'


const PrincipalVentilations = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="principal-ventilations"
    >
      {'Ventilations principales I'}
    </h1>
    <MeasurementTables name="principal-ventilations">
      {measurement => (
        <Table>
          <Column
            name="fromGroundHeight"
            suffixLabelColumn=" au sol"
          />
          <Column name="baseFlooring" />
        </Table>)}
    </MeasurementTables>
    <ToSketchTool name="figure">
      {'Ajouter une ventilation au croquis'}
    </ToSketchTool>
  </div>
)

export default PrincipalVentilations
