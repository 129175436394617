export const AUDIT_ENTRIES = [
  {
    label: 'Préparation',
    name: 'preparation'
  },
  {
    label: 'Visite',
    name: 'visit'
  },
  {
    label: 'Traitement',
    name: 'treatment'
  }
]

export const AUDIT_ENTRY_NAME_RE = AUDIT_ENTRIES.map(auditEntry =>
  auditEntry.name).concat('__transition__').join('|')

export const DOSSIER_ENTRIES = [
  {
    label: 'Audit',
    name: 'audit'
  },
  {
    label: 'Croquis',
    name: 'sketches'
  },
  {
    label: 'Rapport',
    name: 'report'
  }
]

export const DOSSIER_ENTRY_NAME_RE = DOSSIER_ENTRIES.map(dossierEntry =>
  dossierEntry.name).join('|') 
  

export const ID_RE = '[A-Za-z0-9]{2,}'

export const UUID_RE = '[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}'
