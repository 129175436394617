import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import usePostOfflineActivities from 'components/hooks/usePostOfflineActivities' 
import selectIsBackupAnyRequestPending from 'redux/selectors/requests/selectIsAnyRequestPending'
import selectAllOfflineActivities from 'redux/selectors/selectAllOfflineActivities'
import { SAVING_TIME_INTERVAL } from 'utils/config'

const Backup = () => {

    const isAnyBackupRequestPending = useSelector(selectIsBackupAnyRequestPending)
    const allOfflineActivities = useSelector(selectAllOfflineActivities) 
    const emptyActivities = !allOfflineActivities.length
    const shouldNotTriggerBackupRequest = isAnyBackupRequestPending || emptyActivities

    const { handlePostOfflineActivities } = usePostOfflineActivities()

    const saveActivities = useCallback(() => {
        if (shouldNotTriggerBackupRequest) return
        handlePostOfflineActivities()
    }, [shouldNotTriggerBackupRequest, handlePostOfflineActivities])

    const backup = useRef()

    useEffect(() => {
        backup.current = saveActivities
    }, [saveActivities])

    return useEffect(()=> {
        const checkSaveInterval = setInterval(() => backup.current(), SAVING_TIME_INTERVAL)
        return () => {
            clearInterval(checkSaveInterval)
        }
    },[])
}

export default Backup
