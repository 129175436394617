import { v4 as uuidv4 } from 'uuid'

import { createStrictlyIncreasingDate } from 'utils/format_date.js'

export const findPathnameAfterDuplication = (programmeId, dossierId, newSketchActivityIdentifier) => {
    if (!programmeId || !dossierId || !newSketchActivityIdentifier) return false
    const sketchesPathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`
    const redirectPathnameAfterDuplication = `${sketchesPathname}/${newSketchActivityIdentifier}`
    return redirectPathnameAfterDuplication
}

export const duplicateItems = (dossierId, measurements, newSketchActivityIdentifier) => {
    const duplicatedItemsActivities = []
    if (!measurements || !Array.isArray(measurements) || !dossierId || !newSketchActivityIdentifier) return duplicatedItemsActivities

    measurements.forEach(measurement => {
        if (!measurement) return
        /* eslint-disable no-unused-vars */
        const {
            activityIdentifier: tbd1,
            dateCreated: tbd2,
            dateModified: tbd4,
            id: tbd5,
            index: tbd6,
            localIdentifier: tbd7,
            sketchId : tbd8,
            __normalizers__: tbd9,
            __remote__: tb10,
            __tags__: tb11,
            ...patch
        } = measurement
        patch['sketchActivityIdentifier'] = newSketchActivityIdentifier
        
        const activity = {
          dateCreated: createStrictlyIncreasingDate(),
          entityIdentifier : uuidv4(),
          localDossierId: dossierId,
          modelName: 'Measurement',
          patch: {
            ...patch,
          }
        }

        duplicatedItemsActivities.push(activity)
    })
    
    return duplicatedItemsActivities
}