import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'


const AnnexesVentilations = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="annexes-ventilations2"
    >
      {'Ventilations Annexe(s) II'}
    </h1>
    <MeasurementTables name="annexes-ventilations">
      {() => (
        <Table>
          <Column name="altitude" />
          <Column name="waterHeight" />
          <Column name="hasConcealment" />
        </Table>
      )}
    </MeasurementTables>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Commentaires : "
      name="annexesVentilationsComment"
    />
    <TextareaField
      label="Préconisations : "
      name="annexesVentilationsRecommendation"
    />
  </div>
)

export default AnnexesVentilations
