import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'

import ToSketchMeasurement from 'components/layout/ToSketchMeasurement'

import Rows, { Column, selectMeasurementsWithIndexByDossierIdAndRowsName } from './Rows'
import MeasurementContext from '../MeasurementContext'


const Table = ({ children }) => {
  const { measurement } = useContext(MeasurementContext)
  const { index, item, label } = measurement

  const { label: itemLabel } = item || {}

  const measurements = useMemo(() => [measurement], [measurement])


  return (
    <div className="measurement-table">
      <div className="measurement-table-title">
        {`${label || index || ''} - ${itemLabel}`}
      </div>
      <ToSketchMeasurement measurement={measurement} />
      <Rows measurements={measurements}>
        {children}
      </Rows>
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export { Column, Rows, selectMeasurementsWithIndexByDossierIdAndRowsName }
export default Table
