import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { activateData, selectEntityByActivityIdentifier } from 'redux-thunk-data'
import { v4 as uuidv4 } from 'uuid'

import LoadingButton from 'components/layout/LoadingButton'
import { assignDrawing } from 'redux/reducers/drawing'
import selectAuthorizedColorTags from 'redux/selectors/selectAuthorizedColorTags'
import { initialSelectorTool } from 'utils/drawing/tools'
import { createStrictlyIncreasingDate } from 'utils/format_date'
import svgsByName from 'utils/svgs'

import Camera from './Camera'


const SHIFTED_DUPLICATED_LON = 0.000025


const Tool = ({ tool }) => {
  const dispatch = useDispatch()
  const { dossierId, sketchActivityIdentifier } = useParams()
  const fileInputRef = useRef()
  const { name, pans, svg } = tool


  const drawing = useSelector(state => state.drawing)
  const {
    color,
    showPans,
    tool: selectedTool,
    uuids: selectedUuids
  } = drawing
  const selectedToolItem = drawing[selectedTool.name]
  const { name: selectedColorName } = color || {}


  const currentMeasurement = useSelector(state =>
    selectEntityByActivityIdentifier(state, selectedUuids[0]))

  const authorizedColorTags = useSelector(selectAuthorizedColorTags)

  const { isPending: isPhotoPending } = useSelector(state =>
    state.requests['/__activities__-photo']) || {}


  let SVGComponent = svgsByName[svg]
  const { name: itemName, svg: itemSvg } = drawing[name] || {}
  if (pans && itemSvg) {
    SVGComponent = svgsByName[itemSvg]
  }


  const handleClick = useCallback(event => {
    event.preventDefault()
    const hasClickedOnSameTool = selectedTool === tool

    const nextShowPans = !selectedTool ||
                         selectedTool !== tool ||
                         !showPans

    const nextDrawing = {
      showPans: nextShowPans,
      tool: hasClickedOnSameTool && !nextShowPans
        ? initialSelectorTool
        : tool,
    }

    if (tool.name !== 'color') {
      nextDrawing.itemBehindUuids = []
      nextDrawing.uuids = []
    }

    if (!['camera', 'color', 'selector', 'trash'].includes(tool.name)) {
      Object.assign(nextDrawing, {
        figure: null,
        shape: null
      })
    }

    const hasSwitchedToAnotherToolThanColorTool =
      selectedToolItem &&
      selectedTool !== tool &&
      selectedTool.name !== 'color' &&
      tool.name !== 'color'
    if (hasSwitchedToAnotherToolThanColorTool) {
      nextDrawing[selectedTool.name] = null
    }

    if (tool.name === 'camera') {
      fileInputRef.current.click(event)
    } else if (['backward', 'forward'].includes(tool.name)) {
      if (!currentMeasurement) return
      const {
        activityIdentifier,
        depth
      } = currentMeasurement
      const activity = {
        dateCreated: createStrictlyIncreasingDate(),
        entityIdentifier: activityIdentifier,
        localDossierId: dossierId,
        modelName: 'Measurement',
        patch: {
          depth: (depth || 0) + (tool.name === 'forward' ? 1 : -1)
        }
      }
      dispatch(activateData([activity]))
      return
    } else if (tool.name === 'duplicate') {
      if (!currentMeasurement) return
      /* eslint-disable no-unused-vars */
      const {
        activityIdentifier: tbd1,
        dateCreated: tbd2,
        dateModified: tbd4,
        id: tbd5,
        index: tbd6,
        localIdentifier: tbd7,
        __normalizers__: tbd8,
        __remote__: tb9,
        __tags__: tb10,
        ...patch
      } = currentMeasurement

      const isLineOrArrow = patch.positions
      // PAY ATTENTION this if else must be kept in this order
      if (isLineOrArrow) {
       patch.positions = patch.positions.map(position =>
         [
           position[0] - SHIFTED_DUPLICATED_LON,
           position[1] + SHIFTED_DUPLICATED_LON
         ])
      } else {
        patch.lat = patch.lat - SHIFTED_DUPLICATED_LON
        patch.lon = patch.lon + SHIFTED_DUPLICATED_LON
      }

      const entityIdentifier = uuidv4()
      const activity = {
        dateCreated: createStrictlyIncreasingDate(),
        entityIdentifier,
        localDossierId: dossierId,
        modelName: 'Measurement',
        patch: {
          ...patch,
          sketchActivityIdentifier
        }
      }
      dispatch(activateData([activity]))
      nextDrawing.tool = initialSelectorTool
      nextDrawing.uuids = [entityIdentifier ]
    } else if (tool.name === 'trash') {
      if (!selectedUuids.length) return
      nextDrawing.uuids = []
      nextDrawing.tool = initialSelectorTool
      const activities = selectedUuids.map(uuid => ({
        dateCreated: createStrictlyIncreasingDate(),
        entityIdentifier: uuid,
        localDossierId: dossierId,
        modelName: 'Measurement',
        patch: {
          isSoftDeleted: true,
          sketchActivityIdentifier
        }
      }))
      dispatch(activateData(activities))
    }

    dispatch(assignDrawing(nextDrawing))
  }, [currentMeasurement, dispatch, dossierId, fileInputRef, selectedTool, selectedToolItem, selectedUuids, sketchActivityIdentifier, showPans, tool])


  let isDisabled = tool.disabled
  if (typeof isDisabled === 'undefined') {
    if (name === 'color') {
      isDisabled = authorizedColorTags && authorizedColorTags.length === 0
    } else if (['backward', 'duplicate', 'forward', 'trash'].includes(name)) {
      isDisabled = !selectedUuids.length
    }
  }

  const isCameraLoading = tool.name === 'camera' && isPhotoPending


  return (
    <LoadingButton
      className={classnames('tool', name, itemName, {
        'is-disabled': isDisabled,
        'is-selected': tool === selectedTool,
        [selectedColorName]: name === 'color'
      })}
      disabled={isDisabled}
      isLoading={isCameraLoading}
      onClick={handleClick}
      type="button"
    >
      {SVGComponent && <SVGComponent />}
      {tool.name === 'camera' && (
        <Camera fileInputRef={fileInputRef} />
      )}
    </LoadingButton>
  )
}


Tool.propTypes = {
  tool: PropTypes.shape({
    disabled: PropTypes.bool,
    name: PropTypes.string,
    pans: PropTypes.arrayOf(PropTypes.shape),
    svg: PropTypes.string
  }).isRequired
}


export default Tool
