/* eslint-disable sort-keys */
import {
  heavyBuildingPolygonStyle,
  lightBuildingPolygonStyle
} from 'utils/diagnostic'

import { useColorTags } from './colorTool'


const tool = {
  name: 'figure',
  svg: 'ico-30-symbols.svg',
  pans: [
    {
      isHidden: true,
      name: 'Hidden',
      items: [
        {
          label: 'Levé',
          name: 'surveyor-study',
          type: 'symbol'
        }
      ]
    },
    {
      name: 'Bâti et aménagement',
      items: [
        {
          colorTags: [],
          fill: lightBuildingPolygonStyle.fillColor,
          label: 'Bâtiment léger',
          name: 'light-building',
          resizeEnabled: true,
          stroke: 'black',
          strokeWidth: lightBuildingPolygonStyle.weight,
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          colorTags: [],
          fill: heavyBuildingPolygonStyle.fillColor,
          label: 'Bâtiment lourd',
          name: 'heavy-building',
          resizeEnabled: true,
          stroke: 'black',
          strokeWidth: heavyBuildingPolygonStyle.weight,
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          colorTags: [],
          fill: lightBuildingPolygonStyle.fillColor,
          label: 'Masque Bâtiment léger',
          name: 'light-building-mask',
          resizeEnabled: true,
          strokeWidth: 0,
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          colorTags: [],
          fill: heavyBuildingPolygonStyle.fillColor,
          label: 'Masque Bâtiment lourd',
          name: 'heavy-building-mask',
          resizeEnabled: true,
          strokeWidth: 0,
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          colorTags: [],
          fill: 'white',
          label: 'Masquage Blanc',
          name: 'white-mask',
          resizeEnabled: true,
          strokeWidth: 0,
          svg: 'ico-30-polyline.svg',
          type: 'polygon'
        },
        {
          colorTags: ['wall-partition'],
          indexedOver: {
            tag: 'refuges',
            validate: measurement => measurement.toRefuge
          },
          label: 'Accès comble ou mezzanine',
          resizeEnabled: true,
          name: 'mezza-attic-entrance',
          svg: 'ico-30-accesMezzaComble.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          label: 'Ascenseur',
          name: 'elevator',
          rescaleEnabled: true,
          svg: 'ico-30-ascenseur.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          indexedOver: {
            tag: 'refuges',
            validate: measurement => measurement.toRefuge
          },
          label: 'Escalier vers étage ou sous-sol \n(sens de montée)',
          name: 'stairs',
          resizeEnabled: true,
          svg: 'ico-30-marchesEscalier.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          label: 'Différence de niveau \n(seuil ou marches, \nsens de montée)',
          name: 'level-threshold',
          resizeEnabled: true,
          svg: 'ico-30-seuilniveau.svg',
          type: 'symbol'
        }
      ]
    },
    {
      name: 'Ouvrants',
      items: [
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          indexedOverAllSamePanItems: true,
          label: 'Portail',
          labelable: true,
          name: 'portal',
          resizeEnabled: true,
          resizeHomothetic: true,
          svg: 'ico-30-portail.svg',
          type: 'symbol'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          indexedOverAllSamePanItems: true,
          label: 'Porte',
          labelable: true,
          name: 'door',
          resizeEnabled: true,
          resizeHomothetic: true,
          svg: 'ico-30-porte.svg',
          type: 'symbol'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          indexedOverAllSamePanItems: true,
          label: 'Porte-fenêtre ou \nbaie vitrée ouvrante',
          labelable: true,
          name: 'french-door',
          resizeEnabled: true,
          resizeHomothetic: true,
          svg: 'ico-30-porte-fenetre.svg',
          type: 'symbol'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          indexedOverAllSamePanItems: true,
          label: 'Fenêtre',
          labelable: true,
          name: 'window',
          resizeEnabled: true,
          strokeWidth: 6,
          svg: 'ico-30-fenetre.svg',
          type: 'line'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          dash: [6, 2],
          indexedOverAllSamePanItems: true,
          label: 'Baie vitrée non-ouvrante, vitrine',
          labelable: true,
          name: 'shop-front',
          resizeEnabled: true,
          strokeWidth: 6,
          svg: 'ico-30-vitrine.svg',
          type: 'line'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          fill: 'white',
          indexedOverAllSamePanItems: true,
          label: 'Autre entrée d\'eau potentielle',
          labelable: true,
          name: 'other-water-opening',
          rescaleEnabled: true,
          svg: 'ico-30-autreOuvrant.svg',
          type: 'symbol'
        },
        {
          colorTags: [
            'wall-partition',
            'no-recommendation',
          ].concat(useColorTags),
          indexedByAlphabet: true,
          label: 'Aération/ventilation',
          labelable: true,
          name: 'ventilation',
          rescaleEnabled: true,
          svg: 'ico-30-aeration.svg',
          type: 'symbol'
        }
      ]
    },
    {
      name: 'Réseaux',
      items: [
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Chaudière, PAC',
          labelable: true,
          name: 'boiler',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-chaudiere.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Chauffe-eau',
          labelable: true,
          name: 'hot-water-tank',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-chauffeEau.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Cuve',
          labelable: true,
          name: 'tank',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-cuve-exterieure.svg',
          type: 'symbol',
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          isHidden: true,
          label: 'Cuve',
          labelable: true,
          name: 'indoor-tank',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-cuve-exterieure.svg',
          type: 'symbol',
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Électricité : compteur',
          labelable: true,
          name: 'elec-meter',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-elecCompteur.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Électricité : interrupteur',
          labelable: true,
          name: 'elec-switch',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-elecInterrupteur.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Électricité : prise',
          labelable: true,
          name: 'elec-plug',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-elecPrise.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Électricité : radiateur',
          labelable: true,
          name: 'elec-radiator',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-elecRadiateur.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Électricité : tableau \nou disjoncteur général',
          labelable: true,
          name: 'elec-boar',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-elecTableau.svg',
          type: 'symbol'
        },
      ]
    },
    {
      name: 'Extérieur',
      items: [
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Bloc extérieur de clim, PAC',
          labelable: true,
          name: 'air-conditioner',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-blocClim.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Élément flottant',
          labelable: true,
          name: 'floating-element',
          rescaleEnabled: true,
          svg: 'ico-30-objetFlottant.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          isHidden: true,
          label: 'Cuve',
          labelable: true,
          name: 'outdoor-tank',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-cuve-exterieure.svg',
          type: 'symbol',
        },
        {
          colorTags: ['swimming-pool'],
          indexable: false,
          label: 'Piscine',
          labelable: true,
          name: 'swimming-pool',
          resizeEnabled: true,
          svg: 'ico-30-piscine.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          indexedOnlyIfMoreThanOne: true,
          indexable: false,
          label: 'Puits',
          labelable: true,
          name: 'well',
          rescaleEnabled: true,
          svg: 'ico-30-puits.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Regard EU/EP',
          labelable: true,
          name: 'trap',
          rescaleEnabled: true,
          svg: 'ico-30-regard.svg',
          type: 'symbol'
        },
        {
          colorTags: ['wall-partition'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Trappe vide sanitaire',
          labelable: true,
          name: 'trap-door',
          rescaleEnabled: true,
          svg: 'ico-30-trappeVisite.svg',
          type: 'symbol'
        }
      ]
    },
    {
      name: 'Enjeu activité économique',
      items: [
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Enjeu lié à l\'activité',
          labelable: true,
          name: 'activity-stake',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-enjeuActivite.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Équipement informatique',
          labelable: true,
          name: 'informatic-equipment',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-equipementInfo.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Fonc. autonome, \ngroupe électrogène',
          labelable: true,
          name: 'electro-group',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-groupeElectro.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Réseau : équipement',
          labelable: true,
          name: 'network-equipment',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-reseauEquipmt.svg',
          type: 'symbol'
        },
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Réseau : prise',
          labelable: true,
          name: 'network-plug',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-reseauPrise.svg',
          type: 'symbol'
        }
      ]
    },
    {
      items: [
        {
          colorTags: ['stake'],
          indexedOnlyIfMoreThanOne: true,
          label: 'Autre enjeu',
          labelable: true,
          name: 'other-stake',
          rescaleEnabled: true,
          role: 'stake',
          svg: 'ico-30-autreEnjeu.svg',
          type: 'symbol'
        },
        {
          colorTags: [],
          label: 'Sens de l\'écoulement',
          name: 'flow-direction',
          scaleX: 2,
          scaleY: 2,
          svg: 'ico-30-sensEcoulement.svg',
          type: 'symbol'
        },
        {
          colorTags: [],
          label: 'Point TN',
          name: 'tn-point',
          svg: 'ico-30-pointTN.svg',
          type: 'symbol'
        }
      ]
    }
  ]
}


tool.pans.forEach(pan => {
  if (pan.isHidden) return
  pan.items = pan.items.map(item => ({
    ...item,
    clickable: true,
    doubleClickable: true,
    panName: pan.name,
    rotateEnabled: true
  }))
})


export default tool
