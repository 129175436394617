import PropTypes from 'prop-types'
import React from 'react'


import addressType from 'components/propTypes/addressType'
import createGeosjsonType from 'components/propTypes/geojsonType'

import Suggestion from './Suggestion'

const Suggestions = ({ error, onSuggestionClick, suggestions }) => {
  const hasNoError = !error
  const hasNoSuggestions = !suggestions || !suggestions[0]
  if (hasNoError && hasNoSuggestions) return null
  
  return (
    <div className='suggestions'>
      {error
        ? (
          <div className="error">
            Une erreur est survenue. Merci de réessayer votre recherche
          </div>
        )
        : (
          <>
            <div className="instruction">
              Choisissez dans la liste :
            </div>
            {(suggestions || []).map(suggestion => (
              <Suggestion
                key={suggestion.properties.id}
                onClick={onSuggestionClick}
                {...suggestion}
              />
              ))}
          </>
        )}
    </div>
  )
}

Suggestions.defaultProps = {
  error: null,
  suggestions: null
}

Suggestions.propTypes = {
  error: PropTypes.string,
  onSuggestionClick: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(createGeosjsonType(addressType)),
}

export default Suggestions
