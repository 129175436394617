import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import FieldError from '../FieldError'

const CheckboxField = ({
  className,
  disabled,
  id,
  label,
  name,
  required,
  noAsterisk,
  validate,
  ...inputProps
}) => {
  const {
    register,
    ...formContext
  } = useFormContext()
  const { readOnly } = { ...inputProps, ...formContext }

  return (
    <div
      className={classnames('checkbox-field',
        className, name, { readonly: readOnly })}
      id={id}
    >
      <div className="field-control">
        <div className="field-value">
          <input
            {...inputProps}
            className="field-entry"
            disabled={readOnly || disabled}
            name={name}
            ref={register({ required, validate })}
            type='checkbox'
          />
          <span>
            {label}
          </span>
          {required && !readOnly && !noAsterisk && (
            <span className="field-asterisk">
              {'*'}
            </span>)}
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}

CheckboxField.defaultProps = {
  className: null,
  disabled: false,
  id: null,
  label: '',
  noAsterisk: false,
  required: false,
  validate: null
}

CheckboxField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validate: PropTypes.func
}

export default CheckboxField
