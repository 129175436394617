import { Line } from 'konva'

import styles from 'utils/styles'

import { distanceBetweenPoints } from '../../distance'
import patchify from '../../patchify'


export default config => {
  const {
    color,
    handleChange,
    item: { type },
    layers,
    stage
  } = config

  let isDragging = false
  let isPaint = false
  let isFirstEdge = false
  const measurementLayer = layers[1]
  let node


  stage.on('mousedown touchstart', event => {
    if (isPaint) return
    isPaint = true
    const { x, y } = stage.getPointerPosition()
    node = new Line({
      points: [x, y],
      stroke: color
        ? styles[color.name]
        : 'black',
      strokeWidth: 1
    })
    stage.isDrawingNode = true
    measurementLayer.add(node)
    isFirstEdge = true
  })

  stage.on('mousemove touchmove', event => {
    if (!isPaint || !node) return
    if (isFirstEdge) {
      isDragging = true
      const { x, y } = stage.getPointerPosition()
      const newPoints = node.points()
                            .slice(0, 2)
                            .concat([x, y])
      node.points(newPoints)
      measurementLayer.batchDraw()
    } else {
      event.evt.preventDefault()
      if (!isPaint) return
      const { x, y } = stage.getPointerPosition()
      const points = node.points()
      const newPoints = (points.length === 2
        ? points
        : points.slice(0, -2)
      ).concat([x, y])
      node.points(newPoints)
      measurementLayer.batchDraw()
    }
  })

  stage.on('mouseup touchend', event => {
    if (!isPaint || !node || !isFirstEdge) return
    if (isDragging) {
      const { x, y } = stage.getPointerPosition()
      const { points } = node.attrs
      node.points(points.slice(0, -2).concat([x, y]))
      isDragging = false
    }
    measurementLayer.batchDraw()
    isFirstEdge = false
  })


  stage.on('click tap', event => {
    if (!isPaint || !node) return
    const { points } = node.attrs
    if (points.length < 4) return
    const { x, y } = stage.getPointerPosition()
    const startPoint = { x: points[0], y: points[1] }
    if (distanceBetweenPoints(startPoint, { x, y }) < 10) {
      isPaint = false
      const positionPatch = {
        points: points.slice(0, -2).concat([startPoint.x, startPoint.y]),
        type
      }
      handleChange(event, patchify(type, positionPatch, config))
      stage.isDrawingNode = false
    } else {
      const newPoints = points.concat([x, y])
      node.points(newPoints)
    }
    measurementLayer.batchDraw()
  })
}
