import { initialSelectorTool } from 'utils/drawing/tools'
import { wallPartitionColor } from 'utils/drawing/tools/colorTool'


export const ASSIGN_DRAWING = 'ASSIGN_DRAWING'

const initialState = {
  color: wallPartitionColor,
  figureScale: 1,
  tool: initialSelectorTool,
  uuids: []
}


export default (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_DRAWING:
      return {...state, ...action.patch}
    default:
      return state
  }
}


export const assignDrawing = patch => ({
  patch,
  type: ASSIGN_DRAWING
})
