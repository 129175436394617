import React from 'react'

import tools from 'utils/drawing/tools'

import Pans from './Pans'
import Tool from './Tool'


const ToolsBar = () => (
  <div className="tools-bar">
    {tools.map(tool => (
      <Tool
        key={tool.svg}
        tool={tool}
        type="button"
      />
    ))}
    <Pans />
  </div>
)

export default ToolsBar
