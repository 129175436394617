export const wallPartitionColor = {
  label: 'Mur, cloison',
  name: 'black',
  tag: 'wall-partition'
}


const tool = {
  name: 'color',
  pans: [
    {
      items: [
        wallPartitionColor,
        {
          label: 'Enjeu',
          name: 'cerised-red',
          tag: 'stake'
        },
        {
          label: 'Piscine',
          name: 'malibu',
          tag: 'swimming-pool'
        },
        {
          label: 'Sans préconisation',
          name: 'gray',
          tag: 'no-recommendation'
        }
      ],
      name: 'Par nature'
    },
    {
      items: [
        {
          label: 'Logement',
          name: 'deep-cerulean',
          tag: 'housing'
        },
        {
          label: 'Partie commune',
          name: 'studio',
          tag: 'communal-areas'
        },
        {
          label: 'Economique',
          name: 'zest',
          tag: 'economical'
        }
      ],
      name: 'Par usage'
    },
    {
      items: [
        {
          label: 'Autre 1',
          name: 'robin-s-egg-blue',
          tag: 'other-1'
        },
        {
           label: 'Autre 2',
           name: 'hot-pink',
           tag: 'other-2'
         },
         {
           label: 'Autre 3',
           name: 'beaver',
           tag: 'other-3'
         }
      ],
      name: 'Autres'
    }
  ],
  svg: 'ico-30-colorSwatch.svg'
}


export const allColorTags = []
export const useColorTags = []
export const natureColorTags = []
export const otherColorTags = []


tool.pans.forEach(pan =>
  pan.items.forEach(item => {
    allColorTags.push(item.tag)
    if (pan.name === 'Par nature') {
      natureColorTags.push(item.tag)
    } else if (pan.name === 'Par usage') {
      useColorTags.push(item.tag)
    } else if (pan.name === 'Autres') {
      otherColorTags.push(item.tag)
    }
    item.svg = 'ico-30-colorSwatch.svg'
  }))


export default tool
