import { formatFirstLetterToUpperCase } from 'utils/form'

const SurveyorStudy = [
    {
        componentType: "TextField",
        label : "Type géomètre :",
        name : "surveyorName",
        readOnly : true,
    },
    {
        componentType: "TextField",
        label : "Description géomètre :",
        name : "surveyorComment",
        readOnly : true,
    },
    {
        componentType: "TextField",
        format : {formatFirstLetterToUpperCase},
        label : "Nom du plancher pour le rapport :",
        name : "flooring",
    },
    {
        componentType: "TextareaField",
        label : "Description pour le rapport :",
        name : "flooringDescription",
    },
    {
        componentType: "NumberField",
        label : "Cote : ",
        name : "altitude",
        readOnly : true,
        unitLabel : 'm',
    },
    {
        componentType: "NumberField",
        label : "Hauteur d'eau : ",
        name : "waterHeight",
        readOnly : true,
        unitLabel : 'm',
    },
    {
        componentType: "RadiosField",
        isBoolean: true,
        label : "Inclure ce plancher au rapport",
        name : "keepForReport",
    },
]

export default SurveyorStudy