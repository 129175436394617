import { createSelector } from "reselect"

const selectEligibilityByLatAndLonOrDepartmentCodeAndInteropKey = createSelector(
    state => state.data.eligibilities,
    (_state, lat) => lat,
    (_state, _lat, lon) => lon,
    (_state, _lat, _lon, departmentCode) => departmentCode,
    (_state, _lat, _lon, _departmentCode, interopKey) => interopKey,
    (eligibilities, lat, lon, departmentCode, interopKey) => {
        if (!eligibilities) return

        if (departmentCode && interopKey) {
            return eligibilities.find(eligibility =>
                eligibility.departmentCode === departmentCode && eligibility.interopKey === interopKey
            )
        }
        return eligibilities.find(eligibility =>
            eligibility.lat === lat && eligibility.lon === lon
        )
    }
)

export default {
    selectEligibilityByLatAndLonOrDepartmentCodeAndInteropKey
}