import React from 'react'
import { ZoomControl } from 'react-leaflet'

const ResponsizeZoomControl = () => (
  <>
    <ZoomControl position="bottomright" />
    <ZoomControl position="topright" />
  </>
)

export default ResponsizeZoomControl
