import createCachedSelector from 're-reselect'
import { selectEntityByKeyAndId } from 'redux-thunk-data'

import selectRefugeMeasurementsByDossierId from './selectRefugeMeasurementsByDossierId'

const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  selectRefugeMeasurementsByDossierId,
  (state, dossierId) => selectEntityByKeyAndId(state, 'dossiers', dossierId),
  (measurements, dossier) => {
    if (!measurements || !dossier || !dossier.refugeRecommendedMeasurementActivityIdentifier) return
    return measurements.find(measurement =>
      measurement.activityIdentifier === dossier.refugeRecommendedMeasurementActivityIdentifier)
  }
)(mapArgsToCacheKey)
