import {useCallback} from 'react'

const useResetFormWhenFormActivitiesAreUpToDate = (config={}) => {

    const {
        actualFormValues,
        formChange,
        isDebounceActive,
        isDebounceCallbackActive,
        reset,
        setAllowDebounce
    } = config

    const handleResetWhenFormActivitiesAreUpToDate = useCallback(() => {
        if (!isDebounceActive && !isDebounceCallbackActive) {
            if (formChange){
                const formEntries = Object.entries(formChange)
                if (formEntries.length){
                    const differences = formEntries.filter(entry => {
                        const keyFromChange = entry[0]
                        const valueFromChange = entry[1]
                        if (actualFormValues[keyFromChange] !== valueFromChange)
                            return entry
                        return null
                    })
                    if (differences.length){
                        setAllowDebounce(true)
                        return
                    }
                }
            }
            reset(actualFormValues)
        }
    }, [actualFormValues, reset, isDebounceActive, isDebounceCallbackActive, formChange, setAllowDebounce])

    return {handleResetWhenFormActivitiesAreUpToDate}
}

export default useResetFormWhenFormActivitiesAreUpToDate