import React from 'react'

import Field from 'components/layout/form/Field'
import TextareaField from 'components/layout/form/fields/TextareaField'


const Basement = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="basement2"
    >
      {'Sous-sol II'}
    </h1>
    <Field name='basementCrawlSpaceAcessLocation' />
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Observations : "
      name="basementComment"
    />
    <TextareaField
      label="Préconisations : "
      name="basementRecommendation"
    />
  </div>
)

export default Basement
