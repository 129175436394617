import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { showModal, closeModal } from 'redux-react-modals'

import useDownloadFile from 'components/hooks/useDownloadFile'
import Icon from 'components/layout/Icon'

import RedirectionToDrawingModal from './RedirectionToDrawingModal'

const DownloadSnapshot = ({isDownloading}) => {
  const location = useLocation()

  const dispatch = useDispatch()
  const {handleGenerateArchiveAndDownloadWithUI} = useDownloadFile()

  const handleClick = useCallback(() => {
    const optionToggleElement = document.querySelector('.options-toggle')
    if (optionToggleElement) optionToggleElement.click()
    if (location.pathname.endsWith('donnees')){
      dispatch(showModal('main',
        <RedirectionToDrawingModal
          closeModal={closeModal}
          dispatch={dispatch}
        />, { isUnclosable: true }))
      return
    }
    handleGenerateArchiveAndDownloadWithUI()
  },[handleGenerateArchiveAndDownloadWithUI, dispatch, location])

  return (
    <button
      disabled={isDownloading}
      onClick={handleClick}
      type="button"
    >
      <div className='icon-container'>
        <Icon name="ico-30-download.svg" />
      </div>
      Télécharger cette vue
    </button>
  )
}

DownloadSnapshot.propTypes = {
  isDownloading : PropTypes.bool.isRequired,
}

export default DownloadSnapshot