import React from 'react'

import RadiosField from 'components/layout/form/fields/RadiosField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'


const Well = () => (
  <>
    <TextField
      label="Etiquette : "
      name="label"
      placeholder="Ex. : EA1"
    />
    <RadiosField
      isBoolean
      label="Fermeture "
      name="hasLocking"
    />
    <TextareaField
      label="Commentaire : "
      name="comment"
    />
  </>
)

export default Well
