import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useLeaflet, WMSTileLayer } from 'react-leaflet'

import { GEOSERVER_URL } from 'utils/config'

import selectActiveLayers from './_selectors/selectActiveLayers'

const WMSTileLayers = ({ inseeCode, onLayersLoad }) => {
  const { map } = useLeaflet()
  const layers = selectActiveLayers(inseeCode)

  const handleLayerLoad = useCallback(() => {
    if (!onLayersLoad) return
    const visibleLoadingLayers = []

    map.eachLayer(l => {
      if (!l.wmsParams) return
      const layer = layers.find(layer => layer.name === l.wmsParams.layers)

      if (!layer) return
      
      if (l._loading) {
        visibleLoadingLayers.push(layer)
      }
    })
    if (!visibleLoadingLayers.length) onLayersLoad()
  }, [layers, map, onLayersLoad])

  return (
    <>
      {layers.map((layer) => (
        <WMSTileLayer
          format="image/png"
          key={layer.name}
          layers={`${layer.workspace}:${layer.name}`}
          onLoad={handleLayerLoad}
          tiled
          transparent
          url={`${GEOSERVER_URL}/ows`}
          zIndex={layer.zIndex}
        />
      ))}
    </>
  )
}

WMSTileLayers.defaultProps = {
  inseeCode: null,
  onLayersLoad: null
}

WMSTileLayers.propTypes = {
  inseeCode: PropTypes.string,
  onLayersLoad: PropTypes.func
}

export default WMSTileLayers
