import React from 'react'

import TextareaField from 'components/layout/form/fields/TextareaField'
import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'


const AnnexesDoorFrames = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="annexes-door-frames2"
    >
      {'Huisseries annexes II'}
    </h1>
    <MeasurementTables name="annexes-door-frames">
      {() => (
        <Table>
          <Column
            name="comment"
          />
          <Column
            name="altitude"
            suffixLabelColumn=" appui ou seuil"
          />
          <Column
            name="waterHeight"
          />
          <Column
            name="hasBatardeau"
          />
        </Table>
      )}
    </MeasurementTables>
    <div className="entry-subtitle">
      <div>
        {'Commentaires'}
      </div>
    </div>
    <TextareaField
      label="Commentaires : "
      name="annexesDoorFramesComment"
    />
    <TextareaField
      label="Préconisations : "
      name="annexesDoorFramesRecommendation"
    />
  </div>
)

export default AnnexesDoorFrames
