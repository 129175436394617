import React from 'react'

import AddressForm from 'components/layout/AddressForm'
import ContactForm from 'components/layout/ContactForm'


const Specialist = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="specialist"
    >
      {'Référent'}
    </h1>
    <div className="entry-subtitle">
      <div>
        {'Contact référent'}
      </div>
    </div>
    <ContactForm
      labelSuffix=" référent :"
      prefixFieldName="specialist."
    />
    <div className="entry-subtitle">
      <div>
        {'Coordonnées référent'}
      </div>
    </div>
    <AddressForm prefixFieldName="specialist.address." />
  </div>
)

export default Specialist
