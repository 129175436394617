import PropTypes from 'prop-types'
import React from 'react'

const ParcelNotFound = ({ eligibilityFormUrl }) => {
  return (
    <div>
      {'Merci de cliquer sur la parcelle (le terrain) sur laquelle se situe votre bâtiment.'}
      <br />
      <a
        href={eligibilityFormUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        Un doute ? Inscrivez-vous.
      </a>
    </div>
  )
}

ParcelNotFound.defaultProps = {
  eligibilityFormUrl: null
}

ParcelNotFound.propTypes = {
  eligibilityFormUrl: PropTypes.string,
}

export default ParcelNotFound
