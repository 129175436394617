import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import addressType from 'components/propTypes/addressType'


const Suggestion = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick, ...suggestion } = props
  const { properties } = suggestion
  const { city, name, postcode } = properties
  const handleClick = useCallback(
    event => onClick(event, suggestion),
    [onClick, suggestion]
  )
  return (
    <button
      className="suggestion"
      onClick={handleClick}
      type="button"
    >
      <div>
        {name}
      </div>
      <div>
        {postcode}
        ,
        {' '}
        {city}
      </div>
    </button>
  )
}


Suggestion.propsTypes = {
  onClick: PropTypes.func.isRequired,
  suggestion: addressType
}


export default Suggestion
