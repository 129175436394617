import ActivityStake from './items/ActivityStake'
import AirConditioner from './items/AirConditioner'
import Boiler from './items/Boiler'
import Door from './items/Door'
import ElecBoar from './items/ElecBoar'
import ElecMeter from './items/ElecMeter'
import ElectroGroup from './items/ElectroGroup'
import Elevator from './items/Elevator'
import FloatingElement from './items/FloatingElement'
import HotWaterTank from './items/HotWaterTank'
import LevelThreshold from './items/LevelThreshold'
import MezzaAtticEntrance from './items/MezzaAtticEntrance'
import OtherStake from './items/OtherStake'
import OtherWaterOpening from './items/OtherWaterOpening'
import Portal from './items/Portal'
import SurveyorStudy from './items/SurveyorStudy'
import SwimmingPool from './items/SwimmingPool'
import Tank from './items/Tank'
import Text from './items/Text'
import Trap from './items/Trap'
import Ventilation from './items/Ventilation'
import Well from './items/Well'
import Window from './items/Window'

export const measurementFieldsByItemName =  {
    ActivityStake,
    AirConditioner,
    Boiler,
    Door,
    ElecBoar,
    ElecMeter,
    ElecPlug: ElecMeter,
    ElecRadiator: ElecMeter,
    ElecSwitch: ElecMeter,
    ElectroGroup,
    Elevator,
    FloatingElement,
    FrenchDoor: Door,
    HotWaterTank,
    IndoorTank: Tank,
    InformaticEquipment: ElectroGroup,
    LevelThreshold,
    MezzaAtticEntrance,
    NetworkEquipment: ElectroGroup,
    NetworkPlug: ElecMeter,
    OtherStake,
    OtherWaterOpening,
    OutdoorTank: Tank,
    Portal,
    ShopFront: Door,
    Stairs: MezzaAtticEntrance,
    SurveyorStudy,
    SwimmingPool,
    Tank,
    TextMinor: Text,
    TextPhe: Text,
    TextRegular: Text,
    TextTitle: Text,
    Trap,
    TrapDoor: Trap,
    Ventilation,
    Well,
    Window
}