/* eslint-disable react/no-array-index-key */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { activateData } from 'redux-thunk-data'

import { assignDrawing } from 'redux/reducers/drawing'
import selectAuthorizedColorTags from 'redux/selectors/selectAuthorizedColorTags'
import { selectColorByTag } from 'utils/drawing/selectors'
import { initialSelectorTool } from 'utils/drawing/tools'
import { createStrictlyIncreasingDate } from 'utils/format_date'
import svgsByName from 'utils/svgs'

const Item = ({ item }) => {
  const { dossierId } = useParams()
  const {
    label,
    name,
    tag,
    svg
  } = item
  const SVGComponent = svgsByName[svg]
  const dispatch = useDispatch()

  const drawing = useSelector(state => state.drawing) || {}
  const {
    color,
    tool,
    uuids
  } = drawing
  const { name: toolName } = tool
  const isSelected = item === drawing[toolName]

  const authorizedColorTags = useSelector(selectAuthorizedColorTags)

  const handleClick = useCallback(() => {
    if (toolName === 'color' && uuids.length) {
      const activities = uuids.map(uuid => ({
        dateCreated: createStrictlyIncreasingDate(),
        entityIdentifier: uuid,
        localDossierId: dossierId,
        modelName: 'Measurement',
        patch: {
          colorTag: item.tag
        }
      }))
      dispatch(activateData(activities))
    }

    const nextDrawing = {
      showPans: false,
      [toolName]: item
    }


    const forceColorToBeInNextAuthorizedColorTags = item.colorTags &&
                                                    (!color ||
                                                    !item.colorTags.includes(color.tag))
    if (forceColorToBeInNextAuthorizedColorTags) {
        nextDrawing.color = selectColorByTag(item.colorTags[0])
    }

    if (toolName === 'color') {
      nextDrawing.tool = initialSelectorTool
    }

    dispatch(assignDrawing(nextDrawing))
  }, [color, dispatch, dossierId, item, toolName, uuids])


  let isDisabled = item.disabled
  if (typeof isDisabled === 'undefined' && toolName === 'color' && authorizedColorTags) {
    isDisabled = !authorizedColorTags.includes(tag)
  }


  return (
    <button
      className={classnames('item', name, {
        'is-disabled': isDisabled,
        'is-selected': isSelected
      })}
      disabled={isDisabled}
      onClick={handleClick}
      type="button"
    >
      {SVGComponent && <SVGComponent />}
      <div>
        {label.split('\n').map((line, index) => (<p key={index}>
          {line}
        </p>))}
      </div>
    </button>
  )
}

Item.propTypes = {
  item: PropTypes.shape().isRequired
}

export default Item
