import React from 'react'

import Field from 'components/layout/form/Field'


const Evacuation = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="evacuation"
    >
      {'Evacuation I'}
    </h1>
    <Field name="evacuationPropertyElectricPortalPresent" />
    <Field name="evacuationPropertyElectricPortalHasBackupMode" />
  </div>
)


export default Evacuation
