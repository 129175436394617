export const ASSIGN_MAP = 'ASSIGN_MAP'

const initialState = {
  maxLat: null,
  maxLon: null,
  minLat: null,
  minLon: null,
  zoom: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_MAP:
      return { ...state, ...action.patch }
    default:
      return state
  }
}

export const assignMap = patch => ({
  patch,
  type: ASSIGN_MAP
})
