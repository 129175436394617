import createCachedSelector from 're-reselect'

import selectMeasurementsSortedWithItemByDossierId from './selectMeasurementsSortedWithItemByDossierId'


const mapArgsToCacheKey = (state, dossierId) => dossierId || ''


export default createCachedSelector(
  selectMeasurementsSortedWithItemByDossierId,
  measurements => {
    if (!measurements) return

    const measurementsWithComputedProperties = measurements.map(m => ({...m}))

    measurementsWithComputedProperties
      .filter(measurement => ['surveyor-study'].includes(measurement.itemName))
      .forEach(measurement => {
        if (measurement.flooring) {
          measurement.flooringType = measurement.flooring
        } else if (measurement.surveyorName) {
          measurement.flooringType = measurement.surveyorName
        }
      })

    measurementsWithComputedProperties.filter(m => m.itemName === 'swimming-pool').forEach(measurement => {
      const surveyorMeasurement = measurements.find(m => m.id === measurement.swimmingPoolSurveyorId)
      if (!surveyorMeasurement) return
      measurement.baseFlooring = surveyorMeasurement.flooring
      measurement.fromGroundHeight = 0
      measurement.swimmingPoolSurveyorRef = surveyorMeasurement.surveyorName
      if (surveyorMeasurement.surveyorComment !== undefined) {
        measurement.swimmingPoolSurveyorRef += ' - ' + surveyorMeasurement.surveyorComment
      }
      
    })

    measurementsWithComputedProperties.forEach(measurement => {
      measurement.altitude = computeAltitude(measurement, measurementsWithComputedProperties)
    })

    measurementsWithComputedProperties.forEach(measurement => {
      const isIntermediateFloor = measurement.flooring && measurement.baseFlooring
      if (isIntermediateFloor){
        measurement.baseFlooringAltitude = measurement.altitude - measurement.fromGroundHeight
      }

      if (measurement.dossier.highestWaterMark && measurement.altitude) {
        measurement.waterHeight = parseFloat((Math.max((measurement.dossier.highestWaterMark - measurement.altitude), 0)).toFixed(2))
        measurement.isUnderWater = measurement.waterHeight > 0 ? true : false
      }

      if (measurement.maxCeilingHeight && measurement.minCeilingHeight) {
        measurement.meanCeilingHeight = (measurement.maxCeilingHeight + measurement.minCeilingHeight) / 2
      }

      measurement.openingWidth = measurement.openingWidth ||
                                 measurement.portalWidth ||
                                 measurement.windowWidth
    })

    return measurementsWithComputedProperties
  })(mapArgsToCacheKey)


  function computeAltitude(measurement, measurements){
    if (!measurement) return

    if (measurement.surveyorAltitude) {
      return measurement.surveyorAltitude
    }

    const baseFlooringAltitude = computeAltitude(measurements.find(m =>
      (
        m.dossierId === measurement.dossier.id
        || m.sketchActivityIdentifier === measurement.sketchActivityIdentifier
        || m.sketchId === measurement.sketchId
      )
      && m.flooring && measurement.baseFlooring
      && m.flooring.toUpperCase() === measurement.baseFlooring.toUpperCase()), measurements)

    if(isNaN(baseFlooringAltitude)) return undefined

    measurement.altitude = measurement.fromGroundHeight + baseFlooringAltitude
    return measurement.altitude
  }
