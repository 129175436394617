import React from 'react'
import { useFormContext } from 'react-hook-form'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { yesNoOptions } from 'utils/diagnostic'
import { formatToUpperCase } from 'utils/form'


const tiedUpOptions = [
  { label: 'oui', value: 'yes' },
  { label: 'non', value: 'no' },
  { label: 'enterrée', value: 'burried' }
]

const tankTypeOptions = [
  { label: 'Intérieure', value: 'indoor-tank' },
  { label: 'Extérieure', value: 'outdoor-tank' }
]


const Tank = () => {
  const { readOnly } = useFormContext()


  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : CI1"
      />
      <RadiosField
        label="Type"
        name="itemName"
        options={tankTypeOptions}
        placeholder="Intérieure / extérieure"
      />
      <NumberField
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <SelectField
        label="Arrimée ? "
        name="tiedUp"
        options={tiedUpOptions}
        placeholder="Ex. : oui"
      />
      <SelectField
        label="Etanche ? "
        name="isWaterproof"
        options={yesNoOptions}
        placeholder="Ex. : oui"
      />
      <TextareaField
        label="Localisation : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}

export default Tank
