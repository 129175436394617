import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showModal } from 'redux-react-modals'
import { requestData } from 'redux-thunk-data'
import { v4 as uuidv4 } from 'uuid'

import { LocalAsset } from 'redux/persistor'
import { createStrictlyIncreasingDate } from 'utils/format_date'

import PhotoModal from './PhotoModal'

const Camera = ({ fileInputRef }) => {
  const dispatch = useDispatch()
  const { dossierId, sketchActivityIdentifier } = useParams()


  const handlePhotoChange = useCallback(async event => {
    const reader = new FileReader()
    const localAsset = new LocalAsset()
    await localAsset.write(reader.result)

    const uuid = uuidv4()

    reader.addEventListener('load', () => {
      dispatch(requestData({
        apiPath: '/__activities__?withAssetDataUrls=true',
        body: [{
          dateCreated: createStrictlyIncreasingDate(),
          dossierId,
          modelName: 'Measurement',
          patch: {
            photo: {
              folderPath: 'photos',
              localIdentifier: localAsset.identifier,
              type: '__ASSET__'
            },
            sketchActivityIdentifier
          },
          uuid
        }],
        handleSuccess: () => {
          dispatch(showModal('main',
            <PhotoModal uuid={uuid} />,
            { isUnclosable: true }))
        },
        method: 'POST',
        stateKey: 'activities',
        tag: '/__activities__-photo'
      }))
    }, false)
    reader.readAsDataURL(event.target.files[0])

  }, [dispatch, dossierId, sketchActivityIdentifier])


  return (
    <input
      alt="photo"
      className="camera"
      onChange={handlePhotoChange}
      ref={fileInputRef}
      type="file"
    />
  )
}


export default Camera
