import { Label, Tag, Text } from 'konva'

import styles from 'utils/styles'

import patchify from './patchify'
import { selectColorByTag } from './selectors'

const OFFSET_X = 10
const OFFSET_Y = 14


export default (node, {
  colorTag,
  handleChange,
  index,
  label,
  type
}, config) => {
  const { layers, stage } = config
  const measurementLayer = layers[1]
  let x
  let y
  let centerX
  let centerY

  if ((!label || !label.trim()) && !index) return
  const { labelX, labelY } = node.attrs

  if (type === 'line') {
    centerX = ((node.attrs.points[0] + node.attrs.points[2]) / 2)
    centerY = ((node.attrs.points[1] + node.attrs.points[3]) / 2)
  } else {
    centerX = node.attrs.x
    centerY = node.attrs.y
  }

  if (labelX && labelY) {
    x = labelX
    y = labelY
  } else if (centerX && centerY){
    x = centerX - OFFSET_X
    y = centerY - OFFSET_Y
  } else {
    x = node.attrs.x - OFFSET_X
    y = node.attrs.y - OFFSET_Y
  }

  const color = selectColorByTag(colorTag)
  const fill = color
    ? styles[color.name]
    : 'black'

  const labelizerNode = new Label({
    draggable: true,
    fill: 'white',
    x,
    y
  })

  const tagNode = new Tag({
    cornerRadius: 100,
    fill: 'white',
    opacity: 0.8,
  })
  labelizerNode.add(tagNode)

  const textNode = new Text({
    fill,
    fontFamily: styles['font-family-sans-serif'],
    lineHeight: 0.25,
    padding: 6,
    text: label || index
  })
  labelizerNode.add(textNode)

  const logDragEvent = event => {
    if (!window.dragEvents) { window.dragEvents = [] }
    window.dragEvents.push({
      ...event,
      timeStamp: event.evt?.timeStamp
    })
  }

  labelizerNode.on('dragstart', event => {
    logDragEvent(event)
    stage.isDraggingNode = true
    stage.draggingPointerPosition = null
    stage.draggingNode = null
    tagNode.setAttr('fill', 'grey')
    textNode.setAttr('fill', 'white')
  })

  labelizerNode.on('dragmove', event => {
    logDragEvent(event)
    stage.draggingPointerPosition = stage.getPointerPosition()
    stage.draggingNode = event.currentTarget
  })

  labelizerNode.on('dragend', event => {
    logDragEvent(event)
    tagNode.setAttr('fill', 'white')
    textNode.setAttr('fill', fill)
    measurementLayer.batchDraw()
    if (stage.isFreezingDraggingNode) {
      stage.isFreezingDraggingNode = false
    }
    stage.draggingPointerPosition = null
    let { x: labelX, y: labelY } = event.currentTarget.attrs
    handleChange(event, patchify(type, {
      centerX,
      centerY,
      labelX,
      labelY
    }, config))
    stage.isDraggingNode = false
  })

  node.labelizerNode = labelizerNode

  return labelizerNode
}
