import React from 'react'

import useLocationURL from 'components/hooks/useLocationURL'
import { API_URL } from 'utils/config'


const Oauth2Link = () => {
  const locationURL = useLocationURL()
  const from = locationURL.searchParams.get('from') || locationURL.origin

  return (
    <a
      className="oauth2-link"
      href={`${API_URL}/users/signin/oauth2?from=${from}`}
    >
      {'S\'identifier avec Google'}
    </a>
  )
}

export default Oauth2Link
