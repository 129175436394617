import patchify from '../../patchify'


const TYPE = 'text'


export default config => {
  const {
    handleChange,
    stage
  } = config

  stage.on('click tap', event => {
    const { x, y } = stage.getPointerPosition()
    const pointPatch = {
      text: 'Double-tap pour éditer.',
      type: TYPE,
      x,
      y,
      width: 100
    }
    handleChange(event, patchify(TYPE, pointPatch, config))
  })
}
