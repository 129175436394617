import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'redux-react-modals'


const OfflineModal = () => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() =>
    dispatch(closeModal('main')), [dispatch])


  return (
    <div className="offline-modal">
      <div className="title">
        {'Une connexion wifi ou mobile est nécessaire pour sauvegarder. Connectez-vous puis réessayez.'}
      </div>
      <div className="controls">
        <button
          className="ok"
          onClick={handleClick}
          type="button"
        >
          {'Ok'}
        </button>
      </div>
    </div>
  )
}

export default OfflineModal
