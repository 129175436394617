import React from 'react'
import { useSelector } from 'react-redux'

import Pan from './Pan'


const Pans = () => {

  const { tool, showPans } = useSelector(state =>
    state.drawing)
  const { name: toolName, pans } = tool || {}
  if (!showPans || !pans) return null


  return (
    <div className={`pans ${toolName}`}>
      {pans.filter(pan => !pan.isHidden).map((pan, index) => (
        <Pan
          key={index}
          pan={pan}
        />))}
    </div>
  )
}

export default Pans
