import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector} from 'react-redux'

import Toast from "components/layout/Toast"

import useBackup from "./hooks/useBackup"
import useBackupLayout from "./hooks/useBackupLayout"
import useDuplication from "./hooks/useDuplication"
import { DuplicatingToastContent, BackupToastContent, BackupState} from './ToastContent'

const BackgroundProcesses = () => {

    useBackup()

    useDuplication()

    const { isDuplicating } = useSelector(state => state.background_processes.duplication)
    const {handleBackupToastContent, showBackupToast} = useBackupLayout()

    const [backupState, setBackupState] = useState(BackupState.default)

    useEffect(() => {setBackupState(handleBackupToastContent())}, [handleBackupToastContent])

    const toastContent = isDuplicating ? DuplicatingToastContent : BackupToastContent[backupState]

    const showToast = isDuplicating || showBackupToast

    if (!showToast) return null

    return (
      <Toast content={toastContent} />
    )
}

export default BackgroundProcesses