import { Text } from 'konva'
import destination from '@turf/destination'

import styles from 'utils/styles'

import {
  allCoordsNotEmpty,
  distanceBetweenPoints,
  toPointFeature
} from '../../distance'
import { selectColorByTag } from '../../selectors'


export default (positionPatch, config) => {
  const { latLngToKonvaPoint, nodeItem } = config
  const {
    colorTag,
    lat,
    lon,
    text,
    width
  } = positionPatch
  const {
    fill,
    fontSize,
    fontStyle,
    fontWeight,
  } = nodeItem

  if (!allCoordsNotEmpty(lat, lon)) {
    console.error('Invalid properties for text : ', { lat, lon })
    return
  }

  const westPosition = [lat, lon]
  const westFeature = toPointFeature(westPosition)

  const eastPoint = destination(westFeature, width, 0)

  const eastPosition = eastPoint.geometry.coordinates
  const westKonvaPoint = latLngToKonvaPoint(westPosition)
  const eastKonvaPoint = latLngToKonvaPoint(eastPosition)

  const color = selectColorByTag(colorTag)
  const stroke = color
    ? styles[color.name]
    : 'black'

  const node = new Text({
    ...westKonvaPoint,
    fill,
    fontFamily: styles['font-family-sans-serif'],
    fontSize,
    fontStyle,
    fontWeight,
    stroke,
    strokeWidth: 0,
    text,
    width: distanceBetweenPoints(westKonvaPoint,
                                 eastKonvaPoint)
   })

   return node
}


export const handleStopText = (event, node, config) => {
  const {
    handleChange,
    layers,
    stage
  } = config
  const measurementLayer = layers[1]
  const container = stage.container()
  container.tabIndex = null
  document.body.focus()
  handleChange(event, { text: node.attrs.text })
  node.setAttr('fontStyle', 'normal')
  node.off('mouseover')
  measurementLayer.batchDraw()

  stage.isTextingNode = false
  stage.textingNode = null
}


export const handleStartText = (event, node, config) => {
  const {
    layers,
    stage
  } = config
  const measurementLayer = layers[1]
  const container = stage.container()
  container.tabIndex = 1
  container.focus()

  node.setAttr('fontStyle', 'italic')
  node.on('mouseover', () => {
    document.body.style.cursor = 'text'
  })

  measurementLayer.batchDraw()

  stage.isTextingNode = true
  stage.textingNode = node
}
