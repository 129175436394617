import { Line } from 'konva'

import styles from 'utils/styles'

import { distanceBetweenPoints } from '../../distance'
import patchify from '../../patchify'


const MIN_DISTANCE = 25
const MIN_SIDE_DISTANCE = MIN_DISTANCE / Math.sqrt(2)
const TYPE = 'line'


export default config => {
  const {
    color,
    handleChange,
    item,
    layers,
    stage,
    tool
  } = config
  const { dash, strokeWidth } = item || tool

  let isPaint = false
  const measurementLayer = layers[1]
  let node

  stage.on('mousedown touchstart', event => {
    if (isPaint || event.target.attrs.isAnchor) return
    isPaint = true
    const { x, y } = stage.getPointerPosition()
    node = new Line({
      dash,
      points: [x, y, x + MIN_SIDE_DISTANCE, y + MIN_SIDE_DISTANCE],
      stroke: color
        ? styles[color.name]
        : 'black',
      strokeWidth
    })
    measurementLayer.add(node)
  })

  stage.on('mousemove touchmove', () => {
    if (!isPaint || !node || stage.isTransformingNode) return
    const { x, y } = stage.getPointerPosition()
    const newPoints = node.points()
                          .slice(0, 2)
                          .concat([x, y])
    node.points(newPoints)
    stage.isDrawingNode = true
    measurementLayer.batchDraw()
  })

  stage.on('mouseup touchend', event => {
    if (!isPaint || !node || stage.isTransformingNode) return
    isPaint = false
    const points = [...node.attrs.points]

    const pointA = {x: points[0], y: points[1]}
    const pointB = {x: points[2], y: points[3]}
    if (distanceBetweenPoints(pointA, pointB) < MIN_DISTANCE) {
      if (pointA.x === pointB.x) {
        points[2] = pointB.x
        points[3] = points[1] + Math.sign(points[3]-points[1]) * MIN_DISTANCE
      } else if (pointA.y === pointB.y) {
        points[2] = points[2] + Math.sign(points[2]-points[0]) * MIN_DISTANCE
        points[3] = pointA.y
      } else {
        const slope = (pointB.y - pointA.y) / (pointB.x - pointA.x)
        const yAtZero = pointA.y - pointA.x * slope
        const a = (1 + Math.pow(slope, 2))
        const b = 2 * (slope * (yAtZero - pointA.y) - pointA.x)
        const c = Math.pow(pointA.x, 2) + Math.pow(yAtZero - pointA.y, 2) - Math.pow(MIN_DISTANCE, 2)
        const delta = Math.pow(b, 2) - 4 * a * c
        points[2] = (- b + Math.sign(points[2]-points[0]) * Math.sqrt(delta)) / (2 * a)
        points[3] = slope * points[2] + yAtZero
      }
    }
    const pointPatch = {
      points,
      type: TYPE
    }
    stage.isDrawingNode = false
    handleChange(event, patchify(TYPE, pointPatch, config))
  })
}
