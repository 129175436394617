import React from 'react'
import { useFormContext } from 'react-hook-form'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import { formatToUpperCase } from 'utils/form'


const boardTypeOptions = [
  { label: 'Disjoncteurs', value: 'circuit-breakers' },
  { label: 'Fusibles', value: 'fuses' },
  { label: 'Disjoncteurs et fusibles', value: 'circuit-breakers-and-fuses' },
  { label: 'Fusibles céramiques', value: 'ceramic-fuses' },
  { label: 'Hors norme', value: 'beyond-norm' },
  { label: 'Autre', value: 'other' },
]


const ElecBoar = () => {
  const { readOnly } = useFormContext()


  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : T1"
      />
      <NumberField
        label="Hauteur au sol : "
        name="fromGroundHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <TextField
        format={formatToUpperCase}
        label="Plancher ou T.N. de référence : "
        name="baseFlooring"
        placeholder="Ex. : N0"
      />
      <SelectField
        className="with-border"
        label="Type de tableau : "
        name="boardType"
        options={boardTypeOptions}
        placeholder="Choisissez dans la liste"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}

export default ElecBoar
