import React from 'react'

import Field from 'components/layout/form/Field'


const Hazard = () => {
  return (
    <div className="hazard entry-group">
      <h1
        className="entry-title"
        id="hazard2"
      >
        {'Aléa II'}
      </h1>
      <Field
        label="Cote PHE retenue :"
        name="highestWaterMark"
      />
      <Field name="highestWaterMarkSource" />
      <Field name="highestWaterMarkJustification" />
    </div>
  )
}

export default Hazard
