import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deleteRequests, requestData } from 'redux-thunk-data'

import Icon from 'components/layout/Icon'
import LoadingButton from 'components/layout/LoadingButton'

const slicedString = (string, count) =>
  string.slice(0, count) + (string.length > count ? '...' : '')


const FilesForm = ({
  cancelFiles,
  files,
  setFiles
}) => {
  const dispatch = useDispatch()
  const { folderId } = useParams()

  const { errors, isFail, isPending, isSuccess } = useSelector(state =>
    state.requests['/files']) || {}
  const { FILE_EXISTS } = errors || {}
  const error = FILE_EXISTS && `${FILE_EXISTS[0][0]} existe déjà à cet endroit.`


  const handleCancel = useCallback(event => {
    event.stopPropagation()
    setFiles(undefined)
  }, [setFiles])

  const handleReset = useCallback(event => {
    event.stopPropagation()
    dispatch(deleteRequests('/files'))
    setFiles(undefined)
  }, [dispatch, setFiles])

  const handleSend = useCallback(event => {
    event.stopPropagation()
    const body = new FormData()
    files.forEach((file, index) => {
      body.append(`file[${index}]`, file)
    })
    dispatch(requestData({
      tag: '/files',
      apiPath: `/files?folderId=${folderId}`,
      body,
      encode: 'multipart/form-data',
      fetchTimeout: null,
      method: 'POST'
    }))
  }, [dispatch, files, folderId])


  return (
    <>
      <div className="cta">
        Glissez vos fichiers ici
      </div>
      <div className="sub-cta">
        ou <span>cliquez pour les sélectionner</span>
      </div>
      {files && (
        <>
          <ul className="files">
            {(files || []).map(file => (
              <li key={file.lastModified}>
                {slicedString(file.name, 25)}
              </li>
            ))}
          </ul>
          <div className="control">
            {
              (isFail || isSuccess)
              ? (
                <>
                  {isSuccess
                    ? (
                        <div className="success">
                          <Icon name="ico-checked.png" />
                          <p>C'est envoyé !</p>
                        </div>
                      )
                    : (
                        <div className="error">
                          <p>{error || 'Désolé, le téléchargement a échoué !'}</p>
                        </div>
                      )
                  }
                  <button
                    className="reset"
                    onClick={handleReset}
                  >
                    Nouvel Envoi
                  </button>
                </>
              )
              : (
                <>
                  <LoadingButton
                    className="send"
                    disabled={isPending}
                    isLoading={isPending}
                    onClick={handleSend}
                  >
                    Envoyer
                  </LoadingButton>
                  <button
                    className={classnames('cancel', { 'is-seethrough': isPending })}
                    onClick={handleCancel}
                  >
                    Annuler
                  </button>
                </>
              )
            }
          </div>
        </>
      )}
    </>
  )
}


FilesForm.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })),
  setFiles: PropTypes.func.isRequired
}

export default FilesForm
