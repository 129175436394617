import React from 'react'

import AddressForm from 'components/layout/AddressForm'
import ContactForm from 'components/layout/ContactForm'


const Owner = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="owner"
    >
      {'Propriétaire'}
    </h1>
    <div className="entry-subtitle">
      <div>
        {'Contact Propriétaire'}
      </div>
    </div>
    <ContactForm
      labelSuffix=" propriétaire :"
      prefixFieldName="owner."
    />
    <div className="entry-subtitle">
      <div>
        {'Coordonnées du Propriétaire'}
      </div>
    </div>
    <AddressForm prefixFieldName="owner.address." />
  </div>
)

export default Owner
