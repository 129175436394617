import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { ROOT_ASSETS_PATH } from 'utils/config'


const Logo = ({ className, withLink, withName }) => {
  const src = `${ROOT_ASSETS_PATH}/logo${withName ? '_with_name' : ''}.png`

  const isActiveFrom = useCallback(() => false, [])

  if (withLink) {
    return (
      <NavLink
        className={`logo ${className || ''}`}
        isActive={isActiveFrom}
        to="/"
      >
        <img
          alt="Logo"
          src={src}
        />
      </NavLink>
    )
  }
  return (
    <div className={`logo ${className || ''}`}>
      <img
        alt="Logo"
        src={src}
      />
    </div>
  )
}


Logo.defaultProps = {
  className: '',
  withLink: false,
  withName: false
}

Logo.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
  withName: PropTypes.bool
}

export default Logo
