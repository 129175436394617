import React from 'react'

import Field from 'components/layout/form/Field'
import CheckboxField from 'components/layout/form/fields/CheckboxField'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectMeasurementsByDossierIdAndItemName from 'redux/selectors/selectMeasurementsByDossierIdAndItemName'



const Heating = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="heating"
    >
      {'Chauffage I'}
    </h1>
    <Field name='heatProductionType' />
    <Field name='heatDiffusionType' />
    <MeasurementBlocks
      itemName="air-conditioner"
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {(measurement) => (
        <Block>
          <Property
            extraLabel=" du groupe extérieur"
            name="fromGroundHeight"
          />
          {!(measurement.fromGroundHeight || measurement.fromGroundHeight === 0) && (
            <CheckboxField
              checked="true"
              disabled="true"
              label="Non mesurée"
              name="fromGroundHeightNotMeasured"
            />
          )}
          {!(measurement.fromGroundHeight || measurement.fromGroundHeight === 0) && measurement.fromGroundHeightNotMeasuredReason && (
            <Property
              name="fromGroundHeightNotMeasuredReason"
            />
          )}
          <Property
            label="TN de référence du groupe extérieur"
            name="baseFlooring"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="boiler"
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la chaudière"
            name="fromGroundHeight"
          />
          <Property
            extraLabel=" de la chaudière"
            name="baseFlooring"
          />
          <Property
            name="rejectionFromGroundHeight"
          />
          <Property
            name="rejectionBaseFlooring"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="tank"
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne/cuve"
            name="fromGroundHeight"
          />
          <Property
            extraLabel=" de la citerne/cuve"
            name="baseFlooring"
          />
          <Property
            extraLabel=" citerne/cuve"
            name="tiedUp"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="indoor-tank"
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" de la citerne/cuve"
            name="fromGroundHeight"
          />
          <Property
            extraLabel=" de la citerne/cuve"
            name="baseFlooring"
          />
          <Property
            extraLabel=" citerne/cuve"
            name="tiedUp"
          />
        </Block>)}
    </MeasurementBlocks>
    <MeasurementBlocks
      itemName="hot-water-tank"
      select={selectMeasurementsByDossierIdAndItemName}
    >
      {() => (
        <Block>
          <Property
            extraLabel=" du chauffe-eau"
            name="fromGroundHeight"
          />
          <Property
            extraLabel=" du chauffe-eau"
            name="baseFlooring"
          />
        </Block>)}
    </MeasurementBlocks>
  </div>
)

export default Heating
