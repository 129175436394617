import React, { useCallback } from 'react'

import useServiceWorker from '../../providers/useServiceWorker'

const UpdateMessage = () => {
  const { assetsUpdateReady, updateAssets } = useServiceWorker() || {}

  const onClick = useCallback(() => {
    updateAssets()
  }, [updateAssets])

  return assetsUpdateReady ? (
    <div className="alertAppUpdate">
      <span>
        {'Une nouvelle version est disponible !'}
      </span>
      <button 
        className="alertAppUpdateBtn" 
        onClick={onClick} 
        type="button"
      >
        {'Mettre à jour'}
      </button>
    </div>
  ) : (
    <>
    </>
  )
}

export default UpdateMessage
