import { createSelector } from 'reselect'

import selectNamedNotSoftDeletedSketchesByDossierId from 'redux/selectors/selectNamedNotSoftDeletedSketchesByDossierId'
import { sketchPrefixNames } from 'utils/diagnostic'

const selectSketchNameOptionsByDossierId = createSelector(
    selectNamedNotSoftDeletedSketchesByDossierId,
    sketches => sketchPrefixNames.map(sketchPrefixName => {
      const matchingSketches = sketches.filter(sketch => sketch.name.startsWith(sketchPrefixName))
      matchingSketches.sort((s1, s2) =>
        parseInt(s1.name.split(' #')[1]) > parseInt(s2.name.split(' #')[1])
          ? -1
          : 1)
      const matchingSketch = matchingSketches[0]
      const nextSketchIndex = matchingSketch
        ? parseInt(matchingSketch.name.split(' #')[1]) + 1
        : 1
      const sketchName = `${sketchPrefixName} #${nextSketchIndex || 1}`
      return { label: sketchName, value: sketchName }
    })
)

export default selectSketchNameOptionsByDossierId