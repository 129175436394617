import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { selectItemByName } from 'utils/drawing/selectors'

import Block, { Property } from './Block'
import MeasurementContext from './MeasurementContext'

const { Provider } = MeasurementContext


const MeasurementBlocks = ({
  children,
  itemName,
  select,
  showHasItem
}) => {
  const { dossierId } = useParams()

  const measurements = useSelector(state => {
    const result = select(state, dossierId, itemName)
    if (typeof result !== 'undefined' && !Array.isArray(result)) {
      return [result]
    }
    return result
  }, [dossierId, itemName]) || []
  const firstMeasurement = measurements[0]

  const { label } = selectItemByName(itemName) || {}


  const values = useMemo(() =>
    measurements.map(measurement => ({
      itemName,
      measurement
    })), [itemName, measurements])

  return (
    <>
      {showHasItem && (
        <div className="measurement-block">
          <div className="measurement-block-properties">
            <div className="measurement-property">
              <label className="measurement-property-label">
                {`Présence ${label} : `}
              </label>
              <div className="measurement-property-value">
                {typeof firstMeasurement === 'undefined'
                  ? 'Non'
                  : 'Oui'}
              </div>
            </div>
          </div>
        </div>)}
      {values.map(value => (
        <Provider
          key={value.measurement.activityIdentifier}
          value={value}
        >
          {typeof children === 'function'
            ? children(value.measurement)
            : children}
        </Provider>
      ))}
    </>
  )
}


MeasurementBlocks.defaultProps = {
  children: null,
  itemName: null,
  showHasItem: false
}

MeasurementBlocks.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  itemName: PropTypes.string,
  select: PropTypes.func.isRequired,
  showHasItem: PropTypes.bool,
}


export { Block, Property }
export default MeasurementBlocks
