import PropTypes from 'prop-types'

import addressType from './addressType'
import createGeojsonType from './geojsonType'
import parcelType from './parcelType'
import ppriType from './ppriType'

export default PropTypes.shape({
  address: createGeojsonType(addressType),
  parcel: createGeojsonType(parcelType),
  ppri: createGeojsonType(ppriType),
})
