import React from 'react'
import { useFormContext } from 'react-hook-form'

import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'


const FloatingElement = () => {
  const { readOnly } = useFormContext()


  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : EA1"
      />
      <TextField
        label="Nom de l'objet : "
        name="floatingElementName"
        placeholder="Ex. : stock de bois"
      />
      <RadiosField
        isBoolean
        label="Arrimé "
        name="tiedUp"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}

export default FloatingElement
