import { formatToUpperCase } from 'utils/form'

const openingTypeOptions = [
  { label: 'Vélux à pivot central', value: 'central pivot' },
  { label: 'Vélux projection', value: 'projection' },
  { label: 'Terrasse', value: 'patio' },
  { label: 'Balcon', value: 'balcony' },
  { label: 'Fenêtre', value: 'window' }
]

const MezzaAtticEntrance = [
    {
        componentType: "TextField",
        label: "Description : ",
        name: "flooringDescription",
    },
    {
        componentType: "NumberField",
        label: "Hauteur : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,2",
        unitLabel: 'm',
    },
    {
        componentType: "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType: "TextField",
        format: {formatToUpperCase},
        label: "Plancher de l'étage desservi : ",
        name: "flooring",
        placeholder: "Ex. : N1",
    },
    {
        label : "Refuge : ",
        subComponent : 8,
    },
    {
        componentType: "CheckboxField",
        label: "Ces marches conduisent à un étage ou potentiel refuge ",
        name: "toRefuge",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Type d'espace refuge : ",
        name: "refugeSpaceType",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Alimentation électrique : ",
        name: "refugeSpaceWithElectricity",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Superficie de l'espace : ",
        name: "refugeSpaceArea",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Accès principal : ",
        name: "refugePrincipalEntrance",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["showRefugePrincipalEntranceComment", "toRefuge"],
        label : "Commentaire : ",
        name : "refugePrincipalEntranceComment",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Présence d'ouvrant, balcon ou terrasse permettant l'évacuation : ",
        name: "refugeEvacuationPossibleViaOpening",
    },
    {
        componentType: "MeasurementField",
        dependsOn : ["toRefuge"],
        label : "Espace refuge conforme au référentiel du Ministère : ",
        name: "refugeSpaceUpToCode",
    },
    {
        label : "Ouvrant Evacuation : ",
        subComponent : 4,
    },
    {
        componentType: "SelectField",
        label: "Type d'ouverture : ",
        name: "openingType",
        options: openingTypeOptions,
        placeholder: "Choisissez le type",
    },
    {
        componentType: "RadiosField",
        isBoolean: true,
        label: "Présence d'un volet roulant électrique ",
        name: "hasElectricShutter",
    },
    {
        componentType: "NumberField",
        label: "Hauteur de l'ouvrant d'évacuation : ",
        name: "openingLength",
        placeholder: "Ex. : 0,2",
        unitLabel: 'm',
    },
    {
        componentType: "NumberField",
        label: "Largeur de l'ouvrant d'évacuation : ",
        name: "openingWidth",
        placeholder: "Ex. : 0,2",
        unitLabel: 'm',
    },
    {
        label : "Hauteur sous plafond à l'étage : ",
        subComponent : 2,
    },
    {
        componentType: "NumberField",
        label: "Min : ",
        name: "minCeilingHeight",
        placeholder: "Ex. : 0,2",
        unitLabel: 'm',
    },
    {
        componentType: "NumberField",
        label: "Max : ",
        name: "maxCeilingHeight",
        placeholder: "Ex. : 0,2",
        unitLabel: 'm',
    },
    {
        componentType: "TextareaField",
        label: "Commentaire : ",
        name: "comment",
    },
    {
        componentType: "PictureField",
        folderPath : "photos",
        label : "Photo : ",
        name : "photo",
    }
]

export default MezzaAtticEntrance