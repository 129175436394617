import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showModal } from 'redux-react-modals'

import Icon from 'components/layout/Icon'
import MeasurementForm from 'components/layout/MeasurementForm'


const ToForm = ({ measurement, formTitle }) => {
  const dispatch = useDispatch()
  const { activityIdentifier } = measurement

  const handleClick = useCallback(() =>
    dispatch(showModal('main',
      <MeasurementForm
        activityIdentifier={activityIdentifier}
        formTitle={formTitle}
        withToSketchMeasurement
      />,
    { isUnclosable: true })), [activityIdentifier, dispatch, formTitle])

  return (
    <button
      className="to-form"
      onClick={handleClick}
      type="button"
    >
      <Icon name="ico-30-pencil.svg" />
    </button>
  )
}

ToForm.defaultProps = {
  formTitle: null,
}

ToForm.propTypes = {
  formTitle: PropTypes.string,
  measurement: PropTypes.shape().isRequired,
}

export default ToForm
