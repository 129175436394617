import React from 'react'

import DossierInformation from './DossierInformation'
import Hazard from './Hazard'
import Owner from './Owner'
import Specialist from './Specialist'


const Preparation = () => (
  <>
    <DossierInformation />
    <Owner />
    <Specialist />
    <Hazard />
  </>
)

export default Preparation
