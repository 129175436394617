import React, { useEffect, useMemo, useState } from 'react'

import { register, unregister } from 'workers/cache'

// see solution here : https://github.com/facebook/create-react-app/issues/5316
export const ServiceWorkerContext = React.createContext()

export const ServiceWorkerProvider = props => {
  const [assetsCached, setAssetsCached] = useState(false)
  const [assetsUpdateReady, setAssetsUpdateReady] = useState(false)
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)

  const value = useMemo(
    () => ({
      assetsCached,
      assetsUpdateReady,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener('statechange', event => {
            if (event.target.state === 'activated') {
              window.location.reload()
            }
          })
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
      },
    }),
    [assetsUpdateReady, assetsCached, waitingServiceWorker]
  )

  useEffect(() => {
    register({
      onSuccess: () => {
        setAssetsCached(true)
      },
      onUpdate: async registration => {
        setWaitingServiceWorker(registration.waiting)
        setAssetsUpdateReady(true)
      },
    })
    return () => {
      unregister()
    }
  }, [])

  return (
    <ServiceWorkerContext.Provider 
      value={value} 
      {...props} 
    />
  )
}
