/* eslint
  react/jsx-one-expression-per-line: 0 */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { Controller, useFormContext } from 'react-hook-form'


import FieldError from '../FieldError'


const TextareaField = ({
  className,
  disabled,
  label,
  maxLength,
  name,
  noAsterisk,
  placeholder,
  readOnly: propsReadOnly,
  required,
  rows,
  validate,
  ...textareaProps
}) => {
  const {
    control,
    register,
    watch,
    ...formContext
  } = useFormContext()
  const readOnly = formContext.readOnly || propsReadOnly
  const value = watch(name) || ''
  const valueLength = value.length
  const rules = useMemo(() => ({
    maxLength,
    required,
    validate
  }), [maxLength, required, validate])


  const renderTextarea = useCallback(({onChange, value}) => (
    <TextareaAutosize
      className="field-entry"
      id={name}
      onChange={event =>
        onChange(maxLength
          ? event.target.value.slice(0, maxLength)
          : event.target.value)}
      placeholder={readOnly ? '' : placeholder}
      readOnly={readOnly}
      rows={rows}
      value={value || ''}
    />
  ), [maxLength, name, placeholder, readOnly, rows])


  return (
    <div className={classnames('textarea-field', name, { readonly: readOnly })}>
      <label
        className={classnames('field-label', { empty: !label })}
        htmlFor={name}
      >
        <span>{label}</span>
        {required && !readOnly && !noAsterisk && (
          <span className="field-asterisk">
            {'*'}
          </span>)}
        {maxLength && !readOnly && (
          <span className="counter">
            {' '}
            ({valueLength} / {maxLength}){' '}
          </span>
        )}
      </label>
      <div className="field-control">
        <div className="field-value">
          <span className="field-inner">
            <Controller
              control={control}
              defaultValue=''
              name={name}
              render={renderTextarea}
              rules={rules}
            />
          </span>
        </div>
        <FieldError name={name} />
      </div>
    </div>
  )
}

TextareaField.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  maxLength: null,
  noAsterisk: false,
  placeholder: 'Entrer le texte',
  required: false,
  rows: 5,
  validate: null
}

TextareaField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  noAsterisk: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  rows: PropTypes.number,
  validate: PropTypes.func
}

export default TextareaField
