import { API_URL } from './config'

export const fetchBlobFromApi = (path, method, body) => {
  const bodyParams = body ? { body: JSON.stringify(body) } : {}

  method = method || 'GET'

  const init = {
    credentials: 'include',
    method: method,
    ...bodyParams,
  }

  return fetch(`${API_URL}${path}`, init).then(response => {
    return response
  })
}
