export let lastDateCreated = 0

export const createStrictlyIncreasingDate = (date = undefined) => {
    let currentDate = (date || new Date(Date.now())).getTime()
    if (lastDateCreated >= currentDate) {
        const dateTimePlusOneMillisecond = new Date(lastDateCreated).getTime() + 1
        currentDate = dateTimePlusOneMillisecond
    }
    lastDateCreated = currentDate
    return new Date(currentDate).toISOString()
}