import arrow from './arrow'
import circle from './circle'
import line from './line'
import polygon from './polygon'
import rectangle from './rectangle'
import symbol from './symbol'
import text from './text'
import triangle from './triangle'

export default {
  arrow,
  circle,
  line,
  polygon,
  rectangle,
  symbol,
  text,
  triangle
}
