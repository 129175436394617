import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { closeNotification } from 'redux/reducers/notification'

const RedirectionToDrawingModal = ({closeModal, dispatch}) => {
  const history = useHistory()
  const { programmeId, dossierId, sketchActivityIdentifier } = useParams()
  const sketchesPath = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches/${sketchActivityIdentifier}`

  const handleOkClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
    history.push(sketchesPath)
  }, [dispatch, sketchesPath, history, closeModal])

  const handleCancelClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
  }, [dispatch, closeModal])

  return (
    <div className="redirection-to-drawing-modal">
      <div className="title">
        Merci de retourner sur la vue “Plan” pour déclencher le téléchargement.
      </div>
      <div className="controls">
        <button
          className="no"
          onClick={handleCancelClick}
          type="button"
        >
          Annuler
        </button>
        <button
          className="yes"
          onClick={handleOkClick}
          type="button"
        >
          Compris
        </button>
      </div>
    </div>
  )
}

RedirectionToDrawingModal.propTypes = {
  closeModal : PropTypes.func.isRequired,
  dispatch : PropTypes.func.isRequired
}

export default RedirectionToDrawingModal
