import { formatToUpperCase } from 'utils/form'

const AirConditioner = [
    {
        componentType : "TextField",
        label: "Etiquette : ",
        name: "label",
        placeholder: "Ex. : EA1",
    },
    {
        componentType : "NumberField",
        disabled: "fromGroundHeightNotMeasured",
        label: "Hauteur au sol : ",
        name: "fromGroundHeight",
        placeholder: "Ex. : 0,95",
        unitLabel: 'm',
    },
    {
        componentType : "CheckboxField",
        label: "Non mesurée",
        name: "fromGroundHeightNotMeasured",
    },
    {
        componentType : "TextareaField",
        dependsOn : ["fromGroundHeightNotMeasured"],
        label: "Expliquez pourquoi : ",
        name: "fromGroundHeightNotMeasuredReason",
        placeholder: "Ex. : Hors de portée",
    },
    {
        componentType : "TextField",
        format: {formatToUpperCase},
        label: "Plancher ou T.N. de référence : ",
        name: "baseFlooring",
        placeholder: "Ex. : N0",
    },
    {
        componentType : "PictureField",
        folderPath: "photos",
        label: "Photo : ",
        name: "photo",
    }
]

export default AirConditioner