import React from 'react'

import Field from 'components/layout/form/Field'
import MeasurementBlocks, { Block, Property } from 'components/layout/MeasurementBlocks'
import selectRefugeMeasurementsByDossierId from 'redux/selectors/selectRefugeMeasurementsByDossierId'


const RefugeSpace = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="refuge"
    >
      {'Espace(s) refuge I'}
    </h1>
    <Field
      name="refugeSpacePresentFromMeasurements"
      readOnly
    />
    <MeasurementBlocks select={selectRefugeMeasurementsByDossierId}>
      {() => (
        <Block withTitle>
          <Property name="refugeSpaceType" />
          <Property name="refugeSpaceWithElectricity" />
          <Property
            extraLabel=" plancher par rapport au RdC"
            name="fromGroundHeight"
          />
          <Property name="minCeilingHeight" />
          <Property name="maxCeilingHeight" />
          <Property
            extraLabel=" de l'ouvrant d'évacuation"
            label="Hauteur"
            name="openingLength"
          />
          <Property
            extraLabel=" de l'ouvrant d'évacuation"
            name="openingWidth"
          />
          <Property name="refugeEvacuationPossibleViaOpening" />
          <Property name="refugeSpaceArea" />
          <Property name="refugePrincipalEntrance" />
        </Block>)}
    </MeasurementBlocks>
  </div>
)


export default RefugeSpace
