import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import NumberField from 'components/layout/form/fields/NumberField'
import PictureField from 'components/layout/form/fields/PictureField'
import RadiosField from 'components/layout/form/fields/RadiosField'
import SelectField from 'components/layout/form/fields/SelectField'
import TextareaField from 'components/layout/form/fields/TextareaField'
import TextField from 'components/layout/form/fields/TextField'
import selectMeasurementsWithIndexByDossierIdAndRowsName from 'components/layout/MeasurementTables/Table/Rows/selectMeasurementsWithIndexByDossierIdAndRowsName'



const SwimmingPool = () => {
  const { readOnly } = useFormContext()
  const { dossierId } = useParams()

  const measurements = useSelector(state =>
    selectMeasurementsWithIndexByDossierIdAndRowsName(state, dossierId, 'floorings-and-surveyor-studies'),
    [dossierId, 'floorings-and-surveyor-studies'])

  let surveyorData = []
  measurements.filter(m => m.surveyorName).forEach(m => {
    surveyorData.push({
      label: m.surveyorComment ? m.surveyorName + ' - ' + m.surveyorComment : m.surveyorName,
      value: m.id,
    })
  })

  return (
    <>
      <TextField
        label="Etiquette : "
        name="label"
        placeholder="Ex. : EA1"
      />
      <SelectField
        label="Côte de la margelle : "
        name="swimmingPoolSurveyorId"
        options={surveyorData}
        placeholder="Choisissez dans la liste"
      />
      <RadiosField
        isBoolean
        label="Balisée conformément au PPRi "
        name="ppriMarked"
      />
      <NumberField
        label="Hauteur barrière piscine : "
        name="gateHeight"
        placeholder="Ex. : 0,95"
        unitLabel='m'
      />
      <RadiosField
        isBoolean
        label="Autre protection "
        name="otherProtection"
      />
      <TextField
        label="Autre balisage ?"
        name="otherMarking"
      />
      <TextField
        label="Longueur à baliser : "
        name="markedLength"
        placeholder="Ex. : 10x5 m"
      />
      <TextareaField
        label="Commentaire : "
        name="comment"
      />
      <PictureField
        folderPath="photos"
        label={`Photo${readOnly ? '' : ' (facultatif)'} :`}
        name="photo"
      />
    </>
  )
}


export default SwimmingPool
