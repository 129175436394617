import circle from './circle'
import line from './line'
import rectangle from './rectangle'
import symbol from './symbol'
import text from './text'
import triangle from './triangle'


export default {
  arrow: line,
  circle,
  line,
  polygon: line,
  rectangle,
  symbol,
  text,
  triangle
}
