import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { LocalAsset } from 'redux/persistor'
import selectSketchNameOptionsByDossierId from 'redux/selectors/selectSketchNameOptionsByDossierId'
import { createStrictlyIncreasingDate } from 'utils/format_date.js'

import { duplicateSketchName } from './utils/formatText.js'

const useCreateNewSketch = (config = {}) => {
    const { dossierId : givenDossierId, sketch } = config
    const { dossierId : sketchDossierId, name } = sketch || {}
    const dossierId = givenDossierId  ? givenDossierId : sketchDossierId
    const sketchNameOptions = useSelector(state => selectSketchNameOptionsByDossierId(state, dossierId))

    const handleNewSketchNameDuplication = useCallback(() => {
        return duplicateSketchName(name, sketchNameOptions)
    },[name, sketchNameOptions])

    const handleCreateNewSketchWithBackground = useCallback(async (newSketchName) => {
        if (!sketch) return
        const { maxLat, maxLon, minLat, minLon, zoom, background, figureScale } = sketch
        if (!maxLat || !maxLon || !minLat || !minLon || !zoom || !background) return

        if (!newSketchName) {
            const generatedNewSketchName = handleNewSketchNameDuplication()
            if (!generatedNewSketchName) return
            else newSketchName = generatedNewSketchName
        }

        const uuid = uuidv4()

        const sketchIsAlreadyInLocalStorage = 'localIdentifier' in background
        if (sketchIsAlreadyInLocalStorage) {
            const backgroundLocalAsset = new LocalAsset(background.localIdentifier)
            background.dataUrl = await backgroundLocalAsset.read()
        }
        const backgroundDataUrl = background.dataUrl
        const newBackgroundLocalAsset = new LocalAsset()
        await newBackgroundLocalAsset.write(backgroundDataUrl)

        const activity = {
            dateCreated: createStrictlyIncreasingDate(),
            entityIdentifier: uuid,
            localDossierId: dossierId,
            modelName: 'Sketch',
            patch: {
                background: {
                    folderPath: 'croquis/fonds',
                    localIdentifier: newBackgroundLocalAsset.identifier,
                    type: '__ASSET__'
                },
                dossierId,
                figureScale,
                maxLat,
                maxLon,
                minLat,
                minLon,
                name: newSketchName,
                zoom
            }
        }

        return { activity, uuid }

    }, [sketch, dossierId, handleNewSketchNameDuplication])

    return {handleCreateNewSketchWithBackground, handleNewSketchNameDuplication}
}

export default useCreateNewSketch
