import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { createElement, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { showModal } from 'redux-react-modals'
import { activateData } from 'redux-thunk-data'

import Icon from 'components/layout/Icon'
import { createStrictlyIncreasingDate } from 'utils/format_date'

import ConfirmDeleteSketchModal from './ConfirmDeleteSketchModal'
import DownloadSnapshot from './DownloadSnapshot'
import DuplicateSketch from './DuplicateSketch'

const SketchItem = ({ previousSketch, setDisabledScroll, sketch }) => {
  const ref = useRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const { dossierId, programmeId, sketchActivityIdentifier: routeSketchActivityIdentifier } = useParams()
  const { activityIdentifier: sketchActivityIdentifier, name } = sketch
  const { activityIdentifier: previousSketchActivityIdentifier } = previousSketch || {}
  const sketchesPathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`
  const sketchPathname = `${sketchesPathname}/${sketchActivityIdentifier}`
  const redirectPathnameAfterDelete = `${sketchesPathname}/${previousSketchActivityIdentifier || 'creation'}`
  const isCreation = sketchActivityIdentifier === 'creation'
  const isSelected = routeSketchActivityIdentifier === sketchActivityIdentifier
  const isDownloading = useSelector(state => state.background_processes.download.isDownloading)

  const [optionsAlignLeft, setOptionsAlignLeft] = useState(false)
  const [optionsPosition, setOptionsPosition] = useState(null)

  const handleDeleteSketch = useCallback(() => {
    const dateCreated = createStrictlyIncreasingDate()
    const activity = {
      dateCreated,
      entityIdentifier: sketchActivityIdentifier,
      localDossierId: dossierId,
      modelName: 'Sketch',
      patch: { isSoftDeleted: true }
    }
    dispatch(activateData([activity]))
    history.push(redirectPathnameAfterDelete)
  }, [dispatch, dossierId, history, redirectPathnameAfterDelete, sketchActivityIdentifier])


  const handleDeleteClick = useCallback(() => {
    dispatch(showModal('main', createElement(
      ConfirmDeleteSketchModal,
      {
        name,
        onConfirm: () => handleDeleteSketch()
      }, null), { isUnclosable: true }))
  },[dispatch, handleDeleteSketch, name])

  const handleOptionsClick = useCallback(event => {
    if (optionsPosition === null) setDisabledScroll(true)
    else setDisabledScroll(false)
    // positionner la div de façon responsive pour contourner overflow-y : hidden de sketches-bar
    const sketchesBarElement = event.target.closest('.sketches-bar')
    const sketchesBarOffset = sketchesBarElement.offsetLeft
    const optionsWidth = document.querySelector("div.options").clientWidth
    const toggleInfos = event.target.getBoundingClientRect()
    const toggleOffsetLeft = toggleInfos.left
    const toggleWidth = toggleInfos.width
    let position = (toggleOffsetLeft + toggleWidth) - (sketchesBarOffset + optionsWidth)
    if (toggleOffsetLeft <= optionsWidth){
      position = toggleOffsetLeft - sketchesBarOffset
      if (!optionsAlignLeft) setOptionsAlignLeft(true)
    } else if (optionsAlignLeft) setOptionsAlignLeft(false)
    
    setOptionsPosition(optionsPosition =>
      optionsPosition === null
        ? position
        : null)
  }, [setOptionsPosition, setOptionsAlignLeft, optionsAlignLeft, optionsPosition, setDisabledScroll])

  const handleSelectClick = useCallback(() =>
    history.push(sketchPathname), [history, sketchPathname])


  return (
    <div
      className="sketch-item"
      ref={ref}
    >
      <button
        className={classnames('select', {
          'is-creation': isCreation,
          'is-selected': isSelected
        })}
        onClick={handleSelectClick}
        type="button"
      >
        {isCreation
          ? <Icon name="ico-30-circled-plus.svg" />
          : (
            <span>
              {name || '<Sans nom>'}
            </span>)}
      </button>
      {isSelected && (
        <>
          <button
            className={classnames('toggle-downward', { selected: isSelected})}
            onClick={handleOptionsClick}
            type="button"
          >
            <Icon name="ico-30-chevron-down.svg" /> 
          </button>
          <div
            className={classnames("options-container", {"align-left" : optionsAlignLeft, 'not-displayed' : (optionsPosition === null) })}
            style={{ left: optionsPosition || 0}}
          >
            <button
              className='options-toggle'
              onClick={handleOptionsClick}
              type="button"
            >
              <Icon
                name="ico-30-chevron-down.svg"
                style={{ transform: "rotate(180deg)" }}
              /> 
            </button>
            <div
              className='options'
            >
              <DuplicateSketch
                isDownloading={isDownloading}
                sketch={sketch}
              />
              <DownloadSnapshot isDownloading={isDownloading} />
              <button
                disabled={isDownloading}
                onClick={handleDeleteClick}
                type="button"
              >
                <div className='icon-container'>
                  <Icon name="ico-30-close.svg" />
                </div>
                Effacer cette vue
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}


SketchItem.defaultProps = {
  previousSketch: null,
  setDisabledScroll: null
}

SketchItem.propTypes = {
  previousSketch: PropTypes.shape({
    activityIdentifier: PropTypes.string,
  }),
  setDisabledScroll: PropTypes.func,
  sketch: PropTypes.shape({
    activityIdentifier: PropTypes.string,
    name: PropTypes.string
  }).isRequired
}


export default SketchItem
