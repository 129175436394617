import React from 'react'

import MeasurementTables, { Column, Table } from 'components/layout/MeasurementTables'
import ToSketchTool from 'components/layout/ToSketchTool'


const PrincipalDoorFrames = () => (
  <div className="entry-group">
    <h1
      className="entry-title"
      id="principal-door-frames"
    >
      {'Huisseries principales I'}
    </h1>
    <MeasurementTables name="principal-door-frames">
      {measurement => (
        <Table>
          <Column name="comment" />
          <Column name="hasElectricalShutter" />
          <Column name="openingWidth" />
          <Column
            name="fromGroundHeight"
            suffixLabelColumn=" sur plancher"
          />
          <Column name="baseFlooring" />
        </Table>
      )}
    </MeasurementTables>
    <ToSketchTool name="figure">
      {'Ajouter une huisserie au croquis'}
    </ToSketchTool>
  </div>
)

export default PrincipalDoorFrames
