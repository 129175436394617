import PropTypes from 'prop-types'
import React from 'react'

import { ALABRI_URL } from 'utils/config'

const CreateDossierButton = ({ address, parcel, programme }) => {
  const { properties: addressProperties } = address
  const { number, street, postalCode, townCode } = addressProperties || {}
  const { code: parcelCode } = parcel
  let splitedStreet, streetType, streetName
  if (street !== undefined) {
    splitedStreet = street.split(" ")
    streetType = splitedStreet.splice(0, 1)
    streetName = splitedStreet.join(" ")
  }

  const dossierNumber = `${parcelCode}-`
  const createDossierURL = `${ALABRI_URL}/${programme['code']}/gestion/gestionDossier/ajouter?dossier_numero=${encodeURIComponent(dossierNumber)}&adresse_numero=${encodeURIComponent(number)}&adresse_type=${encodeURIComponent(streetType)}&adresse_voie=${encodeURIComponent(streetName)}&code_insee_postal=${encodeURIComponent(townCode)}:${encodeURIComponent(postalCode)}&parcelles=${encodeURIComponent(parcelCode)}&btnFileCreation=Ajouter`

  return (
    <a
      className="action"
      href={createDossierURL}
      rel="noopener noreferrer"
      target='_blank'
    >
      {`Créer un dossier ${programme['name']}`}
    </a>
  )
}

CreateDossierButton.propTypes = {
  address: PropTypes.shape().isRequired,
  parcel: PropTypes.shape().isRequired,
  programme: PropTypes.shape().isRequired,
}

export default CreateDossierButton
