import React from 'react'

import Field from 'components/layout/form/Field'


const MeasurementField = props => (
  <Field
    {...props}
    prefixPath="sketches[*].measurements[*]."
  />
)

export default MeasurementField
