import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { activateData, selectEntityByActivityIdentifier } from 'redux-thunk-data'

import SwitchButton from 'components/layout/SwitchButton'
import { assignDrawing } from 'redux/reducers/drawing'
import { createStrictlyIncreasingDate } from 'utils/format_date'
import { ReactComponent as SymbolsIcon } from 'utils/svgs/ico-30-symbols.svg'

const SMALL_SIZE = 20
const LARGE_SIZE = 30
const SMALL_FIGURE_SCALE = SMALL_SIZE / LARGE_SIZE

export default () => {
  const dispatch = useDispatch()
  const { dossierId, sketchActivityIdentifier } = useParams()


  const sketch = useSelector(state =>
    selectEntityByActivityIdentifier(state, sketchActivityIdentifier)) || {}
  const figureScale = sketch.figureScale || 1
  const initialState = figureScale === 1


  const handleChange = useCallback((event, isLeft) => {
    const figureScale = isLeft ? 1 : SMALL_FIGURE_SCALE
    const activity = {
      dateCreated: createStrictlyIncreasingDate(),
      entityIdentifier: sketchActivityIdentifier,
      localDossierId: dossierId,
      modelName: 'Sketch',
      patch: { figureScale }
    }
    dispatch(activateData([activity]))
  }, [dispatch, dossierId, sketchActivityIdentifier])


  const renderLeft = useCallback(() => (
    <SymbolsIcon
      height={LARGE_SIZE}
      width={LARGE_SIZE}
    />), [])

  const renderRight = useCallback(() => (
    <SymbolsIcon
      height={SMALL_SIZE}
      width={SMALL_SIZE}
    />), [])


  useEffect(() => {
    if (!figureScale) return
    dispatch(assignDrawing({ figureScale }))
  }, [dispatch, figureScale])


  return (
    <SwitchButton
      className='figure-scale-switch-button'
      initialState={initialState}
      onChange={handleChange}
      renderLeft={renderLeft}
      renderRight={renderRight}
    />
  )
}
