import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { closeModal } from 'redux-react-modals'

import { closeNotification } from 'redux/reducers/notification'
import selectLastSketchActivityIdentifierByDossierId from 'redux/selectors/selectLastSketchActivityIdentifierByDossierId'


const AskForOneMoreModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { programmeId, dossierId } = useParams()
  const sketchesPathname = `/diagnostiquer/programmes/${programmeId}/dossiers/${dossierId}/sketches`
  const creationSketchPathname = `${sketchesPathname}/creation`

  const lastSketchActivityIdentifier = useSelector(state =>
    selectLastSketchActivityIdentifierByDossierId(state, dossierId))
  const mainSketchPathname = `${sketchesPathname}/${lastSketchActivityIdentifier}`


  const handleNoClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
    history.push(mainSketchPathname)
  }, [dispatch, mainSketchPathname, history])

  const handleYesClick = useCallback(() => {
    dispatch(closeNotification())
    dispatch(closeModal('main'))
    history.push(creationSketchPathname)
  }, [creationSketchPathname, dispatch, history])


  return (
    <div className="ask-for-one-more-modal">
      <div className="title">
        Souhaitez-vous créer une vue supplémentaire ?
      </div>
      <div className="controls">
        <button
          className="no"
          onClick={handleNoClick}
          type="button"
        >
          Non
        </button>
        <button
          className="yes"
          onClick={handleYesClick}
          type="button"
        >
          Oui
        </button>
      </div>
    </div>
  )
}

export default AskForOneMoreModal
